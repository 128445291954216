<template>
  <el-dialog
    :title="dialog.title"
    width="700px"
    class="download-file"
    v-model="dialog.show"
    :before-close="() => {this.dialog.show = false}"
  >
  <div v-loading="isloading" class="clearfix">
    <ul>
      <li v-for="(item, index) in allList" :key="index" class="file-box">
        <span @click="handerOreview(item)" class="file-name">{{item.disName}}</span>
        <span @click="downLoadFile(item)"><ab-svg name="my-upload" class="one"></ab-svg></span>
      </li>
    </ul>
    <p v-if="!(allList && allList.length) && !isloading" style="text-align: center">暂无附件</p>
  </div>
  <template #footer>
    <span class="dialog-footer">
      <el-button type="primary" :loading="isDownLoad" :disabled="!(allList && allList.length)" @click="allDown">全部下载</el-button>
      <el-button @click="dialog.show = false">取 消</el-button>
    </span>
    </template>
    <ab-preview :preview="preview"></ab-preview>

  </el-dialog>
</template>

<script>
export default {
  name: 'ab-downloadFile',
  data () {
    return {
      list: [],
      allList: [],
      select: [],
      isReload: true,
      accept: '.bmp,.jpg,.png,.tif,.gif,.pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.txt,.zip,.rar',
      state: false,
      isSelect: true,
      checkAll: false,
      isloading: false,
      btnLoading: false,
      preview: {
        show: false
      },
      isDownLoad: false
    }
  },
  props: {
    dialog: {
      type: Object
    },
    isDelete: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    'dialog.show': function(val){
        if (val) {
        this.allList = []
        this.isloading = true
        this.getFileList()
      }
    }
  },
  methods: {
    allDown() {
      if (this.allList.length == 1) {
        this.downLoadFile(this.allList[0])
        return false
      }
      this.isDownLoad = true
      this.$post('/oms/billAttach/compress',
        { listData: this.allList, fileNameDisplayAmount: true }
      ).then((res) => {
        if (res && res.data.result && res.data.code === 'C0000') {
          this.$message({
            type: 'success',
            message: '打包下载成功！',
            grouping: true,
            offset: 380
          })
          let eleLink = document.createElement('a')
          eleLink.download = ''
          eleLink.style.display = 'none'
          // 字符内容转变成blob地址
          eleLink.href = res.data.result
          // 触发点击
          document.body.appendChild(eleLink)
          eleLink.click()
          // 然后移除
          document.body.removeChild(eleLink)
        }
      }).finally(e => {
        this.isDownLoad = false
      })
    },
    downLoadFile(item) {
        let eleLink = document.createElement('a')
        eleLink.download = ''
        eleLink.style.display = 'none'
        let dateTime = new Date()
        let fileUrl = ''
        if (item.fileUrl.indexOf('?') != -1) {
          fileUrl = item.fileUrl + '?v=' + dateTime.getTime()
        } else {
          fileUrl = item.fileUrl + '?=' + dateTime.getTime()
        }
        // 字符内容转变成blob地址
        eleLink.href = fileUrl
        // 触发点击
        document.body.appendChild(eleLink)
        eleLink.click()
        // 然后移除
        document.body.removeChild(eleLink)
    },
    getFileList () {
      let url = this.dialog.url || '/oms/billAttach/queryBySourceData'
      this.$post(url, this.dialog.data ? this.dialog.data : { sourceBillType: this.dialog.sourceBillType, sourceBillId: this.dialog.sourceBillId }).then(({data}) => {
        if (data.code == 'C0000') {
          this.allList = []
          for (const key in data.result) {
              this.allList = this.allList.concat(data.result[key])
          }
        }
      }).finally(e => {
        setTimeout(() => {
          this.isloading = false
        }, 200)
      })
    },
    getSuffix (obj) {
      let index = obj.lastIndexOf('.')
      let svg = obj.substring(index + 1).toLowerCase()
      return this.accept.indexOf(svg) == -1 ? 'accessory' : svg
    },
    handerDownload (item) {
      let eleLink = document.createElement('a')
      eleLink.download = ''
      eleLink.style.display = 'none'
      // 字符内容转变成blob地址
      eleLink.href = item.fileUrl
      // 触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
    },
    // handlerSelected (index) {
    //   let obj = this.list[index]
    //   if (obj.isSelect) {
    //     obj.isSelect = false
    //     this.select = this.select.filter(item => { return item.id !== obj.id })
    //   } else {
    //     obj.isSelect = true
    //     this.select.push(obj)
    //   }
    //   if (this.select.length === this.list.length) {
    //     this.checkAll = true
    //   } else {
    //     this.checkAll = false
    //   }

    //   this.reload()
    // },
    handerOreview (obj) {
      this.preview.fileUrl = obj.fileUrl
      this.preview.show = true
    },
    // handlerRemove () {
    //   if (this.select.length === 0) {
    //     this.$message.error('请先选择要删除的附件！')
    //     return false
    //   }
    //   let arry = []
    //   this.select.forEach(e => {
    //     arry.push(e.id)
    //   })
    //   this.$confirm('确定要删除吗，删除后无法恢复，请谨慎操作？', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.btnLoading = true
    //     this.$http({
    //       url: this.$http.adornUrl(window.SITE_CONFIG.contextPath + '/oms/billAttach/del'),
    //       method: 'post',
    //       data: { ids: arry }
    //     }).then((res) => {
    //       this.$message({
    //         type: 'success',
    //         message: '删除成功！'
    //       })
    //       this.getFileList()
    //     }).finally(e => {
    //       this.btnLoading = false
    //     }, 200)
    //   }).catch(() => { })
    // }
  }
}
</script>

<style lang="scss">
.download-file {
  .el-dialog {
    .el-dialog__header{
      border-bottom: none;
    }
    .el-dialog__body {
      padding: 30px 60px 20px 60px;
      min-height: 200px;
      max-height: 460px;
      .clearfix{
        min-height: 100px;
        max-height: 410px;
        overflow-y:auto;
      }
      ul{
        max-height: 410px;
        li{
          height: 40px;
          background-color: #f3f8fc;
          margin-bottom: 10px;
          padding: 0 20px 0 15px;
          font-size: 14px;
          line-height: 40px;
          .icon-svg{
            float: left;
            margin-top: 7px;
            width: 28px;
            height: 26px;
            margin-right: 11px;
          }
          i{
            float: right;
            margin-top: 15px;
            cursor: pointer;
            font-size: 10px;
          }
          .file-name{
            cursor: pointer;
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .file-box {
    width: 100%;
    height: 36px;
    line-height: 36px;
    padding: 0 10px;
    margin-bottom: 10px;
    background-color: #f0f2f5;
    border-radius: 6px;
    .icon-svg {
        float: right;
        width: 16px;
        cursor: pointer;
        margin-top: 3px;
    }
    .file-name {
        cursor: pointer;
        &:hover{
            text-decoration: underline;
            color: #0B7AFF;
        }
    }
  }
}
</style>
