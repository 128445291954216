import iconSvg from './icon-svg.vue'
import listQuery from './list-query.vue'
import timeSearch from './time-search.vue'
import tableCommon from './table-common.vue'
import pages from './pagination.vue'
import select from './select.vue'
import select2 from './select2.vue'
import issue from './issue/index.vue'
import title from './title.vue'
import couponDialog from './couponDialog.vue' //优惠券大弹窗
import recommended from './recommend.vue'//推荐有礼弹窗
import address from './address.vue'
import handleAds from './handleAddress.vue'
import dateTimePick from './date-time-picker.vue'
import history from './id-card-history.vue'
import aiMakeOrder from './ai-make-order.vue'
import immPreView from './imm_preview.vue'
import money from './money.vue'
import upload from './upload/file-upload.vue'
import revokeSign from './revoke-sign.vue'
import cotninueSign from './continue-sign.vue'
import logisticsInfo from './logisticsInfo.vue'
import logisticsStatus from './logistics-status.vue'
import downloadFile from './upload/downloadFile.vue'
import cropper from './cropper.vue'
import filebtn from './file_btn.vue'
import upload2 from './upload/file_upload-client.vue'
import boostLimit from './boost-limit.vue'
import attPreview from './upload/att-preview.vue'
import accountDialog from './account-dialog.vue'
import fileAlert from './upload/file_alert.vue'
import searchForm from './search-form.vue'
import pageHeads from './page-header.vue'
import status from './status.vue'
import analyDialog from './analy-dialog.vue'


const componentsLib = {
    components: [
        iconSvg,
        listQuery,
        timeSearch,
        tableCommon,
        pages,
        select,
        upload,
        title,
        select2,
        issue,
        couponDialog,
        recommended,
        address,
        handleAds,
        dateTimePick,
        history,
        aiMakeOrder,
        immPreView,
        money,
        revokeSign,
        cropper,
        filebtn,
        cotninueSign,
        logisticsInfo,
        downloadFile,
        cropper,
        logisticsStatus,
        upload2,
        boostLimit,
        attPreview,
        accountDialog,
        fileAlert,
        searchForm,
        pageHeads,
        status,
        analyDialog,
    ]
}
export default componentsLib