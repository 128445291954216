<template>
  <el-dialog title="修改物料信息" width="700px" @open="openInit" v-model="showVisible"
    :close-on-click-modal="false" @close="cancel">
    <div class="row-data">原始需求：{{data.bomData.rowData}}</div>
    <el-form :model="updateData" :rules="rules" ref="updateData" label-position="top">
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="型号" prop="productModel">
            <el-input  v-model="updateData.productModel" placeholder="请输入型号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="品类" prop="brand">
            <el-input  v-model="updateData.productType" placeholder="请输入品类"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="品牌" prop="brand">
            <el-input  v-model="updateData.brand" placeholder="请输入品牌"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="封装" prop="productPackaging">
            <el-input  v-model="updateData.productPackaging" placeholder="请输入封装"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="参数" prop="parameter">
            <el-input  v-model="updateData.parameter" placeholder="请输入参数"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="单用量" prop="aloneNum">
            <el-input type="number" v-model.number="updateData.aloneNum" placeholder="请输入单用量"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="物料编号" prop="materialNo">
            <el-input  v-model="updateData.materialNo" placeholder="请输入物料编号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="confirm">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'update-dialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
      required: true
    },
  },
  computed: {
    showVisible: {
      get() {
        return this.visible
      }
    },
  },
  data() {
    return {
      tableLoading: false,
      changeFlag: false,
      updateData: {
      },
      rules: {
        aloneNum: [
          { required: true, message: '单用量不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    openInit() {
      this.updateData = Object.assign({}, this.data.bomData)
    },
    confirm() {
      this.$refs.updateData.validate(valid => {
        if (valid) {
          this.$emit('confirm', this.updateData, this.changeFlag)
          this.cancel()
        }
      })
    },
    cancel() {
      this.$emit('cancel')
    },
  }
}
</script>

<style lang="scss">
.row-data {
  margin: 0 12px 24px;
  font-size: 16px;
}
</style>
