<template>
    <div class="property-list" v-loading="isLoading">
        <!-- 我的预付贷款 -->
        <div>
            <p class="title">我的预付贷款</p>
            <div class="content">
                <!-- 余额 -->
                <div class="balance">
                    <p class="money"><span>￥</span>{{ prepaidObj ? prepaidObj.availableBalance : 0 }}</p>
                    <p class="text">预付贷款余额</p>
                    <div class="btn" v-if="prepaidObj && prepaidObj.accountStatus == 'pass'">
                        <el-button class="xz-base-btn" type="primary" @click="onChangePrepay">预付贷款</el-button>
                        <el-button plain @click="onChangeRefundPay" class="pub-btn">退回贷款</el-button>
                    </div>
                    <div class="apply-btn" v-else>
                        <el-button class="xz-base-btn" type="primary" @click="isApplied = true">申请预付贷款</el-button>
                    </div>
                </div>
                <!-- 账户信息 -->
                <div class="account">
                    <p>账户状态：{{ prepaidObj ? prepaidObj.accountStatusText : '-' }}</p>
                    <p>公司名称：{{ prepaidObj ? prepaidObj.companyName : '-' }}</p>
                    <p>公司税号：{{ prepaidObj ? prepaidObj.companyTaxNo : '-'  }}</p>
                    <div class="btn">
                        <el-button class="update" link disabled>更改预付贷款公司</el-button>
                        <el-button type="primary" link @click="onChangePayPassword">修改支付密码</el-button>
                        <el-button type="primary" link @click="onChangePayLimit">设置支付限额</el-button>
                    </div>
                    <el-link :href="href" target="_blank" class="download">
                        <ab-svg name="download" class="icon"></ab-svg>
                        <span>下载协议</span>
                    </el-link>
                </div>
            </div>
        </div>
        <!-- 交易记录 -->
        <div>
            <transaction-records-table></transaction-records-table>
        </div>
        <!-- 预付贷款 -->
        <prepayment-loan-dialog :visible="visiblePrepayData.isShow" :data="visiblePrepayData.data" @cancel="visiblePrepayData.isShow = false" @confirm="handlePrepaySubmit"></prepayment-loan-dialog>
        <!-- 退回贷款 -->
        <refund-loan-dialog :visible="visibleRefundData.isShow" :data="visibleRefundData.data" @cancel="visibleRefundData.isShow = false" @confirm="handleRefundSubmit"></refund-loan-dialog>
        <!-- 修改支付密码 -->
        <change-password-dialog :visible="visiblePasswordData.isShow" :data="visiblePasswordData.data" @cancel="visiblePasswordData.isShow = false" @confirm="handlePasswordSubmit"></change-password-dialog>
        <!-- 设置支付限额 -->
        <set-payment-limit-dialog :visible="visibleLimitData.isShow" :data="visibleLimitData.data" @cancel="visibleLimitData.isShow = false" @confirm="handleLimitSubmit"></set-payment-limit-dialog>
        <!-- 申请预付贷款 -->
        <apply-prepay-loan-dialog :visible="isApplied" @cancel="isApplied = false" @confirm="handleApplySubmit"></apply-prepay-loan-dialog>
    </div>
</template>

<script>
import PrepaymentLoanDialog from './box/prepayment-loan-dialog.vue';
import ApplyPrepayLoanDialog from './box/apply-prepay-loan-dialog.vue';
import RefundLoanDialog from './box/refund-loan-dialog.vue';
import ChangePasswordDialog from './box/change-password-dialog.vue';
import SetPaymentLimitDialog from './box/set-payment-limit-dialog.vue';
import TransactionRecordsTable from './box/transaction-records-table.vue';

export default {
    name: 'PropertyList',
    components: {
        PrepaymentLoanDialog,
        ApplyPrepayLoanDialog,
        RefundLoanDialog,
        ChangePasswordDialog,
        SetPaymentLimitDialog,
        TransactionRecordsTable,
    },
    data() {
        return {
            userInfo: this.$utils.getSessionStorage('userInfo')?.user.phone, // 用户信息
            isLoading: false,
            isApplied: false, // 是否申请预付贷款
            prepaidObj: {}, // 账户信息
            visiblePrepayData: { // 预付贷款 弹窗
                isShow: false,
                data: {}
            },
            accountStatus: [], // 账户状态常量
            visibleRefundData: { // 退回贷款 弹窗
                isShow: false,
                data: {},
            },
            visiblePasswordData: { // 修改支付密码 弹窗
                isShow: false,
                data: {},
            },
            visibleLimitData: { // 设置支付限额 弹窗
                isShow: false,
                data: {},
            },
            href: '', // 下载协议链接
        };
    },
    beforeMount() {
        if (!this.userInfo) {
            this.$initSessionStorageData.getUserInfo().then(res => {
                this.userInfo= res.user.phone;
            });
            return;
        }
    },
    mounted() {
        this.initData();
        this.getAccount();
    },
    methods: {
        // 获取账户状态常量
        initData() {
            this.$get('/orders/constants', { type: 'accountStatus' }, 'api').then(({data}) => {
                if (data.code === 'C0000') {
                    this.accountStatus = data.result;
                }
            });
        },
        // 获取账户状态
        getStatusText(value) {
            return this.accountStatus.find(item => item.value === value)?.label;
        },
        // 获取账户信息
        getAccount() {
            this.isLoading = true;
            this.$get('/accounts', undefined, 'api').then(({data}) => {
                if (data.code === 'C0000') {
                    this.prepaidObj = data.result;
                    if (this.prepaidObj && this.prepaidObj.paymentProtocolFileUrl) {
                      this.href = this.prepaidObj.paymentProtocolFileUrl;
                    }
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        // 申请预付贷款提交
        handleApplySubmit(model) {
            this.$post('/accounts', model, 'api').then(({data}) => {
                if (data.code === 'C0000') {
                    this.getAccount();
                }
            }).finally(() => {
                this.isApplied= false;
            });
        },
        onChangePrepay(){
            this.visiblePrepayData.isShow = true;
            this.visiblePrepayData.data = this.prepaidObj;
        },
        // 预付贷款 设置金额
        handlePrepaySubmit(model) {
            this.$post('/accounts/recharge', { amount: model.amount }, 'api').then(({data}) => {
                if (data.code === 'C0000') {
                    this.$message.success(data.message);
                }
            }).finally(() => {
                this.visiblePrepayData.isShow = false;
            });
        },
        onChangeRefundPay(){
            this.visibleRefundData.isShow = true;
            this.visibleRefundData.data = this.prepaidObj;
        },
        // 退回贷款提交
        handleRefundSubmit(model) {
            this.$post('/accounts/refund', model, 'api').then(({data}) => {
                if (data.code === 'C0000') {
                    this.$message.success(data.message);
                }
            }).finally(() => {
                this.visibleRefundData.isShow = false;
            });
            this.getAccount();
        },
        onChangePayPassword() {
            this.visiblePasswordData.isShow = true;
            this.visiblePasswordData.data.userPhone = this.userInfo;
        },
        // 修改支付密码提交
        handlePasswordSubmit(model) {
            this.$post('/accounts/change-password', model, 'api').then(({data}) => {
                if (data.code === 'C0000') {
                    this.$message.success(data.message);
                }
            }).finally(() => {
                this.visiblePasswordData.isShow = false;
            });
        },
        onChangePayLimit(){
            this.visibleLimitData.isShow = true;
            this.visibleLimitData.data.userPhone = this.userPhone;
        },
        // 设置支付限额提交
        handleLimitSubmit(model) {
            this.$post('/accounts/set-payment-limit', model, 'api').then(({data}) => {
                if (data.code === 'C0000') {
                    this.$message.success(data.message);
                }
            }).finally(() => {
                this.visibleLimitData.isShow = false;
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.property-list {
    .title {
        font-size: 34px;
        padding: 40px;
        border-bottom: 5px solid #f6f6f6;
        color: #666;
    }
    .content {
        display: flex;
        border-bottom: 5px solid #f6f6f6;
        & > div {
            flex: 1;
            box-sizing: border-box;
            border-right: 3px solid #f6f6f6;
        }
        .balance {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 60px;
            .money {
                font-size: 40px;
                color: #666;
                font-weight: bold;
                & > span {
                    font-size: 24px;
                    font-weight: normal;
                }
            }
            .text {
                color: #999;
                margin-top: 8px;
            }
            .btn {
                margin-top: 14px;
            }
            .apply-btn {
                margin-top: 14px;
            }
        }
        .account {
            display: flex;
            flex-direction: column;
            padding: 60px;
            color: #999;
            & > p {
                margin-bottom: 8px;
                font-size: 16px;
            }
            .btn {
                margin-top: 12px;
                .update{
                    color: #c0c4cc;
                }
            }
            .download {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 130px;
                padding: 10px;
                border: 1px solid #e8eaec;
                color: #333;
                box-sizing: border-box;
                border-radius: 2px;
                margin-top: 12px;
                .icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }
                &:hover {
                    --el-link-hover-text-color: unset;
                }
            }
        }
    }
}
</style>
