<template>
    <div>
    <el-dialog
  :title="fileTitle"
  v-model="preview.show"
  :append-to-body="true"
  width="80%"
  :lock-scroll="true"
  top="0vh"
  class="imm-dialog-body"
  :close-on-click-modal="!this.preview.isView"
  :before-close="handleClose">
  <div class="main" :style="{height: screenHeight - 270 + 'px'}" >
    <iframe id="immPreviweFile" v-if="!imgSrc" :src="iframeSrc" frameborder="0" style="height:100%"></iframe>
    <img :src="imgSrc" v-if="imgSrc" class="pic" />
    
  
  </div>
    <slot name="desc"></slot>
    <template #footer>
        <span  class="dialog-footer" v-if="preview.isFooter">
            <slot name="button"></slot>
        </span>
    </template>
</el-dialog>
        
  <div class="yx-scale" v-show="imgSrc">
        <i class="el-icon-remove-outline item-scale" @click="checkedImg(1)"></i>
        <div class="scale-text" id="scale_text">{{picSize}}%</div>
        <i class="el-icon-circle-plus-outline item-scale" @click="checkedImg(2)"></i>
    </div>
    </div>
</template>

<script>
export default {
    name: 'ab-preview',
    data () {
        return {
            fileTitle: '附件预览',
            iframeSrc: '',
            imgSrc: '',
            screenHeight: '',
            picSize: 100
        }
    },
    props: {
        preview: {
            type: Object,
            default: {}
        }
    },
    watch: {
        'preview.show': {
            handler (val, oldVal) {
                if (val) {
                    this.screenHeight = this.preview.height || window.screen.height
                    let lastIndex = this.preview.fileUrl.lastIndexOf('/') + 1
                    this.fileTitle = this.preview.fileUrl.substring(lastIndex)
                    let flag = false
                    let imgArry = ['.bmp','.jpg','.png','.tif','.gif']
                    imgArry.map(e => {
                        if (this.preview.fileUrl.indexOf(e) != -1) {
                            setTimeout(() => {
                                this.imgSrc = this.preview.fileUrl
                            }, 50)
                            flag = true
                        }
                    })
                    setTimeout(() => {
                        if (!flag) {
                            if (this.preview.isView) {
                                this.fileTitle = this.preview.fileTitle
                                this.iframeSrc = this.preview.fileUrl
                            } else {
                                let startIndex = 0
                                let type = 'scp'
                                if (this.preview.fileUrl.indexOf('z-easy.com.cn') != -1) {
                                    startIndex = this.preview.fileUrl.indexOf('z-easy.com.cn/') + 14
                                    type = 'z-easy'
                                } else {
                                    startIndex = this.preview.fileUrl.indexOf('cicsc.com/') + 10
                                }
                                this.iframeSrc = 'https://scp-imm.cicsc.com/?file=' + this.preview.fileUrl.substring(startIndex) + '&v=' + new Date().getTime() + '&type=' + type
                            }
                        }
                    }, 50)
                } else {
                    this.iframeSrc = ''
                }
            },
            deep: true
        }
    },
    methods: {
        handleClose () {
            this.$emit('handleClose')
            this.imgSrc = ''
            this.preview.show = false
        },
        checkedImg (str) {
            let image=document.getElementsByClassName("pic")[0]
            if (str == 2) {
                image.style.height=image.height*1.1+'px'
                image.style.width=image.width*1.1+'px'
                this.picSize += 10 
            } else {
                image.style.height=image.height/1.1+'px';
		        image.style.width=image.width/1.1+'px';
                this.picSize -= 10
            }
            
        }
    }
}
</script>

<style lang="scss">
.imm-dialog-body {
    overflow-x: auto !important;
    .el-dialog__body{
        padding: 5px
    }
    #immPreviweFile {
        width: 100%;
        height: 100%;
    }
    .file-preview-desc {
      text-align: center;
      line-height: 30px;
      .el-checkbox__label {
        font-size: 14px;
      }
    }
}
    .yx-scale {
        position: fixed;
        bottom: 15%;
        right: 15%;
        z-index: 99999;
        .item-scale {
            display: inline-block;
            font-size: 22px;
            cursor: pointer;
        }
        .scale-text {
            display: inline-block;
            font-size: 16px;
        }
    }
</style>