export default [
  {
    id: 200,
    path: ['import-orders-list', 'import-orders-detail'],
    list: [
      {
        id: 201,
        question: '我的入仓号是什么？',
        answer: `{warehouseNumber}`
      },
      {
        id: 202,
        question: '一咻仓库地址及联系方式是什么？',
        answer: `香港仓：香港沙田安平街8号偉達中心8樓801室 | 联系人：羅占偉 00852-26089369<br />
        收货要求：随货发票箱单一式两份，备注入仓号，周一-周五下午5点半前，周六上午12点前<br />
        请下载<a data-api="getAccessionPdf">《收货指引》</a>`
      },
      {
        id: 203,
        question: '如何知道进口费用？',
        answer: `订单提交后到订单详情【账单信息】查看；如还未有数据，请耐心等候系统付款通知`
      },
      {
        id: 204,
        question: '什么是垫税可用额度？',
        answer: `指我司可以垫付进口税款的最高金额。`
      },
      {
        id: 205,
        question: '垫税额度怎么扣除？',
        answer: `物料未归类前按金额X海关汇率X0.13系统暂估；归类后，按归类后实际的税率系统自动更新`
      },
      {
        id: 206,
        question: '额度不够怎么办？',
        answer: `点击【提额】，填写要申请的总额度，举例：如原额度是5W，现希望提高到7W，则填写7W`
      },
      {
        id: 207,
        question: '提额要提供什么资料？',
        answer: `50W以下提供上年12个月及近1个月企业纳税申请表（所得税及增值税）,50W以上需补充的材料以批复意见为准`
      },
      {
        id: 208,
        question: '提额审批时间多久？',
        answer: `根据客户提供的资料情况，通常2小时出结果，紧急情况请找专属客服沟通。`
      },
      {
        id: 209,
        question: '交货方式是什么？',
        answer: `指要报关的货物如何交给一咻，请根据情况选择<br />
        有6种方式<br />
        送货到香港仓（非快递送货）：安排香港当地物流送货上门到香港仓，<a data-path="/warehouse-info">查看香港地址</a><br />
        送货到香港仓（快递送货）：安排快递送货上门到香港仓，<a data-path="/warehouse-info">查看香港地址</a><br />
        需香港本地提货：货物在香港供应商处，需一咻安排提货<br />
        送货到国内机场：货物从境外直发到国内指定的机场<br />
        送货到保税区：货物从境外发到指定的保税区`
      },
      {
        id: 210,
        question: '快递送货到香港仓，为什么会产生香港清关费？',
        answer: `不是所有的快递送货都会产生香港清关费，只有从外地到香港的快递才会产生，外地进入香港的货物均需在货物抵达香港14天内向香港海关申报，因此会产生香港清关费`
      },
      {
        id: 211,
        question: '香港清关费如何收取？',
        answer: `按订单货值阶梯收取并按当月海关汇率折算成人民币，2万美金以下70港币/单，2-10万100港币/单，10-20万200港币/单,20万美金以上250万港币，如有多个快递，则会产生多个清关费。`
      },
      {
        id: 212,
        question: '我要香港提货怎么办？',
        answer: `点【新增提货】，填写提货地址、联系人、电话、提货时间、预计箱数，订单提交后，提货信息将自动通知到一咻后台，安排好提货后系统会自动反馈提货司机资料`
      },
      {
        id: 213,
        question: '如何知道提货司机信息呢？',
        answer: `微信关注《一咻通关服务号》，按微信指引绑定账号后，系统将自动推送司机资料`
      },
      {
        id: 214,
        question: '送货到国内机场要填快递号吗？',
        answer: `不是必填，但建议填写，系统会自动获取快递进度，货物到达目的机场后推送微信信息`
      },
      {
        id: 215,
        question: '多个收货需求在哪录入？',
        answer: `提交订单后点【创建发货通知】`
      },
      {
        id: 216,
        question: '送货方式是什么？',
        answer: `指运输方式，有包含5种，请根据送货需求选择<br />
        一咻送货：一咻根据收货需求自行安排送货上门<br />
        快递送货：通过快递运输，一咻常用的快递是跨越和顺丰，如需其他快递公司，请和客服说明<br />
        上门自提：到一咻深圳仓库提货，提货凭提货人身份证及提货授权书验放<br />
        待通知：不确定派送方式时可选择待通知，确定需求后，可在订单点【创建发货通知】创建<br />
        存仓：进口后需在一咻深圳仓库存储，可选择【存仓】，后续出货时到【仓储模块】做出库订单`
      },
      {
        id: 217,
        question: '没有找到我要的收货地址，怎么处理？',
        answer: `点击【新增】，新增收货地址及联系人`
      },
      {
        id: 218,
        question: '智能填写是什么？',
        answer: `将地址及联系人贴到智能填写框，点击【智能填写】，系统可自动拆分字段，提高填写效率`
      },
      {
        id: 219,
        question: '什么是签收方式？',
        answer: `签收货物的验伪方式，支持【凭印章收货】及【凭身份证收货】两种方式<br />
        货物价值比较高，司机按照订单约定的签收方式验证放货，敬请支持与配合。`
      },
      {
        id: 220,
        question: 'AI制单是什么？',
        answer: `通过OCR识别PI PDF文件或图片，提取物料明细，填入到订单明细中，查看<a>操作视频</a>`
      },
      {
        id: 221,
        question: '数据拆分是什么？',
        answer: `拆分净重、毛重和件数到每个物料，选中需拆分的物料行，在弹窗里填入总净重、总毛重、总件数任何一个，即可自动拆分`
      },
      {
        id: 222,
        question: '系统有哪些快捷录入订单方式？',
        answer: `系统有3种便捷录单方式：批量导入，复制创建及AI制单。<br />
        批量导入：物料行右上角下载模板，将模板导入到物料行<br />
        复制创建：在订单列表，勾选需复制的订单，创建一份相同的订单<br />
        AI制单：PI比较清晰且有规则的表格，可联系客服做简单配置后，即可使用AI制单`
      },
      {
        id: 223,
        question: '优惠劵怎么使用？',
        answer: `如有优惠劵，系统自动选择最优的优惠劵从而抵扣服务费`
      },
      {
        id: 224,
        question: '余额从哪里来？ 怎么使用？',
        answer: `余额可通过积分转化，下单时选择使用余额抵扣，系统自动使用余额抵扣服务费`
      },
      {
        id: 225,
        question: '去哪里查看费用？',
        answer: `进入订单详情-账单信息，可查看费用明细；在订单列表-操作栏-账单，也可以下载账单`
      },
      {
        id: 226,
        question: '货什么时候能收到呢？',
        answer: `深圳地区通常报关后次早送达，其他地区视距离和运输方式而定。`
      },
      {
        id: 227,
        question: '下完订单后还需做什么？',
        answer: `还需付汇、支付税款和费用。`
      },
      {
        id: 228,
        question: '在哪看货物的进度呢？',
        answer: `订单详情点查【物流进度】或订单列表-操作栏-点【货物跟踪】 `
      },
      {
        id: 229,
        question: '在哪里可以开票？',
        answer: `付汇、结清费用及报关进口后，系统将按协议约定的时间自动开票。`
      },
      {
        id: 230,
        question: '未完成签章如何重新签署？',
        answer: `在订单详情点【继续签章】或【我的】-【我的签章】-【待签章】继续完成签章。`
      },
      {
        id: 231,
        question: '电子签章可以在手机端签署吗？',
        answer: `可以的，同时电子签章支持在邮件和小程序上操作`
      },
      {
        id: 232,
        question: '怎么下载关单/税票等文件呢？',
        answer: `在订单查询列表，勾选订单，点【下载文件】，选择【报关单】/【海关缴款书】等文件下载。`
      }
    ]
  },
  {
    id: 100,
    path: ['wait-payment-list', 'pledging-payment-list', 'pledging-payment-detail'],
    list: [
      {
        id: 101,
        question: '最晚什么时间可提交付款单？',
        answer: `工作日5点半前。如超过5点半，可能无法当天到账。`
      },
      {
        id: 102,
        question: '一咻收款账号',
        answer: `公司名称：深圳市一咻科技有限公司<br />
        开户银行：{bankName}<br />
        银行账号：73190122000082155<br />
        银行联行号：313584003159`
      },
      {
        id: 103,
        question: '付款后多久有水单？',
        answer: `收到人民币后，系统自动发起银行付汇，通常在30分钟内完成支付`
      },
      {
        id: 104,
        question: '收款方能和下单时的供应商不一致吗？',
        answer: `可以的，但需提供收款授权书，操作时勾选【委托收款】，此处下载<a data-href="https://static.z-easy.com.cn/prod/template/委托收款协议-模板.doc">《收款授权书》</a>模板`
      },
      {
        id: 105,
        question: '可以给收款方多个账户打款吗？',
        answer: `可以的，分多次提付款单即可`
      },
      {
        id: 106,
        question: '银行账号前可以加BANK CODE吗？',
        answer: `一般银行账号前不能加BANK CODE，如收款方有明确要求，请按收款方要求来填写`
      },
      {
        id: 107,
        question: '汇率是怎么定的？',
        answer: `【进口模块】取的是付款当天9点半银行外汇卖出价，每天汇率都不一样，<a data-path="/exchange-rate-list">查看更多汇率</a><br />
        【出口模块】取的是付款当天9点半银行外汇买入价，每天汇率都不一样，<a data-path="/exchange-rate-list">查看更多汇率</a>`
      },
      {
        id: 108,
        question: '银行手续费承担方式是什么意思？',
        answer: `跨境支付会产生手续费，付款方和收款方都有费用<br />
        选择【我方承担】，即付款方和收款方的银行手续费都由付款方承担<br />
        选择【共同承担】，即付款方和收款方各自承担各自银行的手续费<br />
        选择【收款方承担】，即付款方和收款方的银行手续费都由收款方承担`
      },
      {
        id: 109,
        question: '银行手续费怎么收取？',
        answer: `【我方承担】及【共同承担】4USD/笔（2023年）,按当天付款汇率折算人民币收取,如当前不便收取，则计入后续账单<br />
        【收款方承担】则无需支付，收款方收到货款时，对方的银行会自动扣除`
      },
      {
        id: 110,
        question: '一个订单可分多次付款吗？',
        answer: `可以的，修改申请金额即可`
      },
      {
        id: 111,
        question: '如何修改订单付款金额？',
        answer: `直接修改【申请金额】或修改订单明细行上的【本次金额】`
      },
      {
        id: 112,
        question: '银行系统中的名称和我的资料描述有差异怎么办？',
        answer: `以SWIFT CODE为准。银行名称不同资料会有些出入，如，Limited 可能简写为Ltd。`
      },
      {
        id: 113,
        question: '没有找到我要的银行，怎么办？',
        answer: `点收款银行左侧【+】，增加银行信息。`
      },
      {
        id: 114,
        question: '想在银行的水单上添加备注，如何做？',
        answer: `在【给银行的备注】处填写，仅支持英文`
      },
      {
        id: 115,
        question: '备注和银行备注有什么不同？',
        answer: `备注为该付款单常规的备注，可以是内部的单号或特殊情况说明，不会显示到银行水单的<br />
        银行备注特指银行水单上的备注，有书写格式和长度的要求，格式限英文，长度限23个字符`
      },
      /* {
        id: 116,
        question: '如何修改付款订单明细？',
        answer: `订单信息明细行右侧点【删除】，随后点【添加】，选择需要的订单号 `
      }, */
      {
        id: 117,
        question: '付款后要上传水单吗？',
        answer: `不需要，一咻后台可自动查询到账情况`
      },
      {
        id: 118,
        question: '付款申请需提供什么资料？',
        answer: `通常只需提供供应商的PI，系统可自动从订单获取，不需手工上传，如还未创建订单，则需手工上传;如是委托付款，则另提供《付款授权书》。`
      },
      {
        id: 119,
        question: '付汇水单在哪下载？',
        answer: `付款列表右侧点击【水单】即可查看下载`
      },
      {
        id: 120,
        question: '有什么文件可供内部请款用？',
        answer: `下载《付款委托书》，上面有付款明细信息，可用作内部请款用 ，附件处点《付款委托书》或付款列表上打印《请款书》`
      },
      {
        id: 121,
        question: '我想修改付款申请，怎么操作？',
        answer: `点击【修改】按钮，即可修改，若该笔付款已在处理中，则不可修改，请及时联系客服<br />
        【客服】联系方式：{serviceName} {serviceMobile}`
      },
      {
        id: 122,
        question: '今天来不及付人民币了，已经提交的付款，可否取消付款？',
        answer: `可以的，无需重新制单，点【修改】订单，撤回订单后，根据付人民币的时间安排，再提交付款即可`
      },
      {
        id: 123,
        question: '已经提交的付款，后续也不需再付了，如何删除付款？',
        answer: `点【修改】订单，撤回订单后，返回到付款列表，勾选订单，点【删除】`
      }
    ]
  }
]
