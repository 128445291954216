<template>
    <el-dialog title="购买确认" width="400px" :model-value="visible" @close="cancel" :close-on-click-modal='false' class="buy-again-dialog">
        <p class="text">是否将此订单的商品再次生成订单？</p>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" @click="submitForm">确认</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: 'buy-again-dialog',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        orderNo: {
            type: String,
            default: ''
        }
    },
    data() {
        return {};
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        submitForm() {
            this.$emit('confirm', this.orderNo);
        }
    }
};
</script>

<style lang="scss" scoped>
.buy-again-dialog {
    .text {
        text-align: center;
    }
}
</style>
