<template>
    <el-dialog title="导入确认" width="90%" class="analy-dialog" v-model="showVisible" top="5vh" @close="cancel"
        :close-on-click-modal='false'>
        <el-form :model="dataFrom" :rules="rules" ref="dataFrom" label-position="top">
        <div class="formItem" v-for="(item, index) in columns" :key="item" :style="{width: `calc(${100 / columns.length}% - ${index == columns.length -1 ? '0px' : '12px'})`}">
            <el-form-item>
                <el-select v-model="dataFrom[item.props]">
                    <el-option :disabled="isDisabled(it.value)" v-for="it in options" :key="it.value" :label="it.label" :value="it.value"></el-option>
                </el-select>
            </el-form-item>
        </div>
        </el-form>
        <ab-table
            v-loading="btnLoading"
            element-loading-text="导入中，请稍后..."
            indexLabel="序号"
            indexWidth="60"
            :column-list="columns"
            maxHeight="calc(90vh - 300px)"
            :table-list="dataTable">
        </ab-table>
        <template #footer>
        <span class="dialog-footer">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" :loading="btnLoading" @click="confirm">确定</el-button>
        </span>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: 'ab-analy-dialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
            required: true
        },
        btnLoading: {
            type: Boolean,
            default: false
        },
        tableData: {
            type: Array,
            default: []
        }
    },
    computed: {
        showVisible: {
            get() {
                return this.visible
            }
        }
    },
    watch: {
        visible: {
            handler(value) {
                if(value) {
                    if(this.tableData && this.tableData.length > 1) {
                        let tableData1 = []
                        //找出表头
                        let header = 0
                        for (let idx = 1; idx < this.tableData.length; idx++) {
                            // let num = 0
                            // this.tableData[idx].forEach(e=>{
                            //     if(/^[\u4e00-\u9fa5]*$/.test(e) && e) {
                            //         num++
                            //     }
                            // })
                            // if(num > this.tableData[header].length) {
                            //     header = idx
                            // }
                            if(this.tableData[idx].filter(e=>!!e).length > this.tableData[header].filter(e=>!!e).length) {
                                header = idx
                            }
                        }
                        // 表头第一列为空就识别成序号
                        this.tableData[header][0] = this.tableData[header][0] || '序号'
                        // 超出表头长度的列过滤掉
                        let num = this.tableData[header].filter(e=>!!e).length
                        for (let index = header; index < this.tableData.length; index++) {
                            const element = this.tableData[index];
                            let arr = []
                            element.forEach((e,i)=>{
                                if(i < num) {
                                    arr.push(e)
                                }
                            })
                            //过滤掉空白行
                            if(!arr.every(e=>!e)) {
                                tableData1.push(arr)
                            }
                        }
                        // 默认表头下拉选
                        tableData1[0].forEach(e=>{
                            this.columns.push(
                                {
                                    props: e,
                                    labels: e,
                                    widths: 100,
                                    align: 'center'
                                }
                            )
                            this.dataFrom[e] = this.options.filter(el=>e && e.indexOf(el.label) != -1).length ? this.options.filter(el=>e && e.indexOf(el.label) != -1)[0].value : 'none'
                            if(e && e.indexOf('分类') != -1 || e && e.indexOf('产品分类') != -1) {
                                this.dataFrom[e] = 'productType'
                            }
                            if(e && e.indexOf('需求型号') != -1 || e && e.indexOf('原厂料号') != -1) {
                                this.dataFrom[e] = 'productModel'
                            }
                            if(e && e.indexOf('厂牌') != -1) {
                                this.dataFrom[e] = 'brand'
                            }
                            if(e && e.indexOf('规格参数描述') != -1 || e && e.indexOf('参数描述') != -1 || e && e.indexOf('描述') != -1 || e && e.indexOf('参数/规格') != -1 || e && e.indexOf('参数规格') != -1 || e && e.indexOf('规格型号') != -1 || e && e.indexOf('规格参数') != -1) {
                                this.dataFrom[e] = 'parameter'
                            }
                            if(e && e.indexOf('位置') != -1) {
                                this.dataFrom[e] = 'tagNum'
                            }
                            if(e && e.indexOf('用量') != -1) {
                                this.dataFrom[e] = 'aloneNum'
                            }
                            if(e && e.indexOf('需求数量') != -1 || e && e.indexOf('购买数量') != -1 || e && e.indexOf('采购数') != -1 || e && e.indexOf('数量') != -1 || e && e.indexOf('采购数量') != -1) {
                                this.dataFrom[e] = 'demandNum'
                            }
                            if(e && e.indexOf('客户说明') != -1) {
                                this.dataFrom[e] = 'remark'
                            }
                        })
                        // 重复下拉选改成不导入
                        let arr = []
                        for (const key in this.dataFrom) {
                            if(arr.indexOf(this.dataFrom[key]) == -1) {
                                arr.push(this.dataFrom[key])
                            }else{
                                this.dataFrom[key] = 'none'
                            }
                        }
                        // 拼接成表格结构展示
                        for (let index = 1; index < tableData1.length; index++) {
                            let element = tableData1[index];
                            this.dataTable[index - 1] = {}
                            if(element.length) {
                                element.forEach((e, i) => {
                                    this.dataTable[index - 1][this.columns[i].props] = e === 0 || e === '0'? e : e || ''
                                })
                            }
                        }
                    }
                }else {
                    this.cancel()
                }
            },
            deep: true
        }
    },
    data() {
      return {
        dataFrom: {},
        options: [
            {
                label: '型号',
                value: 'productModel'
            },
            {
                label: '品牌',
                value: 'brand'
            },
            {
                label: '类别',
                value: 'productType'
            },
            {
                label: '封装',
                value: 'productPackaging'
            },
            {
                label: '规格',
                value: 'parameter'
            },
            {
                label: '位号',
                value: 'tagNum'
            },
            {
                label: '单机用量',
                value: 'aloneNum'
            },
            {
                label: '需求数',
                value: 'demandNum'
            },
            {
                label: '备注',
                value: 'remark'
            },
            {
                label: '不导入',
                value: 'none'
            },
            {
                label: '物料编码',
                value: 'materialNo'
            }
        ],
        columns: [],
        dataTable: []
      }
    },
    methods: {
      isDisabled(v) {
        let flag = false
        for (const key in this.dataFrom) {
            if (Object.hasOwnProperty.call(this.dataFrom, key)) {
                const element = this.dataFrom[key];
                if(element == v && element != 'none') {
                    flag = true
                }
            }
        }
        return flag
      },
      cancel() {
        this.dataFrom = {}
        this.columns = []
        this.dataTable = []
        this.$emit('cancel')
      },
      confirm(){
        let flag = false
        let flag1 = 0
        let flag2 = 0
        let flag3 = 0
        let flag4 = 0
        let flag5 = 0
        for (const key in this.dataFrom) {
            if (Object.hasOwnProperty.call(this.dataFrom, key)) {
                const element = this.dataFrom[key];
                if(!element) {
                    flag = true
                }
                if(element == 'productModel' || element == 'parameter') {
                    flag1++
                }
                if(element == 'aloneNum' || element == 'demandNum') {
                    flag2++
                }
            }
        }
        for (let index = 0; index < this.dataTable.length; index++) {
            const element = this.dataTable[index];
            let num = 0
            for (const key in element) {
                let reg = /^[1-9]+[0-9]*$/
                if(this.dataFrom[key] == 'demandNum' && (element[key] === 0 || element[key] === '0' || (element[key] && !reg.test(element[key])))) {
                    flag3 = index
                }
                if(this.dataFrom[key] == 'aloneNum' && (element[key] === 0 || element[key] === '0' || (element[key] && !reg.test(element[key])))) {
                    flag4 = index
                }
                if((this.dataFrom[key] == 'demandNum' || this.dataFrom[key] == 'aloneNum') && !element[key]) {
                    num ++
                }
            }
            if(num > 1) {
                flag5 = index
            }
        }
        if(flag) {
            this.$message.error('表头缺失，请选择完整！')
            return
        }
        if(flag1 < 1) {
            this.$message.error('表头必须包含【型号】或【规格】，请选择完整！')
            return
        }
        if(flag2 < 1) {
            this.$message.error('表头必须包含【需求数】或【单机用量】，请选择完整！')
            return
        }
        if(flag3) {
            this.$message.error(`第${flag3 + 1}行的【需求数】必须为正整数！`)
            return
        }
        if(flag4) {
            this.$message.error(`第${flag4 + 1}行的【单机用量】必须为正整数！`)
            return
        }
        if(flag5) {
            this.$message.error(`第${flag5 + 1}行的【单机用量】和【需求数】不能同时为空！`)
            return
        }
        this.$prompt('', '确定采购套数', {
            closeOnClickModal: false,
            closeOnPressEscape: false,
            customClass: 'revoke-sign-prompt',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPlaceholder: '采购套数',
            inputPattern: /^[1-9]+[0-9]*$/,
            inputErrorMessage: '请输入正整数！',
            inputValue: 1
        }).then(({ value }) => {
            let inquiryDetails = []
            this.dataTable.forEach(e=>{
                let obj = {}

                for (const key in e) {
                    if (Object.hasOwnProperty.call(e, key)) {
                        const element = e[key];
                        if(this.dataFrom[key] != 'none') {
                            obj[this.dataFrom[key]] = element
                        }
                    }
                }
                if(!obj.aloneNum && obj.demandNum) {
                    obj.aloneNum = obj.demandNum / value
                }
                if(obj.aloneNum && !obj.demandNum) {
                    obj.demandNum = obj.aloneNum * value
                }
                inquiryDetails.push(obj)
            })
            this.$emit('confirm', inquiryDetails, value)
        }).catch(() => {
            this.loading = false
        })
      }
    }
  }
  </script>

  <style lang="scss">
    .analy-dialog{
        .el-table__body{
            max-height: calc(100vh - 600px);
            overflow-y: scroll;
        }
        .formItem{
            float: left;
            margin-right: 12px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
</style>
