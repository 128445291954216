enum MatchState {
  ZERO = 0,    // 待确认
  ONE = 1,     // 完全匹配
  TWO = 2,     // 无匹配
  THREE = 3,   // 已转单
  FOUR = 4,    // 异常
  FIVE = 5     // 已失效
}

export default MatchState;
