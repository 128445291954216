<template>
    <div class="dashboard">
        <div class="top-info">
            <el-row :gutter="30">
                <el-col :span="8">
                    <div class="user-info"  v-loading="userLoading">
                        <p class="nick-name">{{ personData.nickName }}</p>
                        <p v-if="companyName">{{ companyName }}</p>
                        <div v-if="noCertifiedFlag" class="authentication" @click="$router.push('/my-enterprise')">
                            您尚未做企业认证，【立即认证】>>
                        </div>
                        <div v-else class="authentication" @click="$router.push('/my-enterprise')">
                            您已完成企业认证，立即查看企业信息>>
                        </div>
                    </div>
                </el-col>
                <el-col :span="16">
                    <div class="order-info" v-loading="orderLoging">
                        <p class="title">订单统计</p>
                        <ul>
                            <li v-for="(item, index) in orderList" :key="index">
                                <p class="label">{{ item.label }}</p>
                                <p class="value" @click="orderClick(item)">{{ item.value }}</p>
                            </li>
                        </ul>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="model-list">
            <el-row :gutter="30">
                <el-col :span="8">
                    <div class="item">
                        <div class="title">BOM 询价</div>
                        <div class="content">
                            <div @click="beforeUpload" @drop="beforeUpload">
                                <el-upload class="upload-demo" :disabled="noCertifiedFlag" :action="action" drag
                                    :headers="headers"
                                    :before-upload="beforeAvatarUpload"
                                    :on-success="uploadSuccess"
                                    :on-error="errorUpload"
                                    :show-file-list="false"
                                    multiple
                                >
                                    <div class="el-upload__text">
                                        <p>BOM 文件拖拽到这里</p>
                                        <el-button :loading="btnLoading2" type="primary" :disabled="noCertifiedFlag">点击上传</el-button>
                                        <p>支持xls、xlsx、csv、文件不超过5M</p>
                                        <p @click.stop="downTempate">BOM 标准模板下载</p>
                                    </div>
                                </el-upload>
                            </div>

                        </div>
                    </div>
                    <!-- <div class="search-div">
                        <el-input type="textarea" class="search-input" maxlength="200" rows="8" resize="none"
                            placeholder="可手动输入或EXCEL复制粘贴，多条询价需求请换行粘贴" v-model="searchName"></el-input>
                        <el-button type="primary" class="searchBtn" @click="inputSearch">智能匹配</el-button>
                    </div> -->
                </el-col>
                <el-col :span="8">
                    <div class="item inquire-progress" v-loading="btnLoading">
                        <div class="title">BOM匹配进度 <span
                                style="float: right; cursor: pointer; font-size: 14px; margin-top: 3px" class="link"
                                @click="$router.push({ path: '/inquire-list' })">更多 >></span> </div>
                        <div class="content">
                            <div v-if="bomList.length > 0" style="height: 300px">
                                <div :class="{ 'pro-item pro-item1': true, 'last-item': index == bomList.length - 1 }"
                                    v-for="(item, index) in bomList" :key="item">
                                    <div class="text">
                                        <span @click="goDetailBom(item)" class="link">{{ item.title }}</span>
                                        <span>项数{{ item.number }}</span>
                                    </div>
                                    <ab-status :countNumber="item.countNumber" :statusData="item.data"></ab-status>
                                </div>

                            </div>
                            <div v-else class="no-data-info">
                                <img src="@/assets/img/noImg.png" />
                                <div class="tishi"> 暂无数据</div>
                            </div>
                            <div class="bottom-quanquan">
                                <span><i class="quanquan" style="background-color: #B58623"></i>完全匹配</span>
                                <span><i class="quanquan" style="background-color: #FF8400"></i>待确认</span>
                                <span><i class="quanquan" style="background-color: #FF0000"></i>无匹配</span>
                            </div>

                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="item inquire-progress">
                        <div class="title">订单进度 <span
                                style="float: right; cursor: pointer; font-size: 14px; margin-top: 3px" class="link"
                                @click="$router.push({ path: '/order-list' })">更多 >></span> </div>
                        <div class="content">
                            <div v-if="proList.length > 0">
                                <div :class="{ 'pro-item': true, 'last-item': index == proList.length - 1 }"
                                    v-for="(item, index) in proList" :key="item">
                                    <div class="text">
                                        <span @click="goDetailOrder(item)" class="proNumber">{{ item.orderNo }}/{{item.expressNo}}</span>
                                        <span>项数{{ item.num }}</span>
                                    </div>
                                    <div class="progress-div" @click="viewLog(item)">
                                        <div class="progress-item" :style="{ width: `${per(item.status) + '%'}` }">
                                            {{ item.status }}{{ per(item.status) }}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="no-data-info">
                                <img src="@/assets/img/noImg.png" />
                                <div class="tishi"> 暂无数据</div>
                            </div>
                            <el-drawer v-model="logvisible" title="物流跟踪">
                                <ab-logistics-status ref="logistics-status">
                                </ab-logistics-status>
                            </el-drawer>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <ab-analy-dialog :btnLoading="btnLoading1" @confirm="analyConfirm" v-model:visible="showVisible" :tableData="tableList" @cancel="showVisible = false"></ab-analy-dialog>
        <ab-logistics-status ref="logistics-status" :limitDrawer="limitDrawer">
    </ab-logistics-status>
    </div>
</template>

<script>
import vueCookie from 'vue-cookies'
export default {
    data() {
        return {
            orderLoging: false,
            btnLoading: false,
            btnLoading1: false,
            isBtnLoading: false,
            showVisible: false,
            attachUrl: '',
            searchName: '',
            logvisible: false,
            action: this.$adornUrl('/inquiryBase/bomAnalysis'),
            headers: {Authorization: vueCookie.get('token')},
            orderList: [
                { value: 0, label: '待支付', status: 'pending_payment', type: 1 },
                { value: 0, label: '仓库处理中', status: 'processing', type: 1 },
                { value: 0, label: '已发货', status: 'shipped', type: 1 },
                { value: 0, label: '待开票', status: 'un_invoiced', type: 2 },
            ],
            colorArray: ['#00458E', '#FF8400', '#FF0000', ' #00C0FF'],
            proList: [],
            bomList: [],
            personData: {},
            userLoading:false,
            customerNo: '',
            limitDrawer: {show: false, id: ''},
            companyName: '',
        }
    },
    computed: {
        mainTabs: {
            get() {
                return this.$store.state.mainTabs
            },
            set(val) {
                this.$store.commit('updateMainTabs', val)
            }
        },
        noCertifiedFlag() {
            return !JSON.parse(window.localStorage.getItem('companyId'))
        },
        currentAvatar: {
            get() { return this.$store.state.currentAvatar },
            set(val) { this.$store.commit('updateCurrentAvatar', val) }
        },
    },
    mounted() {
        // 没有认证
        if (this.noCertifiedFlag) {
            this.orderList = this.orderList.map(item => {
                item.value = 0
                return item
            })
            this.proList = []
            this.bomList = []

        } else {
            this.inquiryData()
            this.orderData()
        }
        this.getUserInfo()
        this.getProgress()
    },
    methods: {
        viewLog(row) {
            this.limitDrawer.id = row.orderId
            this.limitDrawer.show = true
        },
        per(key) {
            let text = 0
            switch (key) {
                case '已签收':
                    text = 100
                    break;
                case '在途':
                    text = 66
                    break;
                case '揽收':
                    text = 33
                    break;

                default:
                    break;
            }
            return text
        },
        // 跳转到bom查询编辑页
        goDetail(result) {
            this.$router.push({
                path: '/inquire-detail',
                query: {
                    type: 'edit',
                    inquiryOrderNo: result.inquiryOrderNo,
                    cache: false
                }
            })
        },
        // 新增确认
        analyConfirm(inquiryDetails, value) {
          let params = Object.assign({numberOfSets: value, customerNo: this.customerNo}, {inquiryDetails})
          this.btnLoading1 = true
          this.$post('/bom/match', params, 'api').then(({data}) => {
            if(data.code === 'C0000') {
                if(data.result) {
                    this.showVisible = false
                    let originData = data.result

                    // 筛选出状态为完全匹配或待确认的数据
                    const matchStateObj = {
                        ...data.result,
                        inquiryDetails: originData.inquiryDetails.filter(item => item.matchResult.matchStatus === 1 || item.matchResult.matchStatus === 0)
                    }

                    // 价格接口参数
                    const paramsPrice = {
                        queryList: matchStateObj.inquiryDetails.map(o => {
                            return {
                                mpn: o.bomData.selectedModelMpn,
                                quantity: o.bomData.usageNum || '',
                            }
                        })
                    }

                    // 请求价格接口
                    this.$post('/products/price', paramsPrice, 'api').then(({data}) => {
                        if(data.code === 'C0000') {
                            if (data.result) {
                                const priceData = data.result
                                this.$message.success(data.message)

                                // 匹配结果中匹配状态为完全匹配或待确认的，将价格数据赋值给 matchResult.selectedModel.priceData，并与原本数据进行筛选
                                originData.inquiryDetails.forEach(item => {
                                    if (item.matchResult.matchStatus === 1 || item.matchResult.matchStatus === 0) {
                                        item.matchResult.selectedModel = {
                                            ...item.matchResult.selectedModel,
                                            priceData: priceData.find(o => o.mpn === item.bomData.selectedModelMpn)
                                        }
                                    }
                                })
                                // 将数据保存到sessionStorage
                                this.$utils.setSessionStorage('inquiryData', originData)
                                // 跳转到详情页
                                this.goDetail(JSON.parse(JSON.stringify(originData)))
                            } else {
                                this.$message.error(data.message)
                            }
                        }
                    })
                }else{
                    this.$message.error(data.message)
                }
            }
          }).finally(() => {
            this.btnLoading1 = false
          })
        },
        beforeAvatarUpload(file) {
            const isLtSize = file.size / 1024 / 1024 < 5
            if (!isLtSize) {
                this.$message({
                    type: 'error',
                    message: file.name + '不能超过5MB!',
                    offset: 380,
                    grouping: true,
                })
                return false
            }
            let types = ['xlsx', 'xls'];
            var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
            if (types.indexOf(testmsg.toLowerCase()) === -1) {
                this.$message({
                    message: '仅支持 xlsx 和 xls 文件',
                    type: 'warning'
                })
                return false
            }
            this.isBtnLoading = true
            this.customerNo = file.name ? file.name.split('.')[0] : ''
        },
        uploadSuccess(res) {
            if(res.code === 'C0000') {
                this.tableList = res.result || []
                this.showVisible = true
            }else{
                this.$message.error(res.message)
            }
            this.isBtnLoading = false
        },
        errorUpload(err){
            this.$message.error('接口异常')
        },
        getUserInfo() {
            this.userLoading = true
            this.$get('/auth/info')
                .then(({ data }) => {
                    if (data.code === 'C0000') {
                        if (data.result) {
                            this.personData = data.result.user
                            this.companyName = data.result.user.companyName
                            this.getAttachUrl()//头像信息获取
                        }
                    }
                })
        },
        getProgress() {
            this.$post('/orderBase/expressStatistics').then(({ data }) => {
                if (data.code === 'C0000') {
                    this.proList = data.result || []
                }
            })
        },
        getAttachUrl() {
            // // attachType : business_license 营业执照图片, head sculpture 头像
            //sourceBillType 'company', 'user'
            let data = {
                sourceBillType: 'user',
                sourceBillId: this.personData.id,
                attachType: 'head sculpture'
            }
            this.attachUrl = ''
            this.$get('/api/attactment/info', data).then(({ data }) => {
                if (data.code === 'C0000') {
                    if (data.result) {
                        this.attachUrl = data.result.fileUrl
                        this.currentAvatar = data.result.fileUrl
                    }
                    this.userLoading = false
                }
            })
        },
        inquiryData() {
            this.btnLoading = true
            this.$get('/inquiry-orders/home-data-analysis', undefined, 'api').then(({ data }) => {
                if (data && data.code === 'C0000') {
                    let result = data.result || []
                    let dataArray = []
                    if (result && result.length > 0) {
                        result.map(item => {
                            let obj = {}
                            obj.title = item.inquiryOrderNo
                            obj.countNumber = item.count
                            obj.number = item.count
                            obj.inquiryOrderNo = item.inquiryOrderNo
                            obj.data = [
                                { number: item.perfectMatchCount || 0, color: 'rgb(181, 134, 35)' },
                                { number: item.toBeConfirmedCount || 0, color: 'rgb(255, 132, 0)' },
                                { number: item.unmatchedCount || 0, color: 'rgb(255, 0, 0)' },
                            ]
                            obj.data = obj.data.filter(item => item.number > 0)
                            dataArray.push(obj)
                        })
                    }
                    this.bomList = dataArray
                }
            }).finally(() => {
                this.btnLoading = false
            })
        },
        orderData() {
            this.orderLoging = true
            this.$get('/orders/home-data-analysis',{},'api').then(({ data }) => {
                if (data && data.code === 'C0000') {
                    let result = data.result || {}
                    this.orderList = [
                        { value: result.pendingPaymentCount || 0, label: '待支付', status: 'pending_payment', type: 1, params: 'orderStatus' },
                        { value: result.processingCount || 0, label: '仓库处理中', status: 'processing', type: 1, params: 'orderStatus' },
                        { value: result.shippedCount || 0, label: '已发货', status: 'shipped', type: 1, params: 'orderStatus' },
                        { value: result.pendingInvoiceCount || 0, label: '待开票', status: 'un_invoiced', type: 2, params: 'orderStatus' },
                    ]
                }
            }).finally(() => {
                this.orderLoging = false
            })
        },
        // 上传之前
        beforeUpload() {
            // 没有认证
            if (this.noCertifiedFlag) {
                this.$confirm('您尚未做企业认证，该功能需要企业认证后才可使用，是否去认证？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$router.push({ path: '/my-enterprise' })
                }).catch(() => {
                })

            }
        },
        // 跳转BOm详情
        goDetailBom(item) {
            this.$router.push({
                path: '/inquire-detail',
                query: { type: 'view', id: item.inquiryOrderNo, cache: false }
            })

        },
        // 订单详情
        goDetailOrder(item) {
            this.$router.push({
                path: '/order-detail',
                query: {
                    type: 'view',
                    id: item.orderId
                }
            })
        },
        // 物流进度
        getStatusTimeAxis(item) {
            this.logvisible = true

        },
        // 匹配
        inputSearch() {
            if (this.noCertifiedFlag) {
                this.$confirm('您尚未做企业认证，该功能需要企业认证后才可使用，是否去认证？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$router.push({ path: '/my-enterprise' })
                }).catch((e) => {
                    console.log(e)
                })
            } else {
                this.$router.push({
                    path: '/inquire-detail',
                    query: { type: 'add', cache: false }
                })
            }

        },
        // 跳转
        orderClick(item) {
            // 跳转订单
            if (item.type === 1) {
                this.$router.push({
                    path: '/order-list',
                    query: {
                        status: item.status,
                        params: item.params,
                        isHome: true
                    }
                })
            } else {
                this.$router.push({
                    path: '/invoice-list'
                })
            }
        },
        // 下载模板
        downTempate() {
            window.open('https://scp-static.oss-cn-shenzhen.aliyuncs.com/prod/template/bom/BOM表模板.xlsx')
        }

    }
}
</script>

<style lang="scss">
.dashboard {
    padding: 40px 20px;
    min-height: calc(100vh - 150px);

    .top-info {
        .el-col {
            height: 160px;

            .user-info {
                height: 100%;
                width: 100%;
                position: relative;
                background-image: linear-gradient(to right, #f8d0d0, #fcfeff);
                border-radius: 8px;
                position: relative;
                padding-top: 35px;

                p {
                    text-indent: 50px;
                    font-size: 18px;
                }

                .nick-name {
                    margin-bottom: 8px;
                }

                .authentication {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    background-image: linear-gradient(to right, #f10201, #f9c9c9);
                    color: #fff;
                    font-size: 12px;
                    border-radius: 8px;
                    cursor: pointer;
                }
            }

            .order-info {
                height: 160px;
                width: 100%;
                border-bottom: 1px solid #e5e5e5;
                font-family: DIN_Condensed_Bold;
                .title {
                    font-size: 18px;
                }

                ul {
                    width: 100%;
                    height: 100px;
                    margin-top: 27px;

                    li {
                        float: left;
                        width: 14.2%;
                        text-align: center;
                        padding: 8px 0;

                        p.value {
                            font-size: 30px;
                            cursor: pointer;
                            margin-top: 6px;
                        }
                        &:hover {
                            color: var(--el-color-primary);
                            border-color: var(--el-color-primary-light-7);
                            background-color: var(--el-color-primary-light-9);
                            border-radius: 16px;
                            outline: 0;
                            p.label {
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
    }

    .model-list {
        margin-top: 35px;

        .search-div {
            border-radius: 8px;
            text-align: center;
            height: 175px;
            position: relative;
            margin-top: 10px;

            .searchBtn {
                position: absolute;
                bottom: 15px;
                right: 40%
            }

            .search-input {
                border: 1px solid #E5E5E5;
                height: 100%;
                border-radius: var(--el-input-border-radius, var(--el-border-radius-base));

                .el-textarea__inner {
                    background-color: #fff;
                    height: 100%;
                }

            }
        }

        .item {
            .title {
                font-size: 18px;
                margin-bottom: 40px;
            }

            .upload-demo {
                .el-upload__text {
                    p:nth-child(1) {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }

                    p:nth-child(3) {
                        margin: 10px 0;
                        color: #868686;
                    }

                    p:nth-child(4) {
                        cursor: pointer;
                        font-size: 14px;
                        color: var(--el-color-primary);
                    }
                }

                .el-upload-dragger {
                    padding-top: 100px;
                    border: 1px solid #e5e5e5;
                    background: unset;
                    height: 360px;
                }

                .el-upload {
                    --el-upload-dragger-padding-horizontal: 15px
                }
            }
        }

        .inquire-progress {
            .content {
                padding: 24px 20px 0;
                height: 360px;
                width: 100%;
                border: 1px solid #e5e5e5;
                border-radius: 8px;
                position: relative;

                .no-data-info {
                    text-align: center;
                    margin: 70px 0 100px;
                }
                .proNumber{
                    cursor: pointer;
                    &:hover{
                        color: var(--el-color-primary);
                    }
                }
                .pro-item {
                    margin-bottom: 20px;

                    .text {
                        height: 28px;

                        span:nth-child(1) {
                            float: left;
                        }

                        span:nth-child(2) {
                            float: right;
                        }
                    }

                    &.last-item {
                        margin-bottom: 8px;
                    }
                }

                .pro-item1 {
                    margin-bottom: 35px;
                }

                .bottom-quanquan {
                    margin-top: 5px;
                    width: 100%;
                    display: flex;
                    text-align: center;
                    span{
                        flex: 1;
                    }
                }

                .quanquan {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 4px;
                }

                &>span {
                    margin-right: 14px;
                }

                .progress-div {
                    background: #DBF3FD;
                    height: 16px;
                    border-radius: 8px;
                    cursor: pointer;
                    .progress-item {
                        height: 16px;
                        background: #B58623;
                        border-radius: 8px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
</style>
