import { createStore } from 'vuex'

let [defaultid, defaultTenantid, defaultname, defaultUserId] = ['0', '0', '0', '0']
try {
  if (localStorage.loginname || localStorage.loginid || localStorage.tenantid || localStorage.userId) {
    defaultname = localStorage.loginname
    defaultid = localStorage.loginid
    defaultTenantid = localStorage.tenantid
    defaultUserId = localStorage.userId
  }
} catch (e) {

}

export default createStore({
  state: {
    // 页面文档可视高度(随窗口改变大小)
    documentClientHeight: 0,
    // 侧边栏, 折叠状态
    sidebarFold: false,
    activeMenu: '',
    userInfo: {},//用户信息
    userId: defaultUserId,
    loginid: defaultid, // 登录id
    tenantid: defaultTenantid, // 租户id
    loginname: defaultname,
    mainTabs: [], //菜单TAB
    mainTabsActiveName: '',//当前激活tab
    haveBizType: {
      JW: false,
      CW: false
    },// 签署的业务类型
    currBizType: '',// 工作台切换的业务类型
    nowMoney: 0,  // 账户余额
    balanceIntegral: 0,  //积分
    coupon: 0,  //  优惠券
    showCouponDia: true,  // 是否有优惠券弹框
    unlocking: {},
    workbench: {},
    messageList: [], // 未读消息
    step: '',
    isClient: false,
    posterAddress: '',  //活动海报地址
    shareLink: '',  //活动分享链接
    disCouList: [], // 折扣券
    warehouse: [],//仓库配置
    activeIndexMenu: '00', //顶部导航栏当前状态
    keepAliveView: [],//记录缓存页面
    currentAvatar: '',//当前头像
    messageTotal: 0,//
  },
  getters: {
  },
  mutations: {
    updateDocumentClientHeight(state, height) {
      state.documentClientHeight = height
    },
    updateSidebarFold(state, fold) {
      state.sidebarFold = fold
    },
    updateActiveMenu(state, name) {
      state.activeMenu = name
    },
    userInfo(state, user) {
      state.userInfo = user
    },
    updateUserId(state, id) {
      state.userId = id
      try {
        localStorage.userId = id
      } catch (e) { }
    },
    updateLoginId(state, id) {
      state.loginid = id
      try {
        localStorage.loginid = id
      } catch (e) { }
    },
    updateTenantId(state, id) {
      state.tenantid = id
      try {
        localStorage.tenantid = id
      } catch (e) { }
    },
    updateName(state, name) {
      state.loginname = name
      try {
        localStorage.loginname = name
      } catch (e) { }
    },
    updateMainTabs(state, tabs) {
      state.mainTabs = tabs
    },
    updateMainTabsActiveName(state, name) {
      state.mainTabsActiveName = name
    },
    updateHaveBizType(state, obj) {
      state.haveBizType = obj
    },
    changeBizType(state, value) {
      state.currBizType = value
    },
    updaetNowMoney(state, value) {
      state.nowMoney = value
    },
    updaetBalanceIntegral(state, value) {
      state.balanceIntegral = value
    },
    updaetCoupon(state, value) {
      state.coupon = value
    },
    updaetCouponDia(state, value) {
      state.showCouponDia = value
    },
    SET_OPEN(state, value) {
      state.unlocking = value
    },
    SET_WORK(state, value) {
      state.workbench = value
    },
    SET_MESSAGE_LIST(state, value) {
      state.messageList = value
    },
    SET_MESSAGE_TOTAL(state, value) {
      state.messageTotal = value
    },
    SET_STEP(state, value) {
      state.step = value
    },
    IS_ClIENT(state, value) {
      state.isClient = value
    },
    updaetPosterAddress(state, value) {
      state.posterAddress = value
    },
    updaetShareLink(state, value) {
      state.shareLink = value
    },
    updaetDisCouList(state, value) {
      state.disCouList = value
    },
    WARE_HOUSE(state, value) {
      state.warehouse = value
    },
    activeIndex(state, value) {
      state.activeIndexMenu = value
    },
    updateKeepAliveView(state, value) {
      state.keepAliveView = value
    },
    updateCurrentAvatar(state, value) {
      state.currentAvatar = value
    }
  },
  actions: {
    ActionsLoginId(ctx, value) {
      ctx.commit('updateLoginId', value)
    },
    ActionsUserId(ctx, value) {
      ctx.commit('updateUserId', value)
    },
    ActionsTenantId(ctx, value) {
      ctx.commit('updateTenantId', value)
    },
    ActionsUpdateName(ctx, value) {
      ctx.commit('updateName', value)
    }
  },
  modules: {
  }
})
