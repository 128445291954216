// @ts-nocheck
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import home from '../views/main-modules/home.vue'
import vueCookie from 'vue-cookies'
import utils from '../utils'
import routerConfig from './router-config'
import https, { fetch, post, adornUrl } from '../utils/https'



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: home,
    children: [
      {
        path: '/',
        name: '首页',
        component: () => import('../views/main-modules/dashboard.vue'),
      },
      ...routerConfig
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/main-modules/login.vue'),
  },
  {
    path: '/register',
    component: () => import('../views/main-modules/register'),
    name: 'register'
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/main-modules/forgot-password.vue')
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/main-modules/404.vue')
  }
]

const main: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: home,
    children: [
      ...routerConfig
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  isAddDynamicMenuRoutes: false,
  routes
})



router.beforeEach((to, from, next) => {
  const token = vueCookie.get('token')
  const menuList = JSON.parse(sessionStorage.getItem('menuList') || '[]').filter(e => e.isShow)
  if (to.path == '/login' || to.path == '/forgot-password' || to.path == '/register') {
    next()
  } else if (!token || !/\S/.test(token) && to.fullPath != '/login') {
    utils.tools.clearLoginInfo()
    next({ path: '/login', replace: true })
  } else {
    next()
  }
})

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = []) {
  let temp = []
  for (let i = 0; i < menuList.length; i++) {
    if (menuList[i].url && /\S/.test(menuList[i].url)) {
      temp.push(menuList[i])
    }
    if (menuList[i].children && menuList[i].children.length >= 1) {
      temp = temp.concat(menuList[i].children.filter(e => { e.isShow }))
    }
  }
  if (temp) {
    temp.map(e => {
      const obj = {
        path: e.url,
        name: e.url,
        component: () => require(`../views/${e.url}.vue`),
        meta: {
          menuId: e.id,
          title: e.name
        }
      }
      console.log(obj)
      router.addRoute('/', main)
    })
    return temp
  }
}

export default router
