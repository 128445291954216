<template>
   <!-- 开票申请 -->
   <div class="invoice-list">
    <ab-list-query>
        <template #right>
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-input laceholder="采购单号"  placeholder="采购单号" v-model.trim="formSearch.orderNo" clearable></el-input>
                </el-col>
                <el-col :span="4">
                    <ab-timeSearch
                        class="searchBox"
                        startSrc="订单时间起"
                        endSrc="订单时间止"
                        v-model="formSearch.updateTimeArr1"
                        @searchEvent="changeTimeRange"
                    ></ab-timeSearch>
                </el-col>
                <el-col :span="4" class="text-left">
                    <el-button @click="searchForm">搜索</el-button>
                    <el-button link class="btn-svg btn-clear" @click="formSearch={}"><ab-svg name="qingchu"></ab-svg>清除</el-button>
                </el-col>
            </el-row>
        </template>

        <template #toolbar>
            <!-- <el-button type="primary" :loading="load.isAddLoading" :disabled="isDisabled" @click="applyInvoicing(null)">批量开票</el-button> -->
            <!-- <el-button @click="exportFile" :loading="btnLoading">导出</el-button> -->
        </template>

        <template #table>
            <ab-table
                class="materialTable"
                ref="table"
                stripe
                showSelection
                selectionWidth="45"
                indexWidth="50"
                widths="100"
                isFilter="operator"
                :column-list="columns"
                :table-list="tableData"
                :dataListLoading="load.isloading"
                :handleSelectionChange='handleSelectionChange'
                @filterColumns="filterColumns"
            >
                <!-- <template #operator='scope'>
                    <el-button type="primary" link @click="applyInvoicing(scope.row)">开票</el-button>
                </template> -->
                <template #taxRate='scope'>
                    {{scope.row.taxRate}}%
                </template>
            </ab-table>

        </template>

        <template #page>
            <ab-page
                :pageTotal="page.total"
                :currentPage='page.currentPage'
                :pageSize="page.pageSize"
                :pageSizes="page.pageSizes"
                @searchForm="pageSearch"
            ></ab-page>
        </template>
    </ab-list-query>
   </div>
</template>

<script>
export default {
    data() {
        return {
            //状态汇总
            load:{
                isAddLoading:false,
                isloading:false,
                btnLoading:false,
            },
            formSearch:{},
            multipleSelection:[],
            //开票新增对象
            detailData: {},
            columns: [
                {
                    props: 'orderNo',
                    labels: '采购单号',
                    widths: 100,
                },
                {
                    props: 'createTime',
                    labels: '订单时间',
                    widths: 120
                },
                {
                    props: 'totalAmount',
                    labels: '订单金额',
                    widths: 100,
                    type:'money',
                    align:'center'
                },
                {
                    props: 'invoiceAmount',
                    labels: '可开票金额',
                    widths: 100,
                    type:'money',
                    align:'center'
                }
            ],
            tableData: [],
            page: {
                currentPage: 1,
                pageSize: 10,
                pageSizes: [10, 20, 50, 100],
                total: 0
            },
            sortName: '',
            order: '',
            //进出口判断
            bizTypeList:[],
            entrustType:'',
            bizType:{},
            importTypeList: ['01', '02', '11'],
            exportTypeList: ['03', '04', '06'],
        }
    },
    computed:{
        isDisabled(){
            if(this.multipleSelection.length>0){
                return false
            }else{
                return true
            }
        }
    },
    methods:{
        //日期
        changeTimeRange(obj){
            this.formSearch.updateTimeArr1=obj
            if (obj) {
                this.formSearch.createTimeBegin = obj[0];
                this.formSearch.createTimeEnd = obj[1];
            } else {
                this.formSearch.createTimeBegin = "";
                this.formSearch.createTimeEnd = "";
            }
            this.searchForm()
        },
        filterColumns(val){
            this.columns=val
        },
        pageSearch(val){
            this.page.pageSize=val.pageSize
            this.page.currentPage=val.currentPage
            this.getListData()
        },
        //开票
        invoice(id){
            this.$router.push({
                path:'/invoice-add',
                query:{id}
            })
        },
        //获取数据
        getListData(){
            this.load.isloading=true
            this.formSearch.invoiced = 0
            let dataList = {
                data:{...this.formSearch},
                pageNum: this.page.currentPage,
                pageSize: this.page.pageSize
            }
            this.$post("/orderBase/list",dataList).then(({ data }) => {
                if (data && data.code === "C0000") {
                    this.tableData = data.result.list;
                    this.tableData.forEach(e=>{
                        e.invoiceAmount = this.$calc.sub(e.totalAmount || 0, e.invoiceAmount || 0)
                    })
                    this.page.total = data.result.total;
                } else {
                    this.tableData = [];
                    this.page.total = 0;
                }
            }).finally(() => {
                this.load.isloading=false
            });
        },
        //搜索
        searchForm(){
            this.getListData()
        },
        //批量开票
        applyInvoicing(row) {
            this.load.isAddLoading=true
            let data
            if(row){
                data = { waitInvoiceVos: [row] };
            }else{
                data = { waitInvoiceVos: this.multipleSelection };
            }
            this.$post("/fms/client/drawInvoice/queryAddInfos", data).then(({ data }) => {
                if (data && data.code === "C0000") {
                    if (data.code === "C0000") {
                        this.detailData.result = data.result
                        this.detailData.customerVo = data.result.customerVo;
                        this.detailData.customerVo.number = data.result.number;
                        this.detailData.customerVo.invoiceAmount =data.result.invoiceAmount;
                        this.detailData.customerVo.tax = data.result.tax;
                        this.detailData.customerVo.taxRate = data.result.taxRate;
                        this.detailData.drawInvoiceEntryVos =data.result.drawInvoiceEntryVos;
                        window.sessionStorage.removeItem('detailData')
                        window.sessionStorage.setItem('detailData',JSON.stringify(this.detailData))
                        this.$router.push({path: '/invoice-add'})
                    }
                } else {
                    this.$message({type: 'error', message: data.message, offset: 380, grouping: true})
                }}
            ).finally(() => {
                this.load.isAddLoading=false
            });
        },
        //列表选择项
        handleSelectionChange(array) {
            this.multipleSelection = array;
        },
        //导出
        exportFile() {
            this.load.btnLoading=true
            let params = {}
            let excelField = this.columns.map(e => e.props);
            params.colTitleMapList = this.columns
            params.excelField= excelField
            params.data = this.formSearch,
            this.$post("/fms/client/drawInvoice/deriveWaitList", params).then(({ data }) => {
                if (data && data.code === "C0000") {
                    // 创建隐藏的可下载链接
                    var eleLink = document.createElement("a");
                    eleLink.download = "";
                    eleLink.target = "_blank";
                    eleLink.style.display = "none";
                    // 字符内容转变成blob地址
                    eleLink.href = data.message;
                    // 触发点击
                    document.body.appendChild(eleLink);
                    eleLink.click();
                    // 然后移除
                    document.body.removeChild(eleLink);
                } else {
                    this.$message({type: 'error', message: data.message, offset: 380, grouping: true})
                }
            }).finally(() => {
                this.load.btnLoading=false
            });
        },
    },
    created(){
        this.getListData()
        this.detailData={}
    },
    mounted(){}
}
</script>

<style lang="scss">
.invoice-list{
    .el-menu.el-menu--horizontal{
        display: none;
    }
    .toolbar{
        padding-bottom: 0 !important;
    }
}
</style>
