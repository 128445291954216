<template>
  <div class="login" :style="{ height: documentClientHeight }">
    <!-- <div class="logo"></div> -->
    <div class="login-box">
      <div class="left-logo">
        <img :src="require(`@/assets/img/login-bj.png`)" />
      </div>
      <div class="login-content">
        <el-form :model="dataForm" :rules="rules" @keyup.enter="formSubmit" ref="dataForm">
          <div class="lc-main">
            <template v-if="loginWay === 'wechat'">
              <div class="login-qrcode" id="loginQrcode"></div>

              <div class="scan-login">
                <i></i>
                <span @click="loginWay = 'username'">返回账号登录</span>
              </div>
            </template>
            <el-tabs v-model="loginWay" v-else class="demo-tabs">
              <el-tab-pane label="账号登录" name="username">
                <template v-if="loginWay == 'username'">
                  <el-form-item prop="userName">
                    <el-input v-model="dataForm.userName" placeholder="请输入登录账号"></el-input>
                  </el-form-item>

                  <el-form-item class="password" prop="password" style="margin-bottom: 0">
                    <el-input type="password" v-model="dataForm.password" placeholder="请输入密码"></el-input>
                  </el-form-item>

                  <el-form-item class="checked">
                    <el-checkbox v-model="checked">记住密码</el-checkbox>
                    <p class="forgot-password" @click="forgotPassword">忘记密码?</p>
                  </el-form-item>

                  <el-button class="login-btn-submit" type="primary" @click="loginSubmit"
                    :loading="isloading">登录</el-button>
                  <div class="scan-login">
                    <i class="wechat"></i>
                    <span @click="switchWechatLogin">微信扫码登录</span>
                  </div>
                </template>
              </el-tab-pane>
              <el-tab-pane label="手机登录" name="phone">
                <template v-if="loginWay == 'phone'">
                  <el-form-item prop="username">
                    <el-input v-model="dataForm.username" @input="handleInput('username')" autocomplete="off"
                      placeholder="请输入手机号码"></el-input>
                  </el-form-item>

                  <el-form-item class="captcha" prop="captcha">
                    <el-input v-model="dataForm.captcha" @input="handleInput('captcha')"
                      autocomplete="off" placeholder="请输入手机验证码">
                      <template #append>
                        <el-button style="width:150px;" :class="isActive ? 'activeBtn' : ''" :disabled="isCodeDisabled"
                          @click="checkedPhoneCode">{{
                            phoneCodeBtn }}</el-button>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item class="agreement" prop="agreementChecked">
                    <el-checkbox v-model="dataForm.agreementChecked">我已阅读并同意
                      <a href="https://xzbom.oss-cn-shenzhen.aliyuncs.com/uploads/%E6%96%B0%E6%B3%BD%E5%9C%A8%E7%BA%BF%E9%A2%84%E4%BB%98%E5%8D%8F%E8%AE%AE.docx" target="_blank">《油柑网用户注册协议》</a>
                    </el-checkbox>
                  </el-form-item>

                  <el-button class="login-btn-submit" type="primary" @click="regSubmit"
                    :loading="isloading">注册/登录</el-button>
                  <div class="scan-login">
                    <i class="wechat"></i>
                    <span @click="switchWechatLogin">微信扫码登录</span>
                  </div>
                </template>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-form>
      </div>
    </div>

    <!-- 选择租户 -->
    <el-dialog title="请选择租户" v-model="dialog" width="30%">
      <div class="radio-box">
        <el-radio-group v-model="tenantId">
          <el-radio v-for="(item, i) in tenants" :key="i" :label="i">{{
            item.tenantName
          }}</el-radio>
        </el-radio-group>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialog = false">取 消</el-button>
          <el-button type="primary" :loading="setTenantState" @click="setTenant">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 重置密码 -->
    <el-dialog width="30%" append-to-body title="设置密码" :show-close="false" v-model="password.show"
      :close-on-click-modal="false" :close-on-press-escape="false" class="resetPassword">
      <el-form :model="password.data" status-icon :rules="password.rules" ref="passwordForm" label-width="100px">
        <el-form-item label="密码：" prop="pass">
          <el-input type="password" @change="passwordChange" v-model="password.data.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="checkPass">
          <el-input type="password" v-model="password.data.checkPass" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closePassword">取 消</el-button>
          <el-button type="primary" :loading="password.loading" @click="resetPassword">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 绑定账号 -->
    <el-dialog title="绑定账号" v-model="bindDialog" :before-close="bindDialogClose" :close-on-click-modal="false"
      :close-on-press-escape="false" width="440px">
      <div>
        <p style="margin-bottom:15px;text-align:center;">
          首次扫码登录，请绑定您的账号
        </p>
        <el-form :model="bindForm" :rules="rules" ref="bindForm" class="bindForm" label-width="90px"
          @keyup.enter="bindFormSubmit" status-icon>
          <el-form-item label="账号：" prop="userName" :rules="rules.userName">
            <el-input v-model="bindForm.userName" clearable></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="password" :rules="rules.password">
            <el-input v-model="bindForm.password" type="password" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="bindDialogClose">取 消</el-button>
          <el-button type="primary" :loading="bindLoading" @click="bindFormSubmit">绑定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import vueCookie from 'vue-cookies'
import { Base64 } from 'js-base64'
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入密码'))
      } else {
        let reg = new RegExp('^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,13}$')
        if (!reg.test(value)) {
          return callback(new Error('密码需6-13位，包含大小写字母与数字'))
        }
        callback()
      }
    };

    const validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.password.data.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      documentClientHeight: '400px',
      cnAddress: '', //登录地址
      loginRemoteIp: '', //登录IP
      mac: '', // mac地址
      routeQuery: {},
      redirect_uri: '',
      loginWay: 'username',
      dataForm: {},
      rules: {
        userName: [
          { required: true, message: '帐号不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value) {
                if (!/^1[0-9]{10}$/.test(value)) {
                  callback(new Error('请输入11位手机号码'));
                }
              }
              callback();
            },
            trigger: 'blur'
          }
        ],
        captcha: [
          { required: true, message: '请输入手机验证码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value) {
                if (value.length !== 5) {
                  callback(new Error('验证码错误'));
                }
              }
              callback();
            },
            trigger: 'blur'
          }
        ],
        agreementChecked: [
          { required: true, message: '请阅读并是否同意协议', trigger: 'change' }
        ]
      },
      phoneCodeBtn: '获取手机验证码',
      uuid: '',
      isActive: false,
      isCodeDisabled: false,
      checked: false,
      setTenantState: false,
      dialog: false,
      password: {
        show: false,
        loading: false,
        data: {},
        rules: {
          pass: [{ validator: validatePass, trigger: 'blur' }],
          checkPass: [{ validator: validatePass2, trigger: 'blur' }]
        }
      },
      tenant: '',
      tenantId: '',
      tenants: [],
      bindDialog: false,
      bindLoading: false,
      bindForm: {
        userName: '',
        password: '',
        openId: ''
      },
      isloading: false
    }
  },

  computed: {
    activeMenu: {
      get() {
        return this.$store.state.activeMenu
      },
      set(val) {
        this.$store.commit('updateActiveMenu', val)
      }
    },
    mainTabs: {
      get() { return this.$store.state.mainTabs },
      set(val) { this.$store.commit('updateMainTabs', val) }
    },
    mainTabsActiveName: {
      get() { return this.$store.state.mainTabsActiveName },
      set(val) { this.$store.commit('updateMainTabsActiveName', val) }
    },
  },

  watch: {
    $route(to, from) {
      if (to.query && to.query.code) {
        this.wechatQRCodeLogin(to.query.code)
      }
    }
  },

  created() {
    this.cnAddress = window.returnCitySN ? window.returnCitySN['cname'] : '' //登录地址
    this.loginRemoteIp = window.returnCitySN ? window.returnCitySN['cip'] : '' //登录IP
    this.mac = '' // mac地址
    console.log(this.cnAddress, this.loginRemoteIp)
  },
  mounted() {
    this.documentClientHeight = document.documentElement['clientHeight'] + 'px'
    window.onresize = () => {
      this.documentClientHeight = document.documentElement['clientHeight'] + 'px'
    }

    let query = this.$route.query || {}
    this.routeQuery = Object.keys(query).length ? query : this.routeQuery

    this.redirect_uri = encodeURIComponent(window.location.href)
    // 如果域名为www.xzbom.com则改成xzbom.com
    if (window.location.host.indexOf('www.') > -1) {
      this.redirect_uri = encodeURIComponent(window.location.href.replace('www.', ''))
    }
    // 引入微信登录JS
    const wxScript = document.createElement('script')
    wxScript.type = 'text/javascript'
    wxScript.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
    document.body.appendChild(wxScript)
    this.getAccount()
  },

  methods: {
    passwordChange() {
      if(this.password.data.pass == this.password.data.checkPass) {
        this.$refs.passwordForm.clearValidate('checkPass')
      }
    },
    forgotPassword() {
      this.$router.push({ path: '/forgot-password' })
    },

    getAccount() {
      var curTime = new Date().getTime()
      var data = localStorage.getItem('information')
      var dataObj = JSON.parse(data)
      if (data) {
        if (curTime - dataObj.time > 1000 * 60 * 60 * 24 * 7) {
          localStorage.removeItem('information')
        } else {
          this.dataForm.userName = dataObj.data.userName
          this.dataForm.password = dataObj.data.password
          this.checked = true
        }
      }
    },

    formSubmit() {
      if (this.loginWay === 'username') {
        this.loginSubmit()
      } else if (this.loginWay === 'phone') {
        this.regSubmit()
      }
    },

    loginSubmit() {
      console.log(this.$refs.dataForm)
      this.$refs.dataForm.validate(valid => {
        console.log(valid)
        if (valid) {
          let password = Base64.encode(this.dataForm.password)
          this.isloading = true
          this.$post('/auth/reg', {
            username: this.dataForm.userName,
            password: password,
            type: 'username'
          }).then(({ data }) => {
            if (data && data.code === 'C0000') {
              if (data.result.isPwdExpired) {
                this.password.show = true
              } else {
                this.authAfter(data)
              }
            }
          }).finally(() => {
            setTimeout(() => {
              this.isloading = false
            }, 1000)
          })
        }
      })
    },

    authAfter(data) {
      this.setAccount()
      vueCookie.set('token', data.result.token, {
        domain: process.env.VUE_APP_DOADMIN
      });
      this.$utils.setLocalStorage('companyId', data.result.companyId)
      this.$utils.setSessionStorage('userInfo', data.result)
      this.activeMenu = ''
      this.mainTabs = []
      this.mainTabsActiveName = ''
      this.$utils.setSessionStorage('bizType', {
        clientImportRole: false,
        clientExportRole: false,
        clientWmsRole: false,
        activeIndex: '00'
      })
      // 初始化sessionStorage数据
      this.$initSessionStorageData.init()
      this.$router.replace({
        path: '/',
        query: {
          isOuter: 1
        }
      })
    },

    setAccount() {
      if (this.checked) {
        var curTime = new Date().getTime()
        localStorage.setItem(
          'information',
          JSON.stringify({ data: this.dataForm, time: curTime })
        );
      }
    },
    handleInput(key) {
      if (this.loginWay == 'phone') {
        this.dataForm[key] = this.dataForm[key].replace(/[^0-9]/g, '');
        if (key == 'username') {
          if (/^1[0-9]{10}$/.test(this.dataForm.username)) {
            this.isActive = true
          } else {
            this.isActive = false
          }
        }
      }

    },

    checkedPhoneCode() {
      let that = this
      if (!/^1[0-9]{10}$/.test(this.dataForm.username)) {
        return
      }
      this.isCodeDisabled = true
      this.$get('/auth/sendLoginMsgToPhone', { phone: this.dataForm.username }).then(({ data }) => {
        if (data && data.code === 'C0000') {
          let index = 60
          let codeTime = setInterval(() => {
            if (index <= 0) {
              clearInterval(codeTime)
              that.isCodeDisabled = false
              that.phoneCodeBtn = '获取手机验证码'
              that.isActive = true
            } else {
              this.phoneCodeBtn = '重新获取(' + index + ')'
              that.isActive = false
              index--
            }
          }, 1000);
          that.uuid = data.result
        } else {
          this.isCodeDisabled = false
        }
      })
    },

    regSubmit() {
      this.$refs.dataForm.validate(valid => {
        if (valid) {
          this.isloading = true
          this.$post('/auth/reg', {
            mobile: this.dataForm.username,
            code: this.dataForm.captcha,
            type: 'phone',
            uuid: this.uuid
          }).then(({ data }) => {
            if (data && data.code === 'C0000') {
              let user = data.result && data.result.user
              if (user.user.pwdResetTime) {
                this.authAfter(data)
              } else {
                this.password.show = true
                this.password.userId = user.user.id
                this.password.authData = data
              }

            }
          }).finally(() => {
            setTimeout(() => {
              this.isloading = false
            }, 1000)
          })
        }
      })
    },

    switchWechatLogin() {
      this.loginWay = 'wechat'
      this.$nextTick(() => {
        this.wechatLoginInit()
      })
    },

    wechatLoginInit() {
      let ba64Css =
        'LmltcG93ZXJCb3gge3dpZHRoOiAyMDBweDt9Ci5pbXBvd2VyQm94IC5xcmNvZGUge3dpZHRoOiAyMDBweDttYXJnaW46IDA7fQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnN0YXR1cyB7cGFkZGluZzogMDt0ZXh0LWFsaWduOiBjZW50ZXI7fQouaW1wb3dlckJveCAuc3RhdHVzIC5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZTt9';
      new WxLogin({
        id: 'loginQrcode',
        appid: 'wx278a78e875d7f010',
        scope: 'snsapi_login',
        redirect_uri: this.redirect_uri,
        state: '',
        style: '',
        sandbox: 'allow-scripts allow-top-navigation allow-same-origin',
        href: 'data:text/css;base64,' + ba64Css
      });
      this.$router.push({ path: '/login' })
    },

    wechatQRCodeLogin(code) {
      if (Array.isArray(code)) {
        code = code.pop()
      }
      let data = { code }
      data.cnAddress = this.cnAddress
      data.loginRemoteIp = this.loginRemoteIp
      data.type = 'wechat'

      this.$post('/auth/reg', data).then(({ data }) => {
        if (data && data.code === 'C0000') {
          this.bindForm.openId = data.result.openId
          if (data.result.isBindWechat === undefined || data.result.isBindWechat === true) {
            this.$message({
              type: 'success',
              message: '扫码登录成功！',
              offset: 380,
              grouping: true
            })
            this.authAfter(data)
          } else {
            this.bindDialog = true
          }
        } else {
          this.wechatLoginInit()
        }
      }).catch(() => {
        this.wechatLoginInit()
      })
    },

    bindFormSubmit() {
      this.$refs.bindForm.validate(valid => {
        if (valid) {
          this.bindLoading = true
          this.$post('/auth/reg', {
            username: this.bindForm.userName,
            password: Base64.encode(this.bindForm.password),
            loginType: 'pc',
            loginMode: 'sm',
            clientSource: '2',
            cnAddress: this.cnAddress,
            loginRemoteIp: this.loginRemoteIp,
            openId: this.bindForm.openId,
            type: 'username'
          }).then(({ data }) => {
            if (data && data.code === 'C0000') {
              if (data.result.isPwdExpired) {
                this.password.show = true
              } else {
                this.authAfter(data)
              }
            }
          }).finally(e => {
            setTimeout(() => {
              this.bindLoading = false
            }, 1000)
          })
        }
      });
    },

    bindDialogClose() {
      this.bindDialog = false
      this.$nextTick(() => {
      this.wechatLoginInit()
      })
    },

    closePassword() {
      this.$refs.passwordForm.resetFields()
      this.password.show = false
      this.authAfter(this.password.authData)
    },

    resetPassword() {
      this.$refs.passwordForm.validate(valid => {
        if (valid) {
          this.loading = true;
          let data = {
            password: Base64.encode(this.password.data.pass),
            userId: this.password.userId
          };
          this.$post('/api/users/resetPass', data).then(({ data }) => {
            if (data && data.code === 'C0000') {
              this.$message({
                type: 'success',
                message: data.message,
                offset: 380,
                grouping: true
              })
              setTimeout(() => {
                this.closePassword()
              }, 1000)
            }
          })
        }
      })
    },

    ...mapActions([
      'ActionsLoginId',
      'ActionsUserId',
      'ActionsTenantId',
      'ActionsUpdateName'
    ])
  }
};
</script>

<style lang="scss">
.login {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background: linear-gradient(1deg, #c2c5c7, #fcfeff);
  box-shadow: 0px 3px 68px 0px rgba(0, 0, 0, 0.05);

  .activeBtn {
    color: #00458E !important;
    border-color: #00458E !important;
  }

  .logo {
    background-image: url(@/assets/img/logo.png);
    height: 70px;
    width: 194px;
    position: absolute;
    left: 35px;
    top: 35px;
    background-repeat: no-repeat;
  }

  .login-box {
    width: 800px;
    height: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -400px;
    background-color: #fff;
    border-radius: 12px;

    .left-logo {
      width: 400px;
      float: left;
      height: 450px;
      border-right: 1px solid #e5e5e5;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .login-content {
      float: right;
      width: 400px;
      height: 450px;
      left: 65%;
      text-align: left;
      color: #000;

      .lc-name {
        font-size: 30px;
      }

      .lc-main {
        width: 400px;
        // height: 497px;
        padding: 27px 30px;

        .way {
          margin-bottom: 38px;

          span {
            display: inline-block;
            height: 40px;
            font-size: 20px;
            cursor: pointer;

            &.active {
              color: #01458e;
              position: relative;

              &::after {
                position: absolute;
                content: "";
                width: 40px;
                height: 2px;
                background-color: #01458e;
              }
            }

            &+span {
              margin-left: 36px;
            }
          }
        }

        .el-form-item {
          margin-bottom: 24px;

          .el-input {
            font-size: 14px;

            .el-input__wrapper {
              box-shadow: none;
              border-radius: 8px;
              background: #F0F2F5;

              .el-input__inner {
                height: 50px;
                padding-left: 9px;
                background: #F0F2F5;
              }
            }

            .el-input-group__append {
              box-shadow: none;
            }
          }

          &:nth-child(3) {
            margin: 5px 0 10px;
          }

          &.checked {
            margin-bottom: 0;

            .el-form-item__content {
              display: flex;
              justify-content: space-between;
              font-size: 14px;

              .el-checkbox__label {
                font-size: 14px;
              }

              .forgot-password {
                color: var(--el-text-color-primary);
                cursor: pointer;

                &:hover,
                &:active {
                  color: var(--el-color-primary);
                }
              }
            }
          }

          &.captcha {
            margin-bottom: 37px;

            .el-input__wrapper {
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }
          }
        }

        .login-btn-submit {
          width: 100%;
          height: 50px;
          margin-top: 17px;
          border-radius: 8px;
          background: #f10201;
          font-size: 16px;
        }

        .scan-login {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 25px;
          font-size: 16px;

          i {
            display: inline-block;
            width: 28px;
            height: 28px;
            margin-right: 6px;
            background: url(@/assets/img/return-login.png) no-repeat;

            &.wechat {
              background: url(@/assets/img/wechat.png) no-repeat;
            }
          }

          span {
            cursor: pointer;
          }
        }

        .login-qrcode {
          width: 202px;
          height: 250px;
          overflow: hidden;
          margin: 0 auto;
          margin-top: 40px;
          margin-bottom: 15px;

          iframe {
            width: 202px;

            .wrp_code {
              .lightBorder {
                width: 100px;
              }
            }
          }

          img {
            height: 100px;
          }
        }
      }

      .el-tabs {
        .el-tabs__nav-wrap::after {
          display: none;
        }

        .el-tabs__header {
          margin-bottom: 24px;

          .el-tabs__active-bar {
            width: 40px !important;
            margin-left: 20px;
            background-color: var(--el-color-primary);
          }

          .el-tabs__item {
            font-size: 20px;

            &.is-active {
              color: var(--el-color-primary);
            }
            &:not(.is-disabled):hover {
              color: var(--el-color-primary);
            }
          }
        }
      }

      .agreement {
        a {
          color: var(--el-color-primary);
          text-decoration: none;
        }
      }
    }

  }
}
</style>
