<template>
  <el-row>
    <el-col class="search-content-data-time">
      <el-form :model="formData" inline  class="formFlexBox">
        <el-form-item class="dateGroup">
          <!--<el-radio-group v-model="radioValue" @change="changeDate" class="radioGroup">-->
            <!--<el-radio-button label="今天" @click="searchHandle"></el-radio-button>-->
            <!--<el-radio-button label="本周"></el-radio-button>-->
            <!--<el-radio-button label="本月"></el-radio-button>-->
          <!--</el-radio-group>-->
          <el-date-picker
            v-model="input"
            @change="searchHandle"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="~"
            :start-placeholder="startSrc"
            :end-placeholder="endSrc"
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"            
            class="timeSelect"
            :picker-options="pickershortcutKey">
          </el-date-picker>
        </el-form-item>
        <el-col class="flexItem">
          <el-col class="rightFl">
            <!--<el-button type="primary" @click="searchHandle">查询</el-button>-->
            <!--<el-form-item>-->
              <!--<div class="tmpContent" v-if="showTodoInput">-->
                <!--<el-input-->
                  <!--class="inline-input"-->
                  <!--v-model="formData.registrar"-->
                  <!--ref="enterInputA"-->
                  <!--name="enterInputA"-->
                  <!--placeholder="请输入项目号">-->
                <!--</el-input>-->
              <!--</div>-->
            <!--</el-form-item>-->
            <!--<el-form-item class="search-button">-->
              <!--<el-button type="primary" @click="searchHandle">查询</el-button>-->
              <!--<el-button type="primary" @click="showMoreSearch = !showMoreSearch">更多查询</el-button>-->
            <!--</el-form-item>-->
          </el-col>
        </el-col>
      </el-form>
    </el-col>
<!--    <el-col class="button-content">
      &lt;!&ndash;放一个尺寸为medium的button&ndash;&gt;
      <div>
        <slot name="button"></slot>
      </div>
      <div class="moreSearch" v-show="showMoreSearch">
        &lt;!&ndash;此处放置更多搜索条件&ndash;&gt;
        <slot name="moreSearch"></slot>
      </div>
    </el-col>-->
    <el-col class="table-content">
      <!--放一个table-->
      <slot name="table"></slot>
    </el-col>
  </el-row>
</template>

<script>
  export default {
    name: 'ab-timeSearch',
    model: {
        modelValue: String
        
    },
    emits: ['update:modelValue'],
    data() {
      return {
        pickershortcutKey: {
          shortcuts: [
            {
              text: '今天',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '本周',
              onClick(picker) {
                let now = new Date()
                let nowTime = now.getTime()
                let day = now.getDay()
                let oneDayLong = 24 * 60 * 60 * 1000
                let MondayTime = nowTime - (day - 1) * oneDayLong
                let SundayTime = nowTime + (7 - day) * oneDayLong
                let monday = new Date(MondayTime)
                let sunday = new Date(SundayTime)
                // const end = new Date()
                // const start = new Date()
                // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [monday, sunday])
              }
            },
            {
              text: '本月',
              onClick(picker) {
                let now = new Date()
                let nowMonth = now.getMonth()
                let nowYear = now.getYear()
                nowYear += (nowYear < 2000) ? 1900 : 0
                let monthStartDate = new Date(nowYear, nowMonth, 1)
                let monthEndDate = new Date(nowYear, nowMonth + 1, 1)
                let days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24)
                monthStartDate = new Date(nowYear, nowMonth, 1)
                monthEndDate = new Date(nowYear, nowMonth, days)
                // const end = new Date()
                // const start = new Date()
                // start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [monthStartDate, monthEndDate])
              }
            }
          ]
        },
        formData: {
          date: '',
          registrar: '',
          keyWord: ''
        },
        input: this.modelValue,
        radioValue: '',
        showMoreSearch: false
      }
    },
    props: {
      startSrc: {
        type: String,
        default: '选择日期'
      },
      endSrc: {
        type: String,
        default: '选择日期'
      },
      todoList: {
        type: Array,
        default () {
          return []
        }
      },
      modelValue: { // 输入框值
        type: [Array, String],
        default: ''
      },
      showTodoInput: {
        type: Boolean,
        default: true
      },
    },
    // mounted() {
    //   let _this = this
    //   // 回车事件触发搜索
    //   if (this.showTodoInput) {
    //     this.$refs.enterInputA.$el.querySelector('input[name]').onkeyup = (e) => {
    //       if (e.keyCode === 13) {
    //         _this.searchHandle()
    //       }
    //     }
    //   }
    // },
    watch: {
      modelValue (v) {
        this.input = v
      },
      input (v) {
        // console.log(v)
        if (!v) {
          this.radioValue = ''
        } else if (!v[0]) {
          this.radioValue = ''
        } else {
          this.$emit('update:modelValue', v)
        }
      }
    },
    methods: {
      changeDate(res) {
        let now = new Date()
        let nowTime = now.getTime()
        if (res === '今天') {
          this.input = [this.$utils.getDate(now), this.$utils.getDate(now)]
          this.searchHandle()
        } else if (res === '本周') {
          let day = now.getDay()
          let oneDayLong = 24 * 60 * 60 * 1000
          let MondayTime = nowTime - (day - 1) * oneDayLong
          let SundayTime = nowTime + (7 - day) * oneDayLong
          let monday = new Date(MondayTime)
          let sunday = new Date(SundayTime)
          this.input = [this.$utils.getDate(monday), this.$utils.getDate(sunday)]
          this.searchHandle()
        } else {
          let nowMonth = now.getMonth()
          let nowYear = now.getYear()
          nowYear += (nowYear < 2000) ? 1900 : 0
          let monthStartDate = new Date(nowYear, nowMonth, 1)
          let monthEndDate = new Date(nowYear, nowMonth + 1, 1)
          let days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24)
          monthStartDate = new Date(nowYear, nowMonth, 1)
          monthEndDate = new Date(nowYear, nowMonth, days)
          this.input = [this.$utils.getDate(monthStartDate), this.$utils.getDate(monthEndDate)]
          this.searchHandle()
        }
      },
      searchHandle (e) {
        e || this.$emit('update:modelValue', '')
        this.$emit('searchEvent', this.input)
      }
    }
  }
</script>

<style  lang="scss">
  .search-content-data-time{
    .formFlexBox{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .dateGroup{
        /*flex: 55%;*/
        margin: 0 ;
        .radioGroup{
          vertical-align: top;
        }
      }
      .flexItem{
        flex: 40%;
        .rightFl{
          text-align: right;
          .tmpContent{
            position: relative;
            .inline-input{
              z-index: 5;
              width: 360px;
              background: transparent;
            }
          }
        }
      }
      .search-input {
        width: 360px;
      }
      .search-button{
        position: relative;
        margin-right: 0;
        .absInput{
          z-index: 10;
          position: absolute;
          right: 0;
          bottom: -58px;
        }
      }
      .el-form-item--mini {
        width: 100%;
        .el-form-item__content {
          width: 100%!important;
        }
      }
      
    }
    .el-range__icon {
      line-height: 42px;
    }
  }
  .button-content{
    display: flex;
    justify-content: space-between;
    height: 36px;
    margin-bottom: 12px;
    .moreSearch{
      font-size: 15px;
    }
  }
</style>
<style lang="scss">
  .timeSelect{
    width: 100% !important;
    .el-range-input{
      width: 39%;
    }
  }
  .tmpContent{
    .inline-input{
      input{
        background-color: transparent;
      }
    }
  }
  .search-content{
    .dateGroup {
        width: 100%;
    }
    .el-form-item__content{
        width: 100%;
    }
  }
  
</style>
