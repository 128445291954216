<template>
    <el-dialog title="添加BOM商品" width="90%" class="add-order-dialog list-page" v-model="showVisible" top="5vh" @close="cancel"
      :close-on-click-modal='false'>
        <el-form :model="dataFrom" :rules="rules" ref="dataFrom" label-position="top">
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-input placeholder="BOM单号" v-model.trim="dataFrom.inquiryNo" clearable>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button @click="searchForm">搜索</el-button>
                    <el-button link class="btn-svg" @click="dataFrom = {}"><ab-svg name="qingchu"></ab-svg>清除</el-button>
                </el-col>
            </el-row>
        </el-form>
        <ab-table style="margin-top: 20px;" class="materialTable" border ref="add-order-table" stripe showOrder showSelection selectionWidth="45"
            :handleSelectionChange="handleSelectionChange" indexLabel="序号" indexWidth="60" :column-list="columns"
            :table-list="tableData" :dataListLoading="isloading">
            <template #a1="scope">
                <div class="col-item">
                    <p :title="scope.row.productModel">{{scope.row.productModel}}</p>
                    <p :title="scope.row.parameter" style="font-weight: bold">{{scope.row.parameter}}</p>
                    <p :title="scope.row.remark">备注：{{scope.row.remark}}</p>
                </div>
            </template>
            <template #result="scope">
                <div class="col-item" v-if="(scope.row.status || scope.row.status == 0)">
                    <i class="dot" :class="resultData[scope.row.status].class"></i> {{
                        resultData[scope.row.status].label }}
                </div>
            </template>
            <template #recommend="scope">
                <div v-if="scope.row.status == 2" class="col-item">
                    <p class="tishi"><el-icon>
                            <WarningFilled />
                        </el-icon> 暂无售卖您需要的商品，可拨打电话“0755-83245155”咨询</p>
                </div>
                <div v-else>
                    <div class="col-item">
                        <p>型号：<span class="blue-color">{{ scope.row.recommendProdVo &&
                            scope.row.recommendProdVo.productMpN }}</span> </p>
                        <p>品类：<span class="blue-color"> {{ scope.row.recommendProdVo &&
                            scope.row.recommendProdVo.category }}</span></p>
                        <p>品牌：<span class="blue-color">{{ scope.row.recommendProdVo && scope.row.recommendProdVo.brand
                        }}</span></p>
                        <p>封装：<span class="blue-color">{{ scope.row.recommendProdVo &&
                            scope.row.recommendProdVo.productPackage }}</span></p>
                        <p>规格：<span class="blue-color">{{
                            scope.row.recommendProdVo && scope.row.recommendProdVo.productSpec }}</span>
                        </p>
                        <p class="locationInfo" v-if="scope.row.locationInfo" :title="scope.row.locationInfo">{{ scope.row.locationInfo }}</p>
                    </div>
                </div>
            </template>
            <template #productMoQ="scope">
                <div class="col-item">
                    <p>起订量：{{ scope.row.recommendProdVo && scope.row.recommendProdVo.productMoQ }} </p>
                    <p>增量：{{ scope.row.recommendProdVo && scope.row.recommendProdVo.productIncrement }} </p>
                    <p>交期：{{ scope.row.recommendProdVo && scope.row.recommendProdVo.leadTime }}</p>
                    <p>库存：{{ scope.row.recommendProdVo && scope.row.recommendProdVo.stock }}</p>
                </div>
            </template>
            <template #price="scope">
                <div class="col-item">
                    <p v-if="scope.row.recommendProdVo && scope.row.recommendProdVo.advicePrice">
                        {{ scope.row.recommendProdVo && scope.row.recommendProdVo.advicePrice}}
                    </p>
                    <p class="tishi" v-else>
                        <el-icon>
                            <WarningFilled />
                        </el-icon>暂无报价，无法下单
                    </p>
                </div>
                <!-- <div class="col-icon">
                    <el-popover placement="bottom" title="价格梯度" :width="280" trigger="hover">
                        <template #reference>
                            <p
                                v-if="scope.row.recommendProdVo && scope.row.recommendProdVo.priceTierIds">
                                <el-icon>
                                    <View />
                                </el-icon>
                            </p>
                        </template>
                        <template #default v-if="scope.row.recommendProdVo && scope.row.recommendProdVo.priceTierIds">
                            <p v-for="item in scope.row.recommendProdVo.priceTierIds" :key="item">
                                <span class="blue-color">{{ item.quantity }}-{{ item.endQuantity }}</span> &nbsp;PCS <span
                                    class="blue-color"> {{ item.advicePrice }}&nbsp;</span>RMB
                            </p>
                        </template>
                    </el-popover>
                </div> -->
            </template>
            <template #total="scope">
                <div class="col-item">
                    {{ ((scope.row.recommendProdVo ? scope.row.recommendProdVo.advicePrice || 0 : 0) * scope.row.usageNum).toFixed(2) }}
                </div>
            </template>
        </ab-table>
        <ab-page style="margin-top: 10px" :pageTotal="page.total" :currentPage='page.currentPage' :pageSize="page.pageSize"
          :pageSizes="page.pageSizes" @searchForm="searchForm"></ab-page>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" :loading="btnLoading" :disabled="!selected || !selected.length" @click="confirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </template>

  <script>
  export default {
    name: 'add-order-dialog',
    props: {
      visible: {
        type: Boolean,
        default: false,
        required: true
      },
      btnLoading: {
        type: Boolean,
        default: false
      },
      ids: {
        type: Array,
        default: []
      }
    },
    computed: {
      showVisible: {
        get() {
          return this.visible
        }
      }
    },
    watch: {
        visible: {
            handler(value) {
                if(value) {
                    this.searchForm()
                }
            },
            deep: true
        }
    },
    data() {
      return {
        page: {
            currentPage: 1,
            pageSize: 5,
            pageSizes: [5, 10, 20, 50],
            total: 0
        },
        dataFrom: {},
        columns: [
            {
                props: 'inquiryNo',
                labels: 'BOM单号',
                widths: 135
            },
            {
                props: 'a1',
                labels: '需求规格/型号',
                widths: 220,
                slot: 'slot'
            },
            {
                props: 'brand',
                labels: '需求品牌',
                align: 'center',
                widths: 90
            },
            {
                props: 'productPackaging',
                labels: '需求封装',
                align: 'center',
                widths: 90
            },
            {
                props: 'aloneNum',
                labels: '单机用量',
                align: 'center',
                widths: 90
            },
            {
                props: 'demandNum',
                labels: '需求数',
                align: 'center',
                widths: 80
            },
            {
                props: 'usageNum',
                labels: '采购量',
                align: 'center',
                widths: 80
            },
            {
                props: 'result',
                labels: '匹配结果',
                align: 'center',
                widths: 90,
                slot: 'slot'
            },
            {
                props: 'recommend',
                labels: '推荐商品',
                widths: 200,
                slot: 'slot'
            },
            {
                props: 'productMoQ',
                labels: '起订量/交期',
                widths: 110,
                slot: 'slot'
            },
            {
                props: 'price',
                labels: '单价',
                widths: 120,
                slot: 'slot'
            },
            {
                props: 'total',
                labels: '小计',
                widths: 80,
                slot: 'slot'
            }
        ],
        tableData: [],
        isloading: false,
        // 	匹配状态 0待确认，1完全匹配，2无匹配
        resultData: {
            0: { label: '待确认', class: 'toConfirmClass', color: '#FF8400' },
            1: { label: '完全匹配', color: '#B58623' },
            2: { label: '无匹配', class: 'noClass', color: '#FF0000' },
        },
        selected: []
      }
    },
    methods: {
        // 单价
        unitPriceCount(row) {
            let unitPrice = 0
            let recommendProdVo = row.recommendProdVo || {}
            let priceTierIds = recommendProdVo.priceTierIds
            let quantity = row.usageNum
            if (priceTierIds && priceTierIds.length > 0) {
                priceTierIds.forEach(item => {
                    if (quantity >= item.quantity && quantity <= item.endQuantity) {
                        unitPrice = item.advicePrice
                    } else if (!item.endQuantity && quantity >= item.quantity) {
                        unitPrice = item.advicePrice
                    }
                })
            }
            return unitPrice.toFixed(6)
        },
        handleSelectionChange(val) {
            this.selected = val
        },
      isDisabled(v) {
        let flag = false
        for (const key in this.dataFrom) {
            if (Object.hasOwnProperty.call(this.dataFrom, key)) {
                const element = this.dataFrom[key];
                if(element == v && element != 'none') {
                    flag = true
                }
            }
        }
        return flag
      },
      searchForm(pages) {
        this.isloading = true
        if(pages) {
            this.page.currentPage = pages.currentPage || this.page.currentPage
            this.page.pageSize = pages.pageSize || this.page.pageSize
        }
        this.dataFrom.filterDetailIds = this.ids
        this.$post('/inquiryBase/listDetails', Object.assign({ data: this.dataFrom }, {
            pageNum: this.page.currentPage,
            pageSize: this.page.pageSize,
        })
        ).then(res => {
            if (res.data.code == 'C0000') {
                this.tableData = res.data.result.list
                this.page.total = res.data.result.total
                this.$nextTick(() => {
                    this.tableData = this.tableData.filter(e=>this.ids.indexOf(e.id) == -1)
                    // this.tableData.forEach(e=>{
                    //     if(this.ids.length && this.ids.indexOf(e.id) != -1) {
                    //         this.$refs['add-order-table'].$refs['table-common-table'].toggleRowSelection(e, true)
                    //     }
                    // })
                    this.calcNumber()
                })
            }
        }).finally(() => {
            this.isloading = false
        })
      },
    // 计算需求数和采购量
        calcNumber() {
            this.tableData.forEach(e=>{
                this.aloneBlur(e)
            })
        },
        aloneBlur(row) {
            let num = 0
            if(!row.recommendProdVo) return
            if(row.demandNum > row.recommendProdVo.productMoQ) {
                num = this.$calc.add(row.recommendProdVo.productMoQ, Math.ceil((row.demandNum - row.recommendProdVo.productMoQ) / row.recommendProdVo.productIncrement) * row.recommendProdVo.productIncrement)
            }else if (row.demandNum < row.recommendProdVo.productMoQ) {
                num = row.recommendProdVo.productMoQ
            }else {
                num = row.demandNum
            }
            row.usageNum = num
        },
      cancel() {
        this.dataFrom = {}
        this.tableData = []
        this.$emit('cancel')
      },
      confirm(){
        if(this.ids.length && this.selected.some(e=>this.ids.indexOf(e.id) != -1)) {
            this.$message.error('存在重复物料，请检查！')
            return
        }
        this.$emit('confirm', this.selected)
      }
    }
  }
  </script>

  <style lang="scss">
    .add-order-dialog{
        .el-table__body{
            max-height: calc(100vh - 600px);
            overflow-y: scroll;
        }

        .col-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;

            .del-icon {
                color: #FF453A;
                margin-left: 10px;
            }

            .sure-icon {
                margin-left: 10px;
            }
        }
        .tishi {
            color: #FF0000;

            .el-icon {
                font-size: 16px;
                vertical-align: middle;
                margin-right: 5px;
            }
        }
        .col-item {
            padding: 12px 8px;
            position: relative;
            text-align: left;

            .dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: inline-block;
                background: #00458E;
                margin-right: 3px;
            }

            .noClass {
                background: #FF0000;
            }

            .toConfirmClass {
                background: #FF8400;
            }

            p {
                white-space: nowrap;
                /*内容超宽后禁止换行显示*/
                overflow: hidden;
                /*超出部分隐藏*/
                text-overflow: ellipsis;
                /*文字超出部分以省略号显示*/
            }
            .locationInfo{
                font-size: 12px;
                color: red;
                border: 1px solid red;
                border-radius: 10px;
                padding: 0px 6px;
                display: inline-block;
                height: 18px;
                line-height: 16px;
                margin-top: 6px;
            }
        }
    }
</style>
