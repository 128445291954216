<template>
  <el-dialog :title="title" width="718px"  v-model="showVisible" @close="cancel"
    :close-on-click-modal='false' class="add-address-dialog">
    <el-form :model="dataFrom" v-loading="showloading" :rules="rules" ref="dataFrom" label-position="top">
      <el-row :gutter="30">
        <el-col :span="12" v-if="!dialog.type">
          <el-form-item label="适用业务" prop="bizTypeGroup">
              <el-select    v-model="dataFrom.bizTypeGroup" @change="changeBizTypeGroup" placeholder clearable>
              <el-option
                  v-for="(option, idx) in typeList.addressBizType"
                  :key="idx"
                  :label="option.desc"
                  :value="option.value"
                  :disabled="checkBizType(option.desc)"
                  ></el-option>
              </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="!dialog.type">
          <el-form-item label="地址类型" prop="addressType">
            <el-select   :disabled="dataFrom.bizTypeGroup == '01' || dataFrom.bizTypeGroup == '02'" v-model="dataFrom.addressType" @change="changeAddressType" placeholder clearable>
              <el-option
                v-for="(option, idx) in typeList.addressType"
                :key="idx"
                :label="option.desc"
                :value="option.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="30">
        

        <el-col :span="24" v-if="aiParse && aiSuccess">
          <div class="ai-address-title">请检查拆分地址信息是否准确，如有遗漏请及时补充</div>
        </el-col>

        <el-col :span="24" v-if="aiParse">
          <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="" class="ai-input">
              <el-input   maxLength='200' v-model="waitParseText" clearable placeholder="请黏贴包含地址的信息，点击智能填写，可自动识别拆分">
              </el-input>
            </el-form-item>
            </el-col>
            <el-col :span="4" style="text-align:right;">
              <el-button  @click="hangdlerAiData" plain>智能填写</el-button>
            </el-col>
          </el-row>
        </el-col>
      
        <el-col :span="12">
          <el-form-item label="联系人" prop="linkMan">
            <el-input   maxLength='50' placeholder="联系人" v-model="dataFrom.linkMan" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="linkPhone">
            <el-input   maxLength='20' placeholder="联系电话" v-model="dataFrom.linkPhone" ></el-input>
          </el-form-item>
        </el-col>
      

        <el-col :span="12">
          <el-form-item label="国家/省/市/区" prop="address">
            <ab-address v-model="dataFrom.address" @handleSelect="changeAddressDesc"></ab-address>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="详细地址" prop="addressContent">
            <el-input   v-model="dataFrom.addressContent"  maxLength="250" placeholder="详细地址"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="发货公司" prop="companyName">
            <el-input   v-model="dataFrom.companyName" ></el-input>
          </el-form-item>
        </el-col>
      
        <el-col :span="12">
          <el-form-item label="默认地址" prop="isDefault">
            <el-select   placeholder v-model="dataFrom.isDefault">
              <el-option :label="item.name" :value="item.value" v-for="(item,idx) in isDefaultAdds" :key="idx"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="!(isXplain && dialog.type)">
          <el-form-item label="地址说明" prop="addressExplain">
            <el-select    v-model="dataFrom.addressExplain" placeholder clearable>
              <el-option
                v-for="(option, idx) in typeList.explainList"
                :key="idx"
                :label="option.desc"
                :value="option.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="　">
            <ab-fileBtn v-model="fileData" @getTempBillId="getTempBillId" @change="changefileData"></ab-fileBtn>
          </el-form-item>
        </el-col> -->
      </el-row>

      <!-- <ab-alert v-model="fileList" @change="changefileData1"></ab-alert> -->
    </el-form>
   <template #footer>
      <div  class="dialog-footer">
          <!-- <span class="tips">*地址说明：选择客户地址请提供与下游客户的盖章订单；选择代工厂请提供代工合同。</span> -->
          <el-button type="primary" @click="handlerSave" :loading="isloading">确 定</el-button>
          <el-button @click="handleClose">取 消</el-button>
      </div>
   </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'ab-handlerAds',
  // model: {
  //     prop: 'dialog'
  //     // event: 'change'
  // },
  props: {
      dialog: {
          type: Object,
          default: () => {
              return {
                  show: false
              }
          }
      },
      aiParse: {
        type: Boolean,
        default: false
      }
  },
  data () {
      return {
          isloading: false,
          isXplain: false,
          aiSuccess: false,
          title: '',
          showloading: false,
          isAiLoading: false,
          sourceBillId: '',
          waitParseText: '',
          typeList: {
              addressReceive: [],
              addressBizType: [],
              addressType: [],
              addressSend: [],
              explainList: []
          },
          roleVos: [],
          dataFrom: {
            isDefault: 0
          },
          isDefaultAdds: [{name: '是', value: 1}, {name: '否', value: 0}],
          fileData: {
            sourceBillId: '',
            attachType: '070',
            sourceBillType: 'Address',
            sourceBillNumber: ''
          },
          fileList: [],
          rules: {
              bizTypeGroup: [
                  {required: true, message: '适用业务不能为空', trigger: ['blur', 'change']}
              ],
              addressType: [
                  {required: true, message: '地址类型不能为空', trigger: ['blur', 'change']}
              ],
              addressExplain: [
                  {required: true, message: '地址说明不能为空', trigger: ['blur', 'change']}
              ],
              companyName: [
                  {required: true, message: '公司名称不能为空', trigger: ['blur', 'change']}
              ],
              linkMan: [
                  {required: true, message: '联系人不能为空', trigger: ['blur', 'change']}
              ],
              linkPhone: [
                  {required: true, message: '联系电话不能为空', trigger: ['blur', 'change']},
                  { validator: (rule, value, callback) => {
                    if (/[\u4E00-\u9FA5]/g.test(value)) {
                        callback(new Error('电话号码不能输入中文!'))
                      } else if (value.length > 200){
                        callback(new Error('最大支持200位字符长度'))
                      } else {
                        callback()
                      }
                  }, trigger: ['blur', 'change'] }
              ],
              address: [
                  {required: true, message: '地址不能为空', trigger: ['blur', 'change']}
              ],
              addressContent: [
                  {required: true, message: '详细地址不能为空', trigger: ['blur', 'change']}
              ],
              isDefault: [
                  {required: true, message: '默认地址不能为空', trigger: ['blur', 'change']}
              ]
          }
      }
  },
   watch: {
    dialog: {
      handler: function (val, oldval) {
        this.waitParseText = ''
        if (val.id) {
          this.title = '编辑地址信息'
          this.getDetailData(val.id)
        } else {
          this.title = '新增地址信息'
          setTimeout(() => {
            this.resetData()
            if (this.dialog.type) {
              
              
              this.dataFrom.addressExplain = this.dialog.addressExplain
              this.dataFrom.bizTypeGroup = this.dialog.type
              this.dataFrom.addressType = this.dialog.addressType
              this.isXplain = this.dialog.isXplain
              this.dataFrom.companyName = this.dialog.companyName
              // this.dataFrom.addressExplain = this.dialog.addressExplain
              if (this.dialog.bizType) {
                this.dataFrom.bizType = this.dialog.bizType
              }
              if(this.dialog.defaultAddress) {
                let arr = []
              this.$post('/bas/basArea/list', { isEnabled: 1 })
                .then(({ data }) => {
                  if (data && data.code === 'C0000') {
                    if(data.result && data.result.length > 0) {
                      for (let index = 0; index < data.result.length; index++) {
                        const element = data.result[index];
                        if(element.label.indexOf('香港') != -1) {
                          arr.push(element.id)
                          if(element.children && element.children.length > 0) {
                            element.children.forEach(ele=>{
                              if(ele.label.indexOf('香港') != -1) {
                                arr.push(ele.id)
                              }
                            })
                          }
                          break
                        }
                      }
                    }
                  }
                })
              this.dataFrom.areaDesc = this.dialog.defaultAddress
              this.dataFrom.address = arr
              }
              if (this.dialog.queryPosition) {
                this.dataFrom.queryPosition = this.dialog.queryPosition
              }
              if (this.dialog.principalId) {
                this.dataFrom.principalId = this.dialog.principalId
              }
              
              console.log(this.dialog.isDefaultAdd)
              if (this.dialog.isDefaultAdd) {
                this.dataFrom.isDefault = 1
              }
              if (this.dataFrom.addressType === '01') {
                this.typeList.explainList = this.typeList.addressReceive
              } else {
                this.typeList.explainList = this.typeList.addressSend
              }
              if (this.dialog.sourceBillId) {
                this.fileData.sourceBillId = this.dialog.sourceBillId
                this.fileData.sourceBillType = this.dialog.sourceBillType
              }
            } else {
              this.changeBizTypeGroup()
            }
            if (this.dialog.isEnabled) {
              this.dataFrom.isEnabled = 1
            }
            
          }, 20)
        }
        setTimeout(()=>{
          if(this.dialog.show && (!this.typeList.addressBizType || this.typeList.addressBizType.length === 0) && !this.dialog.noClient) {
            this.$message({
              type: 'error',
              message: '委托方未关联业务类型！',
              grouping: true,
              offset: 380
            })
          }
        }, 500)
      },
      deep: true
    }
   },
  mounted() {
      this.getByUserCenter()
      this.$post('/bas/client/basAddress/typeList').then(res => {
        if (res.data.code == 'C0000') {
          this.typeList.addressReceive  = res.data.result.addressReceive
          this.typeList.addressType  = res.data.result.addressType
          this.typeList.addressSend = res.data.result.addressSend
          this.typeList.addressBizType  = res.data.result.addressBizType
          if (this.typeList.addressBizType && this.typeList.addressBizType.length === 1) { //2021-3-29 业务类型取值改成取全局的，不再从委托方获取
            this.dataFrom.bizTypeGroup = this.typeList.addressBizType[0].value
          }
        }
      })
  },
  
  methods: {
    //递归
    getHkId(arr) {
      let id = ''
      arr.forEach(ele=> {
        if(ele.label.indexOf('香港') != -1) {
          id = ele.id 
        }
      })
      return id
    },
      getByUserCenter(){
        this.$get('/p/user/getByUserCenter').then(({data}) => {
          if (data && data.code == 'C0000') {
            this.roleVos = data.result.roleVos
          }
        })
      },
      checkBizType (desc) {
        let flag = true
        this.roleVos.map(e => {
          if (!e.number) {
            return flag
          }
          if (desc == '进口' && (e.number.toLowerCase() === 'client' || e.number.toLowerCase() === 'clienthost')) {
            flag = false
          }
          if (desc == '出口' && e.number.toLowerCase() === 'clientexport') {
            flag = false
          }
        })
        return flag
      },
      changeAddressType (state) {
          if (typeof state !== 'boolean') { //数据回写时不 重置 地址说明，只切换数据来源
            this.dataFrom.addressExplain = '' //选择地址类型时，对应切换地址说明数据
          }
          if (this.dataFrom.addressType === '01') {
              this.typeList.explainList = this.typeList.addressReceive
          } else {
              this.typeList.explainList = this.typeList.addressSend
          }
      },
      setAddressType () {
        this.dataFrom.addressExplain = ''
        if (this.dataFrom.addressType === '01') {
              this.typeList.explainList = this.typeList.addressReceive
          } else {
              this.typeList.explainList = this.typeList.addressSend
          }
      },
      handlerSave () {
        this.$refs['dataFrom'].validate((valid) => {
            if (valid) {
                // if (this.fileList.length === 0 && this.dataFrom.addressExplain === '2') { //地址说明为客户地址
                //   return this.$alert('地址为客户地址，请上传与客户的订单合同作为佐证材料，谢谢！', '提示')
                // } else if (this.fileList.length === 0 && this.dataFrom.addressExplain === '4') { // 地址说明未代工厂
                //   return this.$alert('地址为代工厂地址，请上传与代工厂的代工合同做为佐证材料，谢谢！', '提示')
                // }
                if (this.dialog.isReset) {//新增地址到其他地方，如供应商，调用传入的方法,因参数不一直，处理逻辑于接口自行处理
                  this.$emit('handlerChange', Object.assign({}, this.dataFrom))
                  return false
                }
                let url = ''
                if (this.dialog.id) {
                  url = '/bas/client/basAddress/mod'
                  this.dataFrom.isEnabled = 1
                } else {
                  url = '/bas/client/basAddress/add'
                }
                
                this.dataFrom.addressIds = this.dataFrom.address.join(',')
                if(this.dialog.principalId) {
                  this.dataFrom.principalId = this.dialog.principalId
                }
                
                this.$post(url, this.dataFrom, this, 'isloading').then(res => {
                  if (res.data.code == 'C0000') {
                    this.$emit('change', Object.assign({}, this.dataFrom))
                    this.handleClose()
                  }
                })
            }
        })
      },
      changeBizTypeGroup () {
        if (this.dataFrom.bizTypeGroup === "01") { //业务类型为进口，地址类型自动选择收货
          this.dataFrom.addressType = '01'
          this.changeAddressType()
        } else if (this.dataFrom.bizTypeGroup === "02") { //业务类型为出口， 地址类型自动选择发货
          this.dataFrom.addressType = '04'
          this.changeAddressType()
        }
        // 业务代采无要求
      },
      handleClose () {
        this.aiSuccess = false
        //this.$emit('change', this.dataFrom)
        setTimeout (() => {
          this.resetData()
          this.dialog.show = false
        }, 200)
      },
      resetData () {
        this.dataFrom = {}
        this.fileData.sourceBillId = ''
        this.fileData.sourceBillNumber = ''
        this.fileList = []
        if(this.$refs.dataFrom) {
          this.$refs.dataFrom.resetFields()
        }
      },
      changeAddressDesc (item, desc) {
        console.log(desc)
        this.dataFrom.areaDesc = desc
      },
      getDetailData (id) {
        this.showloading = true
        this.$get('/bas/client/basAddress/get', {id: id}).then(res => {
          if (res.data.code == 'C0000') {
            this.dataFrom = res.data.result
            if (typeof this.dataFrom.addressIds == 'string') {
              let address = this.dataFrom.addressIds.split(',')
              this.dataFrom.address = []
              if (address.length > 0) {
                address.map(e => {
                  this.dataFrom.address.push(e)
                })
              }
            }
            this.fileData.sourceBillId = this.dataFrom.id
            this.fileData.sourceBillNumber = this.dataFrom.number
            this.changeAddressType(true)
            //this.getFileList()
          }
        }).finally(e => {
          this.showloading = false
        })
      },
      getTempBillId (id) {
        if (id) {
          this.dataFrom.tempBillId = id
        }
      },
      hangdlerAiData () {
        if (!this.waitParseText || this.waitParseText.trim() == '') {
          return false
        }
        this.showloading = true
        this.$post('/bas/client/basAddress/parseAddress', {waitParseText: this.waitParseText}).then(({data}) => {
          if (data && data.code == 'C0000') {
            console.log(data.result)
            let row = data.result
            this.dataFrom.linkMan = row.linkMan
            this.dataFrom.linkPhone = row.linkPhone
            this.dataFrom.address = row.addressIdsDesc
            this.dataFrom.areaDesc = row.addressIdsName
            if (row.companyName) {
              this.dataFrom.companyName = row.companyName
            }
            this.dataFrom.addressContent = row.addressContent
            this.aiSuccess = true
          }
        }).finally(() => {
          this.showloading = false
        })

      }
  }
}
</script>
<style lang="scss">
.add-address-dialog{
  
    .el-dialog__body{
      padding: 30px  40px 0px 40px!important;
    }
  
  .ai-address-title {
    margin-bottom: 3px;
    background-color: #f3ffe0;
    color: #f58507;
    text-align: center;
    line-height: 2;
    font-size: 14px;
  }
  .ai-input {
    .el-input-group__append {
      background-color: #0b7aff;
      color: #ffffff;
    }
  }
}
</style>