export default [
  {
    path: 'inquire-list',
    name: 'BOM配单',
    component: () => import('../views/orders/inquire-list.vue'),
    meta: {
      sidebarFold: true,
    }
  },
  {
    path: 'inquire-detail',
    name: 'BOM配单结果',
    component: () => import('../views/orders/inquire-detail.vue'),
    meta: {
      keepAlive: true, //设置页面是否需要使用缓存
      type: '01'
    }
  },
  {
    path: 'order-detail',
    name: '下单',
    component: () => import('../views/orders/order-detail.vue'),
    meta: {
      keepAlive: true, //设置页面是否需要使用缓存
      type: '01'
    }
  },
  {
    path: 'order-list',
    name: '我的订单',
    component: () => import('../views/orders/order-list.vue'),
    meta: {
      sidebarFold: true
    }
  },
  {
    path: 'user-material',
    name: '物料清单',
    component: () => import('../views/orders/material-list.vue'),
    meta: {
      sidebarFold: true
    }
  },
  {
    path: 'account-payment',
    name: '预付贷款',
    component: () => import('../views/property/property-list.vue'),
    meta: {
      keepAlive: true,
      sidebarFold: true
    }
  },
  {
    path: 'my-enterprise',
    name: '企业信息',
    component: () => import('../views/user-center/my-enterprise.vue'),
    meta: {
      sidebarFold: true
    }
  },
  {
    path: 'invoice-list',
    name: '待开发票',
    component: () => import('../views/invoice/invoice-list.vue'),
    meta: {
      sidebarFold: true
    }
  },
  {
    path: 'invoice-records',
    name: '我的发票',
    component: () => import('../views/invoice/invoice-records.vue'),
    meta: {
      sidebarFold: true
    }
  },
  {
    path: 'invoice-detail',
    name: '开票详情',
    component: () => import('../views/invoice/invoice-detail.vue'),
    meta: {
      keepAlive: true,
      sidebarFold: true
    }
  },
  {
    path: 'account-management',
    name: '账号信息',
    component: () => import('../views/user-center/account-management.vue'),
    meta: {
      sidebarFold: true,
      type: '01'
    }
  },
  {
    path: 'my-center',
    name: '个人中心',
    component: () => import('../views/user-center/my-center.vue'),
    meta: {
      sidebarFold: true,
      type: '01'
    }
  },
  {
    path: 'payment-detail',
    name: '支付详情',
    component: () => import('../views/orders/box/payment-detail.vue'),
    meta: {
      sidebarFold: true
    }
  }
]
