// @ts-nocheck
import vueCookie from 'vue-cookies'
import { post, fetch } from './https'
import { Base64 } from 'js-base64'
/*
* toFixed 精度问题，重写JS 原生方法
**/
Number.prototype.toFixed = function (s) {
  let changenum = (parseInt(this * Math.pow(10, s) + (this > 0 ? 0.51 : -0.51)) / Math.pow(10, s)).toString()
  let index = changenum.indexOf('.')
  if (index < 0 && s > 0) {
    changenum = changenum + '.'
    for (let i = 0; i < s; i++) {
      changenum = changenum + '0'
    }
  } else {
    index = changenum.length - index
    for (let i = 0; i < (s - index) + 1; i++) {
      changenum = changenum + '0'
    }
  }
  return changenum
}

Date.prototype.format = function (fmt) {
  const o = {
    "M+": this.getMonth() + 1, //月份 
    "d+": this.getDate(), //日 
    "h+": this.getHours(), //小时 
    "m+": this.getMinutes(), //分 
    "s+": this.getSeconds(), //秒 
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
    "S": this.getMilliseconds() //毫秒 
  }
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length))
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
    }
  }
  return fmt
}

const tools = {
  /**
  * 设置localStorage
  * @key  @value
  * */
  setLocalStorage(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
  },
  /****
  * 获取localStorage的值
  * @key
  */
  getLocalStorage(key) {
    return JSON.parse(window.localStorage.getItem(key))
  },
  /**
  * 删除localStorage
  * @param key
  */
  removeLocalStorage(key) {
    window.localStorage.removeItem(key)
  },
  /**
  * 删除 all localStorage
  * @param
  */
  clearAllLocalStorage() {
    window.localStorage.clear()
  },
  /**
  * set sionStorage
  * @key  @value
  * */
  setSessionStorage(key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  },
  /****
  * 获取sessionStorage的值
  * @key
  */
  getSessionStorage(key) {
    return JSON.parse(window.sessionStorage.getItem(key))
  },
  /**
  * 删除sessionStorage
  * @param key
  */
  removeSessionStorage(key) {
    window.sessionStorage.removeItem(key)
  },
  /**
  * 删除 all localStorage
  * @param
  */
  clearAllSessionStorage() {
    window.sessionStorage.clear()
  },
  getUrlParam(url) {
    const param = {}
    if (url.includes('?')) {
      const str = url.split('?')[1]
      const arr = str.includes('&') ? str.split('&') : [str]
      arr.forEach(e => {
        if (e.includes('=')) {
          param[e.split('=')[0]] = decodeURI(e.split('=')[1])
        }
      })
    }
    return param
  },
  clearLoginInfo() {
    const baseUrl = process.env.VUE_APP_BASEURL
    vueCookie.remove('token', { domain: baseUrl })
    sessionStorage.clear()
    window.localStorage.clear()
    //  localStorage.removeItem('caseSet', '')
    //  localStorage.removeItem('loginid', '')
    //  localStorage.removeItem('userId', '')
    //  localStorage.removeItem('tenantid', '')
  },
  getDate(theDate = new Date()) {
    if (!theDate) return theDate
    let date = ''
    if (typeof theDate == 'string') {
      date = new Date(theDate.replace(/-/g, '/'))
    } else {
      date = new Date(theDate)
    }
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return year + '-' + this.double(month) + '-' + this.double(day)
  },
  formatDate(date, formatString = 'yyyy-MM-dd') {
    if (!date) {
      return ''
    }
    const t = new Date(date)
    const tf = function (i) {
      return (i < 10 ? '0' : '') + i
    }
    return formatString.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
      switch (a) {
        case 'yyyy':
          return tf(t.getFullYear())
        case 'MM':
          return tf(t.getMonth() + 1)
        case 'mm':
          return tf(t.getMinutes())
        case 'dd':
          return tf(t.getDate())
        case 'HH':
          return tf(t.getHours())
        case 'ss':
          return tf(t.getSeconds())
      }
    })
  },
  getHisOrderData(str, name) {
    const hisData = this.getLocalStorage(str)
    let d = {}
    if (hisData instanceof Array) {
      hisData.map(e => {
        const key = Object.keys(e)[0]
        if (name == key) {
          d = e[key]
        }
      })
    } else {
      d = hisData
    }
    return d
  },
  clearPoll(name) {
    const poll = window.SITE_CONFIG['poll']
    Object.keys(poll).forEach(e => {
      if (e.indexOf(name) > -1 || name == 'all') {
        window.clearInterval(window.SITE_CONFIG['poll'][e])
      }
    })
  },
  getHisOrderData(str, name) {
    const hisData = this.getLocalStorage(str)
    let d = {}
    if (hisData instanceof Array) {
      hisData.map(e => {
        const key = Object.keys(e)[0]
        if (name == key) {
          d = e[key]
        }
      })
    } else {
      d = hisData
    }
    return d
  },
  setHisOrderData(str, parame, name) {
    const hisData = this.getLocalStorage(str) //历史数据
    let attrTemp = [] //临时数据
    let flag = false //是否存在 历史中
    if (hisData instanceof Array) {
      if (hisData.length > 4) { //大于4个 先阉掉其他的
        hisData.reverse()
        attrTemp = hisData.slice(0, 4)
        attrTemp.reverse()
      } else {
        attrTemp = hisData
      }
      const obj = {}
      attrTemp.map((e, idx) => {
        const key = Object.keys(e)[0]
        if (name == key) { //在历史中存在替换掉 历史记录的
          flag = true
          obj[name] = parame
          attrTemp[idx] = obj
        }
      })

      if (!flag) {//历史中不存在，添加进去
        obj[name] = parame
        attrTemp.push(obj)
      }

    } else {//老数据，对象类型 转成数组再存历史记录
      const obj = {}
      obj[name] = parame
      attrTemp.push(obj)
    }
    this.setLocalStorage(str, attrTemp)
  },
  getDate(theDate = new Date()) {
    if (!theDate) return theDate
    let date = ''
    if (typeof theDate == 'string') {
      date = new Date(theDate.replace(/-/g, '/'))
    } else {
      date = new Date(theDate)
    }
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return year + '-' + this.double(month) + '-' + this.double(day)
  },
  double(date) {
    if (date < 10) {
      date = '0' + date
    }
    return date
  },
  getTabDataByUrl(url, isClient = false) {
    const data = {
      type: 'router',
      path: url,
      query: {}
    }
    if (/^http[s]?:\/\/.*/.test(url)) {
      if (url.includes('/#/')) {
        const simpleUrl = '/' + url.split('/#/')[1]
        data.path = simpleUrl.includes('?') ? simpleUrl.split('?')[0] : simpleUrl
      } else {
        data.type = 'window'
      }
    } else {
      if (url.includes('?')) {
        data.path = url.split('?')[0]
      }
    }
    if (data.type === 'router') {
      const param = this.getUrlParam(url)
      if (data.path.substr(0, 1) !== '/') {
        data.path = '/' + data.path
      }
      if (isClient) {
        // 客户端消息中心url替换
        if (['/message-all', '/message-read', '/message-unread'].includes(data.path)) {
          data.path = data.path.replace('message-', 'message-center-')
        }
      }
      data.query = Object.keys(param).length ? Object.assign({}, param, { cache: false, t: new Date().getTime() }) : {}
    }
    return data
  },
  listDownLoad(row) {
    post('/oms/billAttach/compress', { sourceBillId: row.id, sourceBillType: '' }).then(({ data }) => {
      if (data && data.code === 'C0000') {
        const eleLink = document.createElement('a')
        eleLink.download = ''
        eleLink.style.display = 'none'
        // 字符内容转变成blob地址
        eleLink.href = res.data.result
        // 触发点击
        document.body.appendChild(eleLink)
        eleLink.click()
        // 然后移除
        document.body.removeChild(eleLink)
      }
    })
  },
  Base64Encode(val) {
    return Base64.encode(val)
  },
  deepClone(source) {
    if (!source && typeof source !== 'object') {
      throw new Error('error arguments', 'deepClone')
    }
    const targetObj = source.constructor === Array ? [] : {}
    Object.keys(source).forEach(keys => {
      if (source[keys] && typeof source[keys] === 'object') {
        targetObj[keys] = deepClone(source[keys])
      } else {
        targetObj[keys] = source[keys]
      }
    })
    return targetObj
  }
}

const filters = {
  /**
  * 格式化千位符,钱的格式,
  * @num  10000
  * return  1,000
  * **/
  formatMoney(num, isDecimal) {
    num = filters.formatNumber(num)
    if (isNaN(num)) {
      return ''
    }
    if (!isDecimal) {
      num = Number(num).toFixed(2)
    }
    if (!/^(\+|-)?(\d+)(,\d{3})*(\.|\.\d+)?$/.test(num)) {
      return num
    }
    let re = /(\d)(\d{3})(,|$)/, a = RegExp.$1, b = RegExp.$2, c = RegExp.$4
    while (re.test(b)) {
      b = b.replace(re, '$1,$2$3')
    }
    const value = a + '' + b + '' + c
    return value
  },
  formatterTime(time) {
    return time.split(' ')[0]
  },
  /**
  * 还原number 类型,
  * @strval  1,000
  * return 1000
  * **/
  formatNumber(str) {
    str += ''
    if (str.indexOf(',') === -1) {
      return str
    }
    return str.replace(/,/g, '')
  },
  /***
  * 格式化数字类型,保留2位小数
  * @x  100
  * return 100.00
  * **/
  toDecimal2(x) {
    if (!x) {
      return ''
    }
    const s = x.toString()
    const rs = s.split('.')
    const left = rs[0]
    let right = rs[1]
    if (right) {
      if (right.length > 2) {
        right = right.substring(0, 2)
      } else if (right.length < 2) {
        right += 0
      }
    } else {
      right = '00'
    }
    return left + '.' + right
  },
  formatDate(date, mat) {
    return tools.formatDate(date, mat)
  },
  formatBankAccount(str) {
    return str ? str.replace(/\s/g, '').replace(/(.{4})/g, '$1 ') : ''
  }
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').includes(key)
}

// 初始化全局数据
// 用户信息
const userInfo = () => {
  return fetch('/auth/info', undefined)
    .then(res => {
      if (res.data.code === 'C0000') {
        tools.setSessionStorage('userInfo', res.data.result)
        return res.data.result
      }
    })
}
function setCityName(data) {
  if (data.children && data.children.length > 0) {
    return data.children.map(item => {
      item.value = item.number
      item.label = item.name
      if (item.children) {
        item.children = setCityName(item)
      }
    })
  }
}
function formatMenuList(list) {
  const arr = []
  if (Array.isArray(list)) {
    list.map(item => {
      const children = item.children
      item.value = item.id
      item.label = item.name
      if (children) {
        item.children = formatMenuList(children)
      }
      arr.push(item)
    })
  }
  return arr
}




// 城市数据
const cityData = () => {
  return fetch('/api/area/tree')
    .then(({ data }) => {
      if (data && data.code === 'C0000') {
        const cityData = formatMenuList(data.result)
        tools.setSessionStorage('cityData', cityData)
        return cityData
      }
    })
}

// 币别数据
const currencyData = () => {
  return post('/bas/basCurrency/list', { isEnabled: 1, pageNum: 1, pageSize: 9999 })
    .then(({ data }) => {
      if (data && data.code === 'C0000') {
        tools.setSessionStorage('currencyData', data.result.list)
        return data.result
      }
    })
}

const userLoginStep = () => {
  return post('/crm/clientRegisterStep/getCurClientRegisterStep')
    .then(({ data }) => {
      if (data && data.code === 'C0000') {
        tools.setSessionStorage('userStep', data.result)
        return data.result
      }
    })
}

const initSessionStorageData = {
  init() {
    return Promise.all([cityData()])
  },
  getUserInfo() {
    return userInfo()
  },
  getCityData() {
    return cityData()
  },
  getCurrencyData() {
    return currencyData()
  },
  userLoginStep() {
    return userLoginStep()
  }
}

// el-form校验失败滚动到对应的错误位置
export function elFormErrorScrollIntoView(element) {
  // 获取第一个校验错误的元素
  // const element = document.querySelectorAll('.el-form-item__error')[0]
  // 滚动到错误元素对应位置
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  })
}

export default {
  tools,
  filters,
  isAuth,
  initSessionStorageData,
  elFormErrorScrollIntoView
}
