<template>
  <el-button
    :type="buttonType"
    :loading="loading"
    v-if="show"
    :plain="plain"
    :link="link"
    :disabled="disabled"
    @click="revokeSignFun"
  ><slot></slot></el-button>
</template>

<script>
export default {
  name: 'ab-revoke-sign',
  data () {
    return {
      loading: false
    }
  },
  model: {
  },
  props: {
    billId: {
      type: String,
      default: '',
      required: true
    },
    submitUserId: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: '/sys/esignTask/revokeFlow'
    },
    buttonType: {
      type: String,
      default: 'danger'
    },
    link: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    revokeSignFun () {
      console.log(this.submitUserId, '=========');
      this.loading = true
      this.$prompt('', '撤回签章', {
        closeOnClickModal: false,
        closeOnPressEscape: false,
        customClass: 'revoke-sign-prompt',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '撤销原因',
        inputPattern: /\S/,
        inputErrorMessage: '请输入撤销原因'
      }).then(({ value }) => {
        this.$get(this.url, { billId: this.billId, revokeReason: value, submitUserId: this.submitUserId }).then(({ data }) => {
          if (data && data.code === 'C0000') {
            this.$message({ message: data.message, type: 'success',offset: 380,grouping: true })
            this.$emit('callback', data)
          }
          this.loading = false
        })
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
.revoke-sign-prompt {
  .el-message-box__content .el-input .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
</style>
