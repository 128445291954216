<template>
    <el-dialog
        title="预付贷款" width="900px" :model-value="visible" @close="cancel" :close-on-click-modal='false' class="prepayment-loan-dialog"
    >
        <!-- 收付款信息 -->
        <table>
            <tr>
                <th colspan="2">收款信息</th>
                <th colspan="2">付款信息 (必须使用以下账户付款)</th>
            </tr>
            <tr>
                <td colspan="2">收款人户名&nbsp;&nbsp;<span>深圳市油柑科技有限公司</span></td>
                <td colspan="2">公司名称&nbsp;&nbsp;<span>{{ data.companyName }}</span></td>
            </tr>
            <tr>
                <td colspan="2">银行卡号&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>4000 0221 0920 0744 484</span></td>
                <td></td>
            </tr>
            <tr>
                <td colspan="2">开户银行&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>中国工商银行股份有限公司深圳华强支行</span></td>
                <td></td>
            </tr>
        </table>
        <!-- 预付金额 -->
        <div class="prepay">
            <el-form :model="model" :rules="rules" class="form" ref="form">
                <el-form-item label="预付金额" prop="amount">
                    <el-input type="number" v-model.number="model.amount" placeholder="￥请输入金额" clearable></el-input>
                </el-form-item>
            </el-form>
            <span class="tip">汇款到账后，财务将在1个工作日内审核后生效</span>
        </div>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="confirm" :loading="isloading">完成汇款</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: 'prepayment-loan-dialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
            required: true
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            isloading: false,
            model: {
                amount: null
            },
            rules: {
                amount: [
                    { required: true, message: '请输入金额', trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        reset() {
            this.$nextTick(() => {
                this.$refs.form.resetFields();
            });
        },
        cancel() {
            this.$emit('cancel');
            this.reset();
        },
        confirm() {
            this.$refs.form.validate((valid) => {
                if (!valid) return;
                this.$emit('confirm', this.model);
                this.reset();
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.prepayment-loan-dialog {
    table {
        border-collapse: collapse;
        width: 100%;
        th, td {
            border: 1px solid #f0f0f0;
            padding: 12px;
            text-align: left;
            color: #999;
            font-size: 16px;
            > span {
                color: #333;
            }
        }
        th {
        background-color: #f6f6f6;
        color: #666;
        }
    }
    .prepay {
        margin-top: 40px;
        display: flex;
        justify-items: center;
        align-items: center;
        flex-direction: column;
        .form {
            display: flex;
            justify-items: center;
            align-items: center;
            flex-direction: column;
            .el-form-item {
                align-items: center;
                margin-bottom: 20px;
                :deep(.el-input__wrapper) {
                    width: 200px;
                }
            }
        }
        .tip {
            color: var(--el-color-primary);
            font-size: 16px;
            text-align: center;
        }
    }
}
</style>
