<template>
  <div>
    <ul class="ul-bar">
      <li v-for="item in statusData" :key="item" :style="{width: `calc(${(item.number / countNumber * 100 > 100 ? 100 : item.number / countNumber * 100) + '%' } + 16px)`, background: item.color}">
         {{ item.number }}
        </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ab-status',
  props: {
    statusData: {
      type: Array
    },
    countNumber: {
      type: Number
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.ul-bar {
  margin-left: 16px;
  li {
    font-size: 12px;
    box-sizing: content-box;
    float: left;
    height: 16px;
    border-radius: 10px;
    margin-left: -16px;
    text-align: center;
    color: #fff;
  }
}

</style>
