<template>
  <div class="forgot-password-page">
    <div class="top">
        <img src="@/assets/img/logo.png" />
        <span>修改密码</span>
    </div>
    <div class="content-box">
        <div class="step-bar" v-if="index != 3">
            <div class="is-active"><ab-svg name="user"></ab-svg></div>
            <p class="line"></p>
            <div :class="['right', index == 2 ? 'is-active' : '']"><ab-svg name="shezhi"></ab-svg></div>
            <p>
                <span :style="{float: 'left', color: index != 2 ? 'var(--el-color-primary)' : '#000'}">验证账号</span>
                <span :style="{float: 'right', color: index == 2 ? 'var(--el-color-primary)' : '#000'}">设置新密码</span>
            </p>
        </div>
        <div v-if="index == 1">
            <el-form class="ruleForm" :model="formData" ref="ruleForm" :rules="rules1" label-position="top">
                <el-form-item label="登录账号" prop="mobile">
                    <el-input v-model="formData.mobile" placeholder="请输入登录账号"></el-input>
                </el-form-item>
                <el-button style="width: 100%; height: 50px" type="primary" :loading="nextLoading" @click="next('ruleForm')">下一步</el-button>
            </el-form>
            <div class="go-back" @click="goBack"> <div class="quanquan"><el-icon><ArrowLeftBold /></el-icon></div><span>返回登录</span> </div>
        </div>
        <div v-else-if="index == 2">
            <el-form class="ruleForm1" :model="dataForm" ref="ruleForm1" :rules="rules" label-position="top">
                <div class="user-client-reg">
                    <p>手机号码</p>
                    <p style="font-size: 22px;font-weight: bold; margin: 0px 0 20px">{{dataForm.mobile}}</p>
                    <el-form-item prop="code" class="phone-code" label="验证码">
                    <div class="ph-code-number">
                        <el-input
                        v-model="dataForm.code"
                        clearable
                        type="number"
                        placeholder="请输入验证码"
                        ></el-input>
                    </div>
                    <div class="ph-code-btn" style="font-size: 0;">
                        <i></i>
                        <el-button
                        size="small"
                        :disabled="isCodeDisabled || !isCode"
                        @click="checkedPhoneCode"
                        >{{ phoneCodeBtn }}</el-button
                        >
                    </div>
                    </el-form-item>
                    <el-form-item label="新密码" prop="newPassword">
                    <el-input type="password" v-model="dataForm.newPassword"
                        placeholder="请输入新密码"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="confirmPassword">
                    <el-input
                        placeholder="请再次输入新密码"
                        type="password"
                        v-model="dataForm.confirmPassword"
                    ></el-input>
                    </el-form-item>
                </div>
                <el-button style="width: 100%; height: 50px; margin-top: 2px" :loading="confirmLoading" type="primary" @click="submitForm('ruleForm1')">确定</el-button>
            </el-form>
        </div>
        <div class="edit-success" v-else>
            <div class="icon">
                <el-icon><Select /></el-icon>
            </div>
            <h2>恭喜您，您的密码已设置成功！</h2>
            <el-button @click="goBack" type="primary">立即登录</el-button>
        </div>
    </div>
  </div>
</template>

<script>
import { Base64 } from 'js-base64'
export default {
    data() {
        const validatePass = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入密码'))
            } else {
                let reg = new RegExp('^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,13}$')
                if (!reg.test(value)) {
                    return callback(new Error('密码需6-13位，包含大小写字母与数字'))
                }
                callback()
            }
        }
        var validateConfirmPassword = (rule, value, callback) => {
        if (this.dataForm.newPassword !== value) {
            callback(new Error("确认密码与新密码不一致"));
        } else {
            callback();
        }
        };
        return {
            uuid:'',
            userId: '',
            nextLoading: false,
            confirmLoading: false,
            phoneCodeBtn: "获取验证码",
            isCodeDisabled: false,
            index: 1,
            dataForm: {},
            formData: {},
            rules1: {
                mobile: [
                    {required: true, message: '请输入账号', trigger: 'blur'}
                ]
            },
            rules: {
                mobile: [
                    {required: true, message: '请输入手机号', trigger: 'blur'}
                ],
                code: [
                    {required: true, message: '请输入验证码', trigger: 'blur'}
                ],
                newPassword: [
                    { validator: validatePass, trigger: "blur" },
                    { required: true, message: "新密码不能为空", trigger: "blur" }
                ],
                confirmPassword: [
                    { required: true, message: "确认密码不能为空", trigger: "blur" },
                    { validator: validateConfirmPassword, trigger: "blur" }
                ]
            }
        }
    },
    beforeUnmount() {
        console.log('触发了');
        this.index = 1
    },
    computed: {
        isCode: {
            get() {
                return this.dataForm.mobile && this.dataForm.mobile.length === 11
            }
        }
    },
    methods: {
        checkedPhoneCode() {
        if(!/^1[0-9]{10}$/.test(this.dataForm.mobile)) {
            this.$message.error('手机号格式不正确！')
            return
        }
        this.isCodeDisabled = true
        let that = this
        this.$get("/auth/sendUpdatePassToPhone", {
            phone: this.dataForm.mobile
        })
            .then(({ data }) => {
            if (data && data.code === "C0000") {
                let index = 60
                let codeTime = setInterval(() => {
                if (index <= 0) {
                    clearInterval(codeTime)
                    that.isCodeDisabled = false
                    that.phoneCodeBtn = "获取手机验证码"
                } else {
                    this.phoneCodeBtn = "重新获取(" + index + ")"
                    index--
                }
                }, 1000);
                that.uuid = data.result
            } else {
                this.isCodeDisabled = false
                this.$message.error(data.message)
            }
            })
            .catch(() => {})
        },
       next(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.nextLoading = true
            this.$get(`/auth/userInfo?mobile=${this.formData.mobile}`).then(({data}) => {
                if(data && data.code === 'C0000') {
                    if(!data.result || !data.result.mobile) {
                        this.$message.error('账号不存在！')
                    }else{
                        this.index = 2
                        this.userId = data.result.id
                        this.dataForm.mobile = data.result.mobile
                    }
                }else {
                    this.$message.error(data.message)
                }
            }).finally(() => {
                this.nextLoading = false
            })
          }
        });
      },
       submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.confirmLoading = true
            let params = Object.assign(this.dataForm)
            params.uuid = this.uuid
            let password = Base64.encode(params.newPassword)
            this.$post('/auth/updatePassword', {
                mobile: params.mobile,
                code: params.code,
                newPassword: password,
                uuid: this.uuid
            }).then(({data}) => {
                if(data && data.code === 'C0000') {
                    this.index = 3
                }
            }).finally(()=>{this.confirmLoading = false})
          }
        });
      },
      goBack() {
        this.$router.push('/login')
        this.index = 1
      }
    }
}
</script>

<style lang="scss">
    .forgot-password-page{
        .top{
            padding: 20px;
            font-size: 16px;
            span{
                vertical-align: middle;
            }

            img{
                margin-right: 20px;
                vertical-align: middle;
            }
        }
        .content-box{
            padding-top: 30px;
            margin: 0 auto;
            width: 440px;
            height: 300px;
            .step-bar{
                width: 100%;
                height: 60px;
                margin: 30px 0 84px;
                position: relative;
                .line{
                    position: absolute;
                    height: 0px;
                    border-top: 2px dashed #d7d7d7;
                    width: 290px;
                    left: 70px;
                    top: 20px;
                }
                div{
                    float: left;
                    width: 65px;
                    height: 65px;
                    border-radius: 8px;
                    text-align: center;
                    line-height: 65px;
                    background-color: var(--el-color-danger-light-8);
                    .icon-svg{
                        width: 40px;
                        height: 40px;
                        margin-top: 14px;
                        color: var(--el-color-primary);
                    }
                    .is-active{
                        .icon-svg__user,
                        .icon-svg {
                            color: var(--el-color-primary);
                        }
                    }
                    &.right{
                        float: right;
                        margin-right: 8px;
                    }
                }
                p{
                    width: 100%;
                    height: 20px;
                    float: left;
                    font-size: 16px;
                    margin-top: 12px;
                    color: var(--el-color-primary);
                }
            }
            .ruleForm{
                position: relative;
                .el-form-item__error{
                    top: 50px;
                }
            }
            .el-form-item{
                .el-input__wrapper{
                    // background-color: #ecf7fd;
                }
                .el-input__inner{
                    height: 48px;
                }
            }
            .go-back{
                text-align: center;
                font-size: 16px;
                color: #000;
                margin-top: 30px;
                cursor: pointer;
                .quanquan{
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border: 1px solid #d5d5d5;
                    line-height: 30px;
                    font-size: 14px;
                    color: #7f7f7f;
                    margin-right: 5px;
                }
            }
        .user-client-reg {
            margin-top: 30px;
            .el-icon-message,
            .el-icon-mobile-phone {
            color: #606266;
            width: 32px;
            height: 42px;
            font-weight: 600;
            padding-top: 10px;
            font-size: 17px;
            }
            .phone-code {
                height: 83px;
            div {
                float: left;
            }
            .ph-code-number {
                width: 320px;
                .el-input__wrapper{
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                }
            }
            .ph-code-btn {
                position: relative;
                i{
                    position: absolute;
                    width: 1px;
                    height: 30px;
                    left: 0;
                    top: 10px;
                    background-color: #dcdcdc;
                }
                button {
                color:  #000;
                background-color: #ecf7fd;
                border: none;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                height: 50px;
                font-size: 14px;
                border-left: none;
                width: 120px;
                }
            }
            }
        }
                .ruleForm1 {
            display: block;
            width: 100%;
            margin: auto;
            .user {
            input {
                padding-left: 40px;
                height: 42px;
                border-radius: 0;
                background-color: #ecf7fd;
            }
            }
            .pwd {
            margin-bottom: 6px;
            input {
                padding-left: 40px;
                height: 42px;
                border-radius: 0;
            }
            .pwd-icon {
                display: inline-block;
                width: 32px;
                height: 42px;
                background: url("@/assets/img/client/login/pwd.svg") no-repeat center;
            }
            }
            .checked {
            text-align: left;
            margin-bottom: 20px;
            .el-checkbox__inner {
                border-radius: 0;
            }
            }
        }
            .el-form-item{
                margin-bottom: 27px;
                .el-input__wrapper{

                background-color: #ecf7fd;
                }
                .el-form-item__label{
                    margin-bottom: 12px;
                }
            }
        }
        .edit-success {
            .icon{
                padding-top: 7px;
                margin: 100px auto 20px;
                width: 50px;
                height: 50px;
                background-color: #01458e;
                text-align: center;
                line-height: 44px;
                border-radius: 50%;
                font-size: 28px;
                i{
                    color: #fff;
                }
            }
            h2{
                text-align: center;
                margin-bottom: 30px;
            }
            .el-button{
                border-radius: 6px;
                height: 34px;
                padding: 0 34px;
                line-height: 34px;
                display: block;
                margin: 0 auto;
                &:hover{
                    opacity: .8;
                }
            }
        }
    }
</style>
