<!--
组件使用指南
<money
@change="computedMoneyAmount"
v-model.trim="parames.currentTicketDiff"
:disabled="parames.status === 2 || parames.status === 3"
clearable
append="美元"
></money>
-->
<template>
  <div class="commone-money">
    <el-input
      v-bind:class="{'al-right': onOff}"
      v-model="inputData"
      :disabled="disabled"
      :readonly="readonly"
      :clearable="clearable"
      :placeholder="placeholder"
      @change="inputChange"
      @focus="inputFocus"
      @keyup.native="inputKeyup"
      @blur="inputBlur"
      
    >
      <template v-if="append" #append>{{append}}</template>
    </el-input>
  </div>
</template>
<script>
export default {
    name: 'ab-money',
    data () {
        return {
            inputData: this.formatMoney(this.modelValue), //组件初始数据
            onOff: this.isRight //是否在失去焦点后，金额右对齐，默认左对齐
        }
    },
    model: {
        modelValue: String
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: String, //model
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        clearable: {
            type: Boolean,
            default: false
        },
        append: { //是否在后面显示单位
            type: String,
            default: ''
        },
        isRight: { //是否右对齐
            type: Boolean,
            default: false
        }
    },
    watch: {
        modelValue (value) {
            this.inputData = this.formatMoney(value)
        }
    },
    methods: {
        inputFocus (e) { //获取焦点时 移除 千分位
            this.inputData = this.formatNumber(this.inputData)
            if (this.isRight) {
               this.onOff = false
            }
            this.$emit('update:modelValue', this.modelValue)
        },
        inputBlur (e) { //失去焦点时 加上千分位
            this.inputData = this.formatMoney(this.inputData)
            if (this.isRight) {
               this.onOff = true
            }
            this.$emit('update:modelValue', this.modelValue)
        },
        inputChange ($event) { //change事件
            this.$emit('update:modelValue', Number(this.formatNumber(this.inputData)))
        },
        inputKeyup ($event) {
        },
        formatMoney (data) {
            return this.$filters.formatMoney(data, true)
        },
        formatNumber (data) {
            return  this.$filters.formatNumber(data)
        }
    }
}
</script>
<style lang="scss">
.commone-money.tab-input-success .el-input__inner {
  border-color: #67c23a !important;
}
.commone-money.tab-input-error .el-input__inner {
  border-color: #f56c6c !important;
}
.commone-money {
    width: 100%;
  .al-right > input {
    text-align: right;
  }
  .al-left > input {
    text-align: right;
  }
  .el-input-group__append {
    box-shadow: none;
  }
}
</style>
