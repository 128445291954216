<!--
组件的显示的值只能用value，用其他key将无法选中。
自定义渲染插槽用法： 一定要保证其中有value
<template v-slot:content="scope">
    {{scope.item.value}} --- {{scope.item.id}}
</template>
--->
<template>
  <el-autocomplete
    popper-class="ab-select-auto vxe-table--ignore-clear"
    v-model="dataCode"
    :fetch-suggestions="querySearchAsync"
    :placeholder="placeholder"
    clearable
    @select="handleSelect"
    @change="handlerChange"
    :disabled="disabled"
    >
    <template #prefix v-if="prefix">
      <slot name="prefix">
        <el-icon><Search /></el-icon>
      </slot>
    </template>
    <template #suffix v-if="suffix">
      <slot name="suffix">
        <el-icon><ArrowDown /></el-icon>
      </slot>
    </template>
    <template #default="{ item }">
      <slot name="content" :item="item">
        <div :class="{'add-item': item.state === 'add'}">
          <span :class="{isleft: describe}">{{keyFilter(item) }}</span>
          <span v-if="describe" class="isright">{{item[describe]}}</span>
        </div>
      </slot>
    </template>
  </el-autocomplete>
</template>


<script>
export default {
  name: 'ab-select',
  data () {
    return {
      temp: [],
      dataCode: this.modelValue,
      dataItem: {}
    }
  },
  model: {
    modelValue: String
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String
    },
    inputKey: {
      type: String,
      default: 'name'
    },
    descKey: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    urlData: {
      type: Object,
      default: {}
    },
    placeholder: {
      type: String,
      default: '请输入内容'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    methods: {
      type: String,
      default: 'post'
    },
    suffix: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    describe: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: []
    },
    isNotClear: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    modelValue () {
      this.dataCode = this.modelValue
    }
  },
  methods: {
    keyFilter (item) {
      return item[this.descKey ? this.descKey : this.inputKey]
    },
    querySearchAsync (queryString, cb) {
      queryString = queryString ? queryString : ''
      if (this.urlData.data) {
        this.urlData.data[this.inputKey] = queryString
      } else {
        this.urlData[this.inputKey] = queryString
      }
      this.temp = []
      if (this.list.length > 0) {
          this.temp = queryString ? this.list.filter(this.createStateFilter(queryString)) : this.list
          if (this.isAdd) {
              let obj = {state: 'add'}
              obj[this.descKey ? this.descKey : this.inputKey] = '+新增'
              this.temp.unshift(obj)
            }
          cb(this.temp)
        } else if (this.url){
          this.getMethods().then(res => {
            if (res.data && res.data.code === 'C0000' && res.data.result) {
              this.temp = res.data.result.list || res.data.result
              
              if (this.temp && this.temp.length) {
                this.temp.map(e => {
                e.value = this.keyFilter(e)
                })
              }
              if (this.isAdd) {
                let obj = {state: 'add'}
                obj[this.descKey ? this.descKey : this.inputKey] = '+新增'
                this.temp.unshift(obj)
              }
              
            }
      }).finally(() => {
            cb(this.temp)
          })
        } else {
          cb([])
        }
    },
    createStateFilter (queryString) {
      return (state) => {
        return (state[this.descKey ? this.descKey : this.inputKey].toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    getMethods() {
      if (this.methods === 'post') {
        return this.$post(this.url, this.urlData)
      } else if (this.methods === 'get'){
        return this.$get(this.url, this.urlData)
      }
    },
    handleSelect(item) {
      if (item.state) {
        this.dataCode = ''
      }
      this.dataItem = item
      this.$emit('update:modelValue', this.dataCode)
      this.$emit('handleSelect', item)
    },
    handlerChange () {
      setTimeout(() => {
        if (this.isNotClear) {
          this.$emit('update:modelValue', this.dataCode)
          this.$emit('handleSelect', {name: this.dataCode})
          return true
        } else {
          let flag = false
          if (this.temp.length > 0) {
            this.temp.map(e => {
              if (e[this.descKey ? this.descKey : this.inputKey] == this.dataCode) {
                flag = true
                this.dataItem = e
              }
            })
          }
          if (!flag) {
            this.dataCode = ''
            this.dataItem = {}
          }
          this.$emit('update:modelValue', this.dataCode)
          this.$emit('handleSelect', this.dataItem)
        }
      }, 50)
    }
  }
}
</script>

<style lang="scss">
.ab-select-auto {
  .add-item {
    font-weight:400;
    color:rgba(3,103,184,1);
  }
  .isleft {
    float: left;
  }
  .isright {
    float: right;
    color: #8492a6; 
    font-size: 13px
  }
}
</style>