<template>
  <el-dialog title="新增商品" width="700px" class="import-statement-dialog" v-model="showVisible" top="10vh" @close="cancel"
    :close-on-click-modal='false'>
    <el-form :model="dataFrom" :rules="rules" ref="dataFrom" label-position="top">
      <el-row gutter="30">
        <el-col :span="12">
          <el-form-item label="型号" prop="productModel">
            <el-input v-model="dataFrom.productModel" type="text" maxlength="200" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="品牌">
            <el-input v-model="dataFrom.brand" type="text" maxlength="200" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="类别">
            <el-input v-model="dataFrom.productType" type="text" maxlength="200" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="封装">
            <el-input v-model="dataFrom.productPackaging" type="text" maxlength="200" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="规格" prop="parameter">
            <el-input v-model="dataFrom.parameter" type="text" maxlength="200" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="位号">
            <el-input v-model="dataFrom.tagNum" type="text" maxlength="200" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="单用量" prop="aloneNum">
            <el-input v-model.number="dataFrom.aloneNum" type="number" @blur="aloneBlur" maxlength="200" placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="需求数" prop="demandNum">
            <el-input v-model="dataFrom.demandNum" disabled type="number" maxlength="200" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" :loading="btnLoading" @click="confirm">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'import-statement-overdue-dialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    btnLoading: {
      type: Boolean,
      default: false
    },
    numberOfSets: {
      type: Number,
      default: 1
    },
  },
  computed: {
    showVisible: {
      get() {
        return this.visible
      }
    }
  },
  data() {
    const numVlida = (rule, value, callback) => {
      if (value) {
        let reg = /^[1-9]+[0-9]*$/
        if (!reg.test(value)) {
          return callback(new Error('请输入正整数'))
        }
        callback()
      }
      callback()
    }
    return {
      dataFrom: {},
      rules: {
        aloneNum: [
          {required: true, message: '请输入单用量', trigger: ['blur', 'change']},
          { validator: numVlida, trigger: 'blur' }
        ],
        demandNum: [
          {required: false, message: '', trigger: ['blur', 'change']},
          { validator: numVlida, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    aloneBlur() {
      this.dataFrom.demandNum = this.dataFrom.aloneNum ? this.dataFrom.aloneNum * this.numberOfSets : 0
    },
    cancel() {
      this.dataFrom = {}
      setTimeout(() => {
        this.$refs.dataFrom.clearValidate()
      }, 500);
      this.$emit('cancel')
    },
    confirm(){
      this.$refs.dataFrom.validate(valid => {
        if(valid) {
          this.$emit('confirm', this.dataFrom)
        }
      })
    }
  }
}
</script>

<style lang="scss"></style>
