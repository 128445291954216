<template>
    <el-dialog title="修改支付密码" width="700px" :model-value="visible" @close="cancel" :close-on-click-modal='false' class="change-password-dialog">
        <p class="text">向 {{ formatPhone(data.userPhone) }} 的手机号码发送验证码</p>
        <el-form :model="model" :rules="rules" class="form" ref="form" label-width="auto">
            <el-col :span="22">
                <el-form-item label="手机验证码" prop="smsCode">
                    <el-col :span="16">
                        <el-input v-model="model.smsCode" placeholder="请输入短信验证码" style="width: 210px;"></el-input>
                    </el-col>
                    <el-col :span="6">
                        <el-button type="primary" @click="onSendCode" :disabled="countdown > 0">
                            {{ countdown > 0 ? `${countdown}秒后重新发送` : '发送验证码' }}
                        </el-button>
                    </el-col>
                </el-form-item>
            </el-col>
            <el-col :span="22">
                <el-form-item label="新密码" prop="newPaymentPassword">
                    <el-input v-model.trim="model.newPaymentPassword" :type="passwordVisible ? 'text' : 'password'" placeholder="请输入新密码">
                        <template #suffix>
                            <el-icon @click="togglePasswordVisibility">
                                <component :is="passwordVisible ? 'Hide' : 'View'" />
                            </el-icon>
                        </template>
                    </el-input>
                </el-form-item>
            </el-col>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="submitForm">确认</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: 'change-password-dialog',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            model: {
                smsCode: null,
                newPaymentPassword: null
            },
            countdown: 0,
            rules: {
                smsCode: [
                    { required: true, message: '请输入验证码', trigger: ['blur', 'change'] }
                ],
                newPaymentPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, message: '密码长度至少6位', trigger: 'blur' }
                ]
            },
            userPhone: this.data.userPhone,
            passwordVisible: true
        };
    },
    methods: {
        reset() {
            this.$nextTick(() => {
                this.$refs.form.resetFields();
            });
        },
        cancel() {
            this.$emit('cancel');
            this.reset();
        },
        // 发送验证码
        onSendCode() {
            if (this.countdown === 0) {
                this.$post('/accounts/send-sms', { businessType: 'change-password' }, 'api').then(({ data }) => {
                    if (data.code === 'C0000') {
                        this.$message.success('验证码发送成功');
                        if (data.result) {
                            this.model.smsCode = data.result.code;
                        } else {
                            this.$message.error('验证码发送失败，请稍后再试');
                        }
                        // 启用倒计时
                        this.countdown = 60;
                        const interval = setInterval(() => {
                            this.countdown--;
                            if (this.countdown === 0) {
                                clearInterval(interval);
                            }
                        }, 1000);
                    }
                });
            }
        },
        // 对手机号码进行*屏蔽处理, 如 15211950679 => 152****0679
        formatPhone(phone) {
            if (phone && phone.length === 11) {
                return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
            }
            return phone;
        },
        // 切换密码可见性
        togglePasswordVisibility() {
            this.passwordVisible = !this.passwordVisible;
        },
        submitForm() {
            this.$refs.form.validate(valid => {
                if (!valid) return;
                this.$emit('confirm', this.model);
                this.reset();
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.change-password-dialog {
    .text {
        font-size: 18px;
        color: #333;
        margin-bottom: 30px;
        padding-left: 100px;
    }
    .form {
        padding: 0 100px;
        display: flex;
        flex-direction: column;
    }
}
</style>
