<template>
    <el-row :gutter="gutter" >
        <el-col :span="14">
        <el-date-picker
            v-model="datePicker"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @change="changeDateTimePicker"
            placeholder="日期"
            :disabled="disabled"
        ></el-date-picker>
        </el-col>
        <el-col :span="10">
        <el-time-select
            v-if="isReload"
            
            v-model="selectTime"
            :picker-options="pickerOptions"
            placeholder="时间"
            @keyup.native="checkTimePicker"
            @change="changeDateTimePicker"
            :disabled="disabled"
        ></el-time-select>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: 'ab-dateTimePick',
    data () {
        return {
            datePicker: this.modelValue && this.modelValue.split(/\s+/)[0],
            selectTime: this.modelValue && 
            this.modelValue.split(/\s+/)[1] &&
            this.modelValue.split(/\s+/)[1].length > 5 ? this.modelValue.split(/\s+/)[1].substring(0, this.modelValue.split(/\s+/)[1].length - 3) : this.timeDefualt,
            isReload: true
        }
    },
    
    model: {
        modelValue: String
        
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: String,
        },
        size: {
            type: String,
            default: 'small'
        },
        gutter: {
            type: Number,
            default: 50
        },
        disabled: {
            type: Boolean,
            default: false
        },
        pickerOptions: {
            type: Object,
            default: {start: '00:00',step: '00:30',end: '24:00'}
        },
        timeDefualt: {
            type: String,
            default: '10:00'
        }
    },
    watch: {
        modelValue (value) {
            if (!value) {
                this.selectTime = ''
                this.datePicker = ''
                this.$emit('change', '')
                this.reload()
                return false
            }
            let date = value.split(/\s+/)[0], time = value.split(/\s+/)[1] ? value.split(/\s+/)[1] : '00:00'
            this.datePicker = date
            this.$emit('update:modelValue', value)
            this.selectTime = (time && time.length > 5) ?  time.substring(0, time.length - 3) : this.timeDefualt
            this.reload()
        }
    },
    methods: {
        checkTimePicker (e) {
            let value = e.target.value
			let start = '00:00', end = "24:00"
			if (value && !isNaN(value) && value.trim().length < 4) {
				start = Number(value) - 1 < 0 ? 1 : Number(value) - 1 
                end = Number(value) + 1 > 23 ? 24 : Number(value) + 1
                start < 10 ? start = '0' + start + ':00' : start += ':00'
                end < 10 ? end = '0' + end + ':00' : end += ':00'
			}
			this.pickerOptions =  {start: start, step: '00:30', end: end}
        },
        changeDateTimePicker () {
           
            if (!this.datePicker) {
                this.$emit('update:modelValue', '')
            }
            if (!this.selectTime) {
                this.$emit('update:modelValue', '')
            }
            if (this.datePicker && this.selectTime) {
                let value = this.selectTime.trim().length > 5 ? (this.datePicker + ' ' + this.selectTime) : (this.datePicker + ' ' + this.selectTime +':00')
                this.$emit('update:modelValue', value)
            }
             console.log(this.modelValue)
        },
        reload () {
            this.isReload = false
            this.$nextTick(() => {
                this.isReload = true
            })
        }
    },
}
</script>

<style>

</style>