<template>
<div class="limit-money">
<el-drawer v-model="limitDrawer.show" :show-close="false">
    <template #header="{ close }">
        <h4>{{quoatText}}</h4>
        <button
        aria-label="el.drawer.close"
        class="el-drawer__close-btn"
        type="button"
        @click="close"
        >
        <i class="el-icon el-drawer__close">
            <el-icon>
            <CircleCloseFilled />
            </el-icon>
        </i>
        </button>
    </template>

    <template #default>
        <div class="progress" v-if="quoatText == '申请额度'">
            <div :class="{ sure: process == 1 }">
                <div class="progress-detail">
                <div>1</div>
                </div>
                <p>确定申请</p>
            </div>
            <div :class="{ sure: process == 2 }">
                <div class="progress-detail" style="margin-left: 4px">
                <div>2</div>
                </div>
                <p>重签结算页</p>
            </div>
            <div :class="{ sure: process == 3 }">
                <div class="progress-detail">
                <div>3</div>
                </div>
                <p>授予额度</p>
            </div>
            <i></i>
        </div>

        <div class="progress" v-else>
            <div :class="{ sure: process == 1 }">
                <div class="progress-detail">
                <div>1</div>
                </div>
                <p>确认申请</p>
            </div>
            <div :class="{ sure: process == 2 }">
                <div class="progress-detail" style="margin-left: 4px">
                <div>2</div>
                </div>
                <p>风控审批</p>
            </div>
            <i></i>
        </div>

        <div class="sureMoney">
        <el-form
            :model="dataFrom"
            status-icon
            :rules="rules"
            ref="dataFrom"
            label-width="100px"
            class="demo-ruleForm"
            label-position="top"
        >
            <el-form-item prop="type" :required="true" label="额度类型">
            <el-select
                v-model="dataFrom.type"
                class="m-2"
                placeholder="请选择额度类型"
                clearable
            >
                <el-option label="进口税款" value="1" />
                <!-- <el-option label="出口税款" value="2" /> -->
            </el-select>
            </el-form-item>
            <el-form-item prop="applyAmount" :required="true" class="issue">
                <template #label
                    >{{quoatText == '提额' ? '提高额度至' : '申请额度'}}
                    <el-popover
                        placement="top-end"
                        popper-class="form-pop"
                        :width="200"
                        trigger="hover"
                    >
                        <div v-if="quoatText == '申请额度'">首次申请最高可申请5万</div>
                        <div v-else>最低不可低于初始额度，额度大于50万，审批材料可能要求更高</div>
                        <template #reference>
                            <ab-svg name="my-issue"></ab-svg>
                        </template>
                    </el-popover>
                </template>

                <!-- <el-input
                    v-model="dataFrom.applyAmount"
                    placeholder="请输入申请额度"
                /> -->
                <ab-money
                    v-model="dataFrom.applyAmount"
                    placeholder="请输入申请额度"
                    append="CNY"
                    clearable
                >
                </ab-money>
            </el-form-item>
            <el-form-item label="备注">
                <el-input
                    v-model="dataFrom.remark"
                    type="textarea"
                    maxlength="200"
                    :autosize="{ maxRows: 15 }"
                    resize="none"
                    placeholder="请输入备注"
                />
            </el-form-item>
        </el-form>
        <div v-if="quoatText == '提额'">
            <el-alert  title="审批材料: 上年12个月及近1个月企业纳税申请表（所得税）, 上年12个月及近1个月企业纳税申请表（增值税）" type="warning" ></el-alert>
            <ab-upload
                :params="uploadParams"
                @update-billsId="updateBillsId"
                ref="fileUpload"
            ></ab-upload>
        </div>
        </div>
    </template>

    <template #footer>
        <div style="flex: auto">
        <el-button @click="limitDrawer.show = false">取消</el-button>
        <el-button type="primary" :loading="isloading" @click="confirmRaise" style="margin-left: 20px">确定</el-button>
        </div>
    </template>
</el-drawer>
<ab-preview :preview="preview"  @handleClose="handleClose">
    <template #button>
        <el-button @click="handleClose">关 闭</el-button>
    </template>
</ab-preview>
</div>
</template>

<script>
export default {
    name: 'ab-boost-limit',
    data () {
        return {
            userInfo: this.$utils.getSessionStorage('userInfo'),
            dataFrom: {},
            wkStatus: '',
            status: '',
            process: 1,
            rules: {
                type: [
                    { required: true, message: '请选择额度类型', trigger: 'change' }
                ],
                applyAmount: [
                    {  required: true, validator: (rule, value, callback) => {
                        if (!value) {
                            return callback(new Error('请输入申请额度'))
                        }
                        if (/^[1-9]+[0-9]*$/.test(value)) {
                            return callback()
                        }
                        return callback(new Error('请输入正整数'))
                    }, trigger: 'blur'}
                ],
            },
            uploadParams: {
                sourceBillId: '',
                sourceBillNumber: '',
                sourceBillType: 'credit',
                isviewFileUrl: true,
                items: [
                    {
                        title: '所得税',
                        attachType: '010',
                        required: true,
                        viewFileUrl: 'https://static.z-easy.com.cn/prod/Credit/所得税.png',
                        message: '请上传上年12个月及近1个月企业纳税申请表（所得税）附件'
                    },
                    {
                        title: '增值税',
                        attachType: '020',
                        required: true,
                        viewFileUrl: 'https://static.z-easy.com.cn/prod/Credit/增值税.png',
                        message: '请上传上年12个月及近1个月企业纳税申请表（增值税）附件'
                    },
                    {
                        title: '其他材料',
                        attachType: '030'
                    }]
            },
            tempBillId: '',
            isloading: false,
            preview: {
                show: false,
                isView: false,
                isFooter: true,
                fileUrl: ""
            }
        }
    },
    props: {
        limitDrawer: {
            type: Object,
            default: {}
        }
    },
    computed: {
        quoatText() {
            let text = ''
            if(!this.dataFrom.currentAmountFront) {
                text = '申请额度'
            }else if(this.status == 1 || this.status == 2 || this.wkStatus == 1) {
                text = '审批中'
            }else {
                text = '提额'
            }
            return text
        }
    },
    watch: {
        'limitDrawer.show': {
            handler: function(val, oldval) {
                if (val) {
                    this.getClientCreditAmount()
                }
            },
            deep: true
        }
    },
    
    mounted () {
        this.getClientCreditAmount()
    },
    methods: {
        getClientCreditAmount() {
            this.$get('/bas/client/ClientCreditAmount/get').then(({data}) => {
                if(data.code === 'C0000') {
                    if(data.result) {
                        if(data.result.amountEntryVos && data.result.amountEntryVos.length) {
                        this.dataFrom = data.result.amountEntryVos[0]
                        }
                        this.dataFrom.frozenBigDecimal = data.result.frozenBigDecimal
                        this.wkStatus = data.result.wkStatus
                        this.status = data.result.status
                    }
                }
            })
        },
        updateBillsId (tempBillId) {
            this.tempBillId = tempBillId || this.tempBillId
        },
        confirmRaise () {
            let tie = Promise.resolve('1')
            if(this.quoatText == '提额') {
               tie = this.$refs.fileUpload.validate()
            }
            tie.then(()=>{
                this.$refs["dataFrom"].validate(valid => {
                    if (valid) {
                        this.isloading = true
                        if (this.quoatText == "提额") {
                            this.$post("/bas/client/servProtocol/list", {
                            entrustId: this.userInfo.outerOrgId,
                            isEnable: 1,
                            typeList: ["01", "02"],
                            status: "03"
                            }).then(res => {
                                if (res.data && res.data.code === "C0000" && res.data.result.length > 0) {
                                    if (res.data.result[0].trusteeId) {
                                    let params = {
                                        principalId: this.userInfo.outerOrgId,
                                        creditOrgId: res.data.result[0].trusteeId,
                                        applyAmount: this.dataFrom.applyAmount,
                                        remark: this.dataFrom.remark,
                                        tempBillId: this.tempBillId
                                    };
                                    this.$post(
                                        "/bas/client/temp/platformAmount/clientRaiseCreditAmount",
                                        params
                                        ).then(({ data }) => {
                                            if (data.code === "C0000") {
                                            this.isloading = false
                                            this.$message({
                                                type: 'success',
                                                message: data.message,
                                                offset: 380,
                                                grouping: true,
                                            })
                                            this.limitDrawer.show = false
                                            this.$emit('renderlist')
                                            } else {
                                                this.isloading = false
                                                this.$message({
                                                    type: 'error',
                                                    message: data.message,
                                                    offset: 380,
                                                    grouping: true,
                                                })
                                            }
                                        }).catch(() => {
                                            this.isloading = false;
                                        })
                                    }
                                } else if (res.data && res.data.code === "C0000") {
                                    this.isloading = false;
                                    this.$message({
                                        type: 'error',
                                        message: "未查询到对应的服务协议",
                                        offset: 380,
                                        grouping: true,
                                    })
                                    return
                                }
                            }).catch(() => {
                                this.isloading = false;
                            })
                        } else {
                            if (this.dataFrom.applyAmount > 50000) {
                                this.isloading = false
                                this.$message({
                                    type: 'error',
                                    message: "首次申请,最高只能申请5万",
                                    offset: 380,
                                    grouping: true,
                                })
                                return
                            }
                            let params = {
                                applyAmount: this.dataFrom.applyAmount,
                                remark: this.dataFrom.remark
                            }
                            this.$post(
                            "/bas/client/ClientCreditAmount/submitWork",
                            params
                            ).then(({ data }) => {
                            if (data.code === "C0000") {
                                this.isloading = false
                                this.$message({
                                    type: 'success',
                                    message: data.message,
                                    offset: 380,
                                    grouping: true,
                                })
                                this.preview.fileUrl = data.result
                                this.preview.show = true
                                this.preview.isView = true
                            } else {
                                this.isloading = false
                                this.$message({
                                    type: 'error',
                                    message: data.message,
                                    offset: 380,
                                    grouping: true,
                                })
                            }
                            })
                            return
                        }
                    }
                })
            },()=>{
                console.log(45);
            })
        },
        handleClose () {
            this.limitDrawer.show = false
            this.preview.show = false
        }
    }
}
</script>

<style lang="scss">
//弹出框 提额
  .limit-money {
    text-align: left;
    color: #000;
    .el-drawer.rtl.open {
      header {
        font-size: 18px;
        color: #000;
        margin-bottom: 20px;
      }
      .progress {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding-bottom: 40px;
        // color: #0a7cff;
        text-align: center;
        .progress-detail {
          position: relative;
          z-index: 1000;
          width: 64px;
          height: 64px;
          background-color: #f2f7ff;
          border-radius: 8px;
          padding: 14px;
          margin-bottom: 10px;
          div {
            width: 36px;
            height: 36px;
            border-radius: 50%;

            // border: 3px solid #0a7cff;
            border: 3px solid #000;
            text-align: center;
            line-height: 30px;
            font-weight: 700;
          }
        }
        p {
          font-size: 14px;
        }
        i {
          position: absolute;
          top: 32px;
          width: 100%;
          height: 2px;
          border-top: 2px dashed #d6d6d6;
        }
      }
      .el-drawer__footer {
        text-align: center;
      }

      .sureMoney {
        .el-textarea {
          .el-textarea__inner {
            min-height: 43px !important;
            padding-top: 10px;
            color: #000;
            line-height: 23px;
            /*  &::placeholder {
              color: #000;
            } */
          }
        }
        .issue {
          .el-form-item__label {
            .icon-svg {
              width: 14px;
              height: 14px;
              margin-left: 4px;
              vertical-align: middle;
              cursor: pointer;
            }
          }
        }
      }
      //当前流程高亮
      .sure {
        color: #0a7cff;
        .progress-detail {
          div {
            border: 3px solid #0a7cff;
          }
        }
      }
    }
  }
</style>