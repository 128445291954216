<template>
  <div class="oftenIssue">
    <transition name="issues" :appear="true">
      <div class="issue" v-if="hello1">
        <div class="title">
          <h2>常见问题</h2>
          <ab-svg name="close" @click="btn2"></ab-svg>
        </div>

        <el-input
          v-model="searchKeywords"
          class="w-50 m-2"
          placeholder="请输入您的问题关键词"
          clearable
        >
          <template #prefix>
            <el-icon><Search /></el-icon>
          </template>
        </el-input>

        <div class="banner">
          <div
            class="banner-detail"
            v-for="item in issueList"
            :key="item.id"
            @click="issueDialog(item.id)"
          >
            <h5>{{item.question}}</h5>
            <div>
              <span>答复</span>{{ formatContent(replaceField(item.answer)) }}
            </div>
          </div>
        </div>

        <div class="foot">
          <div @click="runVideo">
            <img :src="require(`@/assets/img/教程.png`)" />
            <div>看教程</div>
          </div>
          <div @click="serviceOpen()">
            <img :src="require(`@/assets/img/客服.png`)" />
            <div>找客服</div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="showSide" class="btn" @click="btn" :class="hello2Class">
      <ab-svg name="open" v-if="!hello1"></ab-svg>
      <ab-svg name="close" v-else></ab-svg>
      常见问题
    </div>

    <div
      class="hello3"
      @click="btn3"
      :class="hello3Class"
      v-if="isHelp"
    >
      <img class="logo-2" src="@/assets/img/yixiu-gb2.png" />
    </div>

    <!-- <transition name="issue2"> -->
    <el-dialog
      v-model="dialog.show"
      width="540px"
      :modal='false'
      :lock-scroll='false'
      :show-close='false'
      :class="dialog.show?'b':'c'"
    >
      <template #header>
        {{dialog.data.question}}
        <img
          src="@/assets/img/yixiu-gb3.png"
          alt=""
        >
      </template>
      <template #default>
        <div class="answer-content" v-html="replaceField(dialog.data.answer)" @click="detailEvent($event)"></div>
      </template>
      <template #footer>
        <el-button @click="closeDialog()">知道了</el-button>
      </template>
    </el-dialog>
    <!-- </transition> -->

    <el-drawer
      v-model="serviceDrawer.show"
      title="咨询客服"
      
      custom-class="service-dialog"
      :before-close="serviceClose"
      modal-class="customer"
    >
      <img src="@/assets/img/wxs-qc.jpg"/>
    </el-drawer>
  </div>
</template>

<script>
import issueData from './data'

export default {
  name: 'ab-issue',
  data() {
    return {
      searchKeywords: '',
      hello1: false,
      hello2: false,
      hello2Class: '',
      hello3: true,
      hello3Class: '',
      dialog: {
        show: false,
        data: {}
      },
      warehouseNumber: '',
      servBuz: {},
      serviceDrawer: {
        show: false
      },
      bankData: {}
    }
  },
  porps: {
    isHelp: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    pagePath() {
      return this.$route.path.replace('/', '')
    },
    sideSwatch () {
      let msg = this.$utils.getLocalStorage('msg')
      if (this.pagePath === 'import-orders-detail') {// 订单
        if (!msg || !msg.order) {
          return true
        }
      } else if (this.pagePath === 'pledging-payment-detail') { // 付汇申请
        if (!msg || !msg.pay) {
          return true
        }
      }
      return false
    },
    showSide() {
      if (this.pagePath === 'import-orders-detail') {// 订单
        if ([undefined, 'add', 'edit', 'copy'].includes(this.$route.query.status)) {
          return true
        }
      } else if (this.pagePath === 'pledging-payment-detail') { // 付汇申请
        if (['add', 'wait', 'import', 'out', 'edit'].includes(this.$route.query.action)) {
          return true
        }
      }
      return false
    },

    issueList() {
      let findData = issueData.find(e => e.path.includes(this.pagePath))
      let list = []
      if (findData) {
        list = findData.list
      } else {
        issueData.forEach(row => {
          list.push(...row.list)
        })
      }
      // 关键词过滤
      if (this.searchKeywords) {
        let words = this.searchKeywords.toLowerCase().replace(/(^\s*)|(\s*$)/g, '')
        return list.filter(e => e.question.toLowerCase().includes(words) || e.answer.toLowerCase().includes(words))
      } else {
        return list
      }
    },
    mainTabsActiveName: {
      get () { return this.$store.state.mainTabsActiveName }
    },
  },

  watch: {
    /* $route(to, from) {
      this.closeAll()
      if (this.showSide) {
        this.btn()
      }
    } */
    $route: {
        handler(){
            this.closeAll()
            if (this.showSide && this.sideSwatch) {
                this.btn()
            }
        },
        immediate: true
    }
  },

  mounted() {
    this.getWarehouseNumber()
    this.getServBuz()
    this.bankName()
  },
  
  methods: {
    issueDialog(id) {
      this.dialog.show = true
      this.dialog.data = this.issueList.find(e => e.id === id)
    },
    btn() {
      console.log(1)
      this.hello1 = !this.hello1
      if (this.hello2) {
        this.hello2 = false
        this.hello3 = true
        this.hello2Class = 'hello2Class-enter'
      } else {
        this.hello2 = true
        this.hello3 = false
        this.hello2Class = 'hello2Class-leave'
      }
      this.setDialogSwatch(this.hello2)
      this.getServBuz()
      this.bankName()
    },
    setDialogSwatch (bool) {
      let msg = this.$utils.getLocalStorage('msg')
      if (!msg) {
        msg = {}
      }
      if (this.pagePath === 'import-orders-detail') {
        msg.order = !bool
      } else if (this.pagePath === 'pledging-payment-detail') {
        msg.pay = !bool
      }
      this.$utils.setLocalStorage('msg', msg)
    },
    btn2() {
      console.log(2)
      this.hello1 = !this.hello1
      if (this.hello2) {
        this.hello2 = false
        this.hello3 = true
        this.hello2Class = 'hello2Class-enter'
      } else {
        this.hello2 = true
        this.hello3 = false
        this.hello2Class = 'hello2Class-leave'
      }
      this.setDialogSwatch(this.hello2)
    },
    btn3() {
      if (this.hello3) {
        this.hello3 = false
        this.hello2 = true
        this.hello2Class = 'hello2Class-leave'
        this.hello3Class = 'hello3Class'
        setTimeout(() => {
          this.hello3Class = ''
          this.hello1 = true
        }, 500)
      } else {
        this.hello1 = false
        this.hello2 = false
        this.hello3 = true
        this.hello2Class = 'hello2Class-enter'
      }
    },

    // 关闭弹框
    closeDialog() {
      this.dialog.show = false
      this.dialog.data = {}
    },

    // 获取入仓号
    getWarehouseNumber() {
      this.$post('/bas/client/basCustomer/getClientWorkBench').then(({ data }) => {
        if (data.code === 'C0000' && data.result) {
          this.warehouseNumber = data.result.principal.number
        }
      })
    },

    // 客服信息
    getServBuz() {
      this.$get('/oms/client/workbench/getServBuz').then(({ data }) => {
        if (data.code === 'C0000' && data.result) {
          this.servBuz = data.result
        }
      })
    },

    // 自定义字段替换
    replaceField(str) {
      if (str) {
        str = str.replace(/{warehouseNumber}/g, this.warehouseNumber || '')
        str = str.replace(/{serviceName}/g, this.servBuz.name || '')
        str = str.replace(/{serviceMobile}/g, this.servBuz.mobile || '')
        str = str.replace(/{bankName}/g, this.bankData.name || '')
      }
      return str
    },

    // 去掉html标签
    formatContent(str) {
      return str ? str.replace(/<[^>]+>/g, '') : ''
    },
    //银行账号
    bankName(){
        let data = {
            number: "A-211105-001",
            pageNum: 1,
            pageSize: 10
        }
        this.$post(`/bas/basBankAccount/list`,data).then(({ data }) => {
            if(data.code === 'C0000' && data.message) {
                this.bankData = data.result.list[0] || []
            }
        })
    },

    // 内容中a标签点击
    detailEvent(e) {
      console.log(e)
      if (e.target.nodeName === 'A') {
        let path = e.target.getAttribute('data-path') || ''
        let href = e.target.getAttribute('data-href') || ''
        let api = e.target.getAttribute('data-api') || ''
        console.log(path, href, api)
        if (path) {
          this.$router.push(path)
        } else if (href) {
          window.open(href)
        } else if (api) {
          if (api === 'getAccessionPdf') { // 香港仓收货指引
            this.$get(`/tms/client/accessionNumber/getAccessionPdf?number=${this.warehouseNumber}`).then(({ data }) => {
              if(data.code === 'C0000' && data.message) {
                window.open(data.message)
              }
            })
          }
        }
      }
    },

    // 关闭全部
    closeAll() {
      this.closeDialog()
      this.searchKeywords = ''
      this.hello1 = false
      this.hello2 = false,
      this.hello2Class = '',
      this.hello3 = true,
      this.hello3Class = ''
    },

    serviceOpen() {
      this.serviceDrawer.show = true
    },

    serviceClose() {
      this.serviceDrawer.show = false
    },
    runVideo () {
      this.$alert('功能暂未开放！','温馨提示')
    }
  }
}
</script>

<style lang='scss'>
.oftenIssue {
  position: fixed;
  top: 60px;
  right: 0;
  z-index: 1000;
  color: #000000;
  .btn {
    position: relative;
    top: 70px;
    right: 10px;
    writing-mode: vertical-lr;
    width: 50px;
    height: 150px;
    font-size: 18px;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    line-height: 50px;
    letter-spacing: 7px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: 0.5s;
    .icon-svg {
      width: 19px;
      height: 19px;
    }
  }
  .issue {
    position: absolute;
    top: 70px;
    right: 0px;
    width: 260px;
    height: 600px;
    background-color: #fff;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 2000;
    padding: 20px 0 0;
    transition: all 0.5s;

    .title {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      margin-bottom: 14px;
      padding: 0 15px;
      .icon-svg {
        width: 19px;
        height: 19px;
        cursor: pointer;
      }
    }
    .el-input {
      padding: 0 15px;
      .el-input__wrapper {
        height: 36px;
        .el-input__inner {
          height: 36px;
          line-height: 36px;
        }
      }
    }

    .banner {
      margin-top: 5px;
      height: 416px;
      overflow: auto;
      &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
      }
      &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background: #aaa;
          &:hover {
              background: #999;
          }
      }
      &::-webkit-scrollbar-track {
          background: transparent;
      }
      .banner-detail {
        padding: 7px 15px;
        cursor: pointer;
        &:hover {
          background-color: #f2f7ff;
          div {
            color: #0b7cff;
          }
        }
        h5 {
          font-size: 14px;
          margin-bottom: 8px;
        }
        div {
          color: #999999;
          font-size: 12px;
          line-height: 21px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
        span {
          padding: 1px 3px;
          font-size: 12px;
          color: #f87243;
          background-color: #f8e4de;
          border-radius: 2px;
          margin-right: 3px;
        }
      }
    }

    .foot {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 90px;
      padding: 0 70px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
      & > div {
        text-align: center;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .hello3 {
    position: absolute;
    top: 0;
    right: 22px;
    transition: 0.5s;
    cursor: pointer;
  }
  //弹出框
  .el-overlay-dialog {
    .el-dialog {
      position: absolute;
      top: 20px;
      right: 20px;
      margin: 0;
      padding: 24px 50px 20px 20px;
      border-radius: 16px;
      .el-dialog__header {
        padding: 0;
        margin-bottom: 20px;
        font-size: 18px;
        img {
          position: absolute;
          top: -12px;
          right: -5px;
        }
      }
      .el-dialog__body {
        padding: 0;
        min-height: 120px;
        font-size: 14px;
        line-height: 23px;
        .answer-content {
          &::before {
            content: '答复';
            padding: 3px 3px;
            font-size: 12px;
            color: #f87243;
            background-color: #f8e4de;
            border-radius: 3px;
            margin-right: 3px;
          }
          color: #000;
          a {
            color: #0B7CFF;
            cursor: pointer;
          }
        }
      }
      .el-dialog__footer {
        padding: 0;
        text-align: center;
      }
    }
    .b {
      animation: issue2 0.5s forwards;
    }
    .c {
      animation: issue2reverse 0.5s;
    }
    @keyframes issue2 {
      0% {
        top: 20px;
        right: 20px;
        margin: 0;
      }
      100% {
        top: 50%;
        right: 50%;
        margin: -127px -270px 0 0;
      }
    }
    @keyframes issue2reverse {
      0% {
        top: 50%;
        right: 50%;
        margin: -127px -270px 0 0;
      }
      100% {
        top: 20px;
        right: 20px;
        margin: 0;
      }
    }
  }

  .issues-enter-from,
  .issues-leave-to {
    right: -300px;
  }
  .issues-enter-to,
  .issues-leave-from {
    right: 0px;
  }
  //常见问题按钮
  .hello2Class-enter {
    top: 70px;
  }
  .hello2Class-leave {
    top: 715px;
  }
  //飞机动画
  @keyframes identifier {
    0% {
      top: 0;
      opacity: 1;
    }
    100% {
      top: 440px;
      opacity: 0.1;
    }
  }
  .hello3Class {
    animation: identifier 0.5s;
  }
}
</style>