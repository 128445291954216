<template>
  <el-drawer v-model="dialog.show" class="company-dialog" :title="title">
    <el-form :model="dataFrom" v-loading="showloading" :rules="rules" ref="dataFrom" label-position="top">
      <el-row :gutter="30">
        <el-col :span="24">
          <el-form-item label="公司名称" prop="name">
            <el-input maxLength='30' placeholder="请输入公司名称" v-model="dataFrom.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="所在区域" prop="countryId">
            <ab-address v-model="dataFrom.countryId"></ab-address>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="行业类别" prop="custTyId">
            <el-select placeholder="请选择行业类别" v-model="dataFrom.custTyId">
              <el-option v-for="item in induList" :key="item.label" :label="item.value" :value="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="demo-col">
          <el-form-item label="营业执照" prop="linkPhone">
            <el-upload class="avatar-uploader" action :show-file-list="false" :before-upload="beforeAvatarUpload">
              <img v-if="dataFrom.fileUrl" :src="dataFrom.fileUrl" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
            <div class="demo-img">
              <img src="@/assets/img/demo.png" />
            </div>
          </el-form-item>
          <label class="label-tishi">营业执照复印件加盖公章</label>
        </el-col>
      </el-row>
      <!-- <ab-alert v-model="fileList" @change="changefileData1"></ab-alert> -->
    </el-form>
    <div class="dialog-footer">
      <el-button @click="handleClose" :loading="btnLoading">取 消</el-button>
      <el-button type="primary" @click="handlerSave" :loading="btnLoading">确 定</el-button>
    </div>
  </el-drawer>
</template>

<script>
import vueCookie from 'vue-cookies'
export default {
  name: 'ab-company',
  props: {
    dialog: {
      type: Object,
      default: () => {
        return {
          show: false
        }
      }
    }
  },
  data() {
    return {
      showloading: false,
      btnLoading: false,
      title: '',
      fileData: {
        sourceBillId: '',
        attachType: '070',
        sourceBillType: 'Address',
        sourceBillNumber: ''
      },
      rules: {
        name: [
          { required: true, message: '公司名称不能为空', trigger: ['blur', 'change'] }
        ],
        countryId: [
          { required: true, message: '所在区域不能为空', trigger: ['blur', 'change'] }
        ],
        custTyId: [
          { required: true, message: '行业类别不能为空', trigger: ['blur', 'change'] }
        ],
      },
      dataFrom: {},
      induList: [],
      temImg: ''
    }
  },
  watch: {
    dialog: {
      handler: function (val, oldval) {
        this.waitParseText = ''
        if (val.id) {
          this.title = 'dialog'
          this.getDetailData(val.id)
        } else {
          this.title = '企业认证'
        }
        this.getOptions()
      },
      deep: true
    }
  },
  methods: {
    getOptions() {
      this.$get('/api/dictDetail/detailMaps', { dictName: 'industry' }).then(({ data }) => {
        if (data && data.code === 'C0000') {
          this.induList = data.result ? data.result.industry || [] : []
        }
      })
    },
    handlerSave() {
      this.$refs.dataFrom.validate((voids) => {
        if (voids) {
          this.btnLoading = true
          let params = Object.assign({}, this.dataFrom)
          params.countryId = this.dataFrom.countryId ? this.dataFrom.countryId.join(',') : ''
          this.$post('/company/certification', params).then(({ data }) => {
            if (data && data.code === 'C0000') {
              this.$message.success(data.message || '操作成功！')
              vueCookie.set('token', data.result.token, {
                domain: process.env.VUE_APP_DOADMIN
              });
              this.$utils.setSessionStorage('companyId', data.result.companyId)
              setTimeout(() => {
                this.handleClose()
              }, 1000)
            }
          }).finally(() => {
            this.btnLoading = false
          })
        }
      })
    },
    beforeAvatarUpload(file) {
      const isLtSize = file.size / 1024 / 1024 < 5
      if (!isLtSize) {
        this.$message({
          type: 'error',
          message: file.name + '不能超过5MB!',
          offset: 380,
          grouping: true,
        })
        return false
      }
      // let types = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png'];
      // const isImage = types.includes(file.type);
      // if (!isImage) {
      //   this.$message({
      //     type: 'error',
      //     message: '上传图片只能是 jpg、jpeg、gif、png 格式!',
      //     offset: 380,
      //     grouping: true,
      //   })
      //   return false
      // }
      //转化为blob
      this.fileName = file.name
      let reader = new FileReader();
      reader.onload = e => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.temImg = data
      };
      //转化为base64
      reader.readAsDataURL(file);
      let formData = new FormData();
      formData.append('file', file);
      formData.append('sourceBillType', 'company');
      formData.append('sourceBillId', '');
      formData.append('attachType', 'business_license');
      this.upload(formData).then(({ data }) => {
        if (data && data.code === 'C0000') {
          this.$message({
            type: 'success',
            message: '上传成功',
            offset: 380,
            grouping: true,
          })
          this.dataFrom.fileUrl = data.result ? data.result.fileUrl || '' : ''
        }
      })
    },
    handleClose() {
      this.dialog.show = false
      this.$emit('updateCompany')
    },
    upload(data) {
      return this.$http({
        method: 'post',
        url: this.$adornUrl('/api/attactment/uploadFile'),
        timeout: 20000 * 60,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data', // 关键
        },
      })
    },
  }
}
</script>
<style lang="scss">
.company-dialog {
  padding: 0px 20px 0px 20px !important;

  .ai-input {
    .el-input-group__append {
      background-color: #0b7aff;
      color: #ffffff;
    }
  }

  .dialog-footer {
    position: fixed;
    bottom: 20px;
    text-align: center;
  }

  .avatar-uploader .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

  .avatar-uploader .el-upload {
    border: 2px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
  }

  .avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
  }

  .el-icon.avatar-uploader-icon {
    font-size: 40px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
  }

  .demo-img {
    width: 100px;
    height: 100px;
    margin-left: 46px;
    border-radius: 6px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .demo-col {
    position: relative;

    .label-tishi {
      position: absolute;
      right: 15px;
      top: 0;
      font-size: 14px;
      font-weight: 400;
      color: #797979;
    }
  }
}
</style>