<template>
  <div class="page-header">
    <p>NO：{{ numbers }}</p>
    <p>{{ createTime }}</p>
    <!-- <p class="cus-header" v-if="customerNo">客户单号：{{ customerNo }}</p> -->
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'ab-heads',
  props: {
    numbers: {
      type: String,
      default: ''
    },
    createTime: {
      type: String,
      default: ''
    },
    customerNo: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
  .page-header{
    background-color: var(--el-color-primary-light-9);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: absolute;
    right: 100px;
    top: 0;
    padding: 12px 35px;
    p{
      font-size: 14px;
      text-align: center;
    }
    p:last-child{
      margin-bottom: 0;
    }
    .cus-header{
      background: #FDE22B;
      padding: 0;
      height: 30px;
      line-height: 28px;
    }
  }
</style>
