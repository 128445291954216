<template>
    <el-dialog title="申请预付贷款" width="700px" :model-value="visible" @close="cancel" :close-on-click-modal='false' class="apply-prepay-loan-dialog">
        <el-form :model="model" :rules="rules" ref="form">
            <el-form-item label="支付密码" prop="paymentPassword">
                <el-input v-model.trim="model.paymentPassword" type="password"></el-input>
            </el-form-item>
            <el-form-item label="公司名称" prop="companyName">
                <el-input v-model.trim="model.companyName"></el-input>
            </el-form-item>
            <el-form-item label="公司税号" prop="companyTaxNo">
                <el-input v-model.trim="model.companyTaxNo"></el-input>
            </el-form-item>
            <el-form-item label="下载协议">
                <el-link href="https://xzbom.oss-cn-shenzhen.aliyuncs.com/uploads/%E6%96%B0%E6%B3%BD%E5%9C%A8%E7%BA%BF%E9%A2%84%E4%BB%98%E5%8D%8F%E8%AE%AE.docx"
                    target="_blank" class="download" @clink="onDownload">
                    <el-icon><Download /></el-icon>
                    <span>下载协议</span>
                </el-link>
            </el-form-item>
            <el-form-item label="协议文件" prop="paymentProtocolFileUrl">
                <el-upload
                    class="upload-demo"
                    action
                    :before-upload="beforeUpload"
                    v-model:file-list="fileList"
                    :accept="accept"
                    :limit="1"
                >
                    <el-button type="primary" class="xz-base-btn upload-btn">
                        <el-icon><Upload /></el-icon>
                        <span>点击上传</span>
                    </el-button>
                    <div slot="tip" class="el-upload__tip">只能上传pdf、doc、docx格式的文件</div>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="cancel" disabled>取消</el-button>
                <el-button type="primary" @click="handleSubmit">提交</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: 'apply-prepay-loan-dialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    data() {
        return {
            model: {
                paymentPassword: null,
                companyName: null,
                companyTaxNo: null,
                paymentProtocolFileUrl: null,
            },
            accept: '.pdf,.doc,.docx',
            fileList: [],
            rules: {
                paymentPassword: [
                    { required: true, message: '请输入支付密码', trigger: 'blur' },
                    { min: 6, message: '支付密码最少6位', trigger: 'blur' }
                ],
                companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
                companyTaxNo: [{ required: true, message: '请输入公司税号', trigger: 'blur' }],
                paymentProtocolFileUrl: [{ required: true, message: '请上传协议文件', trigger: 'change' }],
            },
        };
    },
    methods: {
        reset() {
            this.$nextTick(() => {
                this.$refs.form.resetFields();
            });
        },
        cancel() {
            this.$emit('cancel');
            this.reset();
        },
        beforeUpload(file) {
            let fd = new FormData();
            fd.append('file', file);
            this.upload(fd)
                .then((res) => {
                    if (res.data && res.data.code === 'C0000') {
                        this.$message({
                            type: 'success',
                            message: '上传成功！',
                            appendTo: '.upload-demo',
                            grouping: true,
                            offset: 160,
                        });
                        this.fileList.push(file);
                        this.model.paymentProtocolFileUrl = res.data.result;
                    }
                })
                .catch((res) => {
                    console.error('上传失败', res);
                    this.$message.error('上传失败！');
                })
                .finally(() => {
                    setTimeout(() => {}, 1000);
                });
            return false;
        },
		upload(data) {
			return this.$http({
				method: 'post',
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				url: this.$adornUrl('/accounts/upload-agreement','api'),
				timeout: 20000 * 60,
				data: data,
			});
		},
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (!valid) return;
                this.$emit('confirm', this.model);
                this.reset();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.apply-prepay-loan-dialog {
    .download {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 86px;
        width: 100px;
        padding: 10px;
        border: 1px solid #e8eaec;
        border-radius: var(--el-border-radius-base);
        color: #333;
        height: 38px;
        margin-left: 8px;
        .icon {
            width: 20px;
            height: 20px;
            margin-right: 4px;
        }
        &:hover {
            --el-link-hover-text-color: unset;
        }
    }
    .upload-demo {
        :deep(.el-upload) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .upload-btn {
                padding: 10px;
                width: 100px;
            }
        }
        .el-upload__tip {
            color: #999;
        }
    }
}
</style>
