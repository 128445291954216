<template>
    <div class="ab-list-query">
        <el-menu
            :default-active="isActive"
            mode="horizontal"
            @select="selectMenu"
        >
        <el-menu-item :index="item.state" v-for="(item, idx) in tabs" :key="idx">
            <template #title>
                <p class="tab-item-tile">{{item.name}}<span>{{ getItemValue(item.value) }}</span></p>
            </template>
        </el-menu-item>
        </el-menu>
        <div class="list-content">
            <div class="query-right clearfix">
                <div  class="query-seach fl">
                    <slot name="right">

                    </slot>
                </div>
            </div>
            <div class="toolbar" v-if="showToolbar">
                <slot name="toolbar">

                </slot>
            </div>
            <div class="query-list" :style="{marginTop: !showToolbar ? '20px' : '0'}">
                <slot name="table">

                </slot>
                <div class="list-page">
                    <slot name="sum"></slot>
                    <slot name="page">

                    </slot>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'ab-list-query',
    data () {
        return {

        }
    },
    props: {
        tabs: {
            type: Array,
            default: []
        },
        isActive: {
            type: String,
            default: ''
        },
        showToolbar: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        getItemValue (val) {
            if (val) {
                return Number(val) > 99 ? '99+' : val
            }
            return ''
        },
        selectMenu (item) {
            let tabsArray = this.tabs.filter(obj=> obj.state == item)
            let obj = {}
            if(tabsArray && tabsArray.length > 0){
                obj = tabsArray[0]
            }
            this.$emit('handleTabs', item, obj)
        }
    }
}
</script>

<style lang="scss">
.ab-list-query {
    // padding: 5px 0;
    .list-content {
        padding: 15px 20px;
    }

    .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
        background-color: transparent;
    }
    .el-menu {
        border-right: none;
        background:none;
        border-bottom:none;
        height: 50px;
    }
    .el-badge__content.is-fixed {
        top: 15px;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
        position: relative;
        &::after{
            width: 30%;
            background-color: var(--el-color-primary);
            content: '';
            height: 4px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .toolbar {
        text-align: left;
        padding: 20px 0 19px 0;
        .el-button + .el-button {
            margin-left: 20px;
        }
    }
    .query-list {
        margin: 0;
        // padding-top: 20px;
    }
    .tab-item-tile {
        & > span {
            margin-left: 4px;
        }
    }
}
.list-page {
    padding: 10px 0;
    .el-pagination {
        justify-content: center;
        .btn-prev, .btn-next {
            width: 76px;
            height: 36px;
            border: 1px solid #C4C6CF;
            background-color: #ffffff;
            border-radius: 30px;
            margin:0 10px;
        }
        .el-pager {
            .number,
            .btn-quickprev,
            .btn-quicknext {
                border: 1px solid #C4C6CF;
                background-color: #ffffff;
                border-radius: 50%;
                height: 36px;
                width:36px;
                margin-right: 10px;
            }
            .is-active {
                background-color: var(--el-color-primary);
                color: #ffffff;
            }
        }

        .el-pagination__sizes {
            .el-input {
                height: 36px;
            }
            .el-input__wrapper {
                background-color: #ffffff;
                border: 1px solid #C4C6CF;
                border-radius: 30px;
            }
        }
        .el-pagination__jump {
            .el-input__wrapper {
                background-color: #ffffff;
                border: 1px solid #C4C6CF;
                border-radius: 30px;
            }
        }

    }
}
</style>
