<template>
    <el-dialog title="退回贷款" width="700px" :model-value="visible" @close="cancel" :close-on-click-modal='false'  class="refund-loan-dialog">
        <el-form :model="model" :rules="rules" ref="form" class="form">
            <el-alert type="warning" show-icon :closable="false" title="提交申请后，财务将在3个工作日处理退回货款！" class="tips"></el-alert>
            <el-form-item label="公司名称" class="company-name">
                <el-input :model-value="data.companyName" disabled></el-input>
            </el-form-item>
            <el-form-item label="开户银行" prop="bankName">
                <el-input v-model.trim="model.bankName"></el-input>
            </el-form-item>
            <el-form-item label="银行卡号" prop="bankCardNo">
                <el-input v-model.trim="model.bankCardNo"></el-input>
            </el-form-item>
            <el-form-item label="退回金额" prop="amount">
                <el-col :span="16">
                    <el-input type="number" v-model.number="model.amount" placeholder="请输入金额" clearable></el-input>
                </el-col>
                <el-col :span="6">
                    <el-button type="primary" link class="refund-all" @click="onClickRefundAll">退回全部货款</el-button>
                </el-col>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="handleSubmit">确认</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: 'refund-loan-dialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
            required: true
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            model: {
                bankName: null,
                bankCardNo: null,
                amount: null,
            },
            rules: {
                bankName: [{ required: true, message: '请输入开户银行地址', trigger: 'blur' }],
                bankCardNo: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }],
                amount: [{ required: true, message: '请输入金额', trigger: ['blur', 'change'] }],
            },
        };
    },
    methods: {
        reset() {
            this.$nextTick(() => {
                this.$refs.form.resetFields();
            });
        },
        cancel() {
            this.$emit('cancel');
            this.reset();
        },
        onClickRefundAll() {
            this.model.amount = this.data.availableBalance;
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (!valid) return false;
                this.$emit('confirm', this.model);
                this.reset();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.refund-loan-dialog {
    .form {
        padding: 0 26px;
    }
    .tips {
        margin-bottom: 30px;
        padding: 16px;
        :deep(.el-alert__content) {
            display: flex;
            align-items: center;
        }
    }
    .company-name {
        :deep(.el-form-item__label) {
            padding-right: 10px;
            &::after {
                content: "*";
                color: red;
                margin-left: 4px;
            }
        }
    }
    .refund-all{
        margin-left: 12px;
    }
}
</style>
