<template>
  <div v-loading="orderLoading">
    <el-form :model="dataForm" ref="dataForm" label-position="top" class="dataForm">
      <div class="contentInfo">
        <div class="logisticsInfo">
          <div class="title">
            <h3>配送信息</h3>
            <div class="progress" @click="viewLog" v-if="dataForm.orderStatus >= 2 && dataForm.orderStatus <= 6">
              <div class="text">
                <el-badge class="item">物流进度</el-badge>
              </div>
              <ab-logistics-status ref="logistics-status" :limitDrawer="limitDrawer">
              </ab-logistics-status>
            </div>
          </div>
          <div class="address-text">
            <el-row :gutter="20">
              <el-col :span="24">
                <label class="label-text">收货地址</label>
                <span class="text-item">
                  <ab-svg name="dingwei" style="color: #0c9837"></ab-svg>
                  {{ orderAddress.address }}/{{ orderAddress.contactPerson }}/{{ orderAddress.contactPhone }}
                  <span class="exprType-span" v-if="dataForm.courierChannel">{{ getExpressLabel(dataForm.courierChannel) }}</span>
                </span>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 20px;">
              <el-col :span="24">
                <label class="label-text">期望交货日期</label>
                <span class="text-item">
                  {{ dataForm.expectedDeliveryTime }}
                </span>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="goodsInfo" style="margin-top: 30px">
          <import-order-table ref="order-table" :data="goodsInfoData" @updatedForm="init"></import-order-table>
        </div>
      </div>
      <div class="footers">
        <el-button @click="back">返回</el-button>
        <el-button type="primary" @click="sumitForm" v-if="!dataForm.paymentTime">付 款</el-button>
      </div>
    </el-form>
  </div>
  <pay-dialog :visible="visibleData.show" :data="visibleData.data" @cancel="visibleData.show = false" @success="init"></pay-dialog>
</template>
<script>
import importOrderTable from './import-order-table'
import payDialog from './box/pay-dialog'
import { OrderDetailStatus } from '@/constant/index'

export default {
  name: 'order-detail-view',
  components: {
    importOrderTable,
    payDialog
  },
  props: {
    routeQuery: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      visibleData: {
        show: false,
        data: {
          amount: 0,
          orderNo: '',
          availableBalance: 0
        }
      },
      deliverTransportNo: '',
      goodsInfoData: { type: this.routeQuery.type, inquiryDetails: [] },
      activeIndex: 0,
      expressOptionsObj: [], // 快递公司
      shippingMethodObj:[],
      logvisible: false,
      addressVisible: false,
      orderstatus: 1,
      orderLoading: false,
      dataForm: {
        entry: [],

      },
      limitDrawer: {show: false, id: ''},
      orderAddress: {}, // 用户地址信息
    }
  },
  computed: {
    mainTabs: {
      get() {
        return this.$store.state.mainTabs
      },
      set(val) {
        this.$store.commit('updateMainTabs', val)
      }
    },

    mainTabsActiveName: {
      get() {
        return this.$store.state.mainTabsActiveName
      },
      set(val) {
        this.$store.commit('updateMainTabsActiveName', val)
      }
    },
  },
  watch: {
    orderLoading: {
      handler: function(val, oldval) {
        this.init()
      },
      immediate: true
    }
  },
  mounted() {
    this.initData()
    this.init()
  },
  methods: {
    getExpressLabel(value) {
      return this.expressOptionsObj.find(item => item.value === value)?.label
    },
    editReturn() {
      this.$post("/orderBase/modDetails", this.routeQuery.orderNo).then(({ data }) => {
        if (data && data.code === "C0000") {
          this.$message.success('修改成功！')
        }else{
            this.$message({type: 'error', message: data.message || '修改失败，请联系管理员', offset: 380, grouping: true})
        }
      })
    },
    viewLog() {
      this.limitDrawer.id = this.routeQuery.orderNo || this.dataForm.orderNo
      this.limitDrawer.show = true
    },
    handleSelect(item) {
      this.dataForm.countryId = item.countryId
      this.dataForm.cityId = item.countryId
      this.dataForm.countyId = item.countyId
      this.dataForm.addressId = item.id
    },
    handlerAdd() {
      this.addressVisible = true
    },
    expressClick(index) {
      this.activeIndex = index
    },
    initData() {
      // 订单常量
      this.$get('/orders/constants', undefined, 'api').then(({ data }) => {
        if (data.code === 'C0000') {
          this.expressOptionsObj = data.result.express // 快递公司
        }
      })
    },
    init() {
      this.goodsInfoData = {type: this.routeQuery.type, inquiryDetails: [], canceledList: []}
      if (this.routeQuery.orderNo) { // 编辑/详情
        this.$get(`/orders/${this.routeQuery.orderNo}`, {},'api').then(({ data }) => {
          if (data.code === 'C0000') {
            this.dataForm = data.result
            this.goodsInfoData = data.result
            // 用户信息
            this.orderAddress = data.result.orderAddress
            // 商品信息
            this.goodsInfoData.inquiryDetails = []
            // 已失效的数据
            this.goodsInfoData.canceledList = []
            this.goodsInfoData.type = this.routeQuery.type
            // 过滤数据
            data.result.orderDetails.forEach(item => {
              if (item.orderDetailStatus === OrderDetailStatus.CANCELLED) {
                this.goodsInfoData.canceledList.push(item)
              } else {
                this.goodsInfoData.inquiryDetails.push(item)
              }
            })
          }
        })
      }
    },
    removeTabHandle(tabName) {
      this.mainTabs = this.mainTabs.filter(item => item.index !== tabName)
      if (tabName) {
        this.$router.push({ path: '/order-list' })
      }
    },
    sumitForm() {
      this.visibleData.data.amount = this.$refs['order-table'].totalCount
      this.visibleData.data.orderNo = this.routeQuery.orderNo || this.dataForm.orderNo
      this.visibleData.show = true
    },
    back() {  // 返回
      this.removeTabHandle(this.mainTabsActiveName)
    }
  },
  watch: {
    'routeQuery': {
        handler: function(val, oldval) {
            if (val) {
                this.init(val.orderNo)
            }
        },
        deep: true,
        immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.address-text {
  border-bottom: 1px solid #F0F2F5;
  padding-bottom: 20px;

  .label-text {
    background: #F0F2F5;
    border-radius: 4px;
    padding: 5px 10px;
  }

  .text-item {
    margin-left: 20px;

    .icon-svg {
      width: 14px;
      height: 17px;
    }

    .expr-span {
      background: #FFDE2E;
      padding: 5px 6px;
      border-radius: 8px;
      margin-left: 7px;
    }

    .exprType-span {
      background: var(--el-color-primary);
      padding: 5px 6px;
      border-radius: 8px;
      margin-left: 7px;
      color: #ffffff;
    }

  }
}
</style>
