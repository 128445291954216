<template>
    <div class="material-list">
        <div class="header">
            <p>物料清单</p>
        </div>
        <div class="upload-inquire">
            <el-upload
                :disabled="noCertifiedFlag"
                :action="action"
                drag
                :headers="headers"
                :before-upload="beforeAvatarUpload"
                :on-success="onSuccessUpload"
                :show-file-list="false"
            >
                <el-button class="upload-btn" type="primary" :loading="isUploadLoading" :disabled="noCertifiedFlag">
                    <ab-svg name="upload"></ab-svg>
                    <span>上传物料编码文件</span>
                </el-button>
            </el-upload>
            <el-link class="download" href="https://cdn.xzbom.com/uploads/%E6%96%B0%E6%B3%BD%E5%9C%A8%E7%BA%BF%E7%89%A9%E6%96%99%E6%B8%85%E5%8D%95%E4%B8%8A%E4%BC%A0%E6%A8%A1%E6%9D%BF.xlsx" target="_blank">下载导入模板</el-link>
        </div>
        <p class="remark">*此功能可用于导入您的物料编号，建立与商城自营商品编号的对应关系。</p>
        <p class="tip">例如，您购买了商品编号为HQ0000001的自营商品，该商品与贵司对应的物料编码是C00001。在订单详情页面、送货单和物料标签上，我们会显示贵司的物料编号C00001，方便您核对物料。</p>
        <div class="content">
            <ab-list-query>
                <template #right>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-input placeholder="请输入完整的商品编号或贵司物料编号" v-model.trim="formSearch.productCode" clearable @clear="clearProductCode">
                                <template #prefix>
                                    <ab-svg name="search"></ab-svg>
                                </template>
                            </el-input>
                        </el-col>
                        <el-col :span="4">
                            <el-button @click="searchForm" class="pub-btn">查询</el-button>
                            <el-button link class="btn-svg btn-clear" @click="resetForm"><ab-svg name="qingchu"></ab-svg>清除</el-button>
                        </el-col>
                    </el-row>
                </template>
                <template #toolbar>
                    <el-col :span="4">
                        <el-button class="btn-delete-batch pub-btn" :loading="isBtnLoading" @click="removeBatch">
                            <ab-svg name="delete"></ab-svg>
                            <span>批量删除</span>
                        </el-button>
                    </el-col>
                </template>
                <template #table>
                    <ab-table class="materialTable" ref="table" stripe :column-list="columns" showSelection selectionWidth="45" showOrder indexLabel="序号" indexWidth="100"
                        :table-list="tableData" :dataListLoading="isloading" :selectable="selectable" :handleSelectionChange="handleSelectionChange">
                        <template #productCode="props">
                            {{ props.row.product.productCode }}
                        </template>
                        <template #mpnAndPackage="props">
                            {{ props.row.product.mpn }}/{{ props.row.product.package }}
                        </template>
                        <template #description="props">
                            {{ props.row.product.description }}
                        </template>
                        <template #userProductCode="props">
                            {{ props.row.userProductCode }}
                        </template>
                        <template #operator="scope">
                            <el-button class="btn-operator" type="warning" link @click="update(scope.row)">
                                修改
                            </el-button>
                            <el-button class="btn-operator" type="danger" link @click="remove(scope.row.userMaterialId)">
                                删除
                            </el-button>
					    </template>
                    </ab-table>
                </template>
                <template #page>
                    <ab-page :pageTotal="page.total" :currentPage='page.currentPage' :pageSize="page.pageSize"
                    :pageSizes="page.pageSizes" @searchForm="pageSearch"></ab-page>
                </template>
            </ab-list-query>
        </div>
        <!-- 修改物料清单 弹窗 -->
        <update-material-dialog :visible="materialData.visible" :data="materialData.data" @cancel="materialData.visible = false" @success="getListData"></update-material-dialog>
    </div>
</template>

<script>
import vueCookie from 'vue-cookies';
import updateMaterialDialog from './box/update-material-dialog.vue';


export default {
    name: 'material-list',
    components: {
        updateMaterialDialog
    },
    data() {
        return {
            isloading: false,
            isUploadLoading: false,
            isBtnLoading: false,
            formSearch: {},
            action: this.$adornUrl('/user-materials', 'api'),
            headers: {Authorization: vueCookie.get('token')},
            page: {
                currentPage: 1,
                pageSize: 10,
                pageSizes: [10, 20, 50, 100],
                total: 0
            },
            columns: [
                {
                    props: 'productCode',
                    labels: '商城商品编号',
                    widths: 200,
                    slot: 'slot'
                },
                {
                    props: 'mpnAndPackage',
                    labels: '型号/封装',
                    widths: 200,
                    slot: 'slot'
                },
                {
                    props: 'description',
                    labels: '描述',
                    widths: 280,
                    slot: 'slot'
                },
                {
                    props: 'userProductCode',
                    labels: '贵司物料编号',
                    widths: 200,
                    slot: 'slot'
                },
                {
                    props: 'operator',
                    labels: '操作',
                    widths: 100,
                    slot: 'slot'
                }
            ],
            selected: [],
            tableData: [],
            materialData: {
                visible: false,
                data: {
                    userMaterialId: '',
                    userProductCode: '',
                }
            }
        }
    },
    mounted() {
        this.getListData()
    },
    methods: {
        // 列表数据
        getListData() {
            this.isloading = true
            this.$get('/user-materials', {
                page: this.page.currentPage,
                pageSize: this.page.pageSize,
                productCode: this.formSearch.productCode
            }, 'api').then(({ data }) => {
                if (data.code === 'C0000') {
                    this.tableData = data.result.data
                    this.page.total = data.result.pagination.total
                }
            }).finally(() => {
                this.isloading = false
            })
        },
        // 清除搜索框
        clearProductCode() {
            this.formSearch.productCode = '';
            this.searchForm();
        },
        // 查询
        searchForm() {
            this.getListData();
        },
        // 重置
        resetForm() {
            this.formSearch = {};
            this.getListData();
        },
        handleSelectionChange(val) {
			this.selected = val;
		},
        // 修改
        update(row) {
            this.materialData.visible = true;
            this.materialData.data = row;
        },
        // 公用删除方法
        deleteMaterials(userMaterialIdList, title = '提示') {
            if (userMaterialIdList.length === 0) {
                this.$message.warning('请选择要删除的物料');
                return;
            }

            this.$confirm('确定要删除该物料吗？', title, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if(title === "批量删除") { this.isBtnLoading = true; }
                this.$delete('/user-materials', { data: { userMaterialIdList } }, 'api').then(({ data }) => {
                    if (data.code === 'C0000') {
                        this.$message.success('删除成功');
                        this.getListData();
                    } else {
                        this.$message.error(data.message);
                    }
                }).finally(() => {
                    this.isBtnLoading = false;
                });
            }).catch(() => {});
        },
        // 单个删除
        remove(userMaterialId) {
            this.deleteMaterials([userMaterialId], '删除');
        },
        // 批量删除
        removeBatch() {
            const userMaterialIdList = this.selected.map(item => item.userMaterialId);
            this.deleteMaterials(userMaterialIdList, '批量删除');
        },
        // 分页
        pageSearch(obj) {
            this.page.currentPage = obj.currentPage;
            this.page.pageSize = obj.pageSize;
            this.getListData();
        },
        // 上传物料编码文件
        beforeAvatarUpload(file) {
            const isLtSize = file.size / 1024 / 1024 < 8
            if (!isLtSize) {
                this.$message({
                    type: 'error',
                    message: file.name + '不能超过8MB!',
                    offset: 380,
                    grouping: true,
                })
                return false
            }
            let types = ['xlsx', 'xls'];
            var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
            if (types.indexOf(testmsg.toLowerCase()) === -1) {
                this.$message({
                    message: '仅支持 xlsx 和 xls 文件',
                    type: 'warning'
                })
                return false
            }
            this.isUploadLoading = true
            this.customerNo = file.name ? file.name.split('.')[0] : ''
        },
        onSuccessUpload(response) {
            if (response.code !== 'C0000') {
                this.$message.error(response.message)
            }
            this.isUploadLoading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.material-list {
    padding: 40px;
    font-size: 16px;
    .header {
        padding-bottom: 20px;
        border-bottom: 2px solid #f0f0f0;
        p {
            font-size: 24px;
            font-weight: 700;
            color: #333;
        }
    }
    .upload-inquire {
        display: flex;
        margin: 20px 0;
        height: 45px;
        :deep(.el-upload-dragger) {
            border: none;
        }
        .upload-btn {
            display: flex;
            align-items: center;
            margin-right: 30px;
            .icon-svg {
                width: 20px;
                height: 20px;
            }
        }
        .download {
            color: var(--xz-color-base);
            &:hover {
                color: var(--xz-color-hover-base);
            }
        }
        :deep(.el-link) {
            --el-link-hover-text-color: unset !important;
        }
    }
    .remark {
        color: var(--el-color-primary);
        margin-top: 20px;
    }
    .tip {
        margin-top: 10px;
        color: #999;
    }
    .content {
        margin-top: 20px;
        .el-input__prefix-inner {
            .icon-svg {
                width: 20px;
                height: 20px;
            }
        }
        .ab-list-query {
            :deep(.el-menu) {
                display: none;
            }
            :deep(.list-content) {
                padding: 15px 0;
            }
        }
        .btn-delete-batch {
            .icon-svg {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
        }
    }
}
</style>
