<template>
  <el-pagination
    class="cap-pagination"
    :total="pageTotal"
    prev-text="上一页"
    next-text="下一页"
    :page-size="pageSize"
    :page-sizes="pageSizes"
    :current-page="currentPage"
    @size-change="pageSizeChange"
    @current-change="currentChange"
    :layout="layout"
  ></el-pagination>
</template>

<script>
export default {
  name: 'ab-page',
  props: {
    pageTotal: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageSizes: {
      type: Array,
      default: () => {
        return [10, 20, 50, 100]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    }
  },
  data () {
    return {
  //    currentPage: 1
    }
  },
  methods: {
    currentChange (value) { // 切换分页
      this.$emit('searchForm', {
        perPage: this.pageSize,
        currentPage: value
      })
    },
    pageSizeChange (value) { // 切换分页大小
    this.$nextTick(res=>{
      //this.$set(this, 'pageSize', value || this.pageSize)
      this.$emit('searchForm', {
        perPage: value,
        currentPage: this.currentPage
      })
    })
      // this.pageSize = value || this.pageSize
    }
  }
}
</script>

<style lang="scss" >
// .cap-pagination {
//   display: block;
//   .el-select{
//     width: auto !important;
//   }
// }
</style>
