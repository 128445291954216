<template>
  <div class="recommend-dialog" style="" v-if="recommendDialog">
    <div class="recommend-img">
      <ab-svg name="guanbi" class="guanbi" @click="closeDialog">
      </ab-svg>
      <div class="receive" @click="jump">点击查看详情</div>
    </div>
    <div class="DialogMask">
      <div class="mask"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ab-recommend",
  props: {
    recommendDialog: {
      type: Boolean
      }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    jump() {
      this.$emit('share')
    },
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
};
</script>

<style lang="scss">
.recommend-dialog{
    height: 100%;
      background-color: #fff;
    .recommend-img{
      margin: 0 auto;
      z-index: 2000;
      width: 40.625vw;
      height: 38.229167vw;
      border-radius: 10px;
      position: fixed;
      top: 50%;
      margin-top: -19.1145vw;
      right: 50%;
      margin-right: -20.3125vw;
      background: url("../assets/img/integral/promoteQrCode.png");
      background-size: 100% 100%;
      .receive{
        width: 11.822917vw;
        font-size: .9375vw;
        font-weight: 550;
        height: 2.916667vw;
        line-height: 2.916667vw;
        text-align: center;
        position: absolute;
        bottom: .9375vw;
        font-size: 1.145833vw;
        right: 50%;
        border-radius: 24px;
        margin-right: -5.885417vw;
        background: url("../assets/img/integral/share.png");
        background-size: 100% 100%;
        text-align: center;
        line-height: 2.916667vw;
        cursor: pointer;
        color: #f55c00;
      }
    .guanbi{
      position: absolute;
      color: #ccc;
      right: -1.5625vw;
      top: -0.520833vw;
      font-size: 28px;
      cursor: pointer;
    }
    .guanbi:hover{
      opacity: .8;
    }
    }
.DialogMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1999;
  overflow: auto;
  margin: 0;
  .mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: #000;
  }
}
}
</style>
