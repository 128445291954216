<template>
    <div class="id-card-history">
        <ab-select
            isNotClear
            :list="list"
            :suffix="false"
            :inputKey="inputKey"
            v-model="inputData"
            :disabled="disabled"
            placeholder="请输入身份证号码"
            @handleSelect="handleSelect"
            @change="handleChange"
            
            ></ab-select>
    </div>
</template>
<script>
export default {
    name: 'ab-history',
    data () {
        return {
            list: [],
            inputData: this.modelValue
        }
    },
    model: {
       modelValue: String
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: String //model
        },
        disabled: {
            type: Boolean,
            default: false
        },
        inputKey: {
            type: String, //model
            default: 'name'
        },
        historyKey: {
            type: String, //model
            default: ''
        }
    },
    created() {
       
        let idCard =  this.$utils.getLocalStorage('idCard') || []
        console.log(idCard)
        let data = idCard[this.historyKey]
        if (data && data.length > 0) {
            data.reverse()
            data.map(e => {
                let obj = {}
                obj[this.inputKey] = e
                obj.value = e
                this.list.push(obj)
            })
        }
        
    },
    watch: {
        historyKey () {
            this.list = this.$utils.getLocalStorage(this.historyKey) || []
        }
    },
    methods: {
        handleSelect (data) {
            let v = ''
            if (typeof data == 'string') {
                v = data
            } else {
                v = data[this.inputKey]
            }
            this.$emit('update:modelValue', v)
           
        },
        handleChange (data) {
            let v = ''
            if (typeof data == 'string') {
                v = data
            } else {
                v = data[this.inputKey]
            }
            this.$emit('update:modelValue', v)
            
        },
        setHistory (key, data) {
            let idCard = this.$utils.getLocalStorage('idCard') || {}
            let historyData = idCard[key] || []
            if (!historyData.includes(data)) {
                historyData.push(data)
                idCard[key] = historyData
                this.$utils.setLocalStorage('idCard', idCard)
            }
        }
    }

}
</script>

<style lang="scss">
.id-card-history {
    width: 100%;
    .el-autocomplete {
        width: 100%;
    }
}
</style>