<template>
    <div class="let-main-menu">
        <el-button class="zhedie" @click="handlerFold">
            <ab-svg name="zdleft"></ab-svg>
        </el-button>
        <div class="menu-scroll" :style="{ 'height': (documentClientHeight - 136) + 'px' }">
            <el-menu :default-active="activeMenu" class="el-menu-vertical-left" ref="menus" :default-openeds="defaultMenu"
                :unique-opened="false" router @select="selectMenu" v-if="reload" >
                <el-menu-item index="/" :class="[mainTabsActiveName == '/' ? 'is-active' : 'no-active']">
                    <ab-svg name="home"></ab-svg>
                    <span>首页</span>
                </el-menu-item>
                <el-sub-menu :index="idx" :class="[item.children.some(e=>mainTabsActiveName.indexOf(e.path) != -1) ? 'is-active' : 'no-active']" v-for="(item, idx) in (menuList || []).filter(e => !e.hidden && e.children && e.children.length
                )" :key="idx">
                    <template #title>
                        <ab-svg :name="item.meta.icon"></ab-svg>
                        <span>{{ item.name }}</span>
                    </template>
                    <el-menu-item :index="chre.path" :key="chre" v-for="chre in item.children.filter(e => !e.hidden)" :class="[mainTabsActiveName.indexOf(chre.path) != -1 ? 'is-active' : 'no-active']">{{
                        chre.name }} </el-menu-item>
                </el-sub-menu>
            </el-menu>
            <div class="menu-footer">
                <p class="cut"></p>
                <img src="@/assets/img/qc.png" />
                <p>扫描二维码</p>
                <p>掌握订单动态</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            menuList: [],
            reload: true,
            defaultMenu: [0,1,2]

        }
    },
    computed: {
        sidebarFold: {
            get() {
                return this.$store.state.sidebarFold
            },
            set(val) {
                this.$store.commit('updateSidebarFold', val)
            }
        },
        documentClientHeight: {
            get() {
                return this.$store.state.documentClientHeight;
            },
            set(val) {
                this.$store.commit('updateDocumentClientHeight', val);
            }
        },
        activeMenu: {
            get() {
                return this.$store.state.activeMenu
            },
            set(val) {
                this.$store.commit('updateActiveMenu', val)
            }
        },
        mainTabs: {
            get() { return this.$store.state.mainTabs },
            set(val) { this.$store.commit('updateMainTabs', val) }
        },
        mainTabsActiveName: {
            get() { return this.$store.state.mainTabsActiveName },
            set(val) { this.$store.commit('updateMainTabsActiveName', val) }
        },
        keepAliveView: {
            get() { return this.$store.state.keepAliveView },
            set(val) { this.$store.commit('updateKeepAliveView', val) }
        }
    },
    created() {
        this.getMenus()
    },
    mounted() {
        if (this.$route.path != '/' && this.$route.path != 'home') {
            this.activeMenu = this.$route.path.replace('/', '')
            this.setMainTabs({ index: this.activeMenu, name: this.$route.name })
            if (this.$route.path == '/my-list' || this.$route.meta.keepAlive || this.$route.meta.sidebarFold) {
                this.sidebarFold = true
            }
        } else {
            this.setMainTabs({ index: '/', name: '首页' })
            this.sidebarFold = true
        }
    },
    methods: {
        getMenus() {
            let bizType = JSON.parse(sessionStorage.getItem('bizType') || '{}')
            this.$get('/api/menus/build').then(({ data }) => {
                if (data && data.code === 'C0000') {
                    if (data.result.length > 0) {
                        let list = data.result
                        this.menuList = list && list.length ? list : []
                        sessionStorage.setItem('menuList', JSON.stringify(this.menuList))
                        this.isReload()
                    }
                }
            })
        },
        selectMenu(index, indexPath) {
            this.activeMenu = index
            this.getRouterName(index)
        },
        handlerFold() {
            this.sidebarFold = !this.sidebarFold
        },
        handlerOpen(val) {
            // this.oneLevelRouter(val)
        },
        handlerClose(key, keyPath) {
            this.$refs.menus.open(keyPath)
        },
        oneLevelRouter(val) {
            let menu = this.menuList[val]
            if (menu.url) {
                this.$router.push({ path: menu.path })
                this.setMainTabs({ index: menu.path, name: menu.name })
                this.activeMenu = menu.path
                this.mainTabsActiveName = menu.path
            }

        },
        getRouterName(index) {
            let obj = {
                index: '', //routet path
                name: '' // router name
            }
            for (let i = 0; i < this.menuList.length; i++) {
                if (this.menuList[i].children) {
                    for (let y = 0; y < this.menuList[i].children.length; y++) {
                        if (this.menuList[i].children[y].path == index) {
                            obj.index = index
                            obj.name = this.menuList[i].children[y].name
                            break
                        }
                    }
                } else if (this.menuList[i].path == index) {
                    obj.index = index
                    obj.name = this.menuList[i].name
                    break
                }
            }
            this.setMainTabs(obj)
        },
        setMainTabs(obj) {
            let tab = this.mainTabs.filter(item => item.name === obj.name)[0]
            if (!tab) {
                this.mainTabs = this.mainTabs.concat(obj)
            }
            let mainTabs = this.mainTabs.filter(item => item.name != '首页')
            mainTabs.unshift({ index: '/', name: '首页' })
            this.mainTabs = mainTabs
            this.mainTabsActiveName = obj.index
            let viewTemp = []
            this.mainTabs.map(e => {
                let view = e.index.replace('/', '')
                if (view) {
                    viewTemp.push(view)
                }
            })
            this.keepAliveView = viewTemp
        },
        isReload() {
            this.reload = false
            this.$nextTick(() => {
                this.reload = true
            })
        },
    }
}
</script>

<style lang="scss">
.let-main-menu {
    position: relative;
    width: 174px;
    // height: 100%;
    margin-left: 20px;
    background-color: #ffffff;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;

    .zhedie {
        width: 174px;
        background-color: #FBFBFB;
        border: none;
        border-radius: 0;

        .icon-svg {
            width: 16px;
            height: 16px;
            color: #000000;
        }

        &:hover {
            background: #FBFBFB;

            .icon-svg {
                color: var(--el-color-primary);
            }
        }
    }

    .main-left-div {
        text-align: center;
        text-align: center;
        height: 40px;
        line-height: 38px;
    }

    .menu-scroll {
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    .el-menu-vertical-left {
        .icon-svg {
            width: 14px;
            height: 16px;
            margin-right: 10px;
            margin-left: 10px;
        }

        .el-sub-menu__title {
            .el-sub-menu__icon-arrow {
                display: none;
            }
        }

    }

    .el-menu--inline {
        // display: block!important;
    }

    .el-menu--inline {
        .el-menu-item {
            padding-left: 47px !important;
            line-height: 40px;
            height: 40px;
        }
    }

    .el-menu .el-menu-item {
        line-height: 40px;
        height: 40px;
    }

    .el-sub-menu__title {
        height: 40px;
    }

    .menu-footer {
        padding: 20px;

        img {
            width: 120px;
            height: 120px;
            margin: 10px;
        }

        p {
            font-size: 12px;
            font-weight: 400;
            text-align: center;
        }

        .cut {
            border-bottom: 1px solid #D7DBE0;
            margin-bottom: 20px;
        }
    }

    .el-menu-item.is-active {
        background-color: var(--xz-color-gray);
        color: var(--el-color-primary);
        border-radius: 8px;
        font-size: 14px;
        &.no-active{
            background-color: #fff;
            color: #000000;
        }
    }

    .el-sub-menu.is-active {
        .el-sub-menu__title {
            color:#f10201;
        }
        &.no-active{
            .el-sub-menu__title {
                color: #000000;
            }
        }
    }

    .el-sub-menu {
        .el-sub-menu__title {
            font-weight: bold;
        }
    }

    .el-menu {
        margin: 0 5px;
        background-color: #ffffff;
    }

    .el-sub-menu {
        .el-sub-menu__title {
            color: #04070D;
            border-radius: 8px;
        }

        .el-menu-item {
            border-radius: 8px;
            color: #535353;
        }
    }

    .el-sub-menu {
        .is-active {
            color: var(--el-color-primary);
        }
    }


}
</style>
