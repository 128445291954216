<template>
    <div class="payment-detail">
        <div class="payment-content">
            <!-- 订单信息 -->
            <div class="info-section">
                <div class="info-item">
                    <span class="label">订单编号：</span>
                    <span class="value">{{ orderInfo.orderNo }}</span>
                </div>
                <div class="info-item">
                    <span class="label">创建时间：</span>
                    <span class="value">{{ orderInfo.createdAt }}</span>
                </div>
                <div class="info-item">
                    <span class="label">支付状态：</span>
                    <span class="value">{{ orderInfo.paymentStatusText }}</span>
                </div>
            </div>

            <!-- 付款金额 -->
            <div class="amount-section">
                <div class="amount-title">付款金额</div>
                <div class="amount-value">¥ {{ orderInfo.totalAmount }}</div>
            </div>

            <!-- 支付方式 -->
            <div class="payment-method">
                <div class="method-title">支付方式：</div>
                <div class="tabs">
                    <div class="zhifu" :class="{ active: activeName == 'zhifu' }" @click="changeTab('zhifu')">
                        <ab-svg name="pay"></ab-svg>
                        支付宝付款
                        <div class="triangle"></div>
                        <el-icon class="active-icon"><ArrowDownBold /></el-icon>
                    </div>
                    <div class="xianxia" :class="{ active: activeName == 'xianxia' }" @click="changeTab('xianxia')">
                        <ab-svg name="xianxia" style="color: #01458e"></ab-svg>
                        线下付款
                        <div class="triangle"></div>
                        <el-icon class="active-icon"><ArrowDownBold /></el-icon>
                    </div>
                    <div class="weixin" :class="{ active: activeName == 'weixin' }" @click="changeTab('weixin')">
                        <ab-svg name="wechat-pay"></ab-svg>
                        微信支付
                        <div class="triangle"></div>
                        <el-icon class="active-icon"><ArrowDownBold /></el-icon>
                    </div>
                    <div class="yufukuan" :class="{ active: activeName == 'yufukuan' }" @click="changeTab('yufukuan')">
                        <ab-svg name="qianbao"></ab-svg>
                        <div>
                            <span>预付货款</span>
                            <span>余额 ￥0</span>
                        </div>
                        <div v-if="activeName === 'yufukuan'" class="triangle"></div>
                        <el-icon class="active-icon"><ArrowDownBold /></el-icon>
                    </div>
                </div>
                <div class="pay-info" v-if="activeName == 'xianxia'">
                    <p>
                        <span style="color: red">* </span>银行水单<span style="color: red; font-size: 14px">(最多上传5个)</span>
                    </p>
                    <div class="clearfix">
                        <div class="file-item" v-for="(item, index) in fileList" :key="index">
                            <div class="file-item-preview" @click.stop="handerOreview(item.attachmentUrl)">
                                <ab-svg :name="getSuffix(item.attachmentName)" class="icon-center"></ab-svg>
                                <el-icon class="del-icon" @click.stop="del(item.businessNo, item.attachmentId)"><DeleteFilled/></el-icon>
                            </div>
                            <el-tooltip class="item" effect="dark" :content="item.attachmentName" placement="bottom">
                                <p class="name">
                                    {{ item.attachmentName }}
                                </p>
                            </el-tooltip>
                        </div>
                        <el-upload v-if="!fileList.length || fileList.length < 5" class="upload-inquire-list"
                            action drag :before-upload="beforeUpload"
                            multiple
                        >
                            <div class="add-upload">
                                <el-icon><Plus /></el-icon>
                            </div>
                        </el-upload>
                    </div>
                    <p class="tips">温馨提示：线下付款，请将货款打入如下收款账号中！</p>
                    <div class="account-info">
                        <p>企业名称：深圳市新泽沣润电子有限公司</p>
                        <p>开户银行：招商银行深圳深纺大厦支行</p>
                        <p>银行账号：755960613410306</p>
                        <div class="title">收款账号</div>
                        <div class="copy" @click="copy">
                            <ab-svg name="复制"></ab-svg>
                            复制
                        </div>
                    </div>
                </div>
                <div class="weixin-info" v-else-if="activeName == 'weixin'">
                    <canvas id="wx-stage"></canvas>
                </div>
                <div v-else style="height: 50px"></div>
            </div>

            <!-- 支付按钮 -->
            <div class="action-section">
                <el-button type="primary" :loading="loading" @click="handlePay">
                    立即支付
                </el-button>
            </div>
        </div>
        <textarea style="width: 0; height: 0" id="inputCopy">这是幕后黑手</textarea>
		<ab-preview :preview="preview"></ab-preview>
        <!-- 校验支付密码 -->
        <verify-payment-password-dialog :visible="visibleVerifyData.isShow" @cancel="visibleVerifyData.isShow = false" @confirm="handleVerifySubmit"></verify-payment-password-dialog>
    </div>
</template>

<script>
import QRCode from 'qrcode';
import verifyPaymentPasswordDialog from './verify-payment-password-dialog.vue';
import { PaymentStatus } from '@/constant/index';

export default {
    name: 'PaymentDetail',
    components: {
		verifyPaymentPasswordDialog,
	},

    data() {
        return {
            loading: false,
            routeQuery: {},
            orderInfo: {
                orderNo: '',
                createdAt: '',
                paymentStatusText: "",
                totalAmount: ''
            },
            activeName: 'zhifu',
			preview: {
				show: false,
			},
			fileList: [],
			accept: '.bmp,.jpg,.png,.tif,.gif,.pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.txt,.zip,.rar,.ofd',
			fileArr: [],
			loadingInstance: null,
			visibleVerifyData: { // 校验支付密码
				isShow: false,
			},
			pollInterval: null, // 轮询定时器
        }
    },
    mounted() {
        this.routeQuery = this.$route.query
        this.orderInfo = this.routeQuery
    },

    methods: {
        // 获取微信支付二维码链接
		getQrCodeLink() {
			this.$post(`/payments/wechat/${this.routeQuery.orderNo}`, undefined, 'api').then(({ data }) => {
				if (data.code === 'C0000') {
					const link = data.result.code_url;
					this.generateQrCode(link);
					// 启动轮询
					this.startPolling();
				}
			});
		},
		// 生成微信支付二维码
		generateQrCode(link) {
			const stage = document.getElementById('wx-stage');
			QRCode.toCanvas(
				stage,
				link,
				{
					width: 200,
					height: 200,
					color: {
						dark: '#000',
						light: '#fff',
					},
				},
				(error) => {
					if (error) console.error(error);
					if (stage) {
						stage.style.border = '1px solid #e5e5e5';
					}
				}
			);
		},
		// 启动轮询 每1秒查询一次订单状态
		startPolling() {
			this.pollInterval = setInterval(() => {
				this.checkOrderStatus();
			}, 1000);
		},
		// 停止轮询
		stopPolling() {
			if (this.pollInterval) {
				clearInterval(this.pollInterval);
				this.pollInterval = null;
			}
		},
		// 查询订单状态
		checkOrderStatus() {
			this.$get(`/orders/${this.routeQuery.orderNo}`, undefined, 'api').then(({ data }) => {
				if (data.code === 'C0000' && data.result.paymentStatus === PaymentStatus.COMPLETED) {
					this.$message.success('订单已支付');
					// 停止轮询
					this.stopPolling();
					this.$emit('success');
					this.cancel();
				}
			});
		},
		changeTab(name) {
			this.activeName = name;
			if (name === 'weixin') {
				this.getQrCodeLink();
			} else if (name === 'xianxia') {
				this.initUpload();
			} else if (name === 'yufukuan') {
				this.visibleVerifyData.isShow = true;
			}
		},
		getSuffix(obj) {
			if (!obj) {
				return;
			}
			let index = obj.lastIndexOf('.') || 0;
			let svg = obj.substring(index + 1).toLowerCase();
			return this.accept.indexOf(svg) == -1 ? 'accessory' : svg;
		},
		del(businessNo, attachmentId) {
			this.$delete(
				`/payments/remittance/${businessNo}/files/${attachmentId}`,
				undefined,
				'api'
			).then(({ data }) => {
				if (data.code === 'C0000') {
					this.$message.success('删除成功！');
					this.initUpload();
				}
			});
		},
		handerOreview(fileUrl) {
			this.preview.fileUrl = fileUrl;
			this.preview.show = true;
		},
		initUpload() {
			this.fileList = [];
			this.$get(
				`/payments/remittance/${this.routeQuery.orderNo}/files`,
				undefined,
				'api'
			).then(({ data }) => {
				if (data.code === 'C0000') {
					this.fileList = data.result || [];
				}
			});
		},
		beforeUpload(file) {
			let fd = new FormData();
			fd.append('file', file);
			this.upload(fd)
				.then((res) => {
					if (res.data && res.data.code === 'C0000') {
						this.$message({
							type: 'success',
							message: '上传成功！',
							appendTo: '.upload-demo',
							grouping: true,
							offset: 160,
						});
						this.initUpload();
					}
				})
				.catch((res) => {
					if (res && res.data) {
					}
				})
				.finally(() => {
					setTimeout(() => {}, 1000);
				});
			return false;
		},
		upload(data) {
			return this.$http({
				method: 'post',
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				url: this.$adornUrl(
					`/payments/remittance/${this.routeQuery.orderNo}/upload`,
					'api'
				),
				timeout: 20000 * 60,
				data: data,
			});
		},
		copy() {
			var input = document.getElementById('inputCopy'); // 找到虚拟的输入框
			input.value =
				'企业名称：深圳市新泽沣润电子有限公司;开户银行：招商银行深圳深纺大厦支行;银行账号：755960613410306;'; // 修改文本框的内容
			input.select(); // 选中文本
			document.execCommand('copy'); // 执行浏览器复制命令
			this.$message({
				type: 'success',
				message: '复制成功!',
				grouping: true,
				offset: 380,
			});
		},
		reset() {
			this.$nextTick(() => {
				this.$refs.form.resetFields();
			});
		},
		cancel() {
			this.$emit('cancel');
		},
		handlerSave() {
			if (this.activeName == 'zhifu') {
				// 请求支付宝支付/api/payments/alipay/订单编号
				this.$get(`/payments/alipay/${this.routeQuery.orderNo}`, {}, 'api').then(
					({ data }) => {
						window.document.write(data);
					}
				);
				return;
			}
			if (!this.fileList.length) {
				this.$message.error('请上传银行水单！');
				return;
			}

			const params = {
				files: this.fileList.map((item) =>
					_.pick(item, ['attachmentName', 'attachmentPath'])
				),
			};

			this.btnLoading = true;
			this.$post(`/payments/remittance/${this.routeQuery.orderNo}`, params, 'api').then(({ data }) => {
				if (data.code === 'C0000') {
					this.$message.success('支付成功');
					this.$emit('success');
					this.cancel();
				}
			})
			.finally(() => {
				this.btnLoading = false;
			});
		},
		// 校验支付密码
		handleVerifySubmit(model) {
			this.$post(`/payments/account/${this.routeQuery.orderNo}`, model, 'api').then(({ data }) => {
				if (data.code === 'C0000') {
					this.$message.success(data.message);
				}
			}).finally(() => {
				this.visibleVerifyData.isShow = false;
			});
		},
    },
    watch: {
		visible: {
			handler: function (val) {
				if (val) {
					this.initUpload();
				}
			},
			deep: true,
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-detail {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    font-size: 18px;

    .payment-header {
        margin-bottom: 24px;

        h2 {
            font-size: 20px;
            color: #333;
        }
    }

    .info-section {
        padding: 16px;
        background: #f8f8f8;
        border-radius: 4px;

        .info-item {
            margin-bottom: 12px;

            .label {
                color: #666;
            }

            .value {
                margin-left: 8px;
                color: #333;

                &.success {
                    color: #67c23a;
                }

                &.pending {
                    color: #e6a23c;
                }
            }
        }
    }

    .amount-section {
        margin: 24px 0;
        text-align: center;

        .amount-title {
            font-size: 18px;
            color: #666;
        }

        .amount-value {
            margin-top: 10px;
            text-align: center;
			font-size: 42px;
            font-weight: bold;
			color: var(--el-color-primary);
        }
    }

    .payment-method {
        .method-title {
            font-size: 18px;
            color: #333;
        }
		.tabs {
			display: flex;
            margin: 20px 0;
			.triangle {
				display: none;
				position: absolute;
				right: 0;
				bottom: 0;
				width: 0;
				height: 0;
				border-right: 22px solid var(--el-color-primary);
				border-top: 20px solid transparent;
				border-bottom: 0px solid transparent;
				border-bottom-right-radius: 7px;
				font-size: 12px;
				text-align: center;
			}
			.active-icon {
				display: none;
				font-size: 10px;
				color: #fff;
				position: absolute;
				right: 2px;
				bottom: 1px;
			}
			& > div {
				// float: left;
				text-align: center;
				width: calc(50% - 10px);
				border: 1px solid #ccc;
				border-radius: 8px;
                padding: 10px 20px;
				cursor: pointer;
				&.active {
					color: var(--el-color-primary);
					border-color: var(--el-color-primary);
					transform: scale(1.02);
					.triangle,
					.active-icon {
						display: block;
					}
				}
				&:not(:last-child) {
					margin-right: 20px;
				}
				display: flex;
				align-items: center;
				justify-content: center;
				.icon-svg {
					margin-right: 10px;
				}
			}
			.yufukuan {
				& > div {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
				}
			}
		}
		.pay-info {
			position: relative;
			.file-item {
				background-color: #fff;
				margin-bottom: 5px;
				border-radius: 8px;
				width: 100px;
				height: 100px;
				font-size: 12px;
				border: 1px solid #cacfdb;
				text-align: center;
				margin: 10px 10px 10px 0;
				float: left;
				.file-item-preview {
					padding-top: 25px;
					&::before {
						top: 30px;
					}
				}
				p {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					padding: 0 8px;
					background-color: #f2f5f7;
					height: 25px;
					line-height: 25px;
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
				}
				.icon-center {
					margin: 6px auto;
				}
				.name {
					bottom: 0;
					border-top-left-radius: 0px;
					border-top-right-radius: 0px;
					border-bottom-left-radius: 8px;
					border-bottom-right-radius: 8px;
				}
				.file-item-preview {
					position: relative;
				}
				.del-icon {
					position: absolute;
					right: 0;
					top: 0;
					display: none;
					cursor: pointer;
					color: red;
					float: right;
					font-size: 16px;
					line-height: 16px;
					margin-top: 2px;
				}
				&:hover {
					.del-icon {
						display: block;
					}
				}
			}
			.tips {
				color: red;
				margin-bottom: 10px;
			}
            .upload-inquire-list {
                height: 100px;
                width: 100px;
                float: left;
                margin: 10px 0 10px;
                .el-upload-list {
                    margin: 0;
                }
                .el-upload-dragger {
                    float: left;
                    border: none;
                    background-color: unset;
                    padding: 0;
                    margin-right: 20px;
                }
                .el-upload-list__item {
                    transition: none !important;
                    -webkit-transition: none !important;
                    display: none !important;
                }
                .el-upload-list__item-name {
                    transition: none !important;
                    -webkit-transition: none !important;
                }
                .add-upload {
                    width: 100px;
                    height: 100px;
                    line-height: 110px;
                    color: #aaaaaa;
                    border-radius: 8px;
                    text-align: center;
                    font-size: 26px;
                    // border: 1px solid #ccc;
                }
            }
			.account-info {
				padding: 15px;
				background-color: #ecf7fc;
				border-radius: 8px;
				position: relative;
                max-width: 600px;
				p {
					line-height: 24px;
                    margin-bottom: 10px;
				}
				& > div {
					position: absolute;
					right: 0;
				}
				.title {
					top: 0;
					background-color: #bcd3e5;
					padding: 3px 20px;
					border-top-right-radius: 8px;
					border-bottom-left-radius: 26px;
				}
				.copy {
					display: flex;
					bottom: 15px;
					right: 15px;
					cursor: pointer;
					font-weight: bold;
					.icon-svg {
						width: 16px;
						height: 16px;
						margin-right: 6px;
						margin-top: 1px;
					}
				}
			}
		}
		.weixin-info {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 20px 0;
		}
    }

    .action-section {
        text-align: center;

        .el-button {
            width: 200px;
        }
    }
}
</style>
