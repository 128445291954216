<template>
    <!-- 开票详情 -->
    <div class="invoice-detail">
        <ab-heads :numbers=" tableData.length ? tableData[0].orderNo : ''" :createTime="tableData.length ? tableData[0].syncTime : ''"></ab-heads>
        <div class="billingMoney">
            <h3>开票金额</h3>
            <el-row>
                <el-col :span="8">
                    <div>开票金额</div>
                    <p>{{ this.$filters.formatMoney(totalAmount) }} <span>(CNY)</span></p>
                </el-col>
                <el-col :span="8">
                    <div>税额</div>
                    <p>{{ $filters.formatMoney($calc.mul(totalAmount, 0.13)) }} <span>(CNY)</span></p>
                </el-col>
                <el-col :span="8">
                    <div>税率</div>
                    <p>13%</p>
                </el-col>
            </el-row>
        </div>

        <div class="billingMoney-table">
            <div class="title">
                <h3>开票信息</h3>
            </div>

            <ab-table class="materialTable" stripe ref="table" indexWidth="80" widths="140"
                :column-list="columns" :table-list="tableData" :dataListLoading="isloading"
                :showSummary="true"
                :summary-method="(param) => getSummaries(param, ['sqty', 'invoiceAmount', 'taxPrice', 'oamt', 'otax'])">
            </ab-table>
        </div>
        <div class="footers">
            <el-button @click="deletePage">返回</el-button>
        </div>
        <ab-preview :preview="preview" @handleClose="handleClose">
            <template v-slot:button>
                <el-button @click="preview.show = false">关 闭</el-button>
            </template>
        </ab-preview>
    </div>
</template>

<script>
export default {
    name: 'invoice-detail',
    data() {
        return {
            columns: [
                {
                    props: "orderNo",
                    labels: "采购单号",
                    widths: 100
                },
                {
                    props: "productModel",
                    labels: "型号",
                    widths: 130
                },
                {
                    props: "invoiceNo",
                    labels: "发票号",
                    widths: 100
                },
                {
                    props: "sqty",
                    labels: "计价数量",
                    widths: 60
                },
                {
                    props: "invoiceAmount",
                    labels: "金额",
                    widths: 70,
                    type: 'money'
                },
                {
                    props: "oamt",
                    labels: "不含税金额",
                    widths: 70,
                    type: 'money'
                },
                {
                    props: "otax",
                    labels: "税额",
                    widths: 60,
                    type: 'money'
                },
                {
                    props: "taxRate",
                    labels: "税率",
                    widths: 60,
                    align: "center"
                },
            ],
            //基础信息
            dataForm: {},
            loading: false,
            isloading: false,
            routeQuery: {},
            preview: {
                show: false,
                isView: false,
                isFooter: true,
                fileUrl: ''
            },
            tableData: [],
            a: false,
            b: false,
            c: false
        }
    },
    computed: {
        mainTabs: {
            get() {
                return this.$store.state.mainTabs
            },
            set(val) {
                this.$store.commit('updateMainTabs', val)
            }
        },
        mainTabsActiveName: {
            get() { return this.$store.state.mainTabsActiveName },
            set(val) { this.$store.commit('updateMainTabsActiveName', val) }
        },
        totalAmount() {
            let num = 0
            if(this.tableData.length) {
                this.tableData.forEach(e=>{
                    num = this.$calc.add(num, e.invoiceAmount)
                })
            }
            return num.toFixed(2)
        }
    },
    methods: {
        deletePage() {
            this.removeTabHandle(this.mainTabsActiveName);
        },
        removeTabHandle(tabName) {
            this.mainTabs = this.mainTabs.filter(item => item.index !== tabName)
            this.$router.push({ path: '/invoice-records' })
        },
        //表格合计
        getSummaries(param, value) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                let val = value.indexOf(column.property) != -1
                if (val) {
                    const values = data.map(item => Number(item[column.property]))
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return this.$calc.add(prev, curr)
                        } else {
                            return prev
                        }
                    }, 0)
                    sums[index] = column.property == 'sqty' ? Math.ceil(sums[index]) : this.$filters.formatMoney(sums[index])
                }
            });
            return sums;
        },
        //请求获取数据
        getDetailData() {
            this.isloading=true
            this.$post("/orderBase/getInvoice", this.routeQuery.id).then(({ data }) => {
                if (data && data.code === "C0000") {
                    this.tableData = data.result || [];
                        this.tableData.forEach(e=>{
                        e.taxRate = '13%'
                        e.otax = this.$calc.mul(e.invoiceAmount, 13 / 100).toFixed(2)
                        e.oamt = this.$calc.mul(e.invoiceAmount, 87 / 100).toFixed(2)
                    })
                }else{
                    this.$message({type: 'error', message: data.message, offset: 380, grouping: true})
                }
            }).finally(() => {
                this.isloading=false
            });
        },
        //导出
        exportFile() {
            this.loading = true
            let params = {}
            let excelField = this.columns.map(e => e.props);
            params.mergeDtos = this.tableData
            params.excelField = excelField
            params.data = { id: this.routeQuery.id }
            this.$post("/fms/client/drawInvoice/deriveDrawInfo", params).then(({ data }) => {
                if (data && data.code === "C0000") {
                    // 创建隐藏的可下载链接
                    var eleLink = document.createElement("a");
                    eleLink.download = "";
                    eleLink.target = "_blank";
                    eleLink.style.display = "none";
                    // 字符内容转变成blob地址
                    eleLink.href = data.message;
                    // 触发点击
                    document.body.appendChild(eleLink);
                    eleLink.click();
                    // 然后移除
                    document.body.removeChild(eleLink);
                } else {
                    this.$message({ type: 'error', message: data.message, offset: 380, grouping: true })
                }
            }).finally(() => {
                this.loading = false
            });
        },
    },
    created() {
        let query = this.$route.query || {}
        this.routeQuery = Object.keys(query).length ? query : this.routeQuery
        this.getDetailData()
    },
    activated() {
        let query = this.$route.query || {}
        this.routeQuery = Object.keys(query).length ? query : this.routeQuery
        this.getDetailData()
    }
}
</script>

<style lang="scss">
.invoice-detail {
    position: relative;
    padding: 40px 100px;
    text-align: left;
    color: #000000;
    .nnex-info-div{
        .annex-info {
        display: flex;
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid #f0f2f5;

        .el-col.el-col-8 {
            div {
                font-size: 14px;
                margin-bottom: 5px;
            }

            p {
                font-size: 36px;
                color: #F87243;
                font-weight: 500;
                font-family: DIN_Condensed_Bold;

                span {
                    font-size: 14px;
                }
            }
        }
        .invoice-info-ul {
            li {
                display: inline-block;
                width: 240px;
                height: 45px;
                background: #EBF7FC;
                border-radius: 8px;
                margin-left: 20px;
                padding: 0 15px;
                line-height: 44px;

                .downIcon {
                    float: right;
                    width: 19px;
                    margin-top: 6px;
                    cursor: pointer;
                }
            }
        }
    }
    }
    h3 {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 40px;
    }

    .billingMoney {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid #f0f2f5;

        .el-col.el-col-8 {
            div {
                font-size: 14px;
                margin-bottom: 5px;
            }

            p {
                font-size: 36px;
                color: #F87243;
                font-weight: 500;
                font-family: DIN_Condensed_Bold;

                span {
                    font-size: 14px;
                }
            }
        }
    }

    .invoice-herder {
        border-bottom: 1px solid #f0f2f5;
        margin-bottom: 27px;

        .supplierName {
            width: 100%;
            display: flex;
            padding: 0 0 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #F0F2F5;
            line-height: 24px;

            .supplier {
                padding: 5px 10px;
                height: 24px;
                line-height: 14px;
                border-radius: 4px;
                font-size: 14px;
                background-color: #F0F2F5;
            }

            p {
                font-size: 22px;
                font-weight: bold;
                margin-left: 20px;
            }

            .name {
                margin-left: 20px;
                font-size: 14px;
                color: #F87243;
            }
        }

        .el-row {
            font-size: 14px;

            .el-col {
                margin-bottom: 30px;
            }

            .el-col.el-col-24 {
                margin-bottom: 40px;
            }

        }
    }

    .billingMoney-table {
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 27px;

            h3 {
                margin: 0;
            }
        }

        .el-table__footer-wrapper {
            td {
                background-color: #F2f7ff;
            }
        }
    }

    .footers {
        text-align: center;
        margin: 20px 0 0;
        padding: 20px 0 0;
        border-top: 1px solid #F0F2F5;
    }


    //标签
    .number {
        position: absolute;
        width: 200px;
        top: 0px;
        right: 0px;
        border-radius: 0 0 8px 8px;
        background-color: #F2F7FF;
        font-size: 14px;
        text-align: center;
        font-family: Microsoft YaHei;
        ;

        .text {
            padding: 10px 0;
            border-bottom: 1px solid #F0F2F5;
        }

        .time {
            padding: 10px 0;
        }
    }
}</style>
