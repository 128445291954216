<template>
<div class="message-subscription-list">
    <el-drawer v-model="drawer.show" title="消息订阅" :before-close="handleClose" :modal="false">
        <el-row>
            <el-col :span="8">
                <span class="tel">邮件消息</span>
            </el-col>
            <el-col :span="8"><el-checkbox v-model="emailChecked" @change="changeAllEmail">全选</el-checkbox></el-col>
            <el-col :span="8">
                <span class="tel setting" @click="handlerEmail">
                    <ab-svg name="setting"></ab-svg>
                    添加发送邮件</span>
            </el-col>
            <el-col :span="24">
                <el-row>
                    <el-col v-for="(obj,idx) in emailList" :key="idx" :span="8">
                        <el-checkbox-group v-model="emailArry" @change="changeEmail">
                            <el-checkbox :label="obj.label"> {{obj.value}}</el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="8">
                <span class="tel">微信消息</span>
            </el-col>
            <el-col :span="8">
                <el-checkbox v-model="wxeChecked" @change="changeAllWxe">全选</el-checkbox>
            </el-col>
            <el-col :span="24">
                <el-row>
                    <el-col v-for="(obj,idx) in wxelList" :key="idx" :span="8">
                        <el-checkbox-group v-model="wxelArry" @change="changeWxe">
                            <el-checkbox :label="obj.label">{{obj.value}}</el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <template #footer>
            <div style="flex: auto">
                <el-button @click="cancelClick">取消</el-button>
                <el-button type="primary" :loading="isloading" @click="confirmClick">确定</el-button>
            </div>
        </template>
    </el-drawer>

    <el-drawer v-model="emailDialog.show" title="添加发送邮件" :before-close="emailClose" :modal="false">
        <el-form :model="otherEmail" :rules="rules" ref="otherEmail" class="email-from">
            <el-row v-for="(item,idx) in otherEmail.list" :key="idx" :gutter="20">
                <el-col :span="18">
                    <el-form-item :prop="'list.' + idx + '.email'" :rules="rules.email">
                        <el-input size="mini" v-model="item.email" placeholder="请输入邮箱号码"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <span class="toobal" @click="addEmail"><ab-svg name="add"></ab-svg></span>
                    <span class="toobal" v-if="idx != 0" @click="delEmail(item)"><ab-svg name="del"></ab-svg></span>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <div style="flex: auto">
                <el-button @click="emailClose">取消</el-button>
                <el-button type="primary" :loaidng="isloading" @click="handlerAdd">确定</el-button>
            </div>
        </template>
    </el-drawer>
</div>
</template>

<script>
export default {
    data () {
        return {
            emailChecked: false,
            isloading: false,
            emailArry: [],
            emailList: [
                {label: 'accession', value: '到货通知'},
                {label: 'paymentSuccess', value: '付汇完成通知'},
                {label: 'payment', value: '付款通知'},
                {label: 'accounts', value: '对账通知'},
                {label: 'invoiceConfirm', value: '开票合同确认通知'},
                {label: 'invoiceSend', value: '发票寄出通知'},
                {label: 'customTaxlistUpload', value: '报关单及税单上传通知'}
            ],
            wxeChecked: false,
            wxelArry: [],
            wxelList: [
                {label: 'order', value: '订单进度提醒'},
                {label: 'payment', value: '支付状态通知'},
                {label: 'confirm', value: '单据确认通知'},
                {label: 'cusFilb', value: '凭证上传通知'},
            ],
            otherEmail: {
                list: [
                    
                ]
            },
            emailDialog: {
                show: false
            },
            rules: {
                email: [
                    { required: true, message: '请输入邮箱号码', trigger: 'blur'},
                    {
                        validator: (rule, value, callback) => {
                            if (/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(value)) {
                                return callback()
                            }
                            return callback(new Error('请输入正确的邮箱地址'))
                        },
                        trigger: 'blur'
                    }
                    ],
            }
        }
    },
    props: {
        drawer: {
            type: Object,
            default: {}
        }
    },
    computed: {
        step() {
            return this.$store.state.step
        },
        isSign() {
            return this.step === '99'
        },
    },
    mounted () {
        
    },
    methods: {
        handleClose () {
            this.drawer.show = false
        },
        changeAllEmail (val) {
            if (val) {
                let arry = this.emailList.map(e => {return e.label})
                this.emailArry = arry
            } else {
                this.emailArry = []
            }
        },
        handlerEmail () {
            if (this.otherEmail.list.length == 0) {
                this.otherEmail.list.push({email: '', _index: new Date().getTime()})
            }
            this.emailDialog.show = true
        },
        changeWxe (val) {
            if (val.length == this.wxelList.length) {
                this.wxeChecked = true
            } else {
                this.wxeChecked = false
            }
        },
        changeEmail (val) {
            if (val.length == this.emailList.length) {
                this.emailChecked = true
            } else {
                this.emailChecked = false
            }
        },
        changeWxe (val) {
            if (val.length == this.wxelList.length) {
                this.wxeChecked = true
            } else {
                this.wxeChecked = false
            }
        },
        changeAllWxe (val) {
            if (val) {
                let arry = this.wxelList.map(e => {return e.label})
                this.wxelArry = arry
            } else {
                this.wxelArry = []
            }
        },
        cancelClick () {
            this.handleClose()
        },
        confirmClick () {
            if(!this.isSign) {
                this.$confirm('您尚未与我司签约，无法使用该功能，是否马上签约？', '温馨提示', {
                    confirmButtonText: '立即签约',
                    cancelButtonText: '暂不签约',
                    closeOnClickModal: false
                }).then(() => {
                    this.drawer.show = false
                    this.$parent.drawer.show = false
                    this.$router.push('/sign-contract')
                }).catch()
                return
            }
            if ((!this.emailArry || this.emailArry.length == 0) && (!this.wxelArry || this.wxelArry.length == 0)) {
                this.$message({
                    type: 'error',
                    message: "请勾选需要订阅的消息",
                    offset: 380,
                    grouping: true,
                    })
                return false
            }
            let obj = {
                emails: '',
                emailMsgType: '',
                webchatMsgType: ''
            }
            if (this.otherEmail.list && this.otherEmail.list.length) {
                let email = this.otherEmail.list.filter(e => {return e.email})
                obj.emails = email.map(e => {return e.email})
                obj.emails = obj.emails.join(',')
            }
            obj.emailMsgType = this.emailArry.join(',')
            obj.webchatMsgType = this.wxelArry.join(',')
            console.log(obj)
            this.isloading = true
            let url = '/bas/msgConfig/add'
            if (this.id) {
                url = '/bas/msgConfig/mod'
            }
            this.$post(url, obj).then(({data}) => {
                if (data && data.code == 'C0000') {
                    this.$message({
                        type: 'success',
                        message: "操作成功！",
                        offset: 380,
                        grouping: true,
                    })
                }
            }).finally(() => {
                this.isloading = false
            })
        },
        emailClose () {
            this.emailDialog.show = false
        },
        delEmail (item) {
            this.$confirm('确定要删除吗，删除后无法恢复，请谨慎操作？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.otherEmail.list = this.otherEmail.list.filter(e => {
                    if (item.id && item.id != e.id || item._index && item._index != e._index) {
                        return e
                    }
                })
            })
        },
        addEmail () {
            this.otherEmail.list.push(
                {email: '', _index: new Date().getTime()}
            )
        },
        handlerAdd () {
            this.$refs.otherEmail.validate(valid => {
                if (valid) {
                    this.emailDialog.show = false
                    this.confirmClick()
                }
            })
        },
        getCurrent () {
            this.$get('/bas/msgConfig/getCurrent').then(({data}) => {
                if (data && data.code == 'C0000') {
                    if (data.result) {
                        let obj = data.result
                        this.id = obj.id
                        if (obj.emailMsgType && obj.emailMsgType.length) {
                            this.emailArry = obj.emailMsgType.split(',')
                            if(this.emailArry.length == this.emailList.length){
                                this.emailChecked = true
                            }
                        }
                        if (obj.webchatMsgType && obj.webchatMsgType.length) {
                            this.wxelArry = obj.webchatMsgType.split(',')
                            if(this.wxelArry.length == this.wxelList.length){
                                this.wxeChecked = true
                            }
                        }
                        if (obj.emails && obj.emails.length) {
                            this.otherEmail.list = []
                            let email = obj.emails.split(',')
                            email.map((e, idx) => {
                                this.otherEmail.list.push({
                                    _index: new Date().getTime() + '-' + idx,
                                    email: e
                                })
                            })
                            
                        }
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss">
.message-subscription-list {
    .el-drawer__body {
        padding: 20px 40px;
    }
   .el-drawer__header {
        position: initial!important;
        padding-left: 39px
   }
   .tel {
        font-size: 16px;
        line-height: 30px;
   }
   .setting {
        cursor: pointer;
        .icon-svg {
            width: 16px;
            height: 16px;
            vertical-align: middle;
        }
   }
   .el-checkbox{
        margin-bottom: 20px;
   }
   .el-checkbox {
        margin-right: 40px;
   }
   .el-row {
        margin-bottom: 20px;
   }
   .toobal {
        margin-right: 10px;
        line-height: 48px;
        .icon-svg {
            cursor: pointer;
            
            width: 25px;
            height: 25px;
            vertical-align: text-bottom;
        }
    }
    .email-from {
        .el-row {
            margin-bottom: 0;
        }
    }
    .el-drawer__footer{
        text-align: center
    }
}
</style>