<template>
    <!-- 账号管理 -->
    <div class="account-management">
        <ab-list-query>
            <template #right>
                <el-row :gutter="20">
                    <el-col :span="4">
                        <el-input placeholder="姓名" v-model.trim="formSearch.nickName" clearable>
                        </el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-input placeholder="登录名" v-model.trim="formSearch.username" clearable>
                        </el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-input placeholder="手机号" v-model.trim="formSearch.phone" clearable>
                        </el-input>
                    </el-col>
                    <el-col :span="4" class="text-left">
                        <el-button @click="search">搜索</el-button>
                        <el-button link class="btn-svg" @click="formSearch = {}"><ab-svg
                                name="qingchu"></ab-svg>清除</el-button>
                    </el-col>
                </el-row>
            </template>

            <template #toolbar>
                <el-button type="primary" @click="addHandlerEdit">新增账号</el-button>
            </template>

            <template #table>
                <ab-table class="materialTable" ref="table" stripe showEdit showSelection selectionWidth="45"
                    indexWidth="50" widths="160" isFilter="operator" :column-list="columns" :table-list="tableData"
                    :dataListLoading="isloading" @filterColumns="filterColumns">
                    <template #operator="scope">
                        <span v-if="scope.row.enabled">
                            <el-button class="red-color" @click="delRow(scope.row)" link>删除</el-button>
                            <i class="dividingLine"></i>
                        </span>
                        <el-button type="primary" link @click="resetRow(scope.row)">重置</el-button>
                        <i class="dividingLine"></i>
                        <el-button type="primary" link @click="EditRow(scope.row)">编辑</el-button>
                    </template>
                    <template #enabled="scope">
                        <el-switch v-model="scope.row.enabled" @change="switchState($event, scope.row.id, scope.index)">
                        </el-switch>
                    </template>
                    <template #roles="scope">
                        {{ scope.row.roles && scope.row.roles.length && scope.row.roles.some(e=>e.id == 2) ? scope.row.roles.filter(e=>e.id == 2)[0].name : scope.row.roles[0].name }}
                    </template>
                    <template #attachUrl="scope">
                        <el-button v-if="scope.row.attachUrl" type="primary" @click="viewAttorney(scope.row.attachUrl)"
                            link>查看</el-button>
                    </template>
                </ab-table>
            </template>

            <template #page>
                <ab-page ref="pagination" :pageTotal="page.total" :currentPage="page.currentPage" :pageSize="page.pageSize"
                    :pageSizes="page.pageSizes" @searchForm="searchForm"></ab-page>
            </template>
        </ab-list-query>


        <el-drawer v-model="reset" custom-class="drawer" @close="reseUserinfo = {}">
            <template #title>
                <h4>重置密码</h4>
            </template>

            <template #default>
                <el-form label-position="top" :model="reseUserinfo" :rules="resetRules" ref="resetDialog">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="新密码" prop="newPassword">
                                <el-input type="password" placeholder="新密码" v-model="reseUserinfo.newPassword" clearable
                                    show-password></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="24">
                            <el-form-item label="确认密码" prop="confirmPassword">
                                <el-input type="password" placeholder="确认密码" v-model="reseUserinfo.confirmPassword"
                                    clearable show-password></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </template>

            <template #footer>
                <el-button @click="reset = false">取 消</el-button>
                <el-button type="primary" :loading="resetBtnLoading" @click="resetConfirm">确 定</el-button>
            </template>
        </el-drawer>

        <el-drawer v-model="accoutAdd" custom-class="drawer" :title="editType ? '编辑' : '新增'">
            <template #default>
                <el-form label-position="top" :model="accoutAddInfo" :rules="accoutAddRules" ref="accoutAddInfo">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="姓名" prop="nickName">
                                <el-input v-model="accoutAddInfo.nickName" placeholder="姓名" maxlength="9"
                                    clearable></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="手机号" prop="phone">
                                <el-input v-model="accoutAddInfo.phone" placeholder="手机号" @input="handleInput()"
                                    clearable></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="24">
                            <el-form-item label="邮箱" prop="email">
                                <el-input v-model="accoutAddInfo.email" placeholder="邮箱" clearable></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" v-if="!editType">
                            <el-form-item label="密码" prop="password">
                                <el-input type="password" v-model="accoutAddInfo.password" placeholder="密码"
                                    clearable show-password></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" v-if="!editType">
                            <el-form-item label="确认密码" prop="confirmPassword">
                                <el-input type="password" v-model="accoutAddInfo.confirmPassword" placeholder="确认密码"
                                    clearable show-password></el-input>
                            </el-form-item>
                        </el-col>

                    </el-row>
                </el-form>
            </template>

            <template #footer>
                <el-button :loading="addBtnLoading" @click="accoutAdd = false">取 消</el-button>
                <el-button type="primary" :loading="addBtnLoading" @click="confirmAdd">确 定</el-button>
            </template>
        </el-drawer>

    </div>
</template>

<script>
export default {
    name: 'account-management',
    data() {
        var validateConfirmPassword = (rule, value, callback) => {
            let reg = new RegExp('^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,13}$')
            if (!reg.test(value)) {
                return callback(new Error('密码需6-13位，包含大小写字母与数字'))
            }else if (this.accoutAddInfo.password !== value) {
                callback(new Error("确认密码与新密码不一致"));
            } else {
                callback();
            }
        };
        const validatePass = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入密码'))
            } else {
                let reg = new RegExp('^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,13}$')
                if (!reg.test(value)) {
                    return callback(new Error('密码需6-13位，包含大小写字母与数字'))
                }
                callback()
            }
        }
        return {
            rules: {

            },
            resetRules: {
                newPassword: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    {
                        validator: validatePass,
                        trigger: 'blur'
                    }
                ],
                confirmPassword: [
                    { required: true, message: '请再次输入密码', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            let reg = new RegExp('^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,13}$')
                            if (!reg.test(value)) {
                                return callback(new Error('密码需6-13位，包含大小写字母与数字'))
                            }else if (value !== this.reseUserinfo.newPassword) {
                                callback(new Error('两次输入密码不一致!'))
                            } else {
                                callback()
                            }
                        },
                        trigger: 'blur'
                    }
                ]
            },
            accoutAddRules: {
                phone: [
                    {
                        validator: (rule, value, callback) => {
                            if (/^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(value)) {
                                return callback()
                            }
                            return callback(new Error('请输入11位手机号'))
                        },
                        trigger: 'blur',
                        required: true
                    }
                ],
                email: [
                    {
                        validator: (rule, value, callback) => {
                            if (/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(value)) {
                                return callback()
                            }

                            return callback(new Error('请输入正确的邮箱地址'))
                        },
                        trigger: 'blur',
                        required: true,

                    },

                ],
                confirmPassword: [
                    { required: true, message: "确认密码不能为空", trigger: "blur" },
                    { validator: validateConfirmPassword, trigger: "blur" }
                ],
                nickName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' }
                ]
            },
            //是否客户端权限
            isAuth: this.$isAuth('customer:usercenter:account:add'),
            formSearch: {},//搜索参数
            isloading: false,
            btnLoading: false,
            resetBtnLoading: false,
            columns: [
                {
                    props: "nickName",
                    labels: "姓名",
                    widths: 60,
                },
                {
                    props: "username",
                    labels: "登录名",
                    widths: 60
                },
                {
                    props: "phone",
                    labels: "手机号",
                    widths: 80
                },
                {
                    props: "email",
                    labels: "邮箱",
                    widths: 100
                },
                {
                    props: "roles",
                    labels: "用户角色",
                    widths: 160,
                    slot: 'slot'
                },
                {
                    props: "updateTime",
                    labels: "更新时间",
                    widths: 80
                },
                {
                    props: "enabled",
                    labels: "状态",
                    widths: 60,
                    slot: 'slot'
                },
            ],
            //分页
            page: {
                currentPage: 1, //当前页
                pageSize: 10, //每页展示的数据个数
                pageSizes: [10, 20, 50, 100],
                total: 0,
            },
            tableData: [],//表格数据
            userinfo: {},//编辑用户信息
            editType: false,
            formData: {},//进编辑数据

            reset: false,//重置密码
            reseUserinfo: {},
            // resetId:'',
            accoutAdd: false,
            accoutAddInfo: {},
            addBtnLoading: false,
            //账号授权
            preview: {
                show: false,
                isAgree: true,
                isFooter: true,
                isView: false,
                fileUrl: ''
            },
        }
    },
    computed: {
        /* permission(){
            return
        } */
    },
    activated() {
        this.getListData()
    },
    methods: {
        handleInput() {
            this.accoutAddInfo.phone = this.accoutAddInfo.phone.replace(/[^0-9]/g, '');
        },
        filterColumns(val) {
            this.columns = val
        },
        //搜索按钮
        search() {
            this.page.currentPage = 1
            this.getListData()
        },
        //请求数据
        getListData() {
            let data = Object.assign({}, this.formSearch, { pageNum: this.page.currentPage, pageSize: this.page.pageSize })
            this.isloading = true
            this.$get('/api/users', data).then(({ data }) => {
                if (data.code === 'C0000') {
                    let result = data.result
                    this.tableData = result.content || []
                    this.page.total = result.totalElements ? Number(result.totalElements) : 0
                }

            })
                .finally(() => {
                    this.isloading = false
                })
        },
        //筛选表格用户角色字段
        roleNames(array) {
            let arr = array
            arr.forEach(ele => {
                if (ele.name == '进口客户端') {
                    ele.name = '进口操作员'
                } else if (ele.name == '出口客户端') {
                    ele.name = '出口操作员'
                } else if (ele.name == '客户端管理员') {
                    ele.name = '管理员'
                }
            })
            return arr.map(item => { return item.name }).join(',')
        },
        //开关改改变时的回调
        switchState(isEnabled, id, index) {
            let tishi = !isEnabled ? '确定要关闭吗？关闭后账号不可使用！' : '确定要开启吗？开启后账号可以正常使用！'
            this.$confirm(tishi, '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning'
            }).then(() => {
                this.isloading = true
                this.$post('/api/users/modEnabled', { id, enabled: isEnabled }).then(({ data }) => {
                    if (data.code === 'C0000') {
                        this.$message.success(data.message)
                    }
                }).finally(() => {
                    this.isloading = false
                    this.localSearch()
                })
            }).catch((e) => {
                this.tableData[index].enabled = !isEnabled
            })

        },
        localSearch() {
            // this.$refs.pagination.currentChange(1)
        },
        //分页按钮
        searchForm(obj) {
            this.page.currentPage = obj.currentPage;
            this.page.pageSize = obj.pageSize;
            this.getListData()
        },
        //编辑信息
        EditRow(val) {
            this.formData = {}
            this.accoutAdd = true
            this.editType = true
            this.$get('/api/users/get', { id: val.id }).then(({ data }) => {
                if (data.code === 'C0000') {
                    this.formData = data.result
                    this.accoutAddInfo.username = this.formData.username
                    this.accoutAddInfo.nickName = this.formData.nickName
                    this.accoutAddInfo.phone = this.formData.phone
                    this.accoutAddInfo.email = this.formData.email
                    this.accoutAddInfo.enabled = this.formData.enabled
                    this.accoutAddInfo.roles = this.formData.roles
                    this.accoutAddInfo.id = this.formData.id
                }
            })
        },
        // 删除
        delRow(row) {
            this.$confirm('确定要删除吗，删除后无法恢复，请谨慎操作？', '提示', {
                type: 'warning'
            }).then(() => {
                this.$post('/api/users/del', [row.id]).then(({ data }) => {
                    if (data && data.code == 'C0000') {
                        this.$message({
                            type: 'success',
                            message: '删除成功！',
                            offset: 380,
                            grouping: true,
                        })
                        this.getListData()
                    }
                })
            }).catch((e) => {
                console.log(e)
            })

        },
        //编辑确认
        confirm() {
            this.$refs.userinfo.validate((voids) => {
                if (voids) {
                    this.btnLoading = true
                    this.formData.mobile = this.userinfo.mobile
                    this.formData.email = this.userinfo.email
                    this.formData.loginName = this.userinfo.username
                    this.$post('/p/user/clientMod', this.formData).then(({ data }) => {
                        if (data.code === 'C0000') {
                            this.$message.success(data.message)
                            this.editRow = false
                            this.btnLoading = false
                            this.getListData()
                        }
                    })
                        .finally(() => {
                            this.btnLoading = false
                        })
                }
            })
        },
        //重置密码
        resetRow(row) {
            this.reset = true
            this.reseUserinfo.id = row.id

        },
        resetConfirm() {
            this.$refs.resetDialog.validate((voids) => {
                if (voids) {
                    this.resetBtnLoading = true
                    this.$post('/api/users/resetPass', { password: this.$utils.Base64Encode(this.reseUserinfo.newPassword), userId: this.reseUserinfo.id }).then(({ data }) => {
                        if (data.code === 'C0000') {
                            this.$message.success(data.message)
                            this.userinfo = {}
                            this.reset = false
                            this.getListData()
                        }
                    })
                        .finally(() => {
                            this.resetBtnLoading = false
                        })
                }

            })
        },
        addHandlerEdit() {
            this.accoutAdd = true
            this.editType = false
            this.accoutAddInfo = {}
        },
        confirmAdd() {
            this.$refs.accoutAddInfo.validate((voids) => {
                if (voids) {
                    this.addBtnLoading = true
                    if (this.editType) {
                        this.$post('/api/users/mod', this.accoutAddInfo).then(({ data }) => {
                            if (data.code === 'C0000') {
                                this.accoutAddInfo = {}
                                this.$message({
                                    type: 'success',
                                    message: '编辑成功',
                                    offset: 380,
                                    grouping: true,
                                })
                                this.accoutAdd = false
                                this.addBtnLoading = false
                                this.getListData()
                            }
                        }).finally(() => {
                            this.addBtnLoading = false
                        })
                    } else {
                        let data = {
                            password: this.$utils.Base64Encode(this.accoutAddInfo.password),
                            nickName: this.accoutAddInfo.nickName,
                            username: this.accoutAddInfo.phone,
                            email: this.accoutAddInfo.email,
                            enabled: true,
                            phone: this.accoutAddInfo.phone,
                            // companyId: this.$utils.getSessionStorage('companyId'),
                            areaCode: "86",
                            roles: [{
                                id: 3, name: "普通用户"

                            }]
                        }
                        this.$post('/api/users', data).then(({ data }) => {
                            if (data.code === 'C0000') {
                                this.accoutAddInfo = {}
                                this.$message({
                                    type: 'success',
                                    message: '保存成功',
                                    offset: 380,
                                    grouping: true,
                                })
                                this.accoutAdd = false
                                this.addBtnLoading = false
                                this.getListData()
                            }
                        }).finally(() => {
                            this.addBtnLoading = false
                        })
                    }

                }
            })
        },
        //  查看授权书
        viewAttorney(url) {
            this.preview.fileUrl = url
            this.preview.isView = false
            this.preview.show = true
        }
    },
    created() {
        // this.getListData()
    }
}
</script>

<style lang="scss">
.account-management {
    .ab-list-query {
        .el-menu.el-menu--horizontal {
            display: none;
        }

        // .query-list {
        //     margin-top: 20px !important;
        // }
    }

    .addDel {
        .toolbar {
            padding: 20px 0 0 0;
        }
    }

    .drawer {
        padding: 0 20px;
        border-radius: 16px;

        .el-drawer__footer {
            text-align: center;
        }

        .el-drawer__body {
            .nameMobile {
                font-size: 22px;
                font-weight: bold;
                color: #000000;
                margin-bottom: 40px;
                margin-top: 15px;

                span {
                    font-size: 14px;
                    color: #F87243;
                    font-weight: 400;
                    margin-left: 24px;
                }
            }
        }
    }
}
</style>
