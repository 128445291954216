<!--
组件的显示的值只能用value，用其他key将无法选中。
自定义渲染插槽用法： 一定要保证其中有value
<template v-slot:content="scope">
    {{scope.item.value}} --- {{scope.item.id}}
</template>
--->
<template>
<div class="auto-select2">
  <el-autocomplete
    v-model="dataCode"
    :fetch-suggestions="querySearchAsync"
    :placeholder="placeholder + label"
    clearable
    @select="handleSelect"
    :disabled="disabled"
    @change="handlerChange"
    class="auto-complete-select"
    >
    <template #prefix >
      <div class="lable-tel">
          <span v-if="required">*</span>
          <el-popover
            placement="top-end"
            popper-class="form-pop"
            :width="300"
            trigger="hover"
            :disabled="!popover"
            :content="popover"
          >
            <template #reference>
              {{label}}
            </template>
          </el-popover>
      </div>
      <span @click.stop="handlerAdd" v-if="!disabled">
        <ab-svg name="add"></ab-svg>
      </span>
      <ab-svg name="dingwei" style="color: #0c9837"></ab-svg>
    </template>
    <template #suffix>
      <slot name="suffix"  v-if="suffix">
        <el-icon><ArrowDown /></el-icon>
      </slot>
      <el-icon v-if="dataCode" @click.stop="edit" style="cursor: pointer; color: #000"><EditPen /></el-icon>
    </template>
    <template #default="{ item }">
      <slot name="content" :item="item">
        <div v-if="item.value == '暂无数据'" style="color:#999999;text-align: center;"> 暂无数据，可点击左侧”+“进行新增</div>
        <div class="value" v-else>{{item.value}}</div>
      </slot>
    </template>

  </el-autocomplete>

  </div>
</template>


<script>
export default {
  name: 'ab-select2',
  data () {
    return {
      temp: [],
      dataCode: this.modelValue,
      dataItem: {}
    }
  },
  model: {
    modelValue: String
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String
    },
    inputKey: {
      type: String,
      default: 'name'
    },
    descKey: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    urlData: {
      type: Object,
      default: {}
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    methods: {
      type: String,
      default: 'post'
    },
    suffix: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    isNotClear: {
      type: Boolean,
      default: false
    },
    popover: {
      type: String,
      default: ''
    }
  },
  watch: {
    modelValue () {
      this.dataCode = this.modelValue
    }
  },
  methods: {
    keyFilter (item) {
      return item[this.descKey ? this.descKey : this.inputKey]
    },
    querySearchAsync (queryString, cb) {
      queryString = queryString ? queryString : ''
      if (queryString == 'null') {
        queryString = ''
      }
      if (this.urlData.data) {
        this.urlData.data[this.inputKey] = queryString
      } else {
        this.urlData[this.inputKey] = queryString
      }
      this.temp = []
      this.getMethods().then(res => {
        if (res.data && res.data.code === 'C0000' && res.data.result) {
          this.temp = res.data.result.list || res.data.result
          if (this.temp && this.temp.length) {
            this.temp.map(e => {
             e.value = this.keyFilter(e)
            })
          }
          if (this.temp.length == 0) {
            this.temp = [
              {
                value: '暂无数据',
              }
            ]
          }
        }
      }).finally(() => {
        cb(this.temp)
      })
    },
    getMethods() {
      if (this.methods === 'post') {
        return this.$post(this.url, this.urlData)
      } else if (this.methods === 'get'){
        return this.$get(this.url, this.urlData)
      }
    },
    handleSelect(item) {
      if (item.value && item.value == '暂无数据') {
        this.dataCode = ''
        this.$emit('update:modelValue', this.dataCode)
        return false
      }
      this.$emit('update:modelValue', this.dataCode)
      this.dataItem = item
      this.$emit('handleSelect', item)
    },
    handlerAdd () {
      setTimeout(() => {
        this.$emit('handlerAdd')
      }, 50)
    },
    edit () {
      setTimeout(() => {
        this.$emit('handlerEdit')
      }, 50)
    },
    handlerChange () {
      console.log(123321,this.dataCode)
      setTimeout(() => {
        if (this.isNotClear) {
          this.$emit('update:modelValue', this.dataCode)
          return true
        } else {
          let flag = false
          if (this.temp.length > 0) {
            this.temp.map(e => {
              if (e[this.descKey ? this.descKey : this.inputKey] == this.dataCode) {
                this.dataItem = e
                flag = true
              }
            })
          }
          if (!flag) {
            this.dataCode = ''
            this.dataItem = {}
          }
          this.$emit('update:modelValue', this.dataCode)
          this.$emit('handleSelect', this.dataItem)
        }
      }, 50)
    },
  
  }
}
</script>

<style lang="scss">
.auto-select2 {
  border-bottom: 1px solid #F0F2F5;
  width: 100%;
}
.auto-complete-select {
  //border-bottom: 1px solid #F0F2F5;
  padding: 9px 0 10px 0;
   min-width: 500px;
  .el-input__wrapper {
    background:none!important;
    padding: 1px 0px;
  }
  .el-input__wrapper:hover,
  .el-input__wrapper.is-focus,
  .el-input__wrapper
  {
    box-shadow: none!important;
  }
  .lable-tel {
    padding: 5px 10px;
    height: 24px;
    line-height: 14px;
    border-radius: 4px;
    background-color: #F0F2F5;
    color: #000000;
    :first-child {
      color:#FE2424;
      margin-right: 3px;
    }
    
  }
  .el-input__prefix-inner {
    .icon-svg {
      width: 24px;
      height: 24px;
      vertical-align: middle;
      margin: 0 10px;
      cursor: pointer;
    }
  }
  .el-input__inner {
    color:#000000;
  }
  .el-form-item.is-error .el-input__wrapper {
    box-shadow:none;
  }
  .el-input {
    width: 600px;
  }
  .el-input__inner::placeholder {
   font-size: 14px; 
  }
}
</style>