<template>
    <el-dialog title="我要退款" width="800px" :model-value="visible" @close="cancel" :close-on-click-modal='false' class="refund-return-dialog">
        <p class="text">退款请联系您的专属销售代表</p>
        <div class="card">
            <div><img src="@/assets/img/customer-service.jpg" alt="" srcset=""></div>
            <div class="info">
                <div class="name">
                    <p>林婷婷</p>
                    <div>专属销售代表</div>
                </div>
                <div class="contact">
                    <div>
                        <ab-svg name="qq"></ab-svg>
                        <span>3005031561</span>
                    </div>
                    <div>
                        <ab-svg name="weixin"></ab-svg>
                        <span>19925442931</span>
                    </div>
                    <div>
                        <ab-svg name="dianhua"></ab-svg>
                        <span>19925442931</span>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'refund-return-dialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    data() {
        return {};
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        }
    }
};
</script>

<style lang="scss" scoped>
.refund-return-dialog {
    padding: 20px 50px;
    .text {
        padding-left: 80px;
        font-size: 18px;
        line-height: 20px;
    }
    .card {
        display: flex;
        align-items: center;
        margin: 17px auto;
        border: 1px solid #dedede;
        box-shadow: 0 2px 6px 0 #eee;
        border-radius: 8px;
        width: 600px;
        padding: 40px;
        img {
            width: 150px;
            height: 150px;
            margin-top: 8px;
        }
        .info {
            margin-left: 30px;
            .name {
                margin-top: -10px;
                display: flex;
                align-items: center;
                > p {
                    font-size: 26px;
                    color: #373940;
                }
                > div {
                    font-size: 16px;
                    color: #becffa;
                    font-weight: 500;
                    padding: 6px 12px;
                    border: 2px solid #becffa;
                    border-radius: 20px;
                    margin-left: 12px;
                }
            }
            .contact {
                margin-top: 12px;
                > div {
                    display: flex;
                    align-items: center;
                    margin-top: 12px;
                    height: 28px;
                    line-height: 28px;
                    > .icon-svg {
                        width: 24px;
                    }
                    > span {
                        margin-left: 12px;
                        color: #666;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
</style>
