<template>
    <div>
        <div class="logistics-info" ref="logistics" :style="{maxHeight: maxHeight}" v-if="logisticsData">
            <div class="basic-info">
                <el-row>
                    <el-col :span="16">
                        <div style="font-size: 12px">运单号：<span class="number" @click="copyWareInfo">{{deliverTransportNo}} <ab-svg name="copy1"></ab-svg></span></div>
                        <div class="logis-city" v-if="logisticsData.routeInfo && logisticsData.routeInfo.from">
                            <span class="copName" :title="getCity(logisticsData.routeInfo.from)">{{ getCity(logisticsData.routeInfo.from)}}</span>
                            <span class="express-name">
                            <ab-svg name="goto"></ab-svg> <span> {{isInter ? logisticsData.com : logisticsData.com && expressData[logisticsData.com]? expressData[logisticsData.com].name : ''}}</span></span>
                            <span class="copName" :title="getCity(logisticsData.routeInfo.to)">{{ getCity(logisticsData.routeInfo.to)}}</span>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <p class="delivery-time">{{ (logisticsData.ischeck == '1' || !logisticsData.arrivalTime) && logisticsData.data && logisticsData.data.length? logisticsData.data[0].ftime : logisticsData.arrivalTime + ':00' }}</p>
                        <div class="status" v-if="logisticsData.data && logisticsData.data.length">{{logisticsData.data[0].status}}</div>
                    </el-col>
                </el-row>
            </div>
            <iframe :src="logisticsData.trailUrl" frameborder="0" style="width: 100%; height: 90vh"></iframe>
            <div class="detail-info">
                <div v-if="!isInter" class="fold" @click="isFold=!isFold"><i :class="[isFold ? 'el-icon-arrow-up' : 'el-icon-arrow-down', 'el-icon--right']"></i></div>
                <ul :style="{height: isInter ? 'calc(95vh - 174px)' : isFold ? '20vh' : '45vh'}">
                    <li v-for="(item, index) in logisticsData.data" :key="index">
                        <p>{{item.ftime}}</p>
                        <p>{{item.context}}</p>
                        <div class="circle"></div>
                    </li>
                </ul>
            </div>
            <textarea style="width: 0; height: 0" id="inputCopy">这是幕后黑手</textarea>
        </div>
        <div v-else class="noData">暂无数据</div>
    </div>
</template>

<script>
export default {
name: 'ab-logisticsInfo',
  props: {
    deliverTransportNo: {
        type: String,
        default: ''
    },
    isInter: {
        type: Boolean,
        default: false
    },
    maxHeight: {
        type: String,
        default: '90vh'
    }
  },
  data() {
    return {
        isFold: true,
        logisticsData: null,
        reverse: true,
        expressData: {}
    }
  },
  watch: {
    deliverTransportNo(expressNo) {
        if(expressNo) {
            this.init(expressNo)
        }
    }
  },
  methods: {
    getCity(obj) {
        let str = obj && obj.name ? obj.name : ''
        let city = ''
        if(str) {
            city = str.split(',')[str.split(',').length > 1 ? 1 : 0]
        }
        return city
    },
    copyWareInfo() {
      let text = this.deliverTransportNo
      var input = document.getElementById("inputCopy");  // 找到虚拟的输入框
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      this.$message({
        type: 'error',
        message: '复制成功!',
        grouping: true,
        offset: 380
        })
    },
    init(expressNo) {
      let url = this.isInter ? '/tms/track17/getByNumber' : '/sys/express100/getSubscribeExpress'
      let obj = this.isInter ? {number: expressNo} : {expressNo}
      this.$get(url, obj).then(({ data }) => {
        if (data && data.code === "C0000") {
          if (data.result) {
            if(data.result && data.result.json) {
                data.result.json = JSON.parse(data.result.json) 
                this.logisticsData = data.result.json.lastResult
                console.log(this.logisticsData, '=======');
                this.$nextTick(() => {
                    this.$refs['logistics'].scrollTo(0, 0)
                })
            }else{
                this.logisticsData = null
            }
          }else{
                this.logisticsData = null
          }
        }
      });
      if(this.$utils.getSessionStorage('expressDelivery')) {
        this.expressData = this.$utils.getSessionStorage('expressDelivery')
      }else{
        this.$get('/sys/express100/listExpressCompany').then(({data}) => {
            if(data && data.code === 'C0000') {
                this.$utils.setSessionStorage('expressDelivery', data.result)
                this.expressData = data.result
            }
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
    .logistics-info{
        // padding: 20px;
        position: relative;
        height: 95vh;
        .basic-info{
            position: absolute;
            top: 18px;
            left: 30px;
            width: 440px;
            height: 74px;
            padding: 14px 21px;
            background: #FFFFFF;
            box-shadow: 0px 3px 24px 0px rgba(0,0,0,0.0900);
            opacity: 0.85;
            border-radius: 10px;
            .number{
                color: #0A7CFF;
                font-size: 10px;
                line-height: 16px;
                cursor: pointer;
                svg{
                    width: 12px;
                    top: 4px;
                    position: relative;
                    height: 16px;
                }
            }
            .delivery-time{
                font-size: 10px;
                margin-top: 2px;
            }
            .status{
                font-size: 16px;
                font-weight: 550;
                color: #0A7CFF;
            }
            .logis-city{
                font-size: 16px;
                font-weight: 550;
                margin-top: -13px;
                color: #141414;
              .express-name{
                width: 80px;
                float: left;
                font-size: 10px;
                color: #0A7CFF;
                position: relative;
                padding-left: 6px;
                top: 5px;
                height: 40px;
                svg{
                    color: #0A7CFF;
                }
                span{
                    position: absolute;
                    left: 10px;
                    top: 22px;
                }
              }
              .copName{
                margin-top: 15px;
                float: left;
                max-width: 90px;
                white-space:nowrap;
                overflow:hidden;
                text-overflow:ellipsis;
              }
              .icon-svg {
                width: 56px;
              }
            }
        }
        .fold{
            width: 100%;
            text-align: center;
            cursor: pointer;
        }
        .detail-info{
            position: absolute;
            opacity: .85;
            background-color: #fff;
            bottom: 0;
            padding: 5px 20px 20px;
            ul{
                padding: 10px 20px 0;
                overflow-y: scroll;
                li{
                    box-sizing: border-box;
                    position: relative;
                    padding-bottom: 20px;
                    font-size: 10px;
                    border-left: 2px dashed #d9d9d9;
                    padding-left: 20px;
                    p{
                        line-height: 16px;
                        font-size: 14px;
                        margin-bottom: 2px;
                    }
                    .circle{
                        position: absolute;
                        left: -8px;
                        top: 0;
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        border: 2px solid #b5b5b5;
                        background-color: #fff;
                    }
                }
                li:first-child{
                    color: #0A7CFF;
                    .circle{
                        border-color: #0A7CFF;
                        &::after{
                            content: '';
                            position: absolute;
                            background-color: #0A7CFF;
                            border-radius: 50%;
                            left: 2px;
                            top: 2px;
                            width: 6px;
                            height: 6px;
                        }
                    }
                }
                li:last-child{
                    border-color: #fff
                }
            }
        }
    }
    .noData{
        min-height: 300px;
        text-align: center;
        line-height: 300px;
        font-size: 20px;
        color: #999;
    }
</style>
