<template>
    <el-dialog title="校验支付密码" width="700px" :model-value="visible" @close="cancel" :close-on-click-modal='false' class="verify-payment-password-dialog">
        <p class="text">请输入六位数字支付密码</p>
        <el-form :model="model" :rules="rules" ref="form" label-width="auto" class="form">
            <el-form-item label="确认密码" prop="paymentPassword">
                <el-input v-model.trim="model.paymentPassword" :type="passwordVisible ? 'text' : 'password'">
                    <template #suffix>
                        <el-icon @click="togglePasswordVisibility">
                            <component :is="passwordVisible ? 'Hide' : 'View'" />
                        </el-icon>
                    </template>
                </el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="handleSubmit">确认</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: 'verify-payment-password-dialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
            required: true
        },
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            model: {
                paymentPassword: null,
            },
            rules: {
                paymentPassword: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, message: '密码最少6位', trigger: 'blur' }
                ],
            }
        };
    },
    methods: {
        reset() {
            this.$nextTick(() => {
                this.$refs.form.resetFields();
            });
        },
        cancel() {
            this.$emit('cancel');
        },
        // 切换密码可见性
        togglePasswordVisibility() {
            this.passwordVisible = !this.passwordVisible;
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (!valid) return;
                this.$emit('confirm', this.model);
                this.reset();
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.verify-payment-password-dialog {
    .text {
        font-size: 16px;
        margin-bottom: 20px;
        padding-left: 150px;
        color: #666;
    }
    .form {
        padding: 0 150px;
        .el-form-item {
            display: flex;
            align-items: center;
        }
    }
}
</style>
