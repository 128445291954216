<template>
    <div class="transaction-records-table">
        <div class="header">
            <p>交易记录</p>
        </div>
        <div class="content">
            <ab-list-query :tabs="tabs" @handleTabs="handleTabs" :isActive="activedName">
                <template #right>
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <el-input placeholder="请输入订单号查询" v-model.trim="formSearch.businessNo" clearable @clear="clearBusinessNo"></el-input>
                        </el-col>
                        <el-col :span="4">
                            <ab-timeSearch class="searchBox" startSrc="创建日期起" endSrc="创建日期止" v-model="dateTimeRange"
                            @searchEvent="changeTimeRange"></ab-timeSearch>
                        </el-col>
                        <el-col :span="4">
                            <el-button @click="searchForm" class="pub-btn">搜索</el-button>
                            <el-button link class="btn-svg btn-clear" @click="resetForm"><ab-svg name="qingchu"></ab-svg>清除</el-button>
                        </el-col>
                    </el-row>
                </template>

                <template #toolbar>
                    <el-button :loading="isBtnLoading" @click="exportFile" class="pub-btn">导出</el-button>
                </template>
                <template #table>
                    <ab-table class="materialTable" ref="table" stripe :column-list="columns"
                        :table-list="tableData" :dataListLoading="isloading" @filterColumns="filterColumns">
                        <template #transactionNo="props">
                            <span>{{ props.row.transactionNo }}</span>
                        </template>
                        <template #transactionTime="props">
                            {{ props.row.transactionTime }}
                        </template>
                        <template #businessNo="props">
                            {{ props.row.businessNo }}
                        </template>
                        <template #businessTypeText="props">
                            {{ props.row.businessTypeText }}
                        </template>
                        <template #transactionAmount="props">
                            {{ props.row.transactionAmount }}
                        </template>
                        <template #balanceAfterTransaction="props">
                            {{ props.row.balanceAfterTransaction }}
                        </template>
                    </ab-table>
                </template>
                <template #page>
                    <ab-page :pageTotal="page.total" :currentPage='page.currentPage' :pageSize="page.pageSize"
                    :pageSizes="page.pageSizes" @searchForm="pageSearch"></ab-page>
                </template>
            </ab-list-query>
        </div>
    </div>
</template>

<script>
import { download } from "@/utils/download";


export default {
    name: 'transaction-records-table',
    data() {
        return {
            isloading: false,
            isBtnLoading: false,
            tabs: [
                { name: '全部', value: '', state: '' },
                { name: '预付货款', value: '', state: '' },
                { name: '订单支付', value: '', state: '' },
                { name: '退回货款', value: '', state: '' },
                { name: '订单退款', value: '', state: '' },
            ],
            formSearch: {},
            page: {
                currentPage: 1,
                pageSize: 10,
                pageSizes: [10, 20, 50, 100],
                total: 0
            },
            dateTimeRange: '',
            columns: [
                {
                    props: 'transactionNo',
                    labels: '交易流水编号',
                    widths: 140,
                    slot: 'slot'
                },
                {
                    props: 'transactionTime',
                    labels: '交易时间',
                    widths: 140,
                    slot: 'slot'
                },
                {
                    props: 'businessNo',
                    labels: '关联订单',
                    widths: 140
                },
                {
                    props: 'businessTypeText',
                    labels: '交易类型',
                    widths: 140,
                },
                {
                    props: 'transactionAmount',
                    labels: '金额',
                    widths: 140
                },
                {
                    props: 'balanceAfterTransaction',
                    labels: '预付款账户余额',
                    widths: 140
                },
            ],
            tableData: [],
            activedName: ''
        }
    },
    mounted() {
        this.initData()
        this.getListData()
    },
    methods: {
        handleTabs(item, rowObj) {
            this.activedName = item
            this.getListData()
        },
        initData() {
            // 获取tab数据
            this.$get('/orders/constants', { type: 'accountTransactionType' }, 'api').then(({ data }) => {
                if (data && data.code === 'C0000') {
                    this.tabs.forEach((item, index, self) => {
                        data.result.forEach((v, index) => {
                            self[index+1].state = v.value
                        })
                    })
                }
            })
        },
        // 列表数据
        getListData() {
            this.isloading = true
            this.$get('/accounts/transactions', {
                page: this.page.currentPage,
                pageSize: this.page.pageSize,
                businessNo: this.formSearch.businessNo,
                transactionTimeStart: this.formSearch.transactionTimeStart,
                transactionTimeEnd: this.formSearch.transactionTimeEnd,
                transactionType: this.activedName
            }, 'api').then(({ data }) => {
                if (data.code === 'C0000') {
                    this.tableData = data.result.data
                    this.page.total = data.result.pagination.total

                    // 获取数量统计
                    this.$get('/accounts/transactions-count', undefined, 'api').then(({ data }) => {
                        if (data.code === 'C0000') {
                            this.tabs[1].value = data.result.prepay
                            this.tabs[2].value = data.result.paymentOrder
                            this.tabs[3].value = data.result.refund
                            this.tabs[4].value = data.result.refundOrder
                        }
                    })
                }
            }).finally(() => {
                this.isloading = false
            })
        },
        clearBusinessNo() {
            this.formSearch.businessNo = '';
            this.searchForm();
        },
        searchForm() {
            this.getListData()
        },
        resetForm() {
            this.formSearch = {}
            this.dateTimeRange = ''
            this.getListData()
        },
        // 日期
        changeTimeRange(obj) {
            if (obj) {
                this.formSearch.transactionTimeStart = obj[0]
                this.formSearch.transactionTimeEnd = obj[1]
            } else {
                this.formSearch.transactionTimeStart = ''
                this.formSearch.transactionTimeEnd = ''
            }
            this.searchForm()
        },
        // 导出
        exportFile() {
            this.isBtnLoading = true
            this.$get('/accounts/transactions/export', undefined, 'api').then(({data}) => {
                if(data.code === 'C0000') {
                    const { content, filename } = data.result;
                    download(content, filename);
                }else {
                    this.$message.error(data.message || '导出失败，请联系管理员')
                }
            }).finally(() => {
                this.isBtnLoading = false
            })
        },
        pageSearch(obj) {
            this.page.currentPage = obj.currentPage;
            this.page.pageSize = obj.pageSize;
            this.getListData();
        },
        filterColumns(array) {
            this.columns = array;
        },
    }
}
</script>

<style lang="scss" scoped>
.transaction-records-table {
    padding: 40px;
    .header {
        padding-bottom: 20px;
        border-bottom: 3px solid #f0f0f0;
        p {
            font-size: 24px;
            font-weight: 500;
            color: #333;
        }
    }
    .content {
        margin-top: 20px;
    }
}
</style>
