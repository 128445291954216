<template>
  <!-- 开票申请 -->
  <div class="invoice-records">
    <ab-list-query>
      <template #right>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input  placeholder="订单编号" v-model.trim="formSearch.orderNo" clearable @clear="clearOrderNo"></el-input>
          </el-col>
          <el-col :span="4">
            <el-select placeholder="发票状态" v-model="formSearch.invoiceStatus" @change="searchForm" clearable>
            <el-option v-for="item in invoiceStatus" :key="item" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <ab-timeSearch
              class="searchBox"
              startSrc="订单支付时间"
              endSrc="订单支付时间"
              v-model="formSearch.paymentTimeArr"
              @searchEvent="changeTimeRange"
            ></ab-timeSearch>
          </el-col>
          <el-col :span="4" class="text-left">
            <el-button @click="searchForm">搜索</el-button>
            <el-button link class="btn-svg btn-clear" @click="formSearch={}"><ab-svg name="qingchu"></ab-svg>清除</el-button>
          </el-col>
        </el-row>
      </template>

      <template #toolbar>
        <el-button class="btn-invoice" :loading="load.isAddLoading"
          :disabled="isDisabled" @click="applyInvoicing(null)">我要开票</el-button>
      </template>
      <template #table>
        <ab-table
          class="materialTable"
          ref="table"
          stripe
          showEdit
          showSelection
          selectionWidth="45"
          indexWidth="50"
          widths="100"
          isFilter="operator"
          :column-list="columns"
          :table-list="tableData"
          :selectable="selectable"
          :dataListLoading="load.isloading"
          :handleSelectionChange="handleSelectionChange"
          @filterColumns="filterColumns"
        >
          <template #status1="scope">
            <span class="status-span" :class="getOrderStatusClass(scope.row.status)"></span>
            {{ scope.row.status }}
          </template>
          <template #operators="{row}">
            <span>{{ row.status }}</span>
          </template>
          <template #operator="scope">
            <el-button link type="primary" @click="showDetail(scope.row, 'view')">详情</el-button>
          </template>
          <template #moreOper="scope">
            <el-button link>下载</el-button>
          </template>
        </ab-table>
      </template>

      <template #page>
        <ab-page
          :pageTotal="page.total"
          :page='page.page'
          :pageSize="page.pageSize"
          :pageSizes="page.pageSizes"
          @searchForm="pageSearch"
        ></ab-page>
      </template>
    </ab-list-query>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //状态汇总
      load: {
        isAddLoading: false,
        isloading: false,
        btnLoading: false,
      },
      formSearch: {},
      multipleSelection: [],
      invoiceStatus: [],
      //开票新增对象
      detailData: {},
      columns: [
        {
          props: "orderNo",
          labels: "订单编号",
          widths: 120,
        },
        {
          props: "invoiceStatusText",
          labels: "发票状态",
          widths: 100,
        },
        {
          props: "invoiceTime",
          labels: "开票时间",
          widths: 100,
        },
        {
          props: "totalAmount",
          labels: "订单金额",
          widths: 100,
        },
        {
          props: "waitInvoiceAmount",
          labels: "待开票金额",
          widths: 100,
        },
        {
          props: "pendingInvoiceAmount",
          labels: "开票中金额",
          widths: 100,
        },
        {
          props: "invoiceAmount",
          labels: "已开票金额",
          widths: 100,
        },
      ],
      tableData: [],
      page: {
        page: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        total: 0,
      },
      sortName: "",
      order: "",
      //进出口判断
      bizTypeList: [],
      entrustType: "",
      bizType: {},
      importTypeList: ["01", "02", "11"],
      exportTypeList: ["03", "04", "06"],
    };
  },
  computed: {
    isDisabled() {
      if (this.multipleSelection.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    getOrderStatusClass(val) {
      let cls = "status-E21010";
      if (val == "已开票") {
        cls = "status-00458E";
      } else if (val == "部分开票") {
        cls = "status-FF8400";
      }
      return cls;
    },
    //日期
    changeTimeRange(obj) {
      this.formSearch.paymentTimeArr = obj;
      if (obj) {
        this.formSearch.paymentTimeStart = obj[0];
        this.formSearch.paymentTimeEnd = obj[1];
      } else {
        this.formSearch.paymentTimeStart = "";
        this.formSearch.paymentTimeEnd = "";
      }
      this.searchForm();
    },
    filterColumns(val) {
      this.columns = val;
    },
    pageSearch(val) {
      this.page.pageSize = val.pageSize;
      this.page.page = val.page;
      this.getListData();
    },
    //开票
    invoice(id) {
      this.$router.push({
        path: "/invoice-add",
        query: { id },
      });
    },
    // 详情/编辑
    showDetail(row, action) {
      this.$router.push({
        path: "/invoice-detail",
        query: {
          action: action,
          id: row.orderNo,
        },
      });
    },
    //获取数据
    getListData() {
      this.load.isloading = true;
      let dataList = {
        paymentTimeStart: this.formSearch.paymentTimeStart,
        paymentTimeEnd: this.formSearch.paymentTimeEnd,
        orderNo: this.formSearch.orderNo,
        invoiceStatus: this.formSearch.invoiceStatus,
        page: this.page.page,
        pageSize: this.page.pageSize,
      };
      this.$get("/invoices", dataList, "api")
        .then(({ data }) => {
          if (data && data.code === "C0000") {
            this.tableData = data.result ? data.result.data || [] : [];
            this.tableData.forEach((e) => {
              e.taxRate = "13%";
              e.otax = this.$calc.mul(e.invoiceAmount, 13 / 100).toFixed(2);
              e.invoiceType = "增值税专用发票";
            });
            this.page.total = data.result.pagination.total;
          } else {
            this.tableData = [];
            this.page.total = 0;
          }
        })
        .finally(() => {
          this.load.isloading = false;
        });
    },
    clearOrderNo() {
      this.formSearch.orderNo = '';
      this.searchForm();
    },
    //搜索
    searchForm() {
      this.getListData();
    },
    //批量开票
    applyInvoicing(row) {
      this.load.isAddLoading = true;
      let data;
      if (row) {
        data = { waitInvoiceVos: [row] };
      } else {
        data = { waitInvoiceVos: this.multipleSelection };
      }
      this.$post("/fms/client/drawInvoice/queryAddInfos", data)
        .then(({ data }) => {
          if (data && data.code === "C0000") {
            if (data.code === "C0000") {
              this.detailData.result = data.result;
              this.detailData.customerVo = data.result.customerVo;
              this.detailData.customerVo.number = data.result.number;
              this.detailData.customerVo.invoiceAmount =
                data.result.invoiceAmount;
              this.detailData.customerVo.tax = data.result.tax;
              this.detailData.customerVo.taxRate = data.result.taxRate;
              this.detailData.drawInvoiceEntryVos =
                data.result.drawInvoiceEntryVos;
              window.sessionStorage.removeItem("detailData");
              window.sessionStorage.setItem(
                "detailData",
                JSON.stringify(this.detailData)
              );
              this.$router.push({ path: "/invoice-add" });
            }
          } else {
            this.$message({
              type: "error",
              message: data.message,
              offset: 380,
              grouping: true,
            });
          }
        })
        .finally(() => {
          this.load.isAddLoading = false;
        });
    },
    //列表选择项
    handleSelectionChange(array) {
      this.multipleSelection = array;
    },
  },
  created() {
    let importTypeList = [
      { value: "01", desc: "13% 增值税专用发票" }, //合作进口
      { value: "02", desc: "海关税票+6% 服务费发票" }, //代理进口
    ];
    let exportTypeList = [{ value: "04", desc: "代理出口" }];
    let bizTypes = this.$utils.getSessionStorage("bizType") || {};
    this.bizType = bizTypes;
    if (bizTypes.activeIndex == "01") {
      this.entrustType = "import";
      this.bizTypeList = importTypeList;
    } else if (bizTypes.activeIndex == "02") {
      this.bizTypeList = exportTypeList;
      this.entrustType = "export";
    }

    this.$get("/orders/constants", { type: "invoiceStatus" }, "api").then(
      ({ data }) => {
        if (data.code === "C0000") {
          this.invoiceStatus = data.result;
        }
      }
    );

    this.getListData();
    this.detailData = {};
  },
  mounted() {},
};
</script>

<style lang="scss">
.invoice-records {
  .el-menu.el-menu--horizontal {
    display: none;
  }
}
</style>
