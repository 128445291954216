<template>
  <el-drawer ref="address-drawer" v-model="dialog.show" @open="initOpen" :title="title" :before-close="handleClose" class="address-dialog">

  <template #default>
    <el-form :model="dataFrom" v-loading="btnLoading" :rules="rules" ref="dataFrom" label-position="top">
      <el-row :gutter="30">
        <el-col :span="24">
          <el-form-item label="联系人" prop="contactPerson">
            <el-input maxLength='9' placeholder="请输入联系人" v-model="dataFrom.contactPerson"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="联系电话" prop="contactPhone">
            <el-input type="number" placeholder="请输入联系电话" v-model="dataFrom.contactPhone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="省/市/区" prop="address1">
            <ab-address v-model="dataFrom.address1" @handleSelect="changeAddressDesc"></ab-address>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="详细地址" prop="address">
            <el-input maxLength='60' placeholder="请输入详细地址" v-model="dataFrom.address"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="默认地址" prop="isDefault">
            <el-select v-model="dataFrom.isDefault">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </template>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handlerSave" :loading="btnLoading">确 定</el-button>
    </div>
  </template>
  </el-drawer>
</template>
  
<script>
export default {
  name: 'ab-address-dialog',
  props: {
    dialog: {
      type: Object,
      default: () => {
        return {
          show: false
        }
      }
    }
  },
  data() {
    return {
      btnLoading:false,
      rules: {
        contactPerson: [
          { required: true, message: '联系人不能为空', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '联系电话不能为空', trigger:'blur' },
          {
            validator: (rule, value, callback) => {
              if (value && value.length != 11) {
                callback(new Error('请输入11位手机号码'))
              } else {
                callback()
              }
            }, trigger:'blur'
          }
        ],
        address1: [
          { required: true, message: '省/市/区不能为空', trigger: ['blur', 'change'] }
        ],
        address: [
          { required: true, message: '详细地址不能为空', trigger: 'blur' }
        ],
        isDefault: [
          { required: true, message: '默认地址不能为空', trigger: 'blur'}
        ]
      },
      title: '',
      dataFrom: {}
    }
  },
  watch: {
    dialog: {
      handler: function (val, oldval) {
        if (val.id) {
          this.title = '编辑地址'
          this.getDetailData(val.id)
        } else {
          this.title = '新增地址'
        }
      },
      deep: true
    }
  },
  methods: {
    initOpen(){
      this.dataFrom = {}
    },
    changeAddressDesc(item, desc) {
      if (item) {
        this.dataFrom.countryId = item[0]
        this.dataFrom.provinceId = item[1]
        this.dataFrom.cityId = item[2]
        this.dataFrom.countyId = item[3]
      }
      this.dataFrom.areaAddressDesc = desc
    },
    handlerSave() {
      this.$refs.dataFrom.validate((voids) => {
        if (voids) {
          this.btnLoading = true
          let url ='/receivingAddress/add'
          if (this.dialog.id) {
           url = '/receivingAddress/mod'
           this.dataFrom.id = this.dialog.id
          }
          this.$post(url, this.dataFrom).then(({ data }) => {
            if (data && data.code === 'C0000') {
              this.$message.success(data.message || '操作成功！')
              setTimeout(() => {
                this.handleClose()
                this.$emit('updateAddress', this.dataFrom)
              }, 1000)
            }
          }).finally(() => {
            this.btnLoading = false
          })
        }
      })
    },
    getDetailData(id) {
      this.btnLoading = true
      this.$post('/receivingAddress/get', id).then(({ data }) => {
        if (data && data.code === 'C0000') {
          if (data.result) {
            this.dataFrom = data.result
            let country = []
            country.push(this.dataFrom.countryId)
            country.push(this.dataFrom.provinceId)
            country.push(this.dataFrom.cityId)
            country.push(this.dataFrom.countyId)
            this.dataFrom.address1 = country
          }
        }
      }).finally(() => {
        this.btnLoading = false
      })

    },
    handleClose() {
      this.dialog.show = false
    }
  }
}
</script>
<style lang="scss">
.address-dialog {
  padding: 0px 20px 0px 20px !important;

  .ai-input {
    .el-input-group__append {
      background-color: #0b7aff;
      color: #ffffff;
    }
  }

  .dialog-footer {
    text-align: center;
  }
}
</style>