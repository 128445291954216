<template>
  <div class="search-form search-form-filter" ref="searchbox">
    <el-form
      :model="formSearch"
      size="mini"
      ref="formSearch"
    >
      <div class="left-input-box">
        <el-row ref="searchRow" :gutter="30">
          <slot></slot>
          <el-col :span="4" class="text-left">
            <el-button @click="searchForm">搜索</el-button>
            <el-button link class="btn-svg" @click="resetForm"
              ><ab-svg name="qingchu"></ab-svg>清除</el-button
            >
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <div class="fl" v-if="showMore" style="float: left; margin-left: 15px">
                  <el-button type="primary" v-if="isMore" @click="changeMore" size="mini" plain>收起<i class="el-icon-arrow-up el-icon--right"></i></el-button>
                  <el-button type="primary" v-else  @click="changeMore" size="mini" plain>更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30" v-if="isMore">
          <slot name="content">
          </slot>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: 'ab-search',
    props: {
      isToggle: {
        type: Boolean,
        default: false,
      },
      showMore: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        formSearch: {},
        isMore: false
      }
    },
    methods: {
      // 查询
      searchForm() {
        this.$emit('searchForm')
      },
      resetForm() {
        this.$emit('resetForm')
      },
      changeMore () {
          this.isMore = !this.isMore
      }
    }
  }
</script>
<style lang="scss">
    .search-form{
        // height: 60px;
        padding: 20px;
        .el-col{
            height: 30px;
            margin-bottom: 15px;
        }
        background-color: #ffffff;
        margin-bottom: 8px;
        .el-form-item{
          height: 30px;
          margin-bottom: 0 !important;
        }
        .searchBox{
          margin-bottom: 0 !important;
        }
        .fl{
          .el-button{
            padding: 7px 5px;
            color: #162232;
            background: #fff;
            border-color: #e5e5e5;
          }
        }
    }
</style>