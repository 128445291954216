import Vue from 'vue'

var ws = {
  showNotice: null,
  closeNotice: null,
  getMessageList: null
}
ws.init = function () {
  var userId = JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).user.id : ''
  if (userId != 0 && userId != '') {
    let baseUrl = process.env.VUE_APP_BASEURL
    let wsKey = process.env.VUE_APP_WSK
    let hostname = ''
    if (window.location.hostname == 'localhost') {
      hostname = baseUrl.substring(7, baseUrl.length)
    } else {
      hostname = window.location.hostname + '/api'
    }
    let wsUrl = wsKey + hostname + "/websocket?userId=" + userId
    let timer = null
    const websocket = new WebSocket(wsUrl)
    websocket.onopen = function () {
      console.log('open')
      // keepAlive
      timer = setInterval(() => {
        if (websocket != null && websocket != undefined) {
          websocket.send('keepAlive')
        }
      }, 20000)
    }
    websocket.onmessage = function (e) {
      console.log('接收到后台消息为: ' + e.data)
      const data = JSON.parse(e.data)
      console.log(ws.showNotice)
      ws.showNotice(data)
    }
    websocket.onclose = function () {
      console.log('close')
      clearInterval(timer)
      ws.closeNotice()
    }
    // Vue.prototype = {}
    // Vue.prototype.$ws = websocket
  }
}

export default ws
