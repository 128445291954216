<template>
    <el-drawer title="物流跟踪" v-model="limitDrawer.show" size="500px">
        <div class="logistics-status" v-loading="loading" v-if="listData.length || loading">
            <el-tabs v-model="activeName" :class="{'onlyOne': listData.length === 1}">
                <el-tab-pane :label="`发货${index + 1}`" :name="`发货${index + 1}`" v-for="(item, index) in listData" :key="index">
                    <div class="basic-info">
                        <el-row>
                            <el-col :span="9">
                                <p>物流状态：{{item.status}}</p>
                            </el-col>
                            <el-col :span="15">
                                <p style="display: flex; align-items: center">运单编号：{{item.expressNumber}} <ab-svg name="copy" style="width: 14px; color: #01458e; cursor: pointer; margin-left: 4px; height: 14px" @click="copyWareInfo(item.expressNumber)"></ab-svg> </p>
                            </el-col>
                        </el-row>
                        <el-row style="margin-top: 15px;">
                            <el-col :span="9">
                                <p>承运公司：{{item.com}}</p>
                            </el-col>
                            <el-col :span="15">
                                <p>发货时间：{{item.time}}</p>
                            </el-col>
                        </el-row>
                    </div>
                    <ul>
                        <li v-if="item.address" class="address">
                            <p class="tit">收货地址</p>
                            <p>{{item.address}}</p>
                            <div class="circle"><ab-svg name="dingwei"></ab-svg></div>
                        </li>
                        <li v-for="(itm, idx) in item.expressList" :key="idx" :class="{'is-active': idx == 0}">
                            <p class="tit">{{itm.status}} {{itm.time}}</p>
                            <p style="line-height: 16px">{{itm.context}}</p>
                            <div class="circle"></div>
                        </li>
                    </ul>
                    <p style="margin: 15px 0 15px 0; font-size: 16px">发货商品</p>
                    <ab-table
                    indexLabel="序号"
                    indexWidth="55"
                    showOrder
                    :column-list="columns"
                    maxHeight="calc(35vh - 100px)"
                    style="box-shadow: 0px 0px 15px #ccc"
                    :table-list="item.prodList">
                    </ab-table>
                </el-tab-pane>
            </el-tabs>
            <el-dialog
            class="no-padding special-dialog"
            @close="deliverTransportNo=''"
            append-to-body
            title="运单跟踪"
            v-model="dialogVisible"
            top="0vh"
            width="500px">
            <ab-logisticsInfo :deliverTransportNo="deliverTransportNo" :isInter="isInter" maxHeight="95vh">
            </ab-logisticsInfo>
            </el-dialog>
            <textarea style="width: 0; height: 0" id="inputCopy">这是幕后黑手</textarea>
        </div>
        <div v-else class="no-data-info1">
            <img src="@/assets/img/noImg.png" />
            <div class="tishi"> 暂无数据</div>
        </div>
    </el-drawer>
</template>

<script>
export default {
    name: 'ab-logistics-status',
    data () {
        return { 
            dialogVisible: false,
            deliverTransportNo: '',
            isInter: false,
            activeName: 0,
            listData: [],
            loading: false,
            progress: 0,
            columns: [
                {
                    props: 'productModel',
                    labels: '型号',
                    widths: 160
                },
                {
                    props: 'brand',
                    labels: '品牌',
                    widths: 110
                },
                {
                    props: 'productType',
                    labels: '品类',
                    widths: 90
                },
                {
                    props: 'productPackaging',
                    labels: '封装',
                    widths: 120
                },
                {
                    props: 'parameter',
                    labels: '参数',
                    widths: 140
                },
                {
                    props: 'sqty',
                    labels: '发货数量',
                    widths: 80,
                    align: 'center'
                },
            ]
        }
    },
    props: {
        limitDrawer: {
            type: Object,
            default: {show: false}
        }
    },
    methods: {
        getNumber(txt) {
            let num = ''
            if(txt && txt.indexOf('单号：') != -1 || txt.indexOf('单号:') != -1) {
                let a = txt.indexOf('单号：') != -1 ? '单号：' : '单号:'
                let b = txt.split(a)[1].indexOf('，') != -1 || txt.split(a)[1].indexOf(',') != -1 ? txt.split(a)[1].indexOf('，') != -1 ? '，' : ',' : ''
                if(a && b) {
                return txt.split(a)[1].split(b)[0]
                }
            }
            return num
        },
        splitRemark(txt) {
            let num = 0
            if(txt && txt.indexOf('感谢') != -1) {
                num = txt.indexOf('感谢')
            }
            return num
        },
        getStatusTimeAxis(id) {
            this.listData = []
            this.loading = true
            this.$post('/orderBase/queryExpress', {id}).then(({ data }) => {
                if (data.code === 'C0000') {
                this.listData = data.result || []
                if(this.listData.length) {
                    this.listData.forEach(e=>{
                        e.status = e.expressList && e.expressList.length ? e.expressList[0].status || '运输中' : ''
                        e.com = e.expressList && e.expressList.length ? e.expressList[e.expressList.length - 1].com || '' : ''
                        e.expressNumber = e.expressList && e.expressList.length ? e.expressList[e.expressList.length - 1].expressNumber || '' : ''
                        e.time = e.expressList && e.expressList.length ? e.expressList[0].time || '' : ''
                    })
                }
                this.$nextTick(() => {
                    this.activeName = '发货1'
                })
                } else {
                    this.$message({
                        type: 'error',
                        message: data.message,
                        grouping: true,
                        offset: 380
                    })
                }
            }).finally(() => {
                this.loading = false
            })
        },
        copyWareInfo(text) {
            var input = document.getElementById("inputCopy");  // 找到虚拟的输入框
            input.value = text; // 修改文本框的内容
            input.select(); // 选中文本
            document.execCommand("copy"); // 执行浏览器复制命令
            this.$message({
                type: 'success',
                message: '复制成功!',
                grouping: true,
                offset: 380
            })
        },
        showDialog(row) {
            this.dialogVisible = true
            this.$nextTick(() => {
                this.isInter = row.statusGroupName == 'ordered'
                this.deliverTransportNo = row.number ? row.number.trim() : ''
            })
        }
    },
    watch: {
        'limitDrawer.show': {
            handler: function(val, oldval) {
                if (val) {
                    this.getStatusTimeAxis(this.limitDrawer.id)
                }
            },
            deep: true
        }
    },
}
</script>

<style lang="scss">
    .no-data-info1 {
        text-align: center;
        margin-top: 200px
    }
    .logistics-status{
        ::-webkit-scrollbar{
            width: 2px;
        }
        .el-tabs__content{
            height: calc(100vh - 135px);
            overflow-y: auto;
        }
        .basic-info{
            font-size: 14px;
            padding: 15px 20px;
            width: calc(100% - 30px);
            margin: 20px 0 0 15px;
            background-color: #ecf7fd;
            position: relative;
            border-radius: 8px;
            line-height: 14px;
        }
        .el-tabs__nav{
            .el-tabs__item{
                padding: 0 10px;
                background-color: #fff;
                border: none;
                font-size: 14px;
            }
            .el-tabs__item.is-active{
                color: #01458e;
                font-size: 16px;
                padding: 0 10px;
                // &::before{
                //     content: '';
                //     position: absolute;
                //     width: 100%;
                //     height: 2px;
                //     left: 0px;
                //     background-color: #167fff;
                //     bottom: 0px;
                // }
            }
        }
        .el-tabs__nav-wrap{
            border-bottom: 1px solid #f7f7f7;
            &::after{
                display: none;
            }
        }
        ul{
            max-height: calc(55vh - 120px);
            overflow-y: scroll;
            padding: 0px 20px 0;
            margin-top: 20px;
            li{
                position: relative;
                padding: 0px 0 25px 20px;
                border-left: 1px dashed #c9c9c9;
                p{
                    color: #848587;
                    font-size: 14px;
                    line-height: 14px;
                    margin-bottom: 10px;
                }
                .tit{
                    color: #2b3644;
                    font-size: 16px;
                }
                .circle{
                    top: 0;
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    border: 2px solid #b4b4b4;
                    left: -7px;
                    background-color: #fff;
                }
                &:last-child{
                    border: none;
                }
                .number{
                    color: #01458e;
                    cursor: pointer;
                }
                svg{
                    width: 12px;
                    top: 4px;
                    position: relative;
                    height: 16px;
                    color: #01458e;
                    cursor: pointer;
                }
                &.address{
                    .circle{
                        width: 18px;
                        height: 18px;
                        background-color: #0c3d8e;
                        border: 0;
                        left: -9px;
                        svg{
                            width: 10px;
                            top: 2px;
                            color: #fff;
                            left: 4px;
                            position: absolute;
                        }
                    }
                }
            }
            .is-active{
                p{
                    color: #01458e;
                }
                .circle{
                    border-color: #01458e;
                    &::before{
                        width: 6px;
                        height: 6px;
                        content: '';
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        background-color: #01458e;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
    .popup-add-data .el-dialog__body{
        padding-top: 1px;
    }
    .no-padding .el-dialog__body{
        padding: 0;
    }
    .special-dialog{
        height: 100vh;
        margin-bottom: 0;
        overflow: hidden;
    }
    .onlyOne{
        .el-tabs__header{
            display: none;
        }
    }
        .el-tabs__header{
            margin-bottom: 0;
        }
</style>