<template>
  <!-- 企业信息 -->
  <div :class="{'enterprise': true, 'isEdit': isEdit}" v-loading="pageLoading">
    <img v-if="!pageLoading" class="auth-img" :src="require(`@/assets/img/authentication${!!companyId ? '1' : ''}.png`)" alt="">
    <h3 class="basic-title">基本信息</h3>
    <div class="detail-basic" v-if="dataForm.id && !isEdit">
      <el-row>
        <el-col :span="8">
          <span class="label">企业名称（全称）：</span>
          <span class="value">{{ dataForm.name }}</span>
        </el-col>
        <el-col :span="8">
          <span class="label">所在区域：</span>
          <span class="value">{{ dataForm.areaAddressDesc }}</span>
        </el-col>
        <el-col :span="8">
          <span class="label">行业类别：</span>
          <span class="value">{{ dataForm.custTyId ? induList.filter(e=>{return e.label == dataForm.custTyId})[0].value : '' }}</span>
        </el-col>
        <el-col :span="8">
          <span class="label">主要联系方式：</span>
          <span class="value">{{ {'4': '电话', '16': '邮箱'}[dataForm.mainContactType] }}</span>
        </el-col>
        <el-col :span="8" v-if="dataForm.phone">
          <span class="label">电话：</span>
          <span class="value">{{ dataForm.phone }}</span>
        </el-col>
        <el-col :span="8" v-if="dataForm.email">
          <span class="label">邮箱：</span>
          <span class="value">{{ dataForm.email }}</span>
        </el-col>
      </el-row>
    </div>
    <el-form :model="dataForm" :rules="rules" ref="dataForm" label-position="top" v-if="!dataForm.id || isEdit" style="margin-top: 30px;">
      <el-row :gutter="30">
        <el-col :span="8">
          <el-form-item label="企业名称（全称）" prop="name">
            <el-input maxLength='100' placeholder="请输入公司名称" v-model="dataForm.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所在区域" prop="areaAddress">
            <ab-address v-model="dataForm.areaAddress"></ab-address>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="行业类别" prop="custTyId">
            <el-select placeholder="请选择行业类别" v-model="dataForm.custTyId">
              <el-option v-for="item in induList" :key="item.label" :label="item.value" :value="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="主要联系方式" prop="mainContactType">
            <div class="contact">
              <el-radio-group v-model="dataForm.mainContactType">
                <el-radio label="4">电话</el-radio>
                <el-radio label="16">邮箱</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="电话" prop="phone" :rules="[
            { required: dataForm.mainContactType == '4', message: '电话不能为空', trigger:'blur' }
          ]">
            <el-input placeholder="请输入电话" @input="changePhone" v-model="dataForm.phone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="邮箱" prop="email" :rules="[
            { required: dataForm.mainContactType == '16', message: '邮箱不能为空', trigger: ['blur', 'change'] },
            { validator: (rule, value, callback)=> {
                if(!value) {
                  callback()
                }
                if (!/^[^\u4e00-\u9fa5]+$/.test(value)) {
                  callback('邮箱不能输入中文！')
                }else if(!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)){
                  return callback('请输入正确的邮箱格式');
                }else{
                  callback()
                }
              }
            }
          ]">
            <el-input placeholder="请输入邮箱" maxlength="50" v-model="dataForm.email"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <h3 class="invoice-title">发票抬头</h3>
    <ab-table
      stripe
      v-if="dataForm.id && !isEdit"
      :column-list="invoiceColumns"
      :table-list="dataForm.invoiceHeaderVoList">
        <template #isMain="props">
          <span>{{ props.row.isMain ? '是' : '否' }}</span>
        </template>
    </ab-table>
    <vxe-table 
      v-else
      border 
      show-overflow
      resizable 
      keep-source
      auto-resize
      max-height="520"
      :data="dataForm.invoiceHeaderDtoList"
      :edit-config="{trigger: 'click', mode: 'row', showUpdateStatus: true, icon: 'abcd', autoClear: false}" 
      ref="elxEditableInvoice"
      :edit-rules="invoiceRule">
      <vxe-table-column type="seq" width="60" title="序号" align="center"></vxe-table-column>
      <vxe-column field="invoiceHead" title="开票名称" :edit-render="{}">
          <template #edit="{ row }">
              <vxe-input maxlength="100" v-model="row.invoiceHead" type="text" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>
      <vxe-column field="taxNumber" title="纳税人识别号" :edit-render="{}">
          <template #edit="{ row }">
              <vxe-input v-model="row.taxNumber" type="text" maxlength="30" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>
      <vxe-column field="bank" title="开户银行" :edit-render="{}">
          <template #edit="{ row }">
              <vxe-input maxlength="50" v-model="row.bank" type="text" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>
      <vxe-column field="bankAccount" title="银行账号" :edit-render="{}">
          <template #edit="{ row }">
              <vxe-input maxlength="30" v-model="row.bankAccount" type="text" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>

      <vxe-column field="address" title="发票地址" :edit-render="{}">
          <template #edit="{ row }">
              <vxe-input maxlength="200" v-model="row.address" type="text" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>

      <vxe-column field="phone" title="联系电话" :edit-render="{}" width="120">
          <template #edit="{ row }">
              <vxe-input v-model="row.phone" maxlength="60" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>
      <vxe-column field="email" title="邮箱" :edit-render="{}">
          <template #edit="{ row }">
              <vxe-input v-model="row.email" type="text" maxlength="50" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>

      <vxe-column field="isMain" title="默认" :edit-render="{}" width="80">
        <template v-slot="{row}">
            <span>{{['否', '是'][row.isMain]}}</span>
        </template>
        <template v-slot:edit="{ row }">
          <el-select placeholder="是否为主账户" v-model="row.isMain" @change="isDefaultChange1($event, row, 'invoiceHeaderDtoList')" :disabled="dataForm.id && !isEdit">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </template>
      </vxe-column>

      <vxe-column field="oper" title="操作" v-if="!dataForm.id || isEdit" width="60">
          <template #default="{rowIndex , row}">
              <el-button link type="primary" @click="removeInvoiceRow(row, rowIndex)" style="color:#FF0000">删除</el-button>
          </template>
      </vxe-column>
    </vxe-table>
    <div class="addCargo" v-if="!dataForm.id || isEdit">
        <span @click="addInvoice"><ab-svg name="add"></ab-svg> 新增一行</span>
    </div>
    <h3>收货地址</h3>
    <ab-table
      indexLabel="序号"
      indexWidth="50" 
      v-if="dataForm.id && !isEdit"
      stripe
      :column-list="addressColumns"
      :table-list="dataForm.addressVoList">
        <template #isDefault="props">
          <span>{{ props.row.isDefault ? '是' : '否' }}</span>
        </template>
    </ab-table>
    <vxe-table 
      v-else
      border 
      show-overflow
      resizable 
      keep-source
      auto-resize
      max-height="520"
      :data="dataForm.addressDtoList"
      :edit-config="{trigger: 'click', mode: 'row', showUpdateStatus: true, icon: 'abcd', autoClear: false}" 
      ref="elxEditableAddress"
      :edit-rules="addressRule">
      <vxe-table-column type="seq" width="60" title="序号" align="center"></vxe-table-column>
      <vxe-column field="areaAddress" title="区域" :edit-render="{}" min-width="150">
        <template v-slot="{row}">
          <ab-address
            v-model="row.areaAddress"
            placeholder= "请选择区域"
          ></ab-address>
        </template>
        <template #edit="{ row }">
          <ab-address
            v-model="row.areaAddress"
            placeholder= "请选择区域"
          ></ab-address>
        </template>
      </vxe-column>
      <vxe-column field="address" title="详细地址" :edit-render="{}" min-width="200">
          <template #edit="{ row }">
              <vxe-input v-model="row.address" maxlength="255" type="text" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>
      <vxe-column field="contactPerson" title="联系人" :edit-render="{}" width="90">
          <template #edit="{ row }">
              <vxe-input v-model="row.contactPerson" maxlength="20" type="text" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>
      <vxe-column field="contactPhone" title="联系电话" :edit-render="{}" width="150">
          <template #edit="{ row }">
              <vxe-input v-model="row.contactPhone" maxlength="60" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>

      <vxe-column field="isDefault" title="默认" :edit-render="{}" width="100">
        <template v-slot="{row}">
            <span>{{['否', '是'][row.isDefault]}}</span>
        </template>
        <template v-slot:edit="{ row }">
            <el-select placeholder="是否为默认地址" v-model="row.isDefault" @change="isDefaultChange($event, row, 'addressDtoList')">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </template>
      </vxe-column>

      <vxe-column field="oper" title="操作" v-if="!dataForm.id || isEdit" width="60">
          <template #default="{rowIndex , row}">
              <el-button link type="primary" @click="removeAddressRow(row, rowIndex)" style="color:#FF0000">删除</el-button>
          </template>
      </vxe-column>
    </vxe-table>
    <div class="addCargo" v-if="!dataForm.id || isEdit">
        <span @click="addAddress"><ab-svg name="add"></ab-svg> 新增一行</span>
    </div>
    <h3>联系人</h3>
    <ab-table
      indexLabel="序号"
      indexWidth="50" 
      v-if="dataForm.id && !isEdit"
      stripe
      :column-list="contactColumns"
      :table-list="dataForm.contactPersonVoList">
        <template #isDefault="props">
          <span>{{ props.row.isDefault ? '是' : '否' }}</span>
        </template>
    </ab-table>
    <vxe-table 
      v-else
      border 
      show-overflow
      resizable 
      keep-source
      auto-resize
      max-height="520"
      :data="dataForm.contactPersonDtoList"
      :edit-config="{trigger: 'click', mode: 'row', showUpdateStatus: true, icon: 'abcd', autoClear: false}" 
      ref="elxEditableContact"
      :edit-rules="contactRule">
      <vxe-table-column type="seq" width="60" title="序号" align="center"></vxe-table-column>
      <vxe-column field="name" title="姓名" :edit-render="{}">
          <template #edit="{ row }">
              <vxe-input v-model="row.name" maxlength="100" type="text" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>
      <vxe-column field="job" title="职务" :edit-render="{}">
          <template #edit="{ row }">
              <vxe-input v-model="row.job" maxlength="20" type="text" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>
      <vxe-column field="phone" title="手机号码" :edit-render="{}">
          <template #edit="{ row }">
              <vxe-input v-model="row.phone" maxlength="60" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>
      <vxe-column field="vxNo" title="微信" :edit-render="{}">
          <template #edit="{ row }">
              <vxe-input v-model="row.vxNo" maxlength="20" :disabled="dataForm.id && !isEdit"></vxe-input>
          </template>
      </vxe-column>

      <vxe-column field="isDefault" title="默认" :edit-render="{}">
        <template v-slot="{row}">
            <span>{{['否', '是'][row.isDefault]}}</span>
        </template>
        <template v-slot:edit="{ row }">
          <el-select placeholder="是否为默认联系人" v-model="row.isDefault" @change="isDefaultChange($event, row, 'contactPersonDtoList')" :disabled="dataForm.id && !isEdit">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </template>
      </vxe-column>

      <vxe-column field="oper" title="操作" v-if="!dataForm.id || isEdit" width="60">
          <template #default="{rowIndex , row}">
              <el-button link type="primary" @click="removeContactRow(row, rowIndex)" style="color:#FF0000">删除</el-button>
          </template>
      </vxe-column>
    </vxe-table>
    <div class="addCargo" v-if="!dataForm.id || isEdit">
        <span @click="addContact"><ab-svg name="add"></ab-svg> 新增一行</span>
    </div>
    <div class="footers" style="margin-top: 40px;">
      <el-button type="primary" @click="isEdit = true" v-if="dataForm.id && !isEdit">编辑</el-button>
      <el-button type="primary" @click="submit" v-else :loading="showLoad">提交</el-button>
    </div>
  </div>
</template>

<script>
import vueCookie from 'vue-cookies'
export default {
  data() {
    return {
      userInfo: this.$utils.getSessionStorage('userInfo'),
      dataForm: {
        invoiceHeaderDtoList: [],
        addressDtoList: [],
        contactPersonDtoList: [],
        mainContactType: '4'
      },
      induList: [],
      rules: {
        name: [
          { required: true, message: '公司名称不能为空', trigger: ['blur', 'change'] }
        ],
        areaAddress: [
          { required: true, message: '所在区域不能为空', trigger: ['blur', 'change'] }
        ],
        custTyId: [
          { required: true, message: '行业类别不能为空', trigger: ['blur', 'change'] }
        ],
        mainContactType: [
          { required: true, message: '请勾选主要联系方式', trigger: ['blur', 'change'] }
        ]
      },
      invoiceRule: {
        invoiceHead: [
          { required: true, message: '请输入开票名称' }
        ],
        bankAccount: [
          { required: true, message: '请输入银行账号' },
          {
            validator: ({ cellValue }) => {
              if (cellValue && !/^[^\u4e00-\u9fa5]+$/.test(cellValue)) {
                return new Error('银行账号不能输入中文！')
              }
            }
          }
        ],
        taxNumber: [
          { required: false, message: '请输入纳税人识别号' },
          {
            validator: ({ cellValue }) => {
              if (cellValue && !/^[a-zA-Z0-9]+$/.test(cellValue)) {
                return new Error('纳税人识别号只能输入数字和英文！')
              }
            }
          }
        ],
        phone: [
          { required: false, message: '电话不能为空', trigger:'blur' },
          {
            validator: ({ cellValue }) => {
              if (cellValue && /[^\d^\-]+/.test(cellValue)) {
                return new Error('电话格式不正确')
              }
            }
          }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: ['blur', 'change'] },
          {
            validator: ({ cellValue }) => {
              if(cellValue && !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(cellValue)){
                return new Error("请输入正确的邮箱格式");
              }
            }
          }
        ],
        isMain: [
            { required: true, message: '请选择是否默认主账户' }
        ]
      },
      addressRule: {
        areaAddress: [
          { required: true, message: '请选择区域', trigger:'blur' }
        ],
        address: [
            { required: true, message: '请输入详细地址', trigger:'blur' }
        ],
        contactPerson: [
            { required: true, message: '请输入联系人', trigger:'blur' }
        ],
        contactPhone: [
          { required: true, message: '联系电话不能为空', trigger:'blur' },
          {
            validator: ({ cellValue }) => {
              if (cellValue && /[^\d^\-]+/.test(cellValue)) {
                return new Error('电话格式不正确')
              }
            }
          }
        ],
        isDefault: [
            { required: true, message: '请选择是否默认', trigger:'blur' }
        ]
      },
      contactRule: {
        name: [
          { required: true, message: '请输入姓名', trigger:'blur' }
        ],
        job: [
            { required: true, message: '请输入职务', trigger:'blur' }
        ],
        phone: [
          { required: true, message: '手机号码不能为空', trigger:'blur' },
          {
            validator: ({ cellValue }) => {
              if (cellValue && /[^\d^\-]+/.test(cellValue)) {
                return new Error('电话格式不正确')
              }
            }
          }
        ],
        vxNo: [
          { required: false, message: '请输入微信号', trigger: ['blur', 'change'] },
          {
            validator: ({ cellValue }) => {
              if (cellValue && !/^[^\u4e00-\u9fa5]+$/.test(cellValue)) {
                return new Error('微信号不能输入中文！')
              }
            }
          }
        ],
        isDefault: [
            { required: true, message: '请选择是否默认', trigger:'blur' }
        ]
      },
      showLoad: false,
      isEdit: false,
      pageLoading: false,
      invoiceColumns: [
        {
          props: 'invoiceHead',
          labels: '开票名称',
          widths: '100'
        },
        {
          props: 'taxNumber',
          labels: '纳税人识别号',
          widths: '100'
        },
        {
          props: 'bank',
          labels: '开户银行',
          widths: '100'
        },
        {
          props: 'bankAccount',
          labels: '银行账号',
          widths: '100'
        },
        {
          props: 'address',
          labels: '发票地址',
          widths: '100'
        },
        {
          props: 'phone',
          labels: '联系电话',
          widths: '80'
        },
        {
          props: 'email',
          labels: '邮箱',
          widths: '90'
        },
        {
          props: 'isMain',
          labels: '默认',
          widths: '60',
          slot: 'slot'
        }
      ],
      addressColumns: [
        {
          props: 'areaAddressDesc',
          labels: '区域',
          widths: '150'
        },
        {
          props: 'address',
          labels: '详细地址',
          widths: '150'
        },
        {
          props: 'contactPerson',
          labels: '联系人',
          widths: '70'
        },
        {
          props: 'contactPhone',
          labels: '联系电话',
          widths: '80'
        },
        {
          props: 'isDefault',
          labels: '默认',
          widths: '60',
          slot: 'slot'
        }
      ],
      contactColumns: [
        {
          props: 'name',
          labels: '姓名',
          widths: '100'
        },
        {
          props: 'job',
          labels: '职务',
          widths: '100'
        },
        {
          props: 'phone',
          labels: '手机号码',
          widths: '100'
        },
        {
          props: 'vxNo',
          labels: '微信',
          widths: '100'
        },
        {
          props: 'isDefault',
          labels: '默认',
          widths: '100',
          slot: 'slot'
        }
      ]
    };
  },
  computed: {
    mainTabs: {
      get() {
        return this.$store.state.mainTabs
      },
      set(val) {
        this.$store.commit('updateMainTabs', val)
      }
    },
    companyId() {
      return JSON.parse(window.localStorage.getItem('companyId')) || ''
    }
  },
  methods: {
    changePhone() {
      if(this.dataForm.phone) {
        if(this.dataForm.phone.length > 60) {
          this.dataForm.phone = this.dataForm.phone.slice(0, 60)
        }
        this.dataForm.phone = this.dataForm.phone.replace(/[^\d^\-]+/g, "")
      }
    },
    //提交
    submit() {
      this.validate().then(() => {
        // if(!this.dataForm.invoiceHeaderDtoList || !this.dataForm.invoiceHeaderDtoList.length) {
        //   this.$message.error('请添加开票信息！')
        //   return
        // }
        // if(!this.dataForm.addressDtoList || !this.dataForm.addressDtoList.length) {
        //   this.$message.error('请添加地址信息！')
        //   return
        // }
        // if(!this.dataForm.contactPersonDtoList || !this.dataForm.contactPersonDtoList.length) {
        //   this.$message.error('请添加联系人信息！')
        //   return
        // }
        this.showLoad = true
        this.dataForm.countryId = this.dataForm.areaAddress[0] || ''
        this.dataForm.provinceId = this.dataForm.areaAddress[1] || ''
        this.dataForm.cityId = this.dataForm.areaAddress[2] || ''
        this.dataForm.countyId = this.dataForm.areaAddress[3] || ''
        this.dataForm.addressDtoList.forEach(e=>{
          e.countryId = e.areaAddress[0] || ''
          e.provinceId = e.areaAddress[1] || ''
          e.cityId = e.areaAddress[2] || ''
          e.countyId = e.areaAddress[3] || ''
        })
        let url = '/company/certification'
        this.$post(url, this.dataForm).then(({data}) => {
          if(data.code === 'C0000') {
            this.$message.success(data.message || '操作成功！')
            this.isEdit = false
            if(data.result) {
              vueCookie.set('token', data.result.token, {
                domain: process.env.VUE_APP_DOADMIN
              });
              this.$utils.setSessionStorage('companyId', data.result.companyId)
            }
            this.init()
          }else{
            this.$message.error(data.message || '操作失败！')
          }
        }).finally(() => {
          this.showLoad = false
        })
      }).catch(() => {})
    },
    // 删除发票分录
    removeInvoiceRow (row, rowIndex) {
      this.$confirm('确定删除该行数据?','温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
            type: 'success',
            message: '删除成功',
            offset: 380,
            grouping: true,
        })
        this.$refs.elxEditableInvoice.remove(row)
        this.dataForm.invoiceHeaderDtoList.splice(rowIndex, 1)
      })
    },
    // 删除地址分录
    removeAddressRow (row, rowIndex) {
      this.$confirm('确定删除该行数据?','温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
            type: 'success',
            message: '删除成功',
            offset: 380,
            grouping: true,
        })
        this.$refs.elxEditableAddress.remove(row)
        this.dataForm.addressDtoList.splice(rowIndex, 1)
      })
    },
    // 删除联系人分录
    removeContactRow (row, rowIndex) {
      this.$confirm('确定删除该行数据?','温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
            type: 'success',
            message: '删除成功',
            offset: 380,
            grouping: true,
        })
        this.$refs.elxEditableContact.remove(row)
        this.dataForm.contactPersonDtoList.splice(rowIndex, 1)
      })
    },
    // 默认账号/地址
    isDefaultChange1 (v, row, obj, flag) {
      if (row.isMain) {
        this.dataForm[obj].map(item => {
          if (item !== row) {
              item.isMain=0
          }
        })
      }else{
        if(this.dataForm[obj].every(e=>!e.isMain)) {
          this.$message.error('至少要有一条默认！')
          row.isMain = 1
        }
      }
    },
    // 默认账号/地址
    isDefaultChange (v, row, obj, flag) {
      if (row.isDefault) {
        this.dataForm[obj].map(item => {
          if (item !== row) {
              item.isDefault=0
          }
        })
      }else{
        if(this.dataForm[obj].every(e=>!e.isDefault)) {
          this.$message.error('至少要有一条默认！')
          row.isDefault = 1
        }
      }
    },
    addInvoice() {
      this.dataForm.invoiceHeaderDtoList.push(
        {
          invoiceHead: '',
          taxNumber: '',
          bank: '',
          bankAccount: '',
          address: '',
          phone: '',
          email: '',
          isMain: !this.dataForm.invoiceHeaderDtoList || !this.dataForm.invoiceHeaderDtoList.length ? 1 : 0
        }
      )
    },
    addAddress() {
      this.dataForm.addressDtoList.push(
        {
          areaIdDesc: '',
          address: '',
          contactPerson: '',
          contactPhone: '',
          isDefault: !this.dataForm.addressDtoList || !this.dataForm.addressDtoList.length ? 1 : 0
        }
      )
    },
    addContact() {
      this.dataForm.contactPersonDtoList.push(
        {
          name: '',
          job: '',
          phone: '',
          vxNo: '',
          isDefault: !this.dataForm.contactPersonDtoList || !this.dataForm.contactPersonDtoList.length ? 1 : 0
        }
      )
    },
    // 校验函数
    validate () {
        return new Promise((resolve, reject) => {
            this.$refs.dataForm.validate((valid, fields) => {
                if (valid) {
                    this.$refs.elxEditableInvoice.validate(true).then(valid => {
                        if (valid) {
                            return reject(valid)
                        } else {
                            this.$refs.elxEditableAddress.validate(true).then(valid => {
                                if (valid) {
                                  return reject(valid)
                                } else {
                                  this.$refs.elxEditableContact.validate(true).then(valid => {
                                    if (valid) {
                                      return reject(valid)
                                    } else {
                                      return resolve()
                                    }
                                  }).catch(msg => {
                                      return reject(msg)
                                  })
                                }
                            }).catch(msg => {
                                return reject(msg)
                            })
                        }
                    }).catch(msg => {
                        return reject(msg)
                    })
                    
                } else {
                  return reject(fields)
                }
            })
        })
    },
    init() {
      this.pageLoading = true
      this.$get('/api/dictDetail/detailMaps', { dictName: 'customer_type' }).then(({ data }) => {
        if (data && data.code === 'C0000') {
          this.induList = data.result ? data.result.customer_type || [] : []
        }
      }).finally(() => {
        this.$post('/company/getByUser').then(({data}) => {
          if(data.code === 'C0000') {
            this.dataForm = data.result || {invoiceHeaderDtoList: [{isMain: 1}], addressDtoList: [{isDefault: 1}], contactPersonDtoList: [{isDefault: 1}]}
            this.dataForm.invoiceHeaderDtoList = this.dataForm.invoiceHeaderVoList || []
            this.dataForm.addressDtoList = this.dataForm.addressVoList || []
            this.dataForm.contactPersonDtoList = this.dataForm.contactPersonVoList || []
            this.contact = !!this.dataForm.phone || (!this.dataForm.phone && !this.dataForm.email)
            this.contact1 = !!this.dataForm.email
            if(this.dataForm.addressDtoList.length) {
              this.dataForm.addressDtoList.forEach(e=> {
                e.areaAddress = []
                if(e.countryId) {
                  e.areaAddress.push(e.countryId)
                }
                if(e.provinceId) {
                  e.areaAddress.push(e.provinceId)
                }
                if(e.cityId) {
                  e.areaAddress.push(e.cityId)
                }
                if(e.countyId) {
                  e.areaAddress.push(e.countyId)
                }
              })
            }
            this.dataForm.areaAddress = []
            if( this.dataForm.countryId) {
               this.dataForm.areaAddress.push( this.dataForm.countryId)
            }
            if( this.dataForm.provinceId) {
               this.dataForm.areaAddress.push( this.dataForm.provinceId)
            }
            if( this.dataForm.cityId) {
               this.dataForm.areaAddress.push( this.dataForm.cityId)
            }
            if( this.dataForm.countyId) {
               this.dataForm.areaAddress.push( this.dataForm.countyId)
            }
          }
        }).finally(() => {
          this.pageLoading = false
        })
      })
    },
  },
  mounted() {
    this.init()
  }
};
</script>

<style lang='scss'>

.enterprise {
  padding: 40px 100px;
  position: relative;
  .auth-img{
    position: absolute;
    top: 0;
    right: 100px;
  }
  &.isEdit{
    .basic-title{
        margin: 0px 0 0px;
    }
    .invoice-title{
      margin-top: 10px;
    }
  }
  .detail-basic{
    .el-col{
      height: 40px;
      line-height: 40px;
    }
  }
  h3{
    font-size: 18px;
    margin: 40px 0 40px;
  }
  .basic-title{
      margin: 0px 0 20px;
  }
  .invoice-title{
    margin-top: 25px;
  }
  .contact{
    width: 100%;
    height: 45px;
    background-color: #ecf7fd;
    border-radius: 8px;
    padding: 6px 14px;
    span.line{
      float: left;
      width: 2px;
      height: 20px;
      background-color: #ced9df;
      margin-top: 12px;
    }
    .contact-checkbox{
      width: 49%;
      float: left;
      padding: 7px 0 0 20px;
    }
  }
  .vxe-header--row{
    height: 45px;
  }
  .vxe-input.type--number {
    .vxe-input--extra-suffix{
      display: none;
    }
  }
  .addCargo{
      width: 100%;
      height: 45px;
      // margin-bottom:38px ;
      padding-left: 22px;
      background-color: #ecf7fd;
      text-align: left;
      font-size: 14px;
      line-height: 45px;
      
      .icon-svg{
          width: 24px;
          height: 24px;
          vertical-align: middle;
          cursor: pointer;
          color: #01458e;
      }
      span {
        display: inline-block;
        width: 90px;
        cursor: pointer;
      }
  }
  .enterprise-detail {
  }
  /* 清除浮动 */
  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ".";
    clear: both;
    height: 0;
  }

  * html .clearfix {
    zoom: 1;
  }

  *:first-child+html .clearfix {
    zoom: 1;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
}</style>