<template>
  <div class="coupon" style="" v-if="couponDialog.show">
    <div class="couponDialog">
      <i class="el-icon-error" @click="closecouponDialog"></i>
      <div class="content">
        <span class="fireworks"></span>
        <span class="ribbons" :style="{left: ribbonsWidth,top: '30px'}"></span>
        <div class="center">
          <div class="tip">
            恭喜您，获得
            <span style="font-family: '宋体';font-weight: 600; font-size: 34px">
              {{ amountTotal }} </span
            >元现金券
            <p></p>
          </div>
          <ul style="margin-top: 30px" :style="{'width': ulWidth + 'px', 'position': 'relative', 'left': '50%', 'margin-left': -(ulWidth/2) + 'px'}">
            <li  :class="{'li': true, 'lastLi': couponAllList.length== index + 1}" v-for="(item, index) in couponAllList" :key="index">
              <!-- <p style="color: red; font-size: 16px; text-align: center">签约成功优惠券 {{signedCouponsList.length}} 张</p> -->
              <div class="ulItem">
                <!-- <span class="number">x{{signedCouponsList.length}}</span> -->
                <span class="CNY"></span>
                <span class="amount">{{ item.amount }}</span>
                <!-- <span class="use"></span> -->
                <span class="bizeType">{{item.title}}</span>
                <span class="date">{{ dateFormat(item.validStartTime, item.validEndTime)}}</span>
              </div>
            </li>
            <!-- <li class="li" v-if="monthlyCouponsList.length > 0">
              <p style="color: red; font-size: 16px; text-align: center">每月首日优惠券{{monthlyCouponsList.length}}张</p>
              <div class="ulItem">
                <span class="number"> x{{monthlyCouponsList.length}}</span>
                <span class="CNY"></span>
                <span class="amount">{{ monthlyCouponsList[0].amount}}</span>
                <span class="use"></span>
                <span class="bizeType">{{activityCouponsList[0].title}}</span>
                <span class="date">{{ dateFormat(monthlyCouponsList[0].validStartTime, monthlyCouponsList[0].validEndTime)}} </span>
              </div>
            </li>
            <li class="li lastLi" v-if="activityCouponsList.length > 0">
              <p style="color: red; font-size: 16px; text-align: center">大型活动优惠券 {{activityCouponsList.length}} 张</p>
              <div class="ulItem">
                <span class="number"> x{{activityCouponsList.length}}</span>
                <span class="CNY"></span>
                <span class="amount">{{ activityCouponsList[0].amount}}</span>
                <span class="use"></span>
                <span class="bizeType">{{activityCouponsList[0].title}}</span>
                <span class="date">{{  dateFormat(activityCouponsList[0].validStartTime, activityCouponsList[0].validEndTime)}}</span>
              </div>
            </li> -->
            <li style="clear: both"></li>
          </ul>
        </div>
      </div>

       <p class="receive" @click="receive">一键领取</p>
    </div>
    <el-dialog
      title="请选择业务类型"
      class="bizTypeDialog"
      v-model="showDialog"
      width="175px"
      :before-close="() => {this.showDialog = false}"
    >
      <el-row style="text-align: center">
        <span
          :class="{ ischecked: checked, radio: true }"
          @click="
            () => {
              checked = !checked;
              checked1 = false;
            }
          "
        >
          <i></i> 进口</span
        >
      </el-row>
      <el-row style="text-align: center;margin-top: 10px">
        <span
          :class="{ ischecked: checked1, radio: true }"
          @click="
            () => {
              checked1 = !checked1;
              checked = false;
            }
          "
        >
          <i></i> 出口</span
        >
      </el-row>
      <template #footer class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button
          type="primary"
          :disabled="!checked && !checked1"
          :loading="isAddLoading"
          @click="confirmSel"
          >确 定</el-button
        >
      </template>
    </el-dialog>
    <div class="couponDialogMask">
      <div class="mask"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ab-coupon",
  props: {
    couponDialog: {
      type: Object,
      default: {
        show: false,
        couponList: []
      }
    }
  },
  data() {
    return {
      showDialog: false,
      isAddLoading: false,
      checked: false,
      checked1: false
    };
  },
  computed: {
    couponAllList() {
      return this.couponDialog.couponList.filter((item, index) => {
        return index <= 2
      })
    },
    ulWidth() {
      let num = this.couponDialog.couponList.length
      if(num == 1) {
        return 267
      }
      if(num > 3) {
        num = 3
      }
      return 267 * num + (110 * (num - 1))
    },
    ribbonsWidth() {
      let num = this.couponDialog.couponList.length > 2 ? 3 : this.couponDialog.couponList.length
      if(num == 1) {
        return 628 + 'px'
      }
      if(num == 2) {
        return 818 + 'px'
      }
      if(num == 3) {
        return 1004 + 'px'
      }
    },
    //签约的优惠券
    signedCouponsList() {
      let arr = []
      if(this.couponDialog.couponList.length > 0) {
        this.couponDialog.couponList.forEach(ele=>{
          if(ele.offeredType == '01') {
            arr.push(ele)
          }
        })
      }
      return arr
    },
    // 每月首日的优惠券
    monthlyCouponsList() {
      let arr = []
      if(this.couponDialog.couponList.length > 0) {
        this.couponDialog.couponList.forEach(ele=>{
          if(ele.offeredType == '02') {
            arr.push(ele)
          }
        })
      }
      return arr
    },
    // 大型活动的优惠券
    activityCouponsList() {
      let arr = []
      if(this.couponDialog.couponList.length > 0) {
        this.couponDialog.couponList.forEach(ele=>{
          if(ele.offeredType == '03') {
            arr.push(ele)
          }
        })
      }
      return arr
    },
        bizType() {
            return this.$store.state.common.haveBizType
        },
    amountTotal() {
      let num = 0;
      if (this.couponDialog.couponList.length > 0) {
        this.couponDialog.couponList.forEach(ele => {
          num += ele.amount;
        });
      }
      return num;
    }
  },
  mounted() {},
  methods: {
    receive() {
      this.$emit('closeDialog')
    },
        dateFormat(start, end) {
          if(!start || !end) {
            return ''
          }
            let ss = start.indexOf('-') + 1
            let se = start.indexOf(' ')
            let es = end.indexOf('-') + 1
            let ee = end.indexOf(' ')
            return start.slice(ss, se) + '~' + end.slice(es, ee)
        },
    closecouponDialog() {
      if (this.showDialog) {
        return;
      }
      this.couponDialog.show = false;
        this.$emit('closeDialog')
    },
    confirmSel() {
      this.jump(this.checked);
    },
    // 跳转进出口
    jump(val) {
      this.isAddLoading = true;
      let path = val
        ? "/orders/import-orders-detail"
        : "/orders/out-orders-detail";
        // 获取关联委托方
      this.$get(
        `/oms/client/entrust${val ? "Im" : "Ex"}port/getRelatePrincipal`
      )
        .then(res => {
          if (res.data.code === "C0000") {
            this.$router.push({
              path,
              query: {
                status: "add",
                cache: false,
                principalId: res.data.result.id
              }
            });
            this.couponDialog.show = false;
            this.showDialog = false;
            this.$emit('closeDialog')
          } else {
            this.isClient = true;
          }
        })
        .finally(e => {
          this.isAddLoading = false;
        }, 200);
    }
  }
};
</script>

<style lang="scss">
.bizTypeDialog {
  top: -60px !important;
  .el-dialog__body {
    min-height: 0;
  }
  .radio {
    font-size: 20px;
    cursor: pointer;
    i {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      border: 1px solid #ccc;
    }
  }
  .ischecked {
    color: #019fe9;
    i {
      background-color: #019fe9;
    }
  }
}
.couponDialogMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1999;
  overflow: auto;
  margin: 0;
  .mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: #000;
  }
}
.couponDialog {
  position: fixed;
  width: 1100px;
  left: 50%;
  margin-left: -550px;
  margin-top: 250px;
  top: -80px;
  z-index: 99999;
  padding-right: 79px;
  .receive{
    position: absolute;
    text-align: center;
    line-height: 60px;
    color: #f55700;
    font-size: 22px;
    bottom: -80px;
    right: 50%;
    width: 200px;
    margin-right: -61px;
    height: 60px;
    background: url("../assets/img/integral/receive.png");
    background-size: 100% 100%;
    cursor: pointer;
  }
  .el-icon-error {
    position: absolute;
    color: #ccc;
    right: 0;
    top: 0;
    font-size: 24px;
    cursor: pointer;
  }
  .el-icon-error:hover {
    color: #fff;
  }
  .el-dialog__body {
    min-height: 0;
    padding: 0;
    // background-color: #000;
    // opacity: .5;
  }
  .el-dialog__header {
    // background-color: #000;
    // opacity: .5;
  }
  .content {
    margin-top: 80px;
    position: relative;
    .fireworks,
    .ribbons {
      position: absolute;
    }
    .fireworks {
      width: 80px;
      height: 80px;
      background: url("../assets/img/integral/fireworks.png");
      top: -38px;
      right: 200px;
    }
    .ribbons {
      width: 46px;
      height: 103px;
      background: url("../assets/img/integral/ribbons.png");
      top: 0;
      z-index: 3000;
    }
    .center {
      width: 1021px;
      .tip {
        color: red;
        text-align: center;
        font-size: 30px;
        line-height: 30px;
      }
      ul {
        top: 20px;
        height: 259px;
        .li {
          float: left;
          margin-right: 110px;
          .ulItem {
            position: relative;
            width: 267px;
            height: 263px;
            background: url("../assets/img/integral/bgd.png");
            .number{
              color: red;
              font-size: 30px;
              position: absolute;
              right: -40px;
              top: 180px;
            }
            .bizeType{
              position: absolute;
              right: 60px;
              bottom: 38px;
              font-size: 10px;
              color: #fff;
            }
            .CNY {
              position: absolute;
              left: 48px;
              top: 66px;
              width: 28px;
              height: 28px;
              background: url("../assets/img/integral/CNY.png");
            }
            .amount {
              color: #019fe9;
              position: absolute;
              font-family: PingFangSC-Regular, 'Microsoft YaHei';
              font-weight: 600;
              font-size: 70px;
              top: 85px;
              left: 64px;
            }
            .use {
              // cursor: pointer;
              position: absolute;
              width: 146px;
              height: 26px;
              left: 50%;
              margin-left: -73px;
              top: 140px;
              background: url("../assets/img/integral/use.jpg");
            }
            .date {
              position: absolute;
              bottom: 13px;
              font-size: 16px;
              right: 38px;
              color: #fef102;
            }
          }
        }
        .lastLi {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
