<template>
  <el-dialog v-if="visible" title="更换型号" width="840px" class="import-statement-dialog" :model-value="visible" top="5vh"
    :close-on-click-modal="false" :before-close="cancel" @close="cancel">
    <div class="org-demo">
      <p class="title">原始需求</p>
      <p>{{ dataFrom.row.bomData.rowData }}</p>
    </div>
    <ul class="brand-heard clearfix">
      <li :class="{'active': item.active}" v-for="item in brandList" :key="item" @click="brandChange(item)">{{ item.brand }}</li>
    </ul>
    <div class="search">
      <el-input placeholder="请输入关键参数进行搜索" clearable v-model="seachForm" @input="changeList" @clear="getDataList" />
      <el-button class="btn" type="primary" @click="changeList">
        <el-icon class="icon-search">
          <Search />
        </el-icon>
      </el-button>
    </div>
    <ab-table class="changeModelTable" border ref="changeModelTable" stripe showOrder showSelection selectionWidth="45" indexLabel="序号"
      indexWidth="60" :height="500" :handleSelectionChange="handleSelectionChange" widths="120" isFilter="operator"
      :column-list="columns" :table-list="tableData" :dataListLoading="isloading" :arraySpanMethod="arraySpanMethod">
      <template #recommend="scope">
        <div class="col-item">
          <p>型号： {{ scope.row.mpn }}</p>
          <p>品类： {{ scope.row.categoryName }}</p>
          <p>品牌： {{ scope.row.brand }}</p>
          <p>封装： {{ scope.row.productPackaging }}</p>
          <p>参数： {{ scope.row.parameter }}</p>
          <!-- <p class="tishi" v-if="!scope.row.productSpec"><el-icon>
              <WarningFilled />
            </el-icon>参数缺失，请补充参数</p> -->
        </div>
      </template>
      <template #number="scope">
        <div class="col-item">
          <p>起订量：{{ scope.row.priceData?.min_buy_num }} </p>
          <p>增量：{{ scope.row.priceData?.min_increment }} </p>
          <p>交期：{{ scope.row.priceData?.delivery_time }}</p>
          <p>库存：{{ scope.row.priceData?.stock }}</p>
        </div>
      </template>
      <template #price="scope">
        <div class="col-item" v-if="isloading === false">
          <p v-if="scope.row.priceData?.price > 0">
            {{ scope.row.priceData?.price }}
          </p>
          <p class="tishi" v-else>
            <el-icon>
              <WarningFilled />
            </el-icon>暂无报价，无法下单
          </p>
        </div>
        <div v-else></div>
      </template>
    </ab-table>


    <template #footer>
      <div class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm" :loading="isloading">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'import-statement-overdue-dialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    dataFrom: {
      type: Object
    }
  },
  data() {
    return {
      isloading: false,
      selected: [],
      columns: [
        {
          props: 'recommend',
          labels: '推荐商品',
          align: 'center',
          widths: 200,
          slot: 'slot'
        },
        {
          props: 'number',
          labels: '起订量/交期',
          widths: 125,
          align: 'center',
          slot: 'slot'
        },
        {
          props: 'price',
          labels: '单价(含税)',
          widths: 135,
          align: 'center',
          slot: 'slot'
        },
      ],
      brandList: [],
      tableData: [],
      oldTableData: [],
      seachForm: '',
      timerId: ''
    }
  },
  watch: {
    visible: {
      handler(value) {
        this.isloading = true
        this.seachForm = ''
        if(!value) return
        let that = this
        that.brandList = []
        this.dataFrom.data.forEach(e=>{
          if(that.brandList.every(el=>el.brand != e.brand)) {
            that.brandList.push({brand: e.brand, active: false})
          }
        })
        that.tableData = this.dataFrom.row.matchResult.relatedModels;

        // 价格的参数
        const queryList = this.dataFrom.row.matchResult.relatedModels.map(e=>{
          return {
            mpn: e.mpn,
            quantity: this.dataFrom.row.bomData.usageNum || '',
          }
        })

        // 请求价格接口
        this.$post('/products/price', { queryList }, 'api').then(({data}) => {
            if(data.code === 'C0000') {
                if (data.result) {
                    const priceData = data.result;

                    // 合并数据
                    that.tableData = that.tableData.map(o => {
                      return {
                        ...o,
                        priceData: priceData.find(i => i.mpn === o.mpn)
                      }
                    })

                    //记录初始数据
                    this.oldTableData = that.tableData;
                } else {
                    this.$message.error(data.message);
                }
                this.isloading = false;
            }
        })
      },
      deep: true,
    }
  },
  methods: {
    brandChange(item) {
      item.active = !item.active
      let arr = []
      for (let index = 0; index < this.brandList.length; index++) {
        const element = this.brandList[index];
        if(element.active) {
          arr.push(element.brand)
        }
      }
      if(!arr.length) {
        this.tableData = this.oldTableData;
        return
      }
      this.tableData = this.dataFrom.data.filter(e=>arr.indexOf(e.brand) != -1)
      // 价格的参数
      const queryList = this.tableData.map(e=>{
        return {
          mpn: e.mpn,
          quantity: this.dataFrom.row.bomData.usageNum || '',
        }
      })
      // 请求价格接口
      this.$post('/products/price', { queryList }, 'api').then(({data}) => {
          if(data.code === 'C0000') {
              if (data.result) {
                  const priceData = data.result

                  // 合并数据
                  this.tableData = this.tableData.map(o => {
                    return {
                      ...o,
                      priceData: priceData.find(i => i.mpn === o.mpn)
                    }
                  })
              } else {
                  this.$message.error(data.message)
              }
          }
      })
      this.seachForm = ''
    },
    // 推荐购买数
    cancel() {
      this.$emit('cancel')
    },
    handleSelectionChange(val) {
      this.selected = val
    },
    confirm() {
      const isExitPrice = this.selected.every(item => item.priceData && !item.priceData.price)

      if (this.selected && this.selected.length > 0) {
        if(this.selected.length != 1) {
          this.$message.error('只能勾选一条！')
          return
        }
        if(isExitPrice) {
          this.$message.error('请选择有价格的型号！')
          return
        }
        this.$emit('confirm', { selectedModelMpn: this.selected[0].mpn })
        this.cancel()
      } else {
        this.$message({
          type: 'warning',
          message: '请先勾选数据！',
          offset: 380,
          grouping: true,
        })
      }

    },
    changeList () {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.getDataList();
      }, 500)
    },
    getDataList () {
      if (this.seachForm.trim() == '') {
        this.tableData = this.oldTableData;
      } else {
        this.tableData = []
        let params = this.seachForm

        // 搜索接口
        this.$post('/bom/search', { keyword: params }, 'api').then(({data}) => {
          if(data.code === 'C0000') {
            if (data.result) {
              this.tableData = data.result

              // 价格的参数
              const queryList = data.result.map(e=>{
                return {
                  mpn: e.mpn,
                  quantity: this.dataFrom.row.bomData.usageNum || '',
                }
              })

              // 请求价格接口
              this.$post('/products/price', { queryList }, 'api').then(({data}) => {
                  if(data.code === 'C0000') {
                      if (data.result) {
                          const priceData = data.result

                          // 合并数据
                          this.tableData = this.tableData.map(o => {
                            return {
                              ...o,
                              priceData: priceData.find(i => i.mpn === o.mpn)
                            }
                          })
                      } else {
                          this.$message.error(data.message)
                      }
                  }
              })

            } else {
              this.$message.error(data.message)
            }
            this.isloading = false
          }
        })
      }
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (!row.priceData?.min_buy_num) { // 无价格
        if (columnIndex == 3) {
          return {
            rowspan: 0,
            colspan: 0
          }
        } else if (columnIndex == 4) {
          return {
            rowspan: 1,
            colspan: 2
          }
        }
      }
    },
  }
}

</script>

<style lang="scss">
.import-statement-dialog {
  .org-demo{
    padding: 28px 10px 10px 20px;
    border-radius: 10px;
    background-color: #f5f5f5;
    margin-bottom: 20px;
    position: relative;
    .title{
      padding: 2px 16px 2px 20px;
      background-color: #ddd;
      position: absolute;
      left: 0;
      top: 0;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .brand-heard{
    // padding-left: 10px;
    li{
      float: left;
      margin-right: 20px;
      padding: 6px 30px;
      border-radius: 8px;
      margin-bottom: 20px;
      border: 1px solid #9f9f9f;
      &.active{
        border: 2px solid var(--el-color-primary);
        color: var(--el-color-primary);
        box-sizing: border-box;
        padding: 5px 29px;
      }
    }
  }
  .col-item {
    text-align: left;
    padding: 12px 8px;
  }

  .changeModelTable {
    width: 800px;
    height: 500px;
    overflow: auto;
  }

  .tishi {
    color: #FF0000;
    text-align: center;

    .el-icon {
      font-size: 16px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .col-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    .del-icon {
      color: #FF453A;
      margin-left: 10px;
    }

    .sure-icon {
      margin-left: 10px;
    }
  }
  .search {
    margin-bottom: 20px;
    display: flex;
    .el-input__wrapper {
      border:1px solid #c4c6cf;
      border-radius: 8px 0 0 8px;
    }

    .btn {
      height: 48px;
      min-width: 0 !important;
      width: 80px !important;
      padding: 0;
      border-radius: 0 8px 8px 0;

      .icon-search {
        font-size: 32px;
      }
    }
  }
}
</style>
