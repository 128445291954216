<template>
  <el-dialog
    :title="
      params.items && params.items.length ? params.items[0].title : '附件管理'
    "
    v-model="params.show"
    width="700px"
    custom-class="client-dialog-quote"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :append-to-body="appendToBody"
  >
    <div v-if="isReload">
      <el-upload
        v-if="isUpload"
        class="upload-demo"
        drag
        action
        :before-upload="beforeUpload"
        multiple
      >
        <span class="upload-icon">
          <ab-svg name="upload-file"></ab-svg>
        </span>
        <div class="el-upload__text">
          点击 <em>上传文件</em>，或将文件拖到此窗口即可上传！
        </div>
      </el-upload>
      <div class="file-content" :class="{ 'no-upload': !isUpload }">
        <div class="file-item" v-for="(obj, index) in fileList" :key="index">
          <el-row>
            <el-col :span="12">
              <div class="tel" @click="handerOreview(obj)">{{obj.disName}}</div>
            </el-col>
            <el-col :span="12">
              <div class="reference">
                <span @click="handerDownload(obj)">
                  <ab-svg name="my-upload" class="one"></ab-svg>
                </span>
                <span v-if="isDelete" @click="handlerDelete(obj)">
                  <ab-svg name="my-off"></ab-svg>
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <template v-if="showFoot" #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="confirm">确 定</el-button>
        <el-button @click="params.show = false">取 消</el-button>
      </span>
    </template>
    <ab-preview :preview="preview"></ab-preview>
  </el-dialog>
</template>

<script>
export default {
  name: "ab-upload-once",
  data() {
    return {
      accept:
        ".bmp,.jpg,.png,.tif,.gif,.pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.txt,.zip,.rar",
      fileList: [],
      isReload: true,
      preview: {
        show: false
      }
    };
  },
  props: {
    fileTitle: {
      // 标题
      type: String,
      default: "附件管理"
    },
    params: {
      type: Object,
      default: {
        show: false
      }
    },
    isUpload: {
      type: Boolean,
      default: true
    },
    isDelete: {
      type: Boolean,
      default: true
    },
    showFoot: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    params: {
      handler: function(val, oldval) {
        if (val.show) {
          this.getList();
        }
      },
      deep: true
    }
  },
  methods: {
    confirm() {
      this.$message({
        type: 'success',
        message: "操作成功",
        grouping: true,
        offset: 380
      })
      this.params.show = false;
    },
    getSuffix(obj) {
      if (!obj) {
        return;
      }
      let index = obj.lastIndexOf(".") || 0;
      let svg = obj.substring(index + 1).toLowerCase();
      return this.accept.indexOf(svg) == -1 ? "accessory" : svg;
    },
    validateField(types = []) {
      let items = this.items.filter(e => types.includes(e.items[0].attachType));
      for (let option of items) {
        if (option.required) {
          if (!this.allList[option.items[0].attachType].length) {
            this.$message({
              type: 'error',
              message: `请上传${option.title}附件!`,
              grouping: true,
              offset: 380
            })
            return false;
          }
        }
      }
      return true;
    },
    upload(data, item) {
      return this.$http({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: this.$adornUrl('/oms/billAttach/uploadFile'),
        timeout: 20000 * 60,
        data: data,
        onUploadProgress: function (progress) {
        }
      })
    },
    getList() {
      this.fileList = [];
      this.isloading = true;
      this.$post("/oms/billAttach/queryBySourceData", {
        sourceBillType: this.params.sourceBillType,
        sourceBillId: this.params.sourceBillId
      })
        .then(res => {
          this.fileList = res.data.result[this.params.items[0].attachType];
        })
        .finally(e => {
          setTimeout(() => {
            this.reload();
            this.isloading = false;
          });
        }, 500);
    },
    handlerDelete(obj) {
      this.$confirm("确定要删除吗，删除后无法恢复，请谨慎操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.isloading = true;
          var id = obj.id
          this.$post("/oms/billAttach/del", { ids: [id] }).then(res => {
            if (res.data && res.data.code == "C0000") {
              this.$message({
                type: "success",
                message: "删除成功！",
                grouping: true,
                offset: 380
              });
              this.fileList = this.fileList.filter(e => {
                return e.id != id;
              });
              this.getList();
            }
          });
        })
        .finally(e => {
          this.isloading = false;
        });
    },
    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true;
      });
    },
    handerOreview(obj) {
      this.preview.fileUrl = obj.fileUrl || obj.viewFileUrl;
      this.preview.show = true;
    },
    handerDownload(obj) {
      let eleLink = document.createElement('a')
      eleLink.download = ''
      eleLink.style.display = 'none'
      eleLink.href = obj.fileUrl
      document.body.appendChild(eleLink)
      eleLink.click()
      document.body.removeChild(eleLink)
    },
    beforeUpload(file, i) {
      let fd = new FormData();
      let getTime = new Date().getTime() + "-" + i;
      let item = { _$index: getTime, percentage: 0, uploadStatus: undefined };
      fd.append("file", file);
      fd.append("v", getTime);
      fd.append("attachType", this.params.items[0].attachType);
      fd.append("coreBillIds", this.params.coreBillIds || "");
      fd.append("coreBillNumbers", this.params.coreBillNumbers || "");
      fd.append("sourceBillId", this.params.sourceBillId);
      fd.append("sourceBillNumber", this.params.sourceBillNumber);
      fd.append("sourceBillType", this.params.sourceBillType);
      this.upload(fd, item)
        .then(res => {
          if (res.data && res.data.code === "C0000") {
            this.$emit("fileList");
            this.$message({
              type: 'success',
              message: "上传成功！",
              grouping: true,
              offset: 380
            })
            this.getList();
          }
          setTimeout(() => {
            this.reload();
          }, 500);
        })
        .catch(res => {
          if (res && res.data) {
            this.$message({
              type: 'error',
              message: res.data.message,
              grouping: true,
              offset: 380
            })
          }
        });
      return false;
    }
  }
};
</script>

<style lang="scss">
.client-dialog-quote {
  .el-dialog__body {
    padding: 0 60px 45px 60px !important;
    .file-content {
      max-height: 400px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
      }
      &.no-upload {
        margin-top: 20px;
      }
    }
  }
  .upload-icon {
    .icon-svg {
      width: 85px;
      height: 64px;
      margin-bottom: 10px;
    }
  }
  .el-upload-dragger {
    border: none !important;
    width: 100%;
    button {
      width: 100px;
    }
  }
  .upload-demo {
    margin-top: 10px;
  }
  .el-upload-dragger {
    background-color: #ffffff;
    border: none;
  }
  .el-upload__text {
    padding: 10px;
    width: 100%;
  }
  .el-upload--text {
    width: 100%;
  }

  .file-item {
    background-color: #F0F2F5;
    font-size: 14px;
    height: 35px;
    line-height: 35px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .tel {
    text-align: left;
    margin-left: 17px;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
      color: #0B7AFF;
    }
  }

  .reference {
    text-align: right;
    margin-right: 17px;
    .icon-svg {
      width: 19px;
      height: 19px;
      margin-left: 7px;
      vertical-align: text-top;
      cursor: pointer;
    }
  }
}
</style>
