<template>
    <el-dialog title="修改物料清单" width="480px" @open="openInit" :model-value="visible"
        :close-on-click-modal="false" @close="cancel">
        <el-form :model="updateData" :rules="rules" ref="updateDataRef">
            <el-form-item label="用户物料编码" prop="userProductCode">
                <el-input v-model="updateData.userProductCode" placeholder="请输入物料编码"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" :loading="btnLoading" @click="confirm">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: 'update-material-dialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
            required: true
        },
        data: {
			type: Object,
			default: () => {
				return {};
			},
		}
    },
    data() {
        return {
            btnLoading: false,
            updateData: {},
            rules: {
                userProductCode: [{ required: true, message: '请输入物料编码', trigger: 'blur' }]
            }
        }
    },
    methods: {
        openInit() {
            this.updateData = Object.assign({}, this.data)
        },
        reset() {
            this.$nextTick(() => {
                this.$refs.updateDataRef.resetFields()
            })
        },
        cancel() {
            this.$emit('cancel')
            this.reset()
        },
        confirm() {
            this.$refs.updateDataRef.validate(valid => {
                if (valid) {
                    this.btnLoading = true
                    this.$put(`/user-materials/${this.updateData.userMaterialId}`, { userProductCode: this.updateData.userProductCode }, 'api').then(res => {
                        this.$message.success('修改成功')
                        this.$emit('success')
                        this.cancel()
                    }).finally(() => {
                        this.btnLoading = false
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
