<template>
    <div class="message-list">
        <el-drawer v-model="drawer.show" :before-close="handleClose">
            <el-tabs v-model="activeName" class="client-tabs" @tab-click="handleMsg">
                <el-tab-pane label="全部" name="all">

                </el-tab-pane>
                <el-tab-pane label="已读消息" name="read" value="3">
                </el-tab-pane>
                <el-tab-pane label="未读消息" name="unread">
                    <template #label>
                        <el-badge :value="messageTotal" class="item" :hidden="messageTotal == 0">
                            未读消息
                        </el-badge>
                    </template>
                </el-tab-pane>

            </el-tabs>
        <div class="message__content" v-infinite-scroll="infinite">
            <div class="message-all" v-for="(obj,idx) in tabData" @click="getFileRouter(obj)" :key="idx">
                <div>
<!--                    <div class="title">-->
<!--                        <ab-svg name="my-fujian" v-if="obj.typeName == '附件消息'"></ab-svg>-->
<!--                        <ab-svg name="my-gongzuo" v-else></ab-svg>-->
<!--                        -->
<!--                        <h5>{{obj.typeName}}</h5>-->
<!--                        <i v-if="obj.status != 1"></i>-->
<!--                        <div>{{obj.sendtime}}</div>-->
<!--                    </div>-->
                    <div class="notice">{{obj.title}} {{obj.createdAt}}</div>
                    <p>{{obj.content}}</p>
                </div>
                <ul>
<!--                    <li @click="download(obj)" v-if="obj.typeName == 'FILE' && obj.msgUrl">下载</li>-->
                    <li @click="changeState(obj.notificationId)" v-if="obj.isRead === 0">
                        已读
                    </li>
                    <li @click="deleteItem(obj.notificationId)">删除</li>
                </ul>
            </div>
        </div>

        </el-drawer>
    </div>
</template>

<script>
export default {
    data () {
        return {
            page: {
                currentPage: 1,
                pageSize: 10,
                total: 0
            },
            tabData: [],
            activeName: 'all',
            state: '', //消息类型
        }
    },
    props: {
        drawer: {
            type: Object,
            default: {}
        }
    },
    computed: {
        messageList: {
            get() {
                return this.$store.state.messageList
            },
            set(val) {
                this.$store.commit('SET_MESSAGE_LIST', val)
            }
        },
        messageTotal: {
            get() {
                return this.$store.state.messageTotal
            },
            set(val) {
                this.$store.commit('SET_MESSAGE_TOTAL', val)
            }
        },
    },
    created() {
      this.$get('/notifications', {pageNum: 1, pageSize: 10, isRead: 'isUnread'}, 'api').then(({data}) => {
        if (data && data.code == 'C0000') {
          this.messageList = data.result.list.data
          this.page.total = data.result.list.pagination.total
          this.messageTotal = data.result.unreadCount
        }
      })
    },
    watch: {
        "drawer.show": {
            handler (val, oldVal) {
                if (val) {
                    this.tabData = []
                    this.activeName = 'all'
                    this.getDataList('init')
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        getDataList (type) {
            let isRead = this.state;
            let data = {
                pageNum: this.page.currentPage,
                pageSize: this.page.pageSize,
                isRead: isRead
            }
            this.$get('/notifications', data, 'api').then(({data}) => {
                if (data && data.code == 'C0000') {
                  this.tabData = this.tabData.concat(data.result.list.data)
                  this.page.total = data.result.list.pagination.total
                  this.messageTotal = data.result.unreadCount
                }
            })
        },
        handleClose () {
            this.page.currentPage = 1
            this.tabData = []
            this.drawer.show = false
        },
        changeState (id) {
            this.$post(`/notifications/${id}/mark-as-read`, {}, 'api').then(({data}) => {
                if (data && data.code == 'C0000') {
                this.$emit('mesList')
                this.page.currentPage = 1
                this.page.total = 0
                this.tabData = []
                this.getDataList()
                }
            })
        },
        deleteItem (id) {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$delete(`/notifications/${id}`, null,'api').then(({data}) => {
                    if (data && data.code == 'C0000') {
                        this.page.currentPage = 1
                        this.page.total = 0
                        this.tabData = []
                        this.getDataList()
                        this.$emit('mesList')
                        this.$message({
                            type: 'success',
                            message: data.message,
                            offset: 380,
                            grouping: true,
                        })
                    }
                })
            })
        },
        download (row) {
            if (row.typeName === 'FILE') {
                this.$post('/oms/billAttach/compress', {sourceBillId: row.billId, sourceBillType: ''}).then(({data}) => {
                    if (data && data.code == 'C0000') {
                        let eleLink = document.createElement('a')
                        eleLink.download = ''
                        eleLink.style.display = 'none'
                        // 字符内容转变成blob地址
                        eleLink.href = data.result
                        // 触发点击
                        document.body.appendChild(eleLink)
                        eleLink.click()
                        // 然后移除
                        document.body.removeChild(eleLink)
                    }
                })
            } else if (row.msgUrl) {
                if (row.status === 0) {
                    this.markReaded(val.id)
                }
                let route = this.$utils.getTabDataByUrl(row.msgUrl, true)
                if (route.type == 'router') {
                    this.$router.push({
                        path: route.path,
                        query: route.query
                    })
                } else {
                    window.open(route.path)
                }
            }
        },
        markReaded (id) {
            this.$post('/message/msgInmail/consumeMsg', [id]).then(res => {
                if (res.data && res.data.code === 'C0000') {
                    this.getDataList()
                }
            })
        },
        getFileRouter (row) {
            /* if (row.msgUrl) {
                let route = this.$utils.getTabDataByUrl(row.msgUrl, true)
                if (route.type === 'router') {
                    this.$router.push({
                        path: route.path,
                        query: route.query
                    })
                }
            } */
            if (row.typeName === 'FILE') {
                this.download(row)
            }
            if (row.status == 0) {
                let path = 'order-detail'
                if(row.typeId == 'INQUIRY') {
                    path = '/inquire-detail'
                }else if(row.typeId == 'INVOICING' || row.typeId == 'INVOICED') {
                    path = '/invoice-detail'
                }
                this.$router.push({
                    path,
                    query: {
                        type: 'view',
                        cache: false,
                        id: row.billId
                    }
                })
                this.handleClose()
                this.changeState(row.notificationId)
            }
        },
        handleMsg () {
            setTimeout(() => {
                this.page.currentPage = 1
                this.page.total = 0
                this.tabData = []
                let state = ''
                if (this.activeName == 'read') {
                    // state = 1
                    this.state = 'isRead'
                } else if (this.activeName == 'unread') {
                    // state = 0
                    this.state = 'isUnread'
                }else{
                    this.state = 'all'
                }
                this.getDataList()
            }, 50)
        },
        //无限滚动
        infinite(){
            if(this.page.currentPage * this.page.pageSize < this.page.total){
                this.page.currentPage++
                this.getDataList()
            }
        }
    }
}
</script>

<style lang="scss">
.message-list {
    .el-drawer__header {
        position: absolute;
        right: 20px;
        z-index: 9;
    }
    .el-tabs__nav-wrap::after {
        height: 0px;
    }
    .client-tabs{
        padding-left: 20px
    }
    .el-badge__content{
        top: 7px !important;
        right: 3px !important;
    }
}
.message__content {
    .message-all,.message-read,.message-unread {
        position: relative;
        padding: 0 20px;
        & > div {
            padding: 20px 0;
            border-bottom: 1px solid #f0f2f5;
            .title {
                H5 {
                    display: inline-block;
                    font-size: 18px;
                    color: #000;
                }
                .icon-svg {
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                    vertical-align: middle;
                }
                i {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    margin-left: 5px;
                    border-radius: 50%;
                    background-color: #ff0000;
                    margin-bottom: 3px;
                }
                div {
                    float: right;
                }
            }

            .notice {
                margin: 13px 0 15px;
                color: #000;
                font-size: 16px;
            }

            p {
                font-size: 14px;
                color: #757575;
            }
        }
        ul {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px 0;
        background-color: #000;
        border-bottom-left-radius: 20px;
        li {
            float: left;
            padding: 0 20px;
            color: #fff;
            border-right: 1px solid #fff;
            cursor: pointer;
            &:last-child {
            border-right: 0px;
            }
        }
        }

        &:hover {
        background-color: #f2f7ff;
        cursor: pointer;
        ul {
            display: block;
        }
        }
        // &:not(:first-child) > div {
        //     border-top: 1px solid #f0f2f5;
        // }
        .el-tabs__header {
            margin-bottom: 20px;
        }
    }

}
.msg-dingyue {
    position: absolute;
    right: 15%;
    top: 25px;
    width: 60px;
    height: 30px;
    z-index: 9;
    cursor: pointer;
    .icon-svg {
        width: 16px;
        height: 16px;
        vertical-align: bottom;

    }
}
</style>
