<template>
	<div class="ai-make-order">
		<el-dialog title="上传附件" width="650px" class="ai-make-order-upload" v-model="uploadDialog.show"
			:before-close="closeUploadDialog" append-to-body :close-on-click-modal="false">
      <div class="ai-sys" v-if="piListConfig.length || pLListConfig.length" @click="aiSystem">
        <el-icon><Setting /></el-icon>
        AI 设置
      </div>
      <el-row class="file-view">
        <el-col :span="24" >
            <el-radio-group v-model="attachType" @change="changeType">
                <el-radio label="040" >供应商发票<i style="color:#ff2626">*</i></el-radio>
                <el-radio label="050" >供应商箱单</el-radio>
            </el-radio-group>
        </el-col>
        <el-col :span="24">
            <el-upload
                class="upload-demo"
                drag
                action
                :before-upload="beforeUpload"
                :multiple="multiple"
            >

                <span class="upload-icon">
                    <ab-svg name="upload-file"></ab-svg>
                </span>
                <div class="el-upload__text">
                  <em>支持PDF及图片，图片效果更好</em>，请截图上传物料明细部分的图片（不含标题栏和合计栏）
                </div>
            </el-upload>
        </el-col>
        <el-col :span="24">
            <div class="file-item" v-for="(obj,idx) in attachType == '040' ? piList : plList">
                <el-row>
                    <el-col :span="12">
                        <div class="tel"  @click="handerOreview(obj)">{{obj.disName}}</div>
                    </el-col>
                    <el-col :span="12">
                        <div class="reference">
                            <span @click="handlerDelete(obj, idx)">
                                <ab-svg name="my-off"></ab-svg>
                            </span>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-col>
      </el-row>


			
			<template #footer>
				<div class="dialog-footer">
					<el-button type="primary" @click="submitUpload" :loading="loading.ai">确 定</el-button>
					<el-button @click="closeUploadDialog">取 消</el-button>
				</div>
			</template>
		</el-dialog>

		<el-dialog title="温馨提示" v-model="aiDialog.show" width="400px" class="ai-make-order-ing" append-to-body
			:close-on-click-modal="false">
			<div style="height:200px" v-loading="loading.ai" element-loading-text="AI制单正在进行中，请稍后。。。"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.1)">
			</div>
			<div v-if="aiDialog.state == 'success' || aiDialog.state == 'error'" class="el-ai-re">
				<div class="ai-icon">
					<i
						:class="{'el-icon-success': aiDialog.state == 'success', 'el-icon-error': aiDialog.state == 'error'}"></i>
				</div>

				<div class="ai-test">
					<span v-if="aiDialog.state == 'success'">
						AI 识别结果可能不精准，请仔细检查
					</span>
					<span v-if="aiDialog.state == 'error'">
						{{ aiDialog.message }}
					</span>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click.stop="aiDialog.show = false">取 消</el-button>
				</span>
			</template>
		</el-dialog>

    <el-dialog title="AI配置" top="5vh" v-model="aiSysDialog.show" width="900px" class="ai-sys-ing" append-to-body
			:close-on-click-modal="false">
      <div class="tel">* 请确认所需项目识别的内容是否正确(仅以第一行为例)，如有误请直接修改所在列序号，如某项固定值，例如原产地都是中国，则可在固定值填入【中国】</div>
      <h3>供应商发票PI</h3>
      <el-table :data="piSysList" border  style="width: 100%">
        <el-table-column prop="elementName" label="项目" width="180" >
          <template #default="scope">
            <div v-if="scope.row.elementName == '型号'"><span style="color:red">*</span>{{ scope.row.elementName }}</div>
            <div v-else>{{ scope.row.elementName }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="识别内容"  >
          <template #default="scope" v-if="piFirstRowContent.length">
            <el-select v-model="scope.row.content"  clearable @change="changeContent(scope.row)" :disabled="piFirstRowContent.length == 0" placeholder="请选择内容">
              <el-option :label="obj" :value="idx" v-for="(obj,idx) in piFirstRowContent"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="showRow" label="所在序号（从左到右）"  width="180">
          <template #default="scope" >
            <el-input v-model="scope.row.showRow" type="number" @change="changeRow(scope.row, piFirstRowContent)"  placeholder="请输入序号"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="fixContent" label="固定值" width="180" >
          <template #default="scope">
            <el-input v-model="scope.row.fixContent" @blur="blurFixContent(scope.row)" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
      </el-table>

      <h3 class="title" v-if="pLSysList && pLSysList.length">供应商箱单PL</h3>
      <el-table :data="pLSysList" border  style="width: 100%" v-if="pLSysList && pLSysList.length">
        <el-table-column prop="elementName" label="项目" width="180" />
        <el-table-column prop="content" label="识别内容"  >
          <template #default="scope" v-if="plFirstRowContent.length">
            <el-select v-model="scope.row.content" clearable @change="changeContent(scope.row)" :disabled="plFirstRowContent.length == 0" placeholder="请选择内容">
              <el-option :label="obj" :value="idx" v-for="(obj,idx) in plFirstRowContent"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="showRow" label="所在序号（从左到右）" width="180" >
          <template #default="scope" >
            <el-input v-model="scope.row.showRow" type="number" @change="changeRow(scope.row, plFirstRowContent)"  placeholder="请输入序号"></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="fixContent" label="固定值" width="180" >
          <template #default="scope">
            <el-input v-model="scope.row.fixContent" @blur="blurFixContent(scope.row)" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
      </el-table>

      <template #footer>
				<div class="dialog-footer">
					<el-button type="primary" :loading="aiSysDialog.loading" @click="makingOrder">确 定</el-button>
					<el-button @click="aiSysDialog.show = false">取 消</el-button>
				</div>
			</template>
    </el-dialog>

		<ab-preview :preview="preview"></ab-preview>
	</div>
</template>


<script>
export default {
  name: 'ab-ai-make-order',

  props: {

  },

  data () {
    return {
      loading: {
        ai: false
      },
      attachType: '040',
      uploadDialog: {
        show: false,
        data: {}
      },
      fileData: {},
      accept: '.xls,.xlsx,.pdf,.png,.jpg,.jpeg',
      piList: [],
      plList: [],
      preview: {
        show: false
      },
      aiDialog: {
        show: false,
        state: '',
        message: ''
      },
      piListConfig: [],
      pLListConfig: [],
      piSysList: [
       
      ],
      pLSysList: [
      
      ],
      aiSysDialog: {
        show: false,
        type: '',
        loading: false,
        id: ''
      },
      piFirstRowContent: [],
      plFirstRowContent: []

    }
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    'uploadDialog.show': {
      handler: function(val, oldval) {
          if (val) {
              this.ocrTemplateList(this.uploadDialog.data)
              document.addEventListener('paste', this.filePaste)
          } else {
              document.removeEventListener('paste', this.filePaste)
          }
      },
      deep: true
    }
  },

  computed: {

  },

  mounted () {

  },

  methods: {
    ocrTemplateList(dataForm) {
      let params = {}
      params.entrustId = dataForm.id
      params.entrustNumber = dataForm.number
      params.supplierId = dataForm.supplierId
      params.principalName = dataForm.principalName
     
      this.$post('/bigdata/ocrTemplate/v2/getExistsSetting', params).then(({data}) => {
        if (data && data.code == 'C0000') {
          this.piListConfig = data.result.piList || []
          this.pLListConfig = data.result.plList || []
        }
      })
    },
    filePaste (event) {
        let data = (event.clipboardData || window.clipboardData)
        let file = data.items[0].getAsFile()
        this.beforeUpload(file)
    },
    init(dataForm) {
      if (!dataForm.principalId) {
        this.$message({
          type: 'error',
          message: '请选择委托方！',
          offset: 380,
          grouping: true,
        })
        return false
      }
      if (!dataForm.supplierId) {
        this.$message({
          type: 'error',
          message: '请选择供应商！',
          offset: 380,
          grouping: true,
        })
        return false
      }
      if (!dataForm.id) {
        this.$message({
          type: 'error',
          message: '请先保存单据！',
          offset: 380,
          grouping: true,
        })
        return false
      }
      this.uploadDialog.data = dataForm
      this.uploadDialog.show = true
    },
    
    getSuffix(obj) {
      if (!obj) {
        return;
      }
      let index = obj.lastIndexOf('.') || 0;
      let svg = obj.substring(index + 1).toLowerCase();
      return this.accept.indexOf(svg) == -1 ? 'accessory' : svg;
    },

    handlerDelete(obj) {
      this.$confirm('确定要删除吗，删除后无法恢复，请谨慎操作？', '提示', {
        type: 'warning'
      }).then(() => {
        var id = obj.id
        this.$post('/oms/billAttach/del', { ids: [ id ] }).then(({ data }) => {
          if (data && data.code == 'C0000') {
            this.$emit('uploadSuccess')
            this.$message({
              type: 'success',
              message: '删除成功！',
              offset: 380,
              grouping: true,
            })
            
            if (this.attachType === '040') {
              this.piList = this.piList.filter(e => e.id != id)
            } else if (this.attachType === '050') {
              this.plList = this.plList.filter(e => e.id != id)
            }
          }
        })
      })
    },
    
    handerOreview(obj) {
      this.preview.fileUrl = obj.fileUrl || obj.viewFileUrl
      this.preview.show = true
    },

    upload(data) {
      return this.$http({
        method: 'post',
        url: this.$adornUrl('/oms/billAttach/uploadFile'),
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 20000 * 60,
        data: data,
        onUploadProgress: function(progress) {}
      })
    },
    
    beforeUpload(file) {
      
      let accept = this.accept.split(',')
      let suffix = accept.some(e => file.name.toLowerCase().includes(e))
      if (!suffix) {
        this.$message({
          type: 'error',
          message: '仅支持' + this.accept + '格式的文件',
          offset: 380,
          grouping: true,
        })
        return false
      }
      let sourceBillType = 'EntrustAll'
      let fileType = file.type.substring(file.type.lastIndexOf('/') + 1)
      if (['jpg','png','bmp','tif','gif'].includes(fileType)) {
				sourceBillType = 'aiTemp'
			}
      
      let fd = new FormData()
      let getTime = new Date().getTime()
      let item = { _$index: getTime, percentage: 0, uploadStatus: undefined }
      fd.append('file', file)
      fd.append('v', getTime)
      fd.append('attachType', this.attachType)
      fd.append('coreBillIds', this.uploadDialog.data.id || '')
      fd.append('coreBillNumbers', this.uploadDialog.data.custOrderNo || '')
      fd.append('sourceBillId', this.uploadDialog.data.id)
      fd.append('sourceBillNumber', this.uploadDialog.data.custOrderNo)
      fd.append('sourceBillType', sourceBillType)
      this.upload(fd, item).then(({ data }) => {
        if (data && data.code === 'C0000') {
          this.$emit('uploadSuccess')
          this.$message({
            type: 'success',
            message: '上传成功！',
            offset: 380,
            grouping: true,
          })
          
          if (this.attachType === '040') {
            this.piList.push(data.result)
          } else if (this.attachType === '050') {
            this.plList.push(data.result)
          }
        } else {
          this.$message({
            type: 'error',
            message: data.message,
            offset: 380,
            grouping: true,
          })
        }
      }).catch(res => {
        if (res && res.data) {
          this.$message({
            type: 'error',
            message: res.data.message,
            offset: 380,
            grouping: true,
          })
        }
      })
      return false
    },
    submitUpload () {
      if (!this.piListConfig && !this.piList) {
        this.$message({
            type: 'error',
            message: '请先配置PI模板',
            offset: 380,
            grouping: true,
          })
        return false
      }
      if ((this.pLListConfig.length && this.piListConfig.length) || (this.piListConfig.length && !this.plList)) {
        this.aiBegin()
      } else {
        let params = {
          billAttachs: []
        }

        if (this.piList.length > 0) {
          params.billAttachs = params.billAttachs.concat(this.piList)
        } 
        if (this.plList.length > 0) {        
          params.billAttachs = params.billAttachs.concat(this.plList)
        } 
        if (this.piList.length == 0 && this.plList.length == 0) {
          this.$message({
              type: 'error',
              message: '请先上传文件！',
              offset: 380,
              grouping: true,
            })
          return false
        }
        params.entrustId = this.uploadDialog.data.id
        params.entrustNumber = this.uploadDialog.data.number
        params.supplierId = this.uploadDialog.data.supplierId
        params.principalName = this.uploadDialog.data.principalName
        this.loading.ai = true
        this.$post('/bigdata/ocrTemplate/v2/getClientAiSetting', params).then(({data}) => {
          if (data && data.code == 'C0000') {
            this.piFirstRowContent = data.result.piFirstRowContent || []
            this.plFirstRowContent = data.result.plFirstRowContent || []
            if (data.result.piList) {
              this.piSysList = []
              if (data.result.piFirstRowContent && data.result.piFirstRowContent.length) {
                data.result.piList.map(e => {
                  e.content = data.result.piFirstRowContent[e.rowPos]
                  e.showRow = e.rowPos + 1
                  this.piSysList.push(e)
                })
              } else {
                this.piSysList = data.result.piList
              }
              
            } else {
              this.piSysList = []
            }
            if (data.result.plList) {
              this.pLSysList = []
              if (data.result.plFirstRowContent && data.result.plFirstRowContent.length) {
                data.result.plList.map(e => {
                  e.content = data.result.plFirstRowContent[e.rowPos]
                  e.showRow = e.rowPos + 1
                  this.pLSysList.push(e)
                })
              }
              
            } else {
              this.pLSysList = []
            }
            this.aiSysDialog.type = 'edit'
            this.aiSysDialog.show = true
          }
        }).finally(() => {
          this.loading.ai = false
        })
      }
    },
    aiBegin() {
      let params = {
        billAttachs: []
      }
      this.aiSysDialog.show = false
      if (this.piList.length > 0) {
        params.billAttachs = params.billAttachs.concat(this.piList)
      } 
      if (this.plList.length > 0) {        
        params.billAttachs = params.billAttachs.concat(this.plList)
      } 
      if (this.piList.length == 0 && this.plList.length == 0) {
        this.$message({
            type: 'error',
            message: '请先上传文件！',
            offset: 380,
            grouping: true,
          })
        return false
      }
      params.principalId = this.uploadDialog.data.principalId
      params.principalName = this.uploadDialog.data.principalName
      params.supplierId = this.uploadDialog.data.supplierId
      params.supplierName = this.uploadDialog.data.supplierName
      params.entrustId = this.uploadDialog.data.id
      params.entrustNumber = this.uploadDialog.data.number
      this.aiDialog.state = 'begin'
      this.aiDialog.show = true
      this.loading.ai = true
      this.$post('/bigdata/ocrTemplate/ai', params).then(({data}) => {
        if (data && data.code == 'C0000') {
          this.aiDialog.state = 'success'
          this.$emit('aiSuccess')
          this.aiDialog.show = false
          this.closeUploadDialog()
        } else {
          this.aiDialog.message = data.message
          this.aiDialog.state = 'error'
        }
      }).finally(() => {
        this.loading.ai = false
      })
    },

    closeUploadDialog () {
      this.piList = []
      this.plList = []
      this.uploadDialog.show = false
      this.uploadDialog.data = {}
    },
    blurFixContent (row) {
      if (row.fixContent && row.fixContent.trim() != '') {
        row.showRow = ''
        row.content = ''
      }
    },
    makingOrder () {
      let flag = false
      if (this.piSysList && this.piSysList.length) {
        this.piSysList.map(e => {
          if (e.elementName == '型号' && !e.showRow && !e.fixContent) {
            this.$message({
              type: 'error',
              message: '供应商发票PI： 型号所在序号，或固定值不能为空',
              offset: 380,
              grouping: true,
            })
            flag = true
          }
        })
      }
      if (this.pLSysList && this.pLSysList.length) {
        let model = ''
        this.pLSysList.map(e => {
          let obj = this.piSysList.filter(y => {return e.elementName == y.elementName})
          if (obj[0] && obj[0].fixContent && e.showRow) {
            this.$message({
              type: 'error',
              message: `供应商箱单PL： ${e.elementName}只能选择固定值`,
              offset: 380,
              grouping: true,
            })
            flag = true
          }
          if (e.elementName == '型号') {
            model = e
          } else if ((e.showRow || e.fixContent) && !model.showRow && !model.fixContent) {
            this.$message({
              type: 'error',
              message: '供应商箱单PL： 型号所在序号，或固定值不能为空',
              offset: 380,
              grouping: true,
            })
            flag = true
          }
          
        })
      }
      if (flag) {
        return false
      }
      this.aiSysDialog.loading = true
      let params = {
        piList: this.piSysList,
        plList: this.pLSysList,
        principalId: this.uploadDialog.data.principalId,
        supplierId: this.uploadDialog.data.supplierId
      }
      if (this.aiSysDialog.id) {
        params.id = this.aiSysDialog.id
      }
      this.$post('/bigdata/ocrTemplate/client/conifrm', params).then(({data}) => {
        if (data && data.code == 'C0000') {
          this.$message({
            type: 'success',
            message: data.result,
            offset: 380,
            grouping: true,
          })
          if (this.aiSysDialog.type == 'edit') {
            this.aiBegin()
          } else {
            this.aiSysDialog.show = false
          }
        }
      }).finally(() => {
        this.aiSysDialog.loading = false
      })
    },
    aiSystem () {
      if (this.piListConfig.length) {
        this.piSysList = this.piListConfig
      }
      if (this.pLListConfig.length) {
        this.pLSysList = this.pLListConfig
      }
      this.aiSysDialog.type = 'view'
      this.aiSysDialog.show = true
    },
    changeContent (row) {
      row.showRow = row.content + 1 + ''
    },
    changeRow(row, array) {
      if (array && array.length) {
        let content = array[row.showRow - 1]
        if (content) {
          row.content = array[row.showRow - 1]
        } else {
          row.content = ''
          this.$message({
            type: 'error',
            message: '序号不存在',
            offset: 380,
            grouping: true,
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.ai-make-order-upload {
  .ai-sys {
    position: absolute;
    top: 22px;
    right: 80px;
    cursor: pointer;
    .el-icon {
      margin-right: 2px;
      vertical-align: text-top;
      font-size: 18px;
      color: #0A7CFF;
    }
  }
  .el-dialog__body {
        padding-top: 10px;
        margin: 0 20px;
    }
    .upload-demo {
        margin-top: 10px;
        .el-upload__text {
          padding: 0 60px;
        }
        .el-upload-dragger {
          background-color: #fff;
        }
    }
    .upload-icon {
      .icon-svg {
          width: 85px;
          height: 64px;
          margin-bottom: 10px;
      }
    }
    .file-item {
        background-color: #F0F2F5;
        font-size: 14px;
        height: 35px;
        line-height: 35px;
        border-radius: 8px;
        margin-bottom: 10px;
    }
    .tel {
        text-align: left;
        margin-left: 17px;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
            color: #0B7AFF;
        }
    }
    .reference {
        text-align: right;
        margin-right: 17px;
        .icon-svg {
            width: 19px;
            height: 19px;
            margin-left: 7px;
            vertical-align: text-top;
            cursor: pointer;
        }
    }
    .mg-t-5 {
        cursor: pointer;
    }
    .operator {
        display: none;
    }
    .mg-t-5:hover{
        .operator{
            display: inline-block;
        }
    }
  .ai-upload {
    .upload-box {
      margin-top: 20px;
      .el-upload {
        width: 100%;
        .el-upload-dragger {
          position: relative;
          width: 100%;
          height: 100px;
          padding-top: 24px;
          border-radius: 0;
          &.is-dragover {
            border-width: 1px;
          }
          .upload-title {
            position: absolute;
            top: 25px;
            left: 35px;
            z-index: 2;
            font-size: 50px;
            line-height: 50px;
            color: #7f7f7f;
          }
          .el-upload__text {
            margin-top: 10px;
          }
        }
      }
      .file-list {
        .item {
          display: flex;
          align-items: center;
          padding: 0 10px;
          width: 100%;
          height: 40px;
          margin-top: 5px;
          line-height: 40px;
          font-size: 14px;
          background: #f3f8fc;
          .icon-svg {
            width: 26px;
            height: 28px;
          }
          .name {
            flex: auto;
            height: 40px;
            margin-left: 6px;
            overflow: hidden;
          }
          .delete {
            cursor: pointer;
          }
        }
      }
      .el-button {
        color: #ffffff;
      }
      .el-icon {
        margin-right: 5px;
      }
    }
  }
}

.ai-make-order-ing {
  .el-loading-spinner {
    .el-icon-success,
    .el-icon-loading {
      font-size: 30px;
    }
  }
  .el-ai-re {
    position: absolute;
    top: 40%;
    .ai-icon {
      margin-left: 162px;
    }
    .el-icon-success{
      color:rgb(103, 194, 58);
      font-size: 30px;
    }
    .el-icon-error{
      color:#F56C6C;
      font-size: 30px;
    }
    .ai-test {
      text-align: center;
      margin-top: 10px;
      width: 360px;
    }
  }
}
.ai-sys-ing {
  .tel {
    color: red;
    padding-bottom: 10px;
  }
  .el-dialog__body {
    padding-top: 0px;
  }
  h3 {
    line-height: 25px;
  }
  .title{
    margin-top: 20px;
  }
  .el-input__inner {
    height: 30px;
    background-color: #fff;
  }
  .el-table .el-table__cell {
    padding: 5px;
  }
  .el-table__header {
    height: 42px;
  }
  .el-table .cell {
    padding: 0;
  }
  .el-input__wrapper {
    background-color: #fff;
  }
  .el-table__cell {
    border-radius: 0;
  }
  .el-table th:first-child,.el-table td:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .select-trigger {
    height: 32px;
    .el-input {
      line-height: 32px;
      height: 32px;
    }
  }

}
</style>
