<template>
  <el-row :gutter="20" class="ab-alerts">
    <el-col :span="12" v-for="(obj, idx) in showFiles" :key="idx">
      <div class="file-list-item">
        <div class="name" @click="dolowloadFile(obj)">{{ obj.disName }}</div>
        <div class="delete" @click="handleDelete(obj)">
          <ab-svg name="my-off"></ab-svg>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'ab-alert',
  data () {
    return {
      showFiles: this.fileList
    }
  },
  // model: {
  //   prop: 'fileList',
  //   event: 'change'
  // },
  props: {
    fileList: {
      type: Array
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    dolowloadFile (obj) {
      let eleLink = document.createElement('a')
      eleLink.download = ''
      eleLink.style.display = 'none'
      eleLink.target = '_blank'
      // 字符内容转变成blob地址
      eleLink.href = obj.fileUrl
      // 触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
    },
    handleDelete (obj) {
      this.$confirm('确定要删除吗，删除后无法恢复，请谨慎操作？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$post('/oms/billAttach/del', { ids: [obj.id] }).then((res) => {
          this.$message({
            type: 'success',
            message: '删除成功！',
            grouping: true,
            offset: 380
          })
          this.showFiles = this.showFiles.filter(e => { return obj.id !== e.id })
          this.$emit('change', this.showFiles)
          // console.log(this.fileList, obj);
        }).finally(e => {
          this.isloading = false
        }, 200)
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss">
.ab-alerts {
  .el-col {
    padding-bottom: 4px;
  }
  .file-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    background-color: #F0F2F5;
    font-size: 14px;
    height: 35px;
    border-radius: 8px;
    margin-bottom: 10px;
    .name {
      text-decoration: underline;
      cursor: pointer;
    }
    .delete {
      height: 19px;
      cursor: pointer;
      svg {
        width: 19px;
        height: 19px;
      }
    }
  }
}
</style>
