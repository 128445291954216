<template>
  <div :class="{ detail_block: !inline }" class="ab-title-content">
    <div class="cl_div_detail_title" :class="{ title_shadow: !inline }" v-if="title">
      <span class="text">{{ title }}</span>
      <slot name="subTitle"></slot>
    </div>
    <slot name="content">
      <div class="detail_content" :class="{ padding_content: !inline }">
        <slot></slot>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "ab-title",
  props: {
    title: {
      // 根据输入title的值来确定是否显示title
      type: String,
      default: ""
    },
    inline: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.detail_block {
  // border: 1px solid rgba(221, 226, 235, 1);
}

.detail_block + .detail_block {
  margin-top: 8px;
}
.cl_div_detail_title{
  background-color: #fff;
  text-indent: 20px;
  height: 50px;
  text-align: left;
  line-height: 50px;
  color: #000;
  font-size: 18px;
  font-weight: 400;
}
.title_shadow {
  // box-shadow: 0px 1px 0px 0px #F3F8FC;
  border-bottom: 1px solid #E8ECF0;
}
.ab-title-content {
//   box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
}
.detail_content {
  background: #ffffff;
}

.padding_content {
  padding: 10px;
}
</style>
