// @ts-nocheck
import axios from 'axios'
import vueCookie from 'vue-cookies'
import { ElMessage } from 'element-plus'
import store from '../store'


const https = axios.create({
  timeout: 1000 * 300,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
  // errorCode处理
  validateStatus: (status) => {
    return true
  }
})

/**
 * 请求拦截
 */
https.interceptors.request.use((config: any) => {
  const token = vueCookie.get('token');
  token && (config.headers['Authorization'] = vueCookie.get('token')) // 请求头带上token
  if (config.method === 'get') {
    if (config.url.includes('?')) {
      config.url += '&_='
    } else {
      config.url += '?_='
    }
    config.url += new Date().getTime()
  }
  return config
}, error => {
  return Promise.reject(error)
})


https.interceptors.response.use((response: any) => {
  if (response.headers['content-type'] == 'application/octet-stream;charset=UTF-8') {
    return response
  }
  if (response.data.code === 'E0003') { // token失效
    vueCookie.remove('token')
    window.localStorage.clear()
    //window.sessionStorage.clear()
    window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/#/login'
    return response
  } else if (response.status == 404) {
    ElMessage({
      type: 'error',
      message: '请求错误',
      grouping: true,
      offset: 380
    })


  } else if (response.data.code !== 'C0000' && response.data.code !== 'E0008' && response.data.code != 'E0006') {
    ElMessage({
      type: 'error',
      grouping: true,
      message: response.data.message,
      offset: 380
    })

  }
  return response
}, error => {
  if (error.message.includes('timeout')) {
    ElMessage({
      type: 'error',
      message: '请求超时',
      grouping: true,
      offset: 380
    })

  }
  return Promise.reject(error)
})

/**
* 请求地址处理
* @param {*} actionName action方法名称
*/
export function adornUrl(actionName: any, type?: any) {
  if (process.env.NODE_ENV === 'production') {
    // 生产环境
    const baseApiUrl = process.env.VUE_APP_API_BASEURL
    const baseSitUrl = process.env.VUE_APP_SIT_BASEURL
    return type === 'api' ? baseApiUrl + '/api' + actionName : baseSitUrl + actionName
  } else {
    // 非生产环境
    actionName = process.env.VUE_APP_PREFIX + actionName
    return type === 'api' ? '/api' + actionName : '/proxyApi' + actionName
  }
}

/**
* 封装get请求
* @param url
* @param data
* @returns {Promise}
*/
export function fetch(url: any, params = {}, type?: any) {
  return new Promise((resolve, reject) => {
    https.get(adornUrl(url, type), {
      params: params
    })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

// TODO: 为了测试接口，post接口多增加了一个可选参数type
export function post(url: any, data = {}, type?: any, that: any, loading: any) {
  that ? that[loading] = true : ''
  return new Promise((resolve, reject) => {
    https.post(adornUrl(url, type), data)
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      }).finally(() => {
        setTimeout(() => {
          that ? that[loading] = false : ''
        }, 100)
      })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url: any, data = {}, type?: any, that: any, loading: any) {
  that ? that[loading] = true : ''
  return new Promise((resolve, reject) => {
    https.put(adornUrl(url, type), data)
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      }).finally(() => {
        setTimeout(() => {
          that ? that[loading] = false : ''
        }, 100)
      })
  })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function del(url: any, data = {}, type?: any, that: any, loading: any) {
  that ? that[loading] = true : ''
  return new Promise((resolve, reject) => {
    https.delete(adornUrl(url, type), data)
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      }).finally(() => {
        setTimeout(() => {
          that ? that[loading] = false : ''
        }, 100)
      })
  })
}

export default https
