<template>

  <el-button
    :type="type"
    :loading="loading"
    v-if="show"
    :link="link"
    :disabled="disabled"
    @click="revokeSignFun"
  ><slot></slot>
  <ab-preview :preview="preview"  @handleClose="handleClose">
    <template #default>
      <el-button @click="handleClose">关 闭</el-button>
    </template>
  </ab-preview>
  </el-button>
  

</template>

<script>
export default {
  name: 'ab-continue-sign',
  data () {
    return {
      loading: false,
      preview: {
        show: false,
        isView: false,
        fileUrl: '',
        fileTitle: ''
      },
    }
  },
  props: {
    billId: {
      type: String,
      default: ''
    },
    submitUserId: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: true
    },
    billType: { //签署主题
        type: String,
        default: ''
    },
    type: { //签署主题
        type: String,
        default: 'primary'
    },
    customerBillNumber: {
        type: String,
        default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    revokeSignFun () {
        this.loading = true
        this.getBillTypes()
        this.$post('/sys/esignTask/list', {
            data: {
                viewFlag: 0, 
                billType: this.billType,
                customerBillNumber: this.customerBillNumber
            },
            pageNum: 1,
            pageSize: 10
        }).then(({ data }) => {
            if (data && data.code === 'C0000') {
                if (data.result.list.length) {
                    this.getFlowUrl(data.result.list[0])
                }
            } else {
                this.loading = false
            }
        })
    },
    getFlowUrl (row) {
        this.loading = true
        this.$get('/sys/esignTask/getFlowUrl', {id: row.id, flowId: row.flowId, urlType: 0}).then(({data}) => {
            if (data.code === 'C0000') {
                this.preview.isView = true
                this.preview.fileUrl = data.result
                this.preview.show = true
            }
        }).finally(e => {
            setTimeout(e => {
                this.loading = false
            }, 500)
        })
    },
    handleClose () {
        this.$emit('handleClose')
    },
    getBillTypes () {
      //获取签署文件的名称，
      this.$post('/sys/esignFlow/billType')
      .then(({ data }) => {
        if (data.code === 'C0000') {
          let billTypes = data.result
          billTypes.map(e => {
            if (e.value == this.billType) {//参考签章列表的签署类型参数
              this.preview.fileTitle = e.desc
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">


</style>
