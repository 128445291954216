// 校验base64
function isValidBase64(base64: string) {

    return /^[A-Za-z0-9+/]*={0,2}$/.test(base64);
}

// 解码Base64内容

function decodeBase64(content: string) {
    try {
        return window.atob(content);
    } catch (error) {
        console.error('解码base64失败', error);
        throw error;
    }
}

// 创建Blob对象

function createBlob(binaryString: string, type: string) {
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type });
}

// 创建下载链接

function createDownloadLink(blob: Blob | MediaSource, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

// 导出excel
function download(content: any, filename: string) {
    if (!isValidBase64(content)) {
        throw new Error('无效的Base64内容');
    }

    const binaryString = decodeBase64(content);
    const blob = createBlob(binaryString, 'application/vnd.ms-excel');
    createDownloadLink(blob, filename);
}

module.exports = {
    download
}
