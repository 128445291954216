<template>
  <el-cascader
    ref="select"
    style="width:100%;"
    :expand-trigger="trigger"
    popper-class="vxe-table--ignore-clear"
    :options="citysOptions"
    :filterable="filterable"
    :props="{ expandTrigger: expandTrigger }"
    v-model="addressId"
    @change="select"
    clearable
    :placeholder="placeholder"
    :disabled="disabled"
  ></el-cascader>
</template>

<script>
export default {
  name: 'ab-address',
  model: {
    modelValue: String
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Array
    },
    filterable: {
      type: Boolean,
      default: true
    },
    expandTrigger: {
      type: String,
      default: 'hover'
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    addressData: {
      type: Array,
      default: () => {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    }
  },
  data () {
    return {
      addressId: this.modelValue,
      citysOptions: this.addressData
    }
  },
  mounted () {
    if (!this.citysOptions.length) {
      let option = this.$utils.getSessionStorage('cityData')
      if (!option || !option.length) {
        this.$initSessionStorageData.getCityData().then(result => {
          this.citysOptions = this.$utils.getSessionStorage('cityData')
        })
      } else {
        this.citysOptions = option
      }
    }
  },
  watch: {
    addressData (value) {
      this.citysOptions = value
    },
    modelValue(val){
      if (val && Array.isArray(val)) {
        this.addressId = val.filter(item => item !== "0" || !item)
      }
    }
  },
  methods: {
    select (nodes) {
      this.$emit('update:modelValue', this.addressId)
      this.$emit('change', nodes, this.$refs.select.getCheckedNodes() && this.$refs.select.getCheckedNodes().length ? this.$refs.select.getCheckedNodes()[0].text : '')
      this.$emit('handleSelect', nodes, this.$refs.select.getCheckedNodes() && this.$refs.select.getCheckedNodes().length ? this.$refs.select.getCheckedNodes()[0].text : '')
    }
  }
}
</script>

<style lang="scss">
  .el-cascader.is-disabled .el-cascader__label {
    color: #666;
  }
</style>
