<template>
  <el-drawer v-model="accoutAdd" custom-class="client-drawer">
    <template #title>
      <h4>{{ personData.id ? '编辑' : '新增' }}</h4>
    </template>
    <template #default>
      <el-form label-position="top"   :model="accoutAddInfo" :rules="accoutAddRules" ref="accoutAddInfo">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="姓名" prop="nickName">
              <el-input v-model="accoutAddInfo.nickName" placeholder="请输入姓名" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="accoutAddInfo.email" placeholder="请输入邮箱" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>

    <template #footer>
      <div style="flex: auto">
        <el-button :loading="addBtnLoading" @click="accoutAdd = false">取 消</el-button>
        <el-button type="primary" :loading="addBtnLoading" @click="confirmAdd">确 定</el-button>
      </div>
    </template>
  </el-drawer>
  <!-- 个人中心 -->
  <div class="myCenter">
    <div class="big-box"  v-loading="loadingFlag">
      <div class="big-box-top">
        <div class="avatar" @click="avatarUpload">
          <img :src="attachUrl ? attachUrl : require('../../assets/img/logo.jpg')" alt="">
          <div class="modal"></div>
          <div class="word">
            <ab-svg :name="attachUrl ? 'deleteAvatar' : 'uploadAvatar'"></ab-svg>
            <p>{{ attachUrl ? '删除' : '上传头像' }}</p>
          </div>
        </div>
        <div class="info">
          <div>{{ personData.companyId ? personData.companyName : '未认证' }} </div>
          <div><span class="name" v-if="personData.username">{{ personData.nickName }}</span> <span
              style="font-family: DIN_Condensed_Bold">{{ personData.phone }}</span></div>
        </div>
        <div>
          <el-button style="color: #01458e; border-color: #01458e" @click="drawer = true">修改密码</el-button>
        </div>
      </div>
      <el-row class="big-box-bottom">
        <el-col :span="6">
          <div>姓名</div>
          <p>
            <span v-if="personData.nickName">
              {{ personData.nickName }}
            </span>
            <el-icon class="editIcon" @click="editClick">
              <EditPen />
            </el-icon>
          </p>
        </el-col>
        <el-col :span="6">
          <div>邮箱</div>
          <p>
            <span v-if="personData.email">
              {{ personData.email }}
            </span>
            <span v-else>
              -
            </span>
            <el-icon class="editIcon" @click="editClick">
              <EditPen />
            </el-icon>
          </p>
        </el-col>
        <el-col :span="6">
          <div>角色</div>
          <p>{{ personData.role.name }}</p>
        </el-col>
        <el-col :span="6">
          <div>注册时间</div>
          <p>{{ personData.createTime }}</p>
        </el-col>
      </el-row>
    </div>

    <!-- 修改密码 弹框-->
    <div class="password">
      <el-drawer v-model="drawer" :with-header="false" :destroy-on-close='true' @close="drawerClose">
        <el-tabs v-model="activeName" class="passWord">
          <el-tab-pane label="原密码修改" name="dataFrom">
            <el-form :model="dataFrom" status-icon :rules="rules" ref="dataFrom" label-width="100px" class="demo-ruleForm"
              label-position="top">
              <el-form-item prop="oldPass" :required="true" label="原密码">
                <el-input  show-password v-model="dataFrom.oldPass" placeholder="请输入原密码" />
              </el-form-item>
              <el-form-item prop="newPass" :required="true" label="新密码">
                <el-input v-model="dataFrom.newPass" placeholder="请输入新密码" show-password />
              </el-form-item>
              <el-form-item prop="confirmPassword" label="确认新密码">
                <el-input v-model="dataFrom.confirmPassword" placeholder="请再次输入新密码" show-password />
              </el-form-item>
            </el-form>
          </el-tab-pane>

          <el-tab-pane label="验证码修改" name="ruleForm" class="second">
            <div class="phone">手机号码<div>{{ personData.phone }}</div>
            </div>
            <el-form :model="ruleForm" status-icon :rules="rules1" ref="ruleForm" label-width="100px"
              class="demo-ruleForm" label-position="top">
              <el-form-item prop="code" :required="true" label="验证码">
                <el-input v-model="ruleForm.code" placeholder="请输入验证码" @input="handleInput">
                  <template #append>
                    <el-button link :disabled="isCodeDisabled" @click="checkedPhoneCode">{{ phoneCodeBtn }}</el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="newPass" :required="true" label="新密码">
                <el-input v-model="ruleForm.newPass" placeholder="请输入新密码" show-password />
              </el-form-item>
              <el-form-item prop="confirmPassword" label="确认新密码">
                <el-input v-model="ruleForm.confirmPassword" placeholder="请再次输入新密码" show-password />
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <button aria-label="el.drawer.close" class="el-drawer__close-btn" type="button"
          style="position: absolute;top: 26px;right: 40px;" @click="drawer = false">
          <i class="el-icon el-drawer__close">
            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
              <path fill="currentColor"
                d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
              </path>
            </svg>
          </i>
        </button>
        <template #footer>
          <div style="flex: auto">
            <el-button @click="closePassword">取消</el-button>
            <el-button type="primary" style="margin-left: 20px" :loading="passwordLoading"
              @click="resetPassword">确定</el-button>
          </div>
        </template>
      </el-drawer>
    </div>

    <!-- 上传头像 -->
    <el-dialog width="640px" append-to-body title="上传头像" v-model="showAvatarDialog" :close-on-click-modal="false"
      :close-on-press-escape="false" class="uploadAvatarDialog">
      <el-upload class="upload-demo" v-if="!option.img" drag action :before-upload="beforeAvatarUpload" multiple>
        <!-- <el-icon class="el-icon--upload"><upload-filled /></el-icon> -->
        <ab-svg name="upload-file" style=" width: 85px; height: 64px; margin-bottom: 13px"></ab-svg>
        <div class="el-upload__text">
          点击 <em>上传文件</em>，或将文件拖到此窗口即可上传！<br />
          仅支持5MB以内jpg、jpeg、gif、png格式图片上传
        </div>
      </el-upload>

      <div v-else class="cropperImg">
        <ab-cropperImage @uploadImgSuccess="uploadImgSuccess" ref="cropper" :option="option"></ab-cropperImage>
        <div class="editImg">
          <div style="margin-bottom: 25px">长
            <el-input v-model="autoCropWidth" @blur="Enter('autoCropWidth')"></el-input>
          </div>
          <div style="margin-bottom: 25px">宽
            <el-input v-model="autoCropHeight" @blur="Enter('autoCropHeight')"></el-input>
          </div>
          <p>注意：支持裁剪最小宽度120最小高度120固定宽高比例为1:1。</p>
        </div>
      </div>



      <template #footer>
        <el-button v-if="option.img" @click="(() => { option.img = ''; temImg = ''; fileName = '' })">重新选择</el-button>
        <el-button @click="confirmUpload" :type="option.img ? 'primary' : ''">
          {{ option.img ? '确 定' : '取 消' }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
  },

  data() {
    //校验规则
    const validatePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入密码'))
      } else {
        let reg = new RegExp('^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,13}$')
        if (!reg.test(value)) {
          return callback(new Error('密码需6-13位，包含大小写字母与数字'))
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      let reg = new RegExp('^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,13}$')
      if (!value) {
        callback(new Error("确认密码不能为空"));
      }else if(!reg.test(value)) {
          return callback(new Error('密码需6-13位，包含大小写字母与数字'))
      } else if (value !== this.dataFrom.newPass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    let validateConfirmPassword1 = (rule, value, callback) => {
      let reg = new RegExp('^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{6,13}$')
      if (!value) {
        callback(new Error("确认密码不能为空"));
      }else if(!reg.test(value)) {
          return callback(new Error('密码需6-13位，包含大小写字母与数字'))
      } else if (this.ruleForm.newPass !== value) {
        callback(new Error("确认密码与新密码不一致"));
      } else {
        callback();
      }
    };
    return {
      accoutAdd: false,
      loadingFlag:false,
      accoutAddInfo: {},
      activeName: 'dataFrom',
      ruleForm: {},//验证码修改
      personData: {
        realName: '23',
        role: {}
      },//用户数据
      drawer: false, //修改密码
      phoneCodeBtn: "获取验证码",
      passwordLoading: false,
      isCodeDisabled: false,
      userInfos: this.$utils.getSessionStorage('userInfo') || {},
      dataFrom: {},//原密码修改
      attachUrl: '',//头像信息
      showAvatarDialog: false,//上传头像框显示
      option: {
        accept: 'image/png, image/jpeg, image/gif, image/jpg',    // 上传图片的格式
        img: "", //裁剪图片的地址
        outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
        outputType: "jpeg", //裁剪生成图片的格式（jpeg || png || webp）
        info: true, //图片大小信息
        canScale: true, //图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 120, //默认生成截图框宽度
        autoCropHeight: 120, //默认生成截图框高度
        fixed: false, //是否开启截图框宽高固定比例
        fixedNumber: [1, 1], //截图框的宽高比例
        full: false, //false按原比例裁切图片，不失真
        fixedBox: true, //固定截图框大小，不允许改变
        canMove: false, //上传图片是否可以移动
        canMoveBox: true, //截图框能否拖动
        original: true, //上传图片按照原始比例渲染
        centerBox: false, //截图框是否被限制在图片里面
        height: false, //是否按照设备的dpr 输出等比例图片
        infoTrue: true, //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1, //图片根据截图框输出比例倍数
        mode: "120px 120px", //图片默认渲染方式
        showBtn: false,  //是否显示按钮
        preview: false,
        width: '292px',
        height: '238px'
      },
      autoCropWidth: '120',
      autoCropHeight: '120',
      //验证规则   Xx1147
      rules: {
        oldPass: [{ required: true, message: "原密码不能为空", trigger: "blur" }],
        newPass: [
          { validator: validatePass, trigger: "blur", required: true, },
        ],
        confirmPassword: [{ validator: validatePass2, trigger: "blur", required: true }],
      },
      accoutAddRules: {
        phone: [
          {
            validator: (rule, value, callback) => {
              if (/^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(value)) {
                return callback()
              }
              return callback(new Error('请输入正确的手机号'))
            },
            trigger: 'blur',
            required: true
          }
        ],
        email: [
          {
            validator: (rule, value, callback) => {
              if (/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(value)) {
                return callback()
              }

              return callback(new Error('请输入正确的邮箱地址'))
            },
            trigger: 'blur',
            required: true,

          },

        ],
        nickName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ]

      },
      rules1: {
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value) {
                if (value.length !== 5) {
                  callback(new Error('验证码错误'));
                }
              }
              callback();
            },
            trigger: 'blur'
          }
        ],
        newPass: [
          { validator: validatePass, trigger: "blur", required: true, },
        ],
        confirmPassword: [
          { validator: validateConfirmPassword1, trigger: "blur" }
        ]
      },
    };
  },
  computed: {
    //用户签约情况
    step() {
      return this.$store.state.step
    },
    currentAvatar: {
      get() { return this.$store.state.currentAvatar },
      set(val) { this.$store.commit('updateCurrentAvatar', val) }
    },
  },
  methods: {
    //个人相关信息请求
    getPersonData() {
      this.loadingFlag = true
      this.$get('/auth/info')
        .then(({ data }) => {
          if (data.code === 'C0000') {
            if (data.result) {
             
              this.personData = data.result.user
              this.personData.role = data.result.user.roles && data.result.user.roles.length ? data.result.user.roles.some(e=> e.id == 2) ? data.result.user.roles.filter(e=> e.id == 2)[0] : data.result.user.roles[0] : {}
              this.getAttachUrl()//头像信息获取
            }
          }
        })
    },
    // 修改
    editClick() {
      this.accoutAdd = true
      this.accoutAddInfo = {
        nickName: this.personData.nickName,
        email: this.personData.email,
        id: this.personData.id
      }

    },
    // 修改姓名和邮箱
    confirmAdd() {
      this.$refs.accoutAddInfo.validate((voids) => {
        if (voids) {
          this.addBtnLoading = true
          this.$put('/api/users/center', this.accoutAddInfo).then(({ data }) => {
            if (data.code === 'C0000') {
              this.accoutAddInfo = {}
              this.$message({
                type: 'success',
                message: '修改成功',
                offset: 380,
                grouping: true,
              })
              this.accoutAdd = false
              this.addBtnLoading = false
              this.getPersonData()
            }
          }).finally(() => {
            this.addBtnLoading = false
          })
        }
      })
    },
    //修改密码取消
    closePassword() {
      this.$refs[this.activeName].resetFields()
      this.drawer = false
    },
    //修改密码确认
    resetPassword() {
      this.$refs[this.activeName].validate((isValid) => {
        if (isValid) {
          this.passwordLoading = true
          let url = this.activeName == 'dataFrom' ? '/api/users/updatePass' : '/auth/updatePassword'
          let data = this.activeName == 'dataFrom' ? {
            oldPass: this.$utils.Base64Encode(this.dataFrom.oldPass),
            newPass: this.$utils.Base64Encode(this.dataFrom.newPass)
          } : {
            newPassword: this.$utils.Base64Encode(this.ruleForm.newPass),
            code: this.ruleForm.code,
            mobile: this.personData.phone,
            uuid: this.ruleForm.uuid
          }
          this.$post(url, data).then(({ data }) => {
            if (data && data.code === 'C0000') {
              this.$message({
                type: 'success',
                message: data.message,
                offset: 380,
                grouping: true,
              })
              setTimeout(() => {
                this.closePassword()
              }, 1000)
              this.getPersonData()
            } else {
              this.$message({
                type: 'error',
                message: data.message,
                offset: 380,
                grouping: true,
              })
            }
          }).catch((err) => {
            this.$message({
              type: 'error',
              message: '系统繁忙，请稍后再试',
              offset: 380,
              grouping: true,
            })
          }).finally(() => {
            this.passwordLoading = false
          })
        }
      })
    },
    avatarUpload() {
      if (!this.attachUrl) {
        this.showAvatarDialog = true
      } else {
        this.$post('/api/attactment/delByParam', { id: this.personData.id }).then(({ data }) => {
          if (data.code === 'C0000') {
            this.$message({
              type: 'success',
              message: data.message,
              offset: 380,
              grouping: true,
            })
            this.currentAvatar = ''
            this.getAttachUrl()
          }
        })
      }
    },
    upload(data) {
      return this.$http({
        method: 'post',
        url: this.$adornUrl('/api/attactment/uploadFile'),
        timeout: 20000 * 60,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data', // 关键
        },
      })
    },
    confirmUpload() {
      if (!this.option.img) {
        this.showAvatarDialog = false
        return
      }
      this.$refs.cropper.$refs.cropper.getCropBlob(async data => {
        let file = new window.File([data], this.fileName, { type: data.type })
        let formData = new FormData();
        formData.append('file', file);
        formData.append('sourceBillType', 'user');
        formData.append('sourceBillId', this.personData.id);
        // attachType : business_license 营业执照图片, head sculpture 头像
        //sourceBillType 'company', 'user'
        formData.append('attachType', 'head sculpture');
        this.upload(formData).then(({ data }) => {
          if (data && data.code === 'C0000') {
            this.$message({
              type: 'success',
              message: '上传成功',
              offset: 380,
              grouping: true,
            })
            this.option.img = ''
            this.temImg = ''
            this.fileName = ''
            this.showAvatarDialog = false
            let result = data.result
            // this.attachUrl = result.fileUrl
            this.getAttachUrl()
            setTimeout(() => {
              this.getPersonData()
            })
          }
        })
      })
    },
    getAttachUrl() {
      // // attachType : business_license 营业执照图片, head sculpture 头像
      //sourceBillType 'company', 'user'
      let data = {
        sourceBillType: 'user',
        sourceBillId: this.personData.id,
        attachType: 'head sculpture'
      }
      this.attachUrl = ''
      this.$get('/api/attactment/info', data).then(({ data }) => {
        if (data.code === 'C0000') {
          if (data.result) {
            this.attachUrl = data.result.fileUrl
            this.currentAvatar = data.result.fileUrl
          }
          this.loadingFlag = false
        }
      })
    },
    Enter(type) {
      if (!/^[1-9]+[0-9]*$/.test(this[type]) || this[type] > 2000) {
        this.$message({
          type: 'error',
          message: '请输入不大于2000的正整数',
          offset: 380,
          grouping: true,
        })
        return
      }
      this.option.autoCropWidth = this['autoCropWidth']
      this.option.autoCropHeight = this['autoCropHeight']
    },
    beforeAvatarUpload(file) {
      const isLtSize = file.size / 1024 / 1024 < 5
      if (!isLtSize) {
        this.$message({
          type: 'error',
          message: file.name + '不能超过5MB!',
          offset: 380,
          grouping: true,
        })
        return false
      }
      let types = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message({
          type: 'error',
          message: '上传图片只能是 jpg、jpeg、gif、png 格式!',
          offset: 380,
          grouping: true,
        })
        return false
      }
      //转化为blob
      this.fileName = file.name
      let reader = new FileReader();
      reader.onload = e => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.temImg = data
        this.option.img = data
      };
      //转化为base64
      reader.readAsDataURL(file);
      return false
    },
    handleInput() {
        this.ruleForm.code= this.ruleForm.code.replace(/[^0-9]/g, '');
    },
    // 获取验证码
    checkedPhoneCode() {
      if (!/^1[0-9]{10}$/.test(this.personData.phone)) {
        this.$message({
          type: 'error',
          message: '手机号格式不正确！',
          offset: 380,
          grouping: true,
        })
        return
      }
      this.isCodeDisabled = true
      let that = this
      this.$get("/auth/sendUpdatePassToPhone", {
        phone: this.personData.phone
      }).then(({ data }) => {
        if (data && data.code === "C0000") {
          let index = 60
          let codeTime = setInterval(() => {
            if (index <= 0) {
              clearInterval(codeTime)
              that.isCodeDisabled = false
              that.phoneCodeBtn = "获取手机验证码"
            } else {
              this.phoneCodeBtn = "重新获取(" + index + ")"
              index--
            }
          }, 1000);
          this.ruleForm.uuid = data.result
        } else {
          this.isCodeDisabled = false
          this.$message({
            type: 'error',
            message: data.message,
            offset: 380,
            grouping: true,
          })
        }
      }).catch(() => { })
    },
    drawerClose() {
      this.$refs.dataFrom.resetFields()
      this.$refs.ruleForm.resetFields()
    }
  },
  created() {
    let userInfo = this.$utils.getSessionStorage('userInfo') || {}
    this.getPersonData(),

      document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  },
  mounted() {
    this.$store.commit("activeIndex", '06')
  }
}
</script>

<style lang='scss'>
.myCenter {
  height: 870px;
  padding: 40px 100px;
  text-align: left;

  .el-drawer__footer {
    text-align: center;
  }

  .editIcon {
    margin-left: 13px;
    vertical-align: middle;
    cursor: pointer;
  }

  .big-box {
    margin: 0 auto;
    text-align: left;

    .big-box-top {
      padding: 10px 280px 40px;
      border-bottom: 1px solid #f0f2f5;
      display: flex;
      align-items: center;

      .avatar {
        position: relative;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }

        &:hover .modal,
        .word {
          display: block;
        }

        &:hover .word {
          display: block;
        }

        .modal,
        .word {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }

        .modal {
          opacity: .5;

          background-color: #000000;
        }

        .word {
          color: #fff;
          text-align: center;
          font-size: 14px;
          padding-top: 28px;

          .icon-svg {
            width: 38px;
            height: 34px;
          }
        }
      }

      .info {
        width: 60%;
        padding-left: 30px;

        &>div:first-child {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 25px;
        }

        &>div:last-child {
          display: flex;
          align-items: center;
          font-size: 24px;

          .name {
            padding: 4px 8px;
            color: #f87243;
            background-color: #f0f2f5;
            border-radius: 4px;
            margin-right: 15px;
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
    }

    .big-box-bottom {
      .el-col {
        padding-top: 40px;

        div {
          margin-bottom: 15px;
          font-size: 14px;
          font-weight: 400;
          color: #636871;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          color: #000000;
        }
      }
    }
  }

  //修改密码
  .password {
    text-align: left;

    .el-form-item {
      margin-bottom: 27px;
    }

    .el-drawer.rtl.open {
      border-radius: 16px;
      padding: 0 20px;

      header {
        color: #000;
        margin-bottom: 20px;

        h4 {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }

    .icon-svg {
      width: 19px;
      height: 19px;
      vertical-align: middle;
      cursor: pointer;
    }


  }

  /*去掉切换时el-tab-pane底部的蓝色下划线*/
  .el-tabs__active-bar {
    width: 40px !important;
    margin-left: 23px;
  }

  /*去掉tabs底部的下划线*/
  .el-tabs__nav-wrap::after {
    position: static !important;

  }

  .el-tabs__nav-scroll {
    margin-bottom: 20px;

    .el-tabs__item {
      height: 45px;
    }
  }

  .el-tabs__item.is-top {
    padding: 0;
    margin-right: 40px;
    font-size: 16px;

    &.is-active {
      // background-image: url(@/assets/img/nav-active.png);
      background-repeat: no-repeat;
      background-position: bottom;
      border-bottom: none;
      font-size: 18px;
    }
  }

  .el-tabs {
    .second {
      .phone {
        font-size: 14px;
        margin-bottom: 34px;

        div {
          font-size: 22px;
          margin-top: 10px;
          font-weight: 500;
          font-family: DIN_Condensed_Bold;
        }
      }
    }

    .el-input-group__append {
      width: 90px;
      color: #000000;
      background-color: #f0f2f5;
      font-size: 12px;
      box-shadow: 0 0 0 0;

      &::before {
        content: '';
        position: relative;
        left: -30px;
        width: 1px;
        height: 20px;
        background-color: #D2D2D2;
      }
    }
  }
}

.uploadAvatarDialog {
  .el-dialog__body {
    padding: 30px 40px;
  }

  .el-upload-dragger {
    border: none;
    background: none;
  }

  .el-dialog__footer {
    text-align: center;
  }

  .cropperImg {
    display: flex;

    .cropper-content {
      .cropper {
        height: 238px;
      }

      .vue-cropper {
        border-radius: 16px;
      }
    }

    .editImg {
      padding-left: 20px;

      .el-input {
        margin-top: 12px;
      }

      >p {
        color: #F87243;
      }
    }
  }
}</style>
