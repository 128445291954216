
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import _ from 'lodash'
import './icons'
import './assets/css/index.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import componentsLib from './components/index'
import https, { fetch, post, adornUrl, del, put } from './utils/https'
import utils from './utils'
import calculation from './utils/calculation'
import VXETable from 'vxe-table'
// import 'vxe-table/lib/style.css'
import './assets/css/vxe-style.css'
import VueCropper from 'vue-cropper'
import 'vue-cropper/dist/index.css'


const app = createApp(App)
app.config.globalProperties.$_ = _
app.config.globalProperties.$http = https
app.config.globalProperties.$get = fetch
app.config.globalProperties.$post = post
app.config.globalProperties.$delete = del
app.config.globalProperties.$put = put
app.config.globalProperties.$adornUrl = adornUrl
app.config.globalProperties.$utils = utils.tools
app.config.globalProperties.$calc = calculation
app.config.globalProperties.$isAuth = utils.isAuth
app.config.globalProperties.$initSessionStorageData = utils.initSessionStorageData
app.config.globalProperties.$elFormErrorScrollIntoView = utils.elFormErrorScrollIntoView
// @ts-ignore
const filters = {}
Object.keys(utils.filters).forEach(key => {
  // @ts-ignore
  filters[key] = utils.filters[key]
})

app.config.globalProperties.$filters = filters

componentsLib.components.map(component => {
  // FIXME：为避免传递undefined给子组件，解决类型不匹配问题，确保有效的组件被注册
  if (component.name) {
    app.component(component.name, component)
  }

})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {

  app.component(key, component)
}



app.use(store)
app.use(router)
app.use(ElementUI, {
  locale: zhCn,
})
app.use(VXETable)
app.use(VueCropper)
app.mount('#app')
