<template>
  <el-drawer ref="drawerRef" v-model="dialog.show" :title="title" :before-close="handleClose" class="invoice-dialog">
    <el-form :model="dataFrom" v-loading="showloading" :rules="rules" ref="dataFrom" label-position="top">
      <el-row :gutter="30">
        <el-col :span="24">
          <el-form-item label="发票抬头" prop="invoiceHead">
            <el-input maxLength='50' placeholder="请输入发票抬头" v-model="dataFrom.invoiceHead"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="税号" prop="taxNumber">
            <el-input maxLength='30' placeholder="请输入税号" v-model="dataFrom.taxNumber"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="单位地址" prop="address">
            <el-input maxLength='200' placeholder="请输入单位地址" v-model="dataFrom.address"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="电话号码" prop="phone">
            <el-input type="number" placeholder="请输入电话号码" v-model="dataFrom.phone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="开户银行" prop="bank">
            <el-input maxLength='30' placeholder="请输入开户银行" v-model="dataFrom.bank"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="银行账户" prop="bankAccount">
            <el-input maxLength='30' placeholder="请输入银行账户" v-model="dataFrom.bankAccount"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handlerSave" :loading="isloading">确 定</el-button>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'ab-invoice',
  props: {
    dialog: {
      type: Object,
      default: () => {
        return {
          show: false
        }
      }
    }
  },
  data() {
    return {
      rules: {
        invoiceHead: [
          { required: true, message: '发票抬头不能为空', trigger: ['blur', 'change'] }
        ],
        taxNumber: [
          { required: true, message: '税号不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (/[\u4E00-\u9FA5]/g.test(value)) {
                callback(new Error('税号不能输入中文!'))
              } else {
                callback()
              }
            }, trigger: ['blur', 'change']
          }
        ],
        address: [
          { required: true, message: '单位地址不能为空', trigger: ['blur', 'change'] }
        ],
        phone: [
          { required: true, message: '电话号码不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value && value.length > 11) {
                callback(new Error('电话号码不能超过11位!'))
              } else {
                callback()
              }
            }, trigger: ['blur', 'change']
          }
        ],
        bank: [
          { required: true, message: '开户银行不能为空', trigger: ['blur', 'change'] }
        ],
        bankAccount: [
          { required: true, message: '银行账户不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (/[\u4E00-\u9FA5]/g.test(value)) {
                callback(new Error('银行账户不能输入中文!'))
              } else {
                callback()
              }
            }, trigger: ['blur', 'change']
          }
        ]
      },
      title: '',
      dataFrom: {}
    }
  },
  watch: {
    dialog: {
      handler: function (val, oldval) {
        if (val.id) {
          this.title = '编辑发票抬头'
          this.getDetailData(val.id)
        } else {
          this.title = '新增发票抬头'
        }
      },
      deep: true
    }
  },
  methods: {
    // 编辑发票抬头
    getDetailData(id) {
      this.$get('/invoiceHeader/info').then(({ data }) => {
        if (data && data.code === 'C0000') {
          this.dataFrom = data.result || {}
        }
      })
    },
    handlerSave() {
      this.$refs.dataFrom.validate((voids) => {
        if (voids) {
          this.btnLoading = true
          let url = '/invoiceHeader/add'
          if (this.dialog.id) {
            this.dataFrom.id = this.dialog.id
            url = '/invoiceHeader/mod'
          }
          this.$post(url, this.dataFrom).then(({ data }) => {
            if (data && data.code === 'C0000') {
              this.$message.success(data.message || '操作成功！')
              setTimeout(() => {
                this.handleClose()
              }, 1000)
            }
          }).finally(() => {
            this.btnLoading = false
          })
        }
      })
    },
    handleClose() {
      this.dialog.show = false
      this.$emit('updateInvoice')
    }
  }
}
</script>
<style lang="scss">
.invoice-dialog {
  padding: 0px 20px 0px 20px !important;

  .ai-input {
    .el-input-group__append {
      background-color: #0b7aff;
      color: #ffffff;
    }
  }

  .dialog-footer {
    position: fixed;
    bottom: 20px;
    text-align: center;
  }
}
</style>