<template>
    <span class="table-filter">
        
        <el-popover placement="bottom" trigger="click">
            <el-checkbox :indeterminate="isIndeterminate"  v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <el-checkbox-group
              v-model="checkBoxVal"
              class="fliterbox"
              :min="1"
              @change="filterColumns"
            >
              <el-checkbox
                v-for="column in columns"
                :label="column.labels"
                :key="column.labels"
              >{{column.labels}}</el-checkbox>
            </el-checkbox-group>
            <template #reference>
                <ab-svg name="list-filter"></ab-svg>
            </template>
        </el-popover>
    </span>
</template>
<script>
export default {
    data () {
        return {
            columns: [],
            checkBoxVal: [],
            checkAll: [],
            isIndeterminate: true

        }
    },
    props: {
        columnsOptions: {
            type: Array,
            default: () => {
                return []
            }
        },
        selected: {
            type: Array,
            default: () => {
                return []
            }
        },
        filterArry: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    computed: {
        // checkAll () {
        //     return this.checkBoxVal.length === this.columns.length
        // },
        // isIndeterminate () {
        //     return this.checkBoxVal.length > 0 && this.checkBoxVal.length < this.columns.length
        // },
    },
    watch: {
        selected (value) {
            this.checkBoxVal = value
        }
    },
    mounted () {
        this.columns = this.columnsOptions
        this.checkBoxVal = this.columnsOptions.map(e => {return e.labels})
        if (this.filterArry && this.filterArry.length) {
            this.filterArry.map(e => {
                this.checkBoxVal = this.checkBoxVal.filter(item => {return e != item})
            })
        }
        this.filterColumns()
    },
    methods: {
        popoverHide () {

        },
        handleCheckAllChange (bool) {
            if (bool) {
                this.checkBoxVal = this.columns.map(e => {return e.labels})
            } else {
                this.checkBoxVal = [this.columns.slice(0, 1)[0].labels]
            }
            this.filterColumns()
        },
        filterColumns () {

            this.$emit('filterColumns', this.columns.filter(i => this.checkBoxVal.includes(i.labels)))
        },
        searchForm () {
            this.$emit('searchForm')
        },
    }
}
</script>

<style lang="scss">
.table-filter {
    float: right;
    margin-right: 10px;
    .icon-svg {
      width: 17px;
      height: 15px;
      vertical-align: text-bottom;
      margin-left: 20px;
      cursor: pointer;
    }
  }
</style>